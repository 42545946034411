<div class="pl-checklist-item">
  <div class="header">
    <span class="check-icon">
      <pl-input-checkbox
        [disabled]="saving"
        (onChange)="itemChange()"
        [(model)]="isChecked"
      >
      </pl-input-checkbox>
    </span>
    <h5 class="title">{{ item.title }}</h5>
    <span class="status-chip" [ngClass]="{ complete: isItemCompleted(item) }">
      {{ item.status }}
    </span>
    <span class="toggle-expand" (click)="toggleCollpase()">
      <pl-icon
        [width]="16"
        [height]="16"
        [svg]="item.collapsed ? 'chevron-down' : 'chevron-up'"
        class="gray-darker"
        [verticalAlign]="'middle'"
      ></pl-icon>
    </span>
  </div>
  <div class="body" [ngClass]="{ expanded: !item.collapsed }">
    <p class="description" [innerHtml]="item.description"></p>
    <div class="info">
      <span class="info-link">
        <a *ngIf="item?.fn" class="info-link-button" (click)="item.fn()">
          {{ item.linkText }}
        </a>
        <a
          *ngIf="item?.link"
          class="info-link-button"
          [href]="item.link"
          target="_blank"
        >
          {{ item.linkText }}
        </a>
        <pl-icon
          [width]="16"
          [height]="16"
          [svg]="'info'"
          *ngIf="item.infoDescription"
          class="blue-medium info-link-icon"
          (click)="toggleInfo()"
        ></pl-icon>
      </span>

      <div
        class="info-box"
        [innerHtml]="item.infoDescription"
        [ngClass]="{ expanded: !infoCollapsed }"
      ></div>
    </div>
  </div>
</div>
