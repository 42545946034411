export enum PLClinicalServiceTypeCode {
  RI = 'ri',
  PT = 'pt',
  APE = 'ape',
  GROUPBMH = 'groupbmh',
  PA = 'pa',
  SPED = 'sped',
  BMH = 'bmh',
  OT = 'ot',
  SLT = 'slt',
}

export enum PLClinicalServiceTypeShortName {
  RI = 'RI',
  PT = 'PT',
  APE = 'APE',
  GROUPBMH = 'GROUPBMH',
  PA = 'PA',
  SPED = 'SPED',
  BMH = 'BMH',
  OT = 'OT',
  SLT = 'SLT',
}

export enum PLClinicalServiceTypeLongName {
  RI = 'Reading Instruction',
  PT = 'Physical Therapy',
  APE = 'Adapted Physical Education',
  GROUPBMH = 'Group BMH Program',
  PA = 'Psychoeducational Assessments',
  SPED = 'SPED Instruction',
  BMH = 'Behavioral and Mental Health',
  BMH_SVCS = 'Behavioral and Mental Health Services',
  BHM_ASSESSMENT = 'Behavioral and Mental Health Assessments',
  BMH_CONSULTATION_SVCS = 'Behavioral and Mental Health Consultation Services',
  BMH_THERAPY = 'Behavioral & Mental Health Therapy',
  OT = 'Occupational Therapy',
  OT_SV = 'Occupational Therapy Supervision',
  OT_ASSESSMENT = 'Occupational Therapy Assessments',
  SLT = 'Speech-Language Therapy',
  SLT_SV = 'Speech-Language Therapy Supervision',
  SLT_ASSESSMENT = 'Speech-Language Assessments',
  SPDH = 'School Psychologist Dedicated Hours',
}

export const clinicalServiceTypeCodeToShortName: Record<
  PLClinicalServiceTypeCode,
  PLClinicalServiceTypeShortName
> = {
  [PLClinicalServiceTypeCode.RI]: PLClinicalServiceTypeShortName.RI,
  [PLClinicalServiceTypeCode.PT]: PLClinicalServiceTypeShortName.PT,
  [PLClinicalServiceTypeCode.APE]: PLClinicalServiceTypeShortName.APE,
  [PLClinicalServiceTypeCode.GROUPBMH]: PLClinicalServiceTypeShortName.GROUPBMH,
  [PLClinicalServiceTypeCode.PA]: PLClinicalServiceTypeShortName.PA,
  [PLClinicalServiceTypeCode.SPED]: PLClinicalServiceTypeShortName.SPED,
  [PLClinicalServiceTypeCode.BMH]: PLClinicalServiceTypeShortName.BMH,
  [PLClinicalServiceTypeCode.OT]: PLClinicalServiceTypeShortName.OT,
  [PLClinicalServiceTypeCode.SLT]: PLClinicalServiceTypeShortName.SLT,
};

export const clinicalServiceTypeCodeToLongName: Record<
  PLClinicalServiceTypeCode,
  PLClinicalServiceTypeLongName
> = {
  [PLClinicalServiceTypeCode.RI]: PLClinicalServiceTypeLongName.RI,
  [PLClinicalServiceTypeCode.PT]: PLClinicalServiceTypeLongName.PT,
  [PLClinicalServiceTypeCode.APE]: PLClinicalServiceTypeLongName.APE,
  [PLClinicalServiceTypeCode.GROUPBMH]: PLClinicalServiceTypeLongName.GROUPBMH,
  [PLClinicalServiceTypeCode.PA]: PLClinicalServiceTypeLongName.PA,
  [PLClinicalServiceTypeCode.SPED]: PLClinicalServiceTypeLongName.SPED,
  [PLClinicalServiceTypeCode.BMH]: PLClinicalServiceTypeLongName.BMH,
  [PLClinicalServiceTypeCode.OT]: PLClinicalServiceTypeLongName.OT,
  [PLClinicalServiceTypeCode.SLT]: PLClinicalServiceTypeLongName.SLT,
};
