<div class="pl-onboarding-tasks">
  <div *ngIf="v2OnboardingTasks">
    <pl-provider-onboarding-v2
      [currentUser]="currentUser"
      [tasks]="tasksV2Onboarding"
    ></pl-provider-onboarding-v2>
  </div>
  <div
    class="tasks grid-item"
    *ngIf="
      tasksOnboarding.length > 0 &&
      currentUser.xProvider &&
      currentUser.xProvider.providerSubStatus === 'Onboarding'
    "
  >
    <span class="section-header">
      <span>Onboarding Checklist</span>
    </span>
    <div *ngIf="!tasksOnboardingAllComplete" class="padding padding-large-lr">
      Thanks for updating your information! While we verify a few things on our
      end, you can get started on the next required onboarding tasks.
    </div>
    <div
      *ngIf="tasksOnboardingAllComplete"
      class="onboarding-tasks-complete margin-b padding-large"
    >
      Woohoo! You did it! Now take a look around our platform and practice using
      our tools so you’re comfortable, confident, and ready to serve your kids
      when you get your first assignment! Your Empowerment Manager will be
      following up with you to make sure you're comfortable navigating the
      Presence Platform and Therapy Room.
    </div>
    <div class="card">
      <div
        *ngFor="let task of tasksOnboarding"
        class="task-onboarding-item flexbox"
      >
        <div class="task-onboarding-icon">
          <pl-icon
            *ngIf="task.xIsComplete"
            class="green pointer"
            [svg]="'check-circle'"
            [scale]="1"
            (click)="tasksOnboardingToggleHidden(task)"
          ></pl-icon>
          <div *ngIf="!task.xIsComplete">
            <pl-input-checkbox
              [(model)]="tasksOnboardingCheckboxVals[task.taskType.code]"
              (click)="tasksOnboardingCompleteTask(task)"
            ></pl-input-checkbox>
          </div>
        </div>

        <ng-container
          *ngTemplateOutlet="
            orientation;
            context: {
              showTask: task.taskType.code === ONBOARDING_TASK.orientation,
              showDetails: !task.xHidden
            }
          "
        >
        </ng-container>

        <ng-container
          *ngTemplateOutlet="
            nationalProviderIdentifier;
            context: {
              showTask: task.taskType.code === ONBOARDING_TASK.npin,
              showDetails: !task.xHidden
            }
          "
        >
        </ng-container>

        <ng-container
          *ngTemplateOutlet="
            taxInfo;
            context: {
              showTask: task.taskType.code === ONBOARDING_TASK.w9Tax,
              showDetails: !task.xHidden
            }
          "
        >
        </ng-container>

        <ng-container
          *ngTemplateOutlet="
            liabilityInsurance;
            context: {
              showTask:
                task.taskType.code === ONBOARDING_TASK.liabilityInsurance,
              showDetails: !task.xHidden
            }
          "
        >
        </ng-container>

        <ng-container
          *ngTemplateOutlet="
            driverLicense;
            context: {
              showTask: task.taskType.code === ONBOARDING_TASK.driverLicense,
              showDetails: !task.xHidden
            }
          "
        >
        </ng-container>

        <ng-container
          *ngTemplateOutlet="
            providerLounge;
            context: {
              showTask: task.taskType.code === ONBOARDING_TASK.lounge,
              showDetails: !task.xHidden
            }
          "
        >
        </ng-container>

        <ng-container
          *ngTemplateOutlet="
            telehealthInstitute;
            context: {
              showTask: task.taskType.code === ONBOARDING_TASK.telehealth,
              showDetails: !task.xHidden
            }
          "
        >
        </ng-container>

        <ng-container
          *ngTemplateOutlet="
            assignmentReadinessCheck;
            context: {
              showTask: task.taskType.code === ONBOARDING_TASK.arc,
              showDetails: !task.xHidden,
              tasks: arcTasks
            }
          "
        >
        </ng-container>

        <ng-container
          *ngTemplateOutlet="
            documentationAndBilling;
            context: {
              showTask: task.taskType.code === ONBOARDING_TASK.docAndBilling,
              showDetails: !task.xHidden
            }
          "
        >
        </ng-container>

        <ng-container
          *ngTemplateOutlet="
            demographicSurvey;
            context: {
              showTask:
                task.taskType.code === ONBOARDING_TASK.demographicSurvey,
              showDetails: !task.xHidden
            }
          "
        >
        </ng-container>
      </div>
    </div>
  </div>

  <div
    class="tasks grid-item"
    *ngIf="
      !currentUser.xProvider ||
      currentUser.xProvider.providerSubStatus !== 'Onboarding'
    "
  >
    <span class="section-header">
      <span>My Tasks</span>
      <span class="subheader" *ngIf="!loadingTasks"
        >({{ tasks.length }} total<span *ngIf="newCount > 0">
          / {{ newCount }} new</span
        >)</span
      >
      <mat-icon
        class="blue-medium pointer"
        svgIcon="info"
        [style.height]="'18px'"
        [style.width]="'18px'"
        (click)="toggleShowTips('tasks')"
      ></mat-icon>
      <div [hidden]="!showTips.tasks" class="tip">
        Tasks will help keep you organized. As you complete tasks, they will
        disappear from this list.
      </div>
    </span>
    <div class="card">
      <div class="no-tasks" *ngIf="tasks.length <= 0 && !loadingTasks">
        <pl-icon
          class="blue-medium"
          [svg]="'mr-clipboard'"
          [height]="160"
          [width]="160"
        ></pl-icon>
        <span class="padding-tb" *ngIf="!loadingTasks"
          >All done for the day!</span
        >
      </div>
      <ul class="task-list">
        <li class="task placeholder" *ngIf="loadingTasks">
          <div class="task-container loading severity3">
            <span class="icon"></span>
            <span class="description">&nbsp;</span>
          </div>
        </li>
        <li class="task placeholder" *ngIf="loadingTasks">
          <div class="task-container loading severity3">
            <span class="icon"></span>
            <span class="description">&nbsp;</span>
          </div>
        </li>
        <li class="task placeholder" *ngIf="loadingTasks">
          <div class="task-container loading severity3">
            <span class="icon"></span>
            <span class="description">&nbsp;</span>
          </div>
        </li>
        <li class="task" *ngFor="let task of tasks">
          <span
            (click)="handleTask(task)"
            class="task-container severity{{ task.severity }}"
          >
            <div class="icon">
              <pl-icon
                *ngIf="task.severity === 1"
                class="white"
                [svg]="'alert'"
                [scale]="1"
              ></pl-icon>
              <pl-icon
                *ngIf="task.severity === 2"
                class="white"
                [svg]="'caution'"
                [scale]="1"
              ></pl-icon>
              <pl-icon
                *ngIf="task.severity === 3"
                class="white"
                [svg]="'info'"
                [height]="18"
                [width]="18"
              >
              </pl-icon>
            </div>
            <span class="description">
              <span class="message" [innerHtml]="task.message"></span>
              <span class="new">
                <pl-icon
                  *ngIf="!task.isRead"
                  class="gray"
                  [svg]="'new'"
                  [scale]="1"
                ></pl-icon>
              </span>
            </span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- Onboarding Tasks Below Wrapped in Templates -->

<ng-template #orientation let-showTask="showTask" let-showDetails="showDetails">
  <div class="flex1" *ngIf="showTask">
    <div class="margin-small-b">
      <h4>Complete Orientation Course</h4>
    </div>
    <div *ngIf="showDetails">
      <div class="margin-small-b">
        <b>Start here!</b> Learn more about Presence ahead of your Welcome Call
        by completing the orientation course.
      </div>
      <div class="margin-small-b">
        <a target="_blank" href="{{ learnUponDashboardUrl }}"
          ><b>Complete Orientation Course</b>
        </a>
      </div>
      <div>
        You can expect a welcome call from your Empowerment Manager to help kick
        things off. In the meantime, please continue with completing the
        onboarding courses in Telehealth Institute.
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #telehealthInstitute
  let-showTask="showTask"
  let-showDetails="showDetails"
>
  <div class="flex1" *ngIf="showTask">
    <div class="margin-small-b">
      <h4>Complete Onboarding Courses in Telehealth Institute</h4>
    </div>
    <div *ngIf="showDetails">
      <div class="margin-small-b">
        The Telehealth Institute includes a variety of courses to help you excel
        as a teletherapist using the Presence platform.
      </div>
      <div class="margin-small-b">
        <a target="_blank" href="{{ learnUponDashboardUrl }}"
          ><b>Complete Telehealth Institute Courses</b>
        </a>
      </div>
      <div class="margin-small-b">
        <a target="_blank" [href]="telehealthLink">
          Read Help Center Article on Telehealth Institute
        </a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #assignmentReadinessCheck
  let-showTask="showTask"
  let-showDetails="showDetails"
  let-tasks="tasks"
>
  <div class="flex1" *ngIf="showTask">
    <div class="margin-small-b">
      <h4>Complete Your Assignment Readiness Check (ARC)</h4>
    </div>
    <div *ngIf="showDetails">
      <div class="margin-b">
        <div class="margin-b">
          {{
            isCurrentUserPsychoEducationalAssessor
              ? 'To ensure you are ready to get started serving students using the Presence platform, please submit a recent redacted report demonstrating the quality of your psycho-educational evaluation report writing skills. Then, you can complete your Assignment Readiness Check (ARC) by clicking on the link below.'
              : 'To ensure you are ready to get started serving students using the Presence platform, please complete your Assignment Readiness Check (ARC) by clicking on the link below.'
          }}
        </div>

        <div class="margin-b">
          <i>
            ** You must complete the required Telehealth Institute courses
            before you can take your ARC. The ARC will be available in your list
            of courses after you complete all the onboarding courses in the
            Telehealth Institute. You will need to achieve an 80% or higher to
            pass this assessment and will be given up to 3 attempts to
            successfully complete the ARC if needed. **
          </i>
        </div>

        <div class="margin-large-l">
          <ol>
            <li *ngFor="let task of tasks">
              <a target="_blank" [href]="task.href">
                {{ task.label }}
              </a>
            </li>
          </ol>
        </div>
      </div>
      <div class="margin-small-b">
        After completing, please be on the lookout for communication from your
        Empowerment Manager with next steps.
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #nationalProviderIdentifier
  let-showTask="showTask"
  let-showDetails="showDetails"
>
  <div class="flex1" *ngIf="showTask">
    <div class="margin-small-b">
      <h4>Submit Your National Provider Identifier Number</h4>
    </div>
    <div *ngIf="showDetails">
      <div class="margin-small-b">
        The National Provider Identifier (NPI) number is a HIPAA requirement
        which allows our school partners to accurately report and bill for
        services. Presence needs to collect NPI numbers for all providers from
        all disciplines: occupational therapists, speech language therapists,
        and mental and behavioral health professionals.
      </div>
      <div class="margin-small-b">
        <a target="_blank" href="https://nppes.cms.hhs.gov/"
          >Apply for a New NPI number</a
        >
      </div>
      <div class="margin-small-b">
        <a
          target="_blank"
          [href]="'https://www.tfaforms.com/4808819?tfa_4=' + salesforceId"
        >
          <b>Submit Your NPI number</b>
        </a>
      </div>
      <div class="margin-small-b">
        <a target="_blank" [href]="npiLink"
          >Read About the NPI Number in the Help Center</a
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template #taxInfo let-showTask="showTask" let-showDetails="showDetails">
  <div class="flex1" *ngIf="showTask">
    <div class="margin-small-b">
      <h4>Submit Tax Information</h4>
    </div>
    <div *ngIf="showDetails">
      <div class="margin-small-b">
        If you legally reside in California, New York, Massachusetts, Oregon,
        Illinois, or Utah, you must provide proof of being incorporated (Inc.),
        operating as a limited liability company (LLC), or have an employer
        identification number (EIN). This information needs to be submitted
        along with a completed W-9 tax form. Remember to use your
        <b>full legal name</b> and / or <b>business name</b> along with your
        <b>Social Security Number</b> or <b>Employer Identification Number</b> -
        whichever you use to file your taxes.
      </div>
      <div class="margin-small-b">
        <a target="_blank" href="https://www.irs.gov/pub/irs-pdf/fw9.pdf"
          >Download W-9 Form from the IRS Website</a
        >
      </div>
      <div class="margin-small-b">
        <a
          target="_blank"
          [href]="
            'https://www.tfaforms.com/4808978?tfa_4=' +
            salesforceId +
            '&tfa_53=' +
            currentUser.xProvider.billing_state +
            '&tfa_105=' +
            fullName
          "
        >
          <b>Submit Your Tax Information</b>
        </a>
      </div>
      <div class="margin-small-b">
        <a target="_blank" [href]="taxLink"
          >Read Tax Information in the Help Center</a
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #liabilityInsurance
  let-showTask="showTask"
  let-showDetails="showDetails"
>
  <div class="flex1" *ngIf="showTask">
    <div class="margin-small-b">
      <h4>Submit Proof of Liability Insurance</h4>
    </div>
    <div *ngIf="showDetails">
      <div class="margin-small-b">
        In order to provide services on behalf of Presence, all contractors must
        maintain active professional liability insurance that meets the
        requirements listed below.
      </div>
      <div class="margin-small-b">
        <a
          target="_blank"
          [href]="'https://www.tfaforms.com/4808817?tfa_3=' + salesforceId"
          ><b>Submit Your Proof of Liability Insurance</b></a
        >
      </div>
      <div class="margin-small-b">
        <a target="_blank" [href]="liabilityInsuranceLink"
          >Read About Liability Insurance Requirements in the Help Center</a
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #driverLicense
  let-showTask="showTask"
  let-showDetails="showDetails"
>
  <div class="flex1" *ngIf="showTask">
    <div class="margin-small-b">
      <h4>Submit Your Driver's License</h4>
    </div>
    <div *ngIf="showDetails">
      <div class="margin-small-b">
        Presence maintains a copy of your driver's license to assist with
        identity verification and background checks.
      </div>
      <div class="margin-small-b">
        <a
          target="_blank"
          [href]="'https://www.tfaforms.com/4808957?tfa_3=' + salesforceId"
          ><b>Submit Your Driver’s License</b></a
        >
      </div>
      <div class="margin-small-b">
        <a target="_blank" [href]="driverLicenseLink"
          >Read About the Driver’s License Requirement in the Help Center</a
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #providerLounge
  let-showTask="showTask"
  let-showDetails="showDetails"
>
  <div class="flex1" *ngIf="showTask">
    <div class="margin-small-b">
      <h4>Visit the Provider Lounge</h4>
    </div>
    <div *ngIf="showDetails">
      <div class="margin-small-b">
        Log into the Lounge to connect with other Presence Care Network
        Providers. Access this online community to help you on your teletherapy
        journey.
      </div>
      <div class="margin-small-b">
        <a target="_blank" href="https://www.presencelearning.com/lounge/"
          ><b>Visit the Provider Lounge</b></a
        >
      </div>
      <div class="margin-small-b">
        <a target="_blank" [href]="loungeLink"
          >Read Help Center Article on Community Guidelines for the Lounge</a
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #documentationAndBilling
  let-showTask="showTask"
  let-showDetails="showDetails"
>
  <div class="flex1" *ngIf="showTask">
    <div class="margin-small-b">
      <h4>
        Prepare for Scheduling, Documenting, and Submitting Your Invoice in the
        Presence Platform
      </h4>
    </div>
    <div *ngIf="showDetails">
      <div class="margin-b">
        Learn the ropes and expectations so you’re fully prepared to service
        students, document, and submit your invoices on time to get paid.
      </div>
      <div class="margin-small-b">
        Below you’ll find a curated list of the most popular and useful Help
        Center articles.
      </div>
      <div class="margin-small-b">
        <a target="_blank" [href]="gettingStartedLink">
          <b> Getting Started in the Help Center </b>
        </a>
      </div>
      <div>
        Your Empowerment Manager will be in touch with next steps towards
        servicing students and once you have an assigned caseload, you can
        access the
        <a target="_blank" [href]="organizationHandbookLink">
          Organization Handbook
        </a>
        to learn about the organization’s policies.
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #demographicSurvey
  let-showTask="showTask"
  let-showDetails="showDetails"
>
  <div class="flex1" *ngIf="showTask">
    <div class="margin-small-b">
      <h4>Complete Demographic Survey (Optional)</h4>
    </div>
    <div *ngIf="showDetails">
      <div class="margin-b">
        Presence invites you to participate in a voluntary demographic survey.
        All questions are optional. Submission of this information is voluntary
        and you will not be penalized if you choose not to participate. The
        information will be kept confidential and only used for internal
        purposes. Should you choose not to participate, please mark this task as
        complete.
      </div>
      <div class="margin-small-b">
        <a
          target="_blank"
          [href]="'https://www.tfaforms.com/4990483?tfa_882=' + salesforceId"
        >
          <b>Complete Survey Now</b>
        </a>
      </div>
    </div>
  </div>
</ng-template>
