import { InvoiceErrors } from '../models';

export function getInvoiceErrorCount(errors: InvoiceErrors) {
  if (!errors) {
    return 0;
  }

  let appointmentsErrorsCount = 0;

  const { appointments_without_records, unsigned_records_exist } = errors;

  if (appointments_without_records) {
    appointmentsErrorsCount +=
      appointments_without_records.appointments_without_records_count;
  }

  if (unsigned_records_exist) {
    appointmentsErrorsCount +=
      errors.unsigned_records_exist.unsigned_records_count;
  }

  return appointmentsErrorsCount;
}
