import { Component } from '@angular/core';

import { PLTableFrameworkService } from './pl-table-framework.service';

@Component({
  selector: 'pl-table-header',
  templateUrl: './pl-table-header.component.html',
  styleUrls: ['./pl-table-header.component.less'],
  inputs: [],
})
export class PLTableHeaderComponent {}
