import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pl-header',
  templateUrl: 'pl-header.component.html',
  styleUrls: ['pl-header.component.less'],
})
export class PLHeaderComponent {
  @Input() iconName: string;
  @Input() closable: boolean = false;
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  constructor() {}

  close() {
    this.onClose.emit();
  }
}
