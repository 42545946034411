import { Component, EventEmitter, Output, Input } from '@angular/core';
import {
  REFERRAL_REASON,
  REFERRAL_REASON_LABEL,
} from '@root/src/app/common/constants';
import { PLReferral } from '@root/src/app/common/interfaces/pl-referral';
import { PLReferralsService } from '../pl-referrals.service';

@Component({
  selector: 'pl-client-referral-unmatch',
  templateUrl: './pl-client-referral-unmatch.component.html',
  styleUrls: ['./pl-client-referral-unmatch.component.less'],
})
export class PlClientReferralUnmatchComponent {
  @Input() referral: PLReferral;
  @Input() action: string;

  @Output() readonly unmatch: EventEmitter<any> = new EventEmitter();
  @Output() readonly cancel: EventEmitter<any> = new EventEmitter();

  declineReason = [
    { value: '', label: '--' },
    {
      value: REFERRAL_REASON.CLINICAL_REASON,
      label: REFERRAL_REASON_LABEL.CLINICAL_REASON,
    },
    {
      value: REFERRAL_REASON.DUPLICATE_REFERRAL,
      label: REFERRAL_REASON_LABEL.DUPLICATE_REFERRAL,
    },
    {
      value: REFERRAL_REASON.DUPLICATE_OF_SERVICE_IN_PROGRESS,
      label: REFERRAL_REASON_LABEL.DUPLICATE_OF_SERVICE_IN_PROGRESS,
    },
    { value: REFERRAL_REASON.GROUPING, label: REFERRAL_REASON_LABEL.GROUPING },
    {
      value: REFERRAL_REASON.INCORRECT_REFERRAL,
      label: REFERRAL_REASON_LABEL.INCORRECT_REFERRAL,
    },
    { value: REFERRAL_REASON.LANGUAGE, label: REFERRAL_REASON_LABEL.LANGUAGE },
    {
      value: REFERRAL_REASON.SCHEDULING_CONFLICT,
      label: REFERRAL_REASON_LABEL.SCHEDULING_CONFLICT,
    },
  ];

  isUnmatching = false;
  unmatchingReasonId = '';

  constructor(private plReferralsService: PLReferralsService) {}

  onCancel() {
    this.cancel.emit();
  }

  /**
   * For unmatching first we need to unmatch and then get the referrals.
   * If unmatch is successful; then get the referrals for updating the buttons of each row
   *
   * @param referral PLReferral object
   * @param action Two buttons call this function, the action tells who is calling
   */
  onUnmatchReferral(): void {
    this.isUnmatching = true;

    const params = {
      referralId: this.referral.id,
      reasonToUnmatch: this.unmatchingReasonId,
    };
    const errorMsg = 'There was an error while trying to perform this action';
    const successMsg =
      this.action === 'Unmatch'
        ? 'Referral successfully unmatched'
        : 'Proposal successfuly undone';

    this.plReferralsService.unmatchReferral(params).subscribe({
      next: unmatched => {
        if (!unmatched.unmatchReferral.errors) {
          this.unmatch.emit({
            result: 'success',
            msgTitle: 'Confirmed',
            msg: successMsg,
          });
        } else {
          this.unmatch.emit({
            result: 'fail',
            msgTitle: unmatched.unmatchReferral.errors.message || errorMsg,
            msg: `Unable to ${this.action}`,
          });
        }
      },
      error: () =>
        this.unmatch.emit({
          result: 'fail',
          msgTitle: errorMsg,
          msg: `Unable to ${this.action}`,
        }),
    });
  }
}
