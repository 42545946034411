import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { Option } from '@common/interfaces';
import { PLAddReferralsDataTableService } from '@modules/add-referrals/pl-add-referrals-table-data.service';
import { PLBaseStepComponent } from '../common/pl-base-step.component';
import { PLAddReferralsLocationYearService } from '../pl-add-referrals-location-year.service';
import { PLAddReferralsNavigationService } from '../pl-add-referrals-navigation.service';

@Component({
  selector: 'pl-location-matching',
  templateUrl: './pl-location-matching.component.html',
  styleUrls: [
    './pl-location-matching.component.less',
    '../pl-add-referrals.component.less',
  ],
})
export class PlLocationMatchingComponent
  extends PLBaseStepComponent
  implements OnInit
{
  model: any = {};

  canNext: boolean = false;

  uploadedLocations: any[];
  matchedLocationsOpts: Option[] = [];
  matchedLocations = {};
  locationOpts: Option[] = [];
  unmatchedLocationsOpts: Option[] = [];
  unmatchedLocations = {};

  locationColumnLabel: string = 'Location';

  skipLocationOpt: Option = {
    label: 'Do not import / Skip',
    value: 'skip',
  };

  constructor(
    private pLAddReferralsNavigationService: PLAddReferralsNavigationService,
    private locationService: PLAddReferralsLocationYearService,
    private tableDataService: PLAddReferralsDataTableService,
  ) {
    super();
  }

  ngOnInit() {
    this.locationService.currentSchoolYear$.subscribe(
      (currentSchoolYear: any) => {
        if (currentSchoolYear && currentSchoolYear.code) {
          this.model.selectedSchoolYearCode = currentSchoolYear.code;
        }
      },
    );

    this.setupNavigationActions();

    this.uploadedLocations = this.tableDataService.getColumnDataByLabel(
      this.locationColumnLabel,
      true,
      true,
    );

    this.locationOpts = [];

    this.locationService
      .getLocationsLoaded()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result: any) => {
        this.locationOpts = result.locationOpts ? result.locationOpts : [];

        this.locationOpts = this.locationOpts.sort();

        if (
          !this.locationOpts.some(
            opt => opt.label === this.skipLocationOpt.label,
          )
        ) {
          this.locationOpts.unshift(this.skipLocationOpt);
        }

        // match locations and sort into matched and unmatched based off uploaded locations in the uploaded template
        // for each uploaded location, check if it matches a locationOpt exactly.
        // if it does, and it's not already in matchedLocations, then add it to matchedLocations
        // if it doesn't, and it's not already in unmatchedLocations, then add it to unmatchedLocations
        this.uploadedLocations.forEach(uploadedLocation => {
          let matchingLocationOpt = this.locationOpts.find(
            location => location.label === uploadedLocation.uploadedValue,
          );

          if (
            matchingLocationOpt &&
            !(uploadedLocation.uploadedValue in this.matchedLocations)
          ) {
            this.matchedLocationsOpts.push(matchingLocationOpt);
            this.matchedLocations[uploadedLocation.uploadedValue] = {
              ...uploadedLocation,
              matchedLocation: matchingLocationOpt.value,
            };
          }

          if (
            !matchingLocationOpt &&
            !(uploadedLocation.uploadedValue in this.unmatchedLocations)
          ) {
            this.unmatchedLocationsOpts.push({
              label: uploadedLocation.uploadedValue,
              value: uploadedLocation.uploadedValue,
            });
            this.unmatchedLocations[uploadedLocation.uploadedValue] = {
              ...uploadedLocation,
              matchedLocation: null,
            };
          }
        });

        this.checkValidity();
      });
  }

  setupNavigationActions() {
    this.pLAddReferralsNavigationService.navigateRequested$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(stepIndex => {
        if (this.canNext) {
          this.tableDataService.locationsMapping = {
            ...this.matchedLocations,
            ...this.unmatchedLocations,
          };
          this.pLAddReferralsNavigationService.confirmNavigate(stepIndex);
        } else {
          this.pLAddReferralsNavigationService.disableCancelButton();
        }
      });

    this.pLAddReferralsNavigationService.cancelRequested$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.pLAddReferralsNavigationService.confirmCancel());

    this.pLAddReferralsNavigationService.finishRequested$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.pLAddReferralsNavigationService.confirmFinish());
  }

  checkValidity() {
    // check if all unmatched locations have a matched location
    this.canNext =
      Object.values(this.unmatchedLocations).every(
        unmatchedLocation => unmatchedLocation['matchedLocation'] !== null,
      ) &&
      Object.values(this.matchedLocations).every(
        matchedLocation => matchedLocation['matchedLocation'] !== null,
      );

    if (this.canNext) this.pLAddReferralsNavigationService.enableNextButton();
    else this.pLAddReferralsNavigationService.disableNextButton();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.pLAddReferralsNavigationService.enableNextButton();
  }
}
