<div class="pl-customer-onboarding">
  <div class="welcome">
    <p class="title">Welcome</p>
    <p class="body" [innerHtml]="customerOnboardingData?.welcomeText"></p>
  </div>
  <div
    [hidden]="hideChecklists"
    *ngFor="let checklist of customerOnboardingData?.checklists"
    class="section"
  >
    <pl-checklist
      [checklist]="checklist"
      (onItemStatusChange)="onItemStatusChange($event)"
    >
    </pl-checklist>
  </div>
  <span [hidden]="hideChecklists" class="need-help">
    Need help? Contact our Support Services team at
    <a href="mailto:asksupport@presencelearning.com"
      >asksupport@presencelearning.com</a
    >
  </span>
</div>
