<ng-container
  *ngIf="organizationName === ''; else providerHasOnlyOneorganizationTemplate"
>
  <mat-select
    class="text"
    [(ngModel)]="selected"
    (ngModelChange)="selectionChanged()"
  >
    <mat-option *ngFor="let option of options$ | async" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select>
</ng-container>
<ng-template #providerHasOnlyOneorganizationTemplate>
  <div class="text">{{ organizationName }}</div>
</ng-template>
