import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PLUtilService } from './pl-util.service';
import { User } from '../../modules/user/user.model';
import { PLProviderType } from '@modules/schedule/models';
import {
  PLGQLProviderTypesService,
  PLGraphQLService,
  PLMayService,
} from '@root/index';
import { PLUserGroupProviderTypeCode } from '../enums/pl-provider-types';

@Injectable()
export class PLProviderTypesService {
  // TODO: replace this subject with NGRX for handling provider types across state.
  providerTypesSubject = new BehaviorSubject([]);

  readonly ERR_MSG = {
    nonExistentProviderType:
      "The provider type for this user doesn't exist.Please contact support.",
    mapUserGroupWithProviderType:
      "The groups of this user couldn't be mapped to any provider type available",
  };

  constructor(
    private plMaySvc: PLMayService,
    private plUtilSvc: PLUtilService,
    private plGraphQL: PLGraphQLService,
    private plGQLProviderTypesSvc: PLGQLProviderTypesService,
  ) {}

  fetch(): Observable<{ providerTypes: PLProviderType[] }> {
    const variables: any = {
      first: 100,
    };
    return this.plGraphQL.query(
      `query ProviderTypes($first: Int) {
                providerTypes(first: $first) {
                    edges {
                        node {
                            id
                            code
                            longName
                            shortName
                            isActive
                        }
                    }
                }
            }`,
      variables,
      {},
    );
  }

  /**
   * Checks the provider type that the user belongs to based on its groups.
   * An array with the provider type codes that the user belongs to is returned.
   *
   * @param user The current user logged in.
   * @returns An array with the provider type(s) the user is or an empty array in case of error.
   */
  getProviderTypeFromUser(user: User): string[] {
    if (this.plMaySvc.isProvider(user) && user?.groups) {
      const providerTypeOptionCodes = user.groups
        .filter((userGroup: string) => PLUserGroupProviderTypeCode[userGroup])
        .map((userGroup: string) => PLUserGroupProviderTypeCode[userGroup]);

      if (!providerTypeOptionCodes?.length) {
        console.error(`Error:`, this.ERR_MSG.mapUserGroupWithProviderType);
        this.plUtilSvc.errorLog(this.ERR_MSG.nonExistentProviderType, {
          userGroups: user.groups,
          providerTypesAvailable: PLUserGroupProviderTypeCode,
        });

        return [];
      }

      if (providerTypeOptionCodes.length > 1) {
        providerTypeOptionCodes.sort();
      }

      return providerTypeOptionCodes;
    }

    console.error(
      `Error:`,
      `The user is not a provider or isn't assigned to any group.`,
    );
    return [];
  }

  /**
   * Triggers a call to GQL for getting and forming the provider types available in edu-clients.
   * The providers are formed with their long name.
   *
   * The formed providers are accessible from the behaviour subject `providerTypesSubject`.
   */
  formProviderTypeOptions(): void {
    this.plGQLProviderTypesSvc.get().subscribe(_ => {
      const providerTypes = this.plGQLProviderTypesSvc.formOpts(null, {
        labelKey: 'longName',
      });
      this.providerTypesSubject.next(providerTypes);
    });
  }
}
