import { OnInit, OnDestroy , Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { User } from '@modules/user/user.model';

import { AppStore } from '@root/src/app/appstore.model';
import { selectCurrentUser } from '@root/src/app/common/store';

@Component({
  template: '',
})
export abstract class PLBaseStepComponent implements OnDestroy {
  currentUser: User;
  destroyed$ = new Subject<boolean>();

  constructor(private store: Store<AppStore> = null) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  abstract setupNavigationActions(): void;

  getCurrentUser() {
    if (!this.store) {
      throw new Error('Store is missing in the constructor');
    }

    return this.store.select(selectCurrentUser).pipe(
      takeUntil(this.destroyed$),
      tap(user => (this.currentUser = user)),
    );
  }
}
