import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, first, pluck } from 'rxjs/operators';

import { PLProviderProfile, PLGridQueryParams } from '@common/interfaces';
import { PLGraphQLService, PLHttpService } from '@root/index';
import { Option } from '@root/src/lib-components/common/interfaces';
import { PLProviderAssignmentTypes } from '../enums';

const providerProfilesQuery = require('./queries/provider-profiles.graphql');

type ProviderProfileResults = {
  providers: PLProviderProfile[];
  totalCount: number;
};

enum NotificationPreference {
  email = 'EMAIL',
  sms = 'SMS',
  push = 'PUSH',
}

interface ProvidersQueryParams extends PLGridQueryParams {
  lastName_Icontains?: string;
  firstName_Icontains?: string;
  locationId?: string;
  organizationId?: string;
}

@Injectable()
export class PLProviderProfileService {
  private limit: number;

  notificationPreferencesOptions: Option[] = [
    {
      value: 'EMAIL',
      label: 'Email',
    },
    {
      value: 'SMS',
      label: 'SMS',
    },
    {
      value: 'PUSH',
      label: 'Push Notifications',
    },
  ];

  constructor(
    private plGraphQL: PLGraphQLService,
    private plHttp: PLHttpService,
  ) {
    this.limit = 100;
  }

  // TODO: A Dinamic Query can be done
  getProviderProfiles(
    params: ProvidersQueryParams,
  ): Observable<ProviderProfileResults> {
    const _params: ProvidersQueryParams = Object.assign({}, params, {
      isActive: true,
      userIsActive: true,
    });
    const limit = params.first || this.limit;
    const page = params.page || 1;
    if (params && !params.first) {
      _params.first = limit;
    }
    if (params && !params.offset) {
      _params.offset = (page - 1) * limit;
    }
    return this.plGraphQL.query(providerProfilesQuery, _params).pipe(
      map(({ providerProfiles, providerProfiles_totalCount }) => ({
        providers: providerProfiles,
        totalCount: providerProfiles_totalCount,
      })),
    );
  }

  getProviderLanguages() {
    return this.plGraphQL.query(GQL_GET_LANGUAGES).pipe(
      pluck('languages'),
      map((objs: any) => {
        return objs
          .filter((obj: any) => obj.code !== 'en')
          .sort((a: any, b: any) => a.name.localeCompare(b.name))
          .map((obj: any) => ({ label: obj.name, value: obj.code }));
      }),
      first(),
    );
  }

  setProviderLanguages(userId: any, languageCodes: any[]) {
    const variables = {
      userId,
      languageCodes,
    };

    return this.plGraphQL
      .query(GQL_MUTATE_PROVIDER_LANGUAGES, variables)
      .pipe(first());
  }

  getAreasOfSpecialty() {
    return this.plGraphQL.query(GQL_GET_AREAS_OF_SPECIALTY).pipe(
      pluck('areasOfSpecialty'),
      map((objs: any) => {
        return objs
          .sort((a: any, b: any) => a.name.localeCompare(b.name))
          .map((obj: any) => ({ label: obj.name, value: obj.id }));
      }),
      first(),
    );
  }

  setAreasOfSpecialty(userId: any, areaOfSpecialtyIds: any[]) {
    const variables = {
      userId,
      areaOfSpecialtyIds,
    };

    return this.plGraphQL
      .query(GQL_MUTATE_PROVIDER_AREAS_OF_SPECIALTY, variables)
      .pipe(first());
  }

  setNotificationPreferences(
    userId: string,
    notificationPreference: Array<NotificationPreference>,
    phoneNumber?: string,
  ) {
    const variables = {
      uuid: userId,
      notification_preference: notificationPreference,
    };

    if (phoneNumber) {
      variables['phone'] = phoneNumber;
    }

    return this.plHttp.patch(`providers`, variables).pipe(first());
  }

  getNotificationPreferences(): Option[] {
    return this.notificationPreferencesOptions;
  }

  setAssignmentTypePreference(
    userId: any,
    assignmentTypePreference: PLProviderAssignmentTypes,
  ) {
    const variables = {
      userId,
      assignmentTypePreference,
    };

    return this.plGraphQL
      .query(GQL_MUTATE_PROVIDER_ASSIGNMENT_TYPE, variables)
      .pipe(first());
  }
}

const GQL_GET_LANGUAGES = `
    query languages {
        languages {
            edges {
                node {
                    code
                    name
                }
            }
        }
    }
`;

const GQL_MUTATE_PROVIDER_LANGUAGES = `
    mutation updateProviderProfile($userId: ID!, $languageCodes: [String]!) {
        updateProviderProfile(input: { userId: $userId, languageCodes: $languageCodes }) {
            errors { code }
        }
    }
`;

const GQL_GET_AREAS_OF_SPECIALTY = `
    query areasOfSpecialty {
        areasOfSpecialty {
            id
            name
        }
    }
`;

const GQL_MUTATE_PROVIDER_AREAS_OF_SPECIALTY = `
    mutation updateProviderProfile($userId: ID!, $areaOfSpecialtyIds: [ID]!) {
        updateProviderProfile(input: { userId: $userId, areaOfSpecialtyIds: $areaOfSpecialtyIds }) {
            errors { code }
        }
    }
`;

const GQL_MUTATE_PROVIDER_NOTIFICATION_PREFERENCES = `
    mutation updateProviderProfile($userId: ID!, $notificationPreference: [String]!) {
        updateProviderProfile(input: { userId: $userId, notificationPreference: $notificationPreference }) {
            errors { code }
        }
    }
`;

const GQL_MUTATE_PROVIDER_ASSIGNMENT_TYPE = `
    mutation updateProviderProfile($userId: ID!, $assignmentTypePreference: [String]!) {
        updateProviderProfile(input: { userId: $userId, assignmentTypePreference: $assignmentTypePreference }) {
            errors { code }
        }
    }
`;
