import { Input, Component, Output, EventEmitter } from '@angular/core';
import { String } from 'lodash';

@Component({
  selector: 'pl-alert-banner',
  templateUrl: './pl-alert-banner.component.html',
  styleUrls: ['./pl-alert-banner.component.less'],
})
export class PLAlertBannerComponent {
  @Input() type: 'default' | 'warning' | 'error' | 'info' | 'success' =
    'default';
  @Input() title: String;
  @Input() buttonLabel: string;
  @Input() showCloseButton = true;

  @Output() buttonClicked = new EventEmitter();
  @Output() closeClicked = new EventEmitter();

  readonly iconMap = {
    default: 'info',
    warning: 'warning_amber',
    error: 'error',
    info: 'info',
    success: 'check_circle_outline',
  };
  icon: string;

  ngOnInit() {
    this.icon = this.iconMap[this.type];
  }
  close() {
    this.closeClicked.emit();
  }
  action() {
    this.buttonClicked.emit();
  }
}
