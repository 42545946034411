import { Component, Input, OnInit } from '@angular/core';
import { PLProviderProfileService } from '@common/services';
import { PROVIDER_ASSIGNMENT_TYPE_OPTIONS } from '@root/src/app/common/constants';
import { PLProviderAssignmentTypes } from '@root/src/app/common/enums/pl-provider-types';

@Component({
  selector: 'pl-provider-assignment-type',
  templateUrl: './pl-provider-assignment-type.component.html',
  styleUrls: ['./pl-provider-assignment-type.component.less'],
})
export class PLProviderAssignmentTypeComponent implements OnInit {
  @Input() userId: string;
  @Input() selectedAssignmentType: PLProviderAssignmentTypes =
    PLProviderAssignmentTypes.ASSESSMENT_DIRECT;
  @Input() onSave: (
    assignmentTypePreference: PLProviderAssignmentTypes,
  ) => void;

  assignmentTypePreference: PLProviderAssignmentTypes;

  assignmentTypeOptions = PROVIDER_ASSIGNMENT_TYPE_OPTIONS;

  constructor(private plProviderProfileService: PLProviderProfileService) {}

  ngOnInit(): void {
    this.assignmentTypePreference = this.selectedAssignmentType;
  }

  onClickSave(): void {
    this.plProviderProfileService
      .setAssignmentTypePreference(this.userId, this.assignmentTypePreference)
      .subscribe(() => {
        this.onSave(this.assignmentTypePreference);
      });
  }
}
