import { createSelector } from '@ngrx/store';
import { selectBillingCodesEntities } from '@common/store/billing';
import { eventAdapter } from './schedule.entities';
import { initialState } from './schedule.reducer';
import { selectFeatureState } from '../feature.state';

// #region Selectors
const selectEventState = createSelector(
  selectFeatureState,
  state => state?.events ?? initialState,
);

export const { selectEntities: selectEventsEntities, selectAll: selectEvents } =
  eventAdapter.getSelectors(selectEventState);

export const selectScheduleView = createSelector(
  selectEventState,
  state => state.view,
);

export const selectIsViewUnsigned = createSelector(
  selectScheduleView,
  view => view.unsigned,
);

export const selectIsLoadingEvents = createSelector(
  selectEventState,
  state => state.loading > 0,
);

export const selectCurrentEventId = createSelector(
  selectEventState,
  state => state.currentAppointmentId,
);

export const selectCurrentEvent = createSelector(
  selectEventsEntities,
  selectCurrentEventId,
  (entities, key) => (key ? entities[key] : null),
);

export const selectDatesLoaded = createSelector(
  selectEventState,
  state => state.datesLoaded,
);

export const selectEvaluations = createSelector(
  selectEventState,
  state => state.evaluations,
);

export const selectEventsWithBillingCode = createSelector(
  selectEvents,
  selectBillingCodesEntities,
  (events, billingCodes) =>
    events.map(event => {
      const billingCode = billingCodes[event.billing_code];
      return {
        ...event,
        billing_expanded: event.billing_expanded ?? billingCode,
      };
    }),
);
// #endregion
