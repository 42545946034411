import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PLProvidersService } from '@modules/schedule/services';
import { CurrentUserService } from '@modules/user/current-user.service';
import {
  FeatureFlagName,
  FeatureFlagsService,
} from '@root/src/app/common/feature-flags';
import { PLMayService } from '@root/src/lib-components';

@Component({
  selector: 'pl-provider-details',
  templateUrl: './pl-provider-details.component.html',
  styleUrls: [
    './pl-provider-details.component.less',
    './pl-provider-details.common.less',
  ],
})
export class PLProviderDetailsComponent implements OnInit {
  provider: any = {};
  userType: string;
  showPageNewOverView = false;
  currentUserEnabledUiFlags: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private providerService: PLProvidersService,
    private router: Router,
    private plMayService: PLMayService,
    private plCurrentUserService: CurrentUserService,
    private featureFlagsService: FeatureFlagsService,
  ) {}

  isAdmin() {
    return ['SUPERUSER', 'CAM'].includes(this.userType);
  }

  ngOnInit() {
    this.plCurrentUserService.getCurrentUser().subscribe((user: any) => {
      this.userType = this.plMayService.isSuperuser(user)
        ? 'SUPERUSER'
        : this.plMayService.isClinicalAccountManager(user)
        ? 'CAM'
        : this.plMayService.isSupport(user)
        ? 'SUPPORT'
        : this.plMayService.isProvider(user)
        ? 'PROVIDER'
        : 'CUSTOMER';
      this.currentUserEnabledUiFlags = user.xEnabledUiFlags;
    });

    this.route.parent.parent.params.subscribe(params => {
      this.providerService.get(undefined, params.id).subscribe((res: any) => {
        this.provider = res;
      });
    });

    this.featureFlagsService
      .isFeatureEnabled(FeatureFlagName.showNewProviderOverviewPage)
      .subscribe(enabled => {
        this.showPageNewOverView = enabled;
      });
  }
}
