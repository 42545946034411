import { Component, ElementRef, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PLSpreadsheetService } from '@common/services/pl-spreadsheet.service';

import { PLToastService, PLConfirmDialogService } from '@root/index';
import {
  FeatureFlagName,
  FeatureFlagsService,
} from '@root/src/app/common/feature-flags';
import { PLFileImportService, ImportedSheet } from './pl-file-import.service';
import { PLBaseStepComponent } from '../common/pl-base-step.component';
import { REFERRAL_INPUTS_CONFIG } from '../constants/referral-inputs-config';
import { PLAddReferralsLocationYearService } from '../pl-add-referrals-location-year.service';
import { PLAddReferralsNavigationService } from '../pl-add-referrals-navigation.service';
import {
  Field,
  PLAddReferralsDataTableService,
} from '../pl-add-referrals-table-data.service';

type FileOnChange = {
  existingFiles: [];
  file: File;
  files: File[];
  removedFile: File;
  removedFiles: File[];
};

type FilesOnChange = {
  model: FileOnChange;
  oldVal: FileOnChange;
};

@Component({
  selector: 'pl-upload-referrals',
  templateUrl: './pl-upload-referrals.component.html',
  styleUrls: [
    './pl-upload-referrals.component.less',
    '../pl-add-referrals.component.less',
  ],
  providers: [PLSpreadsheetService],
})
export class PLUploadReferralsComponent
  extends PLBaseStepComponent
  implements OnInit
{
  displayData: any[] = [];

  fieldChoices: Field[] = [];

  unmappedFields: string[] = [];
  dataColumnsUnmapped: number[];

  readonly TABLE_PAGE_SIZE: number = 10;
  readonly MAX_FILE_SIZE_MB: number = 3;

  locationName: string;
  organizationName: string;

  showImportedData: boolean = false;

  loadingFile = false;
  noFileInputError = false;

  missingFieldsError = false;
  missingFields = '';

  myElement: any;
  tableDataService: PLAddReferralsDataTableService;

  errorRows: any[] = [];
  warningRows: any[] = [];
  conflictRows: any[] = [];

  isViewingErrors = false;
  hasEditedData = false;
  tableDataSubject: Subject<any> = new Subject();

  referralTemplateLink: string;
  newReferralTemplateLink: string;
  newUploadReferral: boolean;

  constructor(
    private plImport: PLFileImportService,
    private pLAddReferralsNavigationService: PLAddReferralsNavigationService,
    private locationService: PLAddReferralsLocationYearService,
    tableDataService: PLAddReferralsDataTableService,
    private plToast: PLToastService,
    private plConfirm: PLConfirmDialogService,
    myElement: ElementRef,
    private plSpreadsheet: PLSpreadsheetService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    super();
    this.myElement = myElement;
    this.tableDataService = tableDataService;
    this.tableDataService.reset();

    if (
      tableDataService.importedFile &&
      tableDataService.importedData.length > 0
    ) {
      this.showImportedData = true;
      this.displayData = tableDataService.importedData;
    }

    this.setupNavigationActions();
  }

  ngOnInit() {
    this.featureFlagsService
      .isFeatureEnabled(FeatureFlagName.newUploadReferral)
      .subscribe(enabled => {
        this.newUploadReferral = enabled;
        this.newReferralTemplateLink =
          'https://cdn.presencelearning.com/statics/Presence%20Referrals%20Template.xlsx';
        if (!enabled)
          this.locationName = this.locationService.getSelectedLocationName();
        else
          this.organizationName =
            this.locationService.getSelectedOrganizationName();
      });
    this.updateChildTables();

    this.referralTemplateLink =
      'https://cdn.presencelearning.com/statics/PL-Referrals-Template-FY24.xlsx';

    this.fieldChoices = this.tableDataService.targetFields;
  }

  setupNavigationActions() {
    this.pLAddReferralsNavigationService.navigateRequested$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(stepIndex => {
        if (!this.tableDataService.importedFile) {
          this.noFileInputError = true;
        } else if (this.testMappingCompleteness()) {
          this.confirmContinue(stepIndex);
        }
      });

    this.pLAddReferralsNavigationService.cancelRequested$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.pLAddReferralsNavigationService.confirmCancel());

    this.pLAddReferralsNavigationService.finishRequested$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.pLAddReferralsNavigationService.confirmFinish());
  }

  updateChildTables() {
    this.tableDataSubject.next('');
  }

  prefixIDs(data: any[], col: number, startRow: number): any[] {
    const prefix = new Date().getTime();
    for (let i = startRow; i < data.length; i++) {
      const row = data[i];
      if (row[col].length) {
        row[col] = prefix + row[col];
      }
    }
    return data;
  }

  testMappingCompleteness() {
    this.unmappedFields = this.tableDataService.findUnmappedFields();
    const complete = this.unmappedFields.length === 0;
    this.missingFieldsError = !complete;
    this.missingFields = `Map the required Presence label(s) to a column in your spreadsheet: ${this.unmappedFields.join(
      ', ',
    )}`;
    this.dataColumnsUnmapped = this.tableDataService.findUnmappedColumn();
    return complete;
  }

  confirmContinue(stepIndex: number) {
    const referralCount = this.tableDataService.getReferralAttemptCount();
    this.plConfirm.show({
      header: 'Confirm Referral Upload',
      content: `<div style="padding-bottom:12px;">You are about to add ${referralCount} referrals to:</div>
                        <div style="padding-bottom:12px;font-weight:bold;">${
                          this.newUploadReferral
                            ? this.organizationName
                            : this.locationName
                        }</div>
                        ${this.buildErrorsConfirmMessage()}
                        <br/>
                        <div>If you continue from here, all valid referrals will be added to the platform. Do you wish to continue?</div>`,
      primaryLabel: 'Continue',
      secondaryLabel: 'Make Corrections',
      primaryCallback: () => {
        this.tableDataService.importData();
        this.pLAddReferralsNavigationService.confirmNavigate(stepIndex);
      },
      secondaryCallback: () => {
        // console.log('Making corrections...');
      },
    });
  }

  importNewFile() {
    this.plConfirm.show({
      header: 'Import New File',
      content: `<div>Importing a new file will replace the existing file and replace all the imported fields.
                      Are you sure you want to import a new file?</div>`,
      primaryLabel: 'Yes',
      secondaryLabel: 'No',
      primaryCallback: () => {
        this.tableDataService.reset();
        this.showImportedData = false;
        this.missingFieldsError = false;
      },
      secondaryCallback: () => {
        // console.log('Not uploading new file');
      },
    });
  }

  setData(data: ImportedSheet) {
    const richText = this.parseRichText(data.values);
    const cleanedData = this.cleanTopTemplateInstructions(richText);
    this.tableDataService.setData(cleanedData);
    this.tableDataService.setDataFormats(data.formats);
    this.displayData = cleanedData
      .map(row => row.slice(0, this.tableDataService.endCol + 1))
      .slice(0, this.tableDataService.endRow + 1);
    this.testMappingCompleteness();
    this.hasEditedData = false;
  }

  onSheetChange(evt: any) {
    this.confirmSheetChange(evt.oldVal, evt.model);
  }

  confirmSheetChange(prevSheet: string, newSheet: string) {
    this.plConfirm.show({
      header: 'Change Sheet',
      content: `<div>If you leave this sheet "${prevSheet}", your changes will not be saved. <br /><br />
                      Are you sure you want to change sheets?</div>`,
      primaryLabel: 'Continue',
      secondaryLabel: 'Cancel',
      primaryCallback: () => {
        this.setData(this.tableDataService.selectSheet(newSheet));
        this.validateImportedReferrals();
      },
      secondaryCallback: () => {
        this.tableDataService.currentSheetName = prevSheet;
      },
      closeCallback: () => {
        this.tableDataService.currentSheetName = prevSheet;
      },
    });
  }

  onFileChange(changes: FilesOnChange): void {
    this.displayData = [];
    this.tableDataService.importedData = [];
    this.noFileInputError = false;
    this.loadingFile = true;

    const errorToast = (msg: string) => {
      this.plToast.show('error', msg);
    };

    if (!changes.model?.file?.size) {
      errorToast(
        `Oops, something went wrong. The file was read incorrectly or
        corrupt on upload. Please try again. If the problem persists,
        please contact us for support.`,
      );
      this.loadingFile = false;
      return;
    }

    if (changes.model.file.size > this.MAX_FILE_SIZE_MB * 1024 * 1024) {
      this.tableDataService.importedFile = null;
      errorToast(`
                The file you selected exceeds ${this.MAX_FILE_SIZE_MB} MB.
                Please select a smaller file and try again.`);
      this.loadingFile = false;
      return;
    }

    this.plImport
      .parseFile(changes.model.file)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (sheets: ImportedSheet[]) => {
          this.tableDataService.setSheets(sheets);
          this.setData(sheets[0]);
          this.loadingFile = false;
          this.isViewingErrors = false;
          this.validateImportedReferrals();
          if (!this.tableDataService.importedDataHasAllRequiredHeaders()) {
            return errorToast(
              'The template you provided is not supported. Please use the new template file format.',
            );
          }
          this.showImportedData = true;
        },
        (error: string) => {
          switch (error) {
            case this.plImport.UNSUPPORTED:
              errorToast(
                'The file type you selected is not supported. Please upload an Excel file.',
              );
              break;
            default:
              errorToast(
                'Oops, something went wrong. Please try again. If the problem persists, please contact ' +
                  'us for support.',
              );
          }
          this.loadingFile = false;
        },
      );
  }

  async validateImportedReferrals() {
    await this.tableDataService.importData();
    this.buildLocalErrorsRows();
  }

  buildLocalErrorsRows() {
    this.warningRows = [...this.tableDataService.duplicateRows];

    this.errorRows = [
      ...this.tableDataService.incompleteRows,
      ...this.tableDataService.invalidRows,
      ...this.tableDataService.templateErrorRows,
    ];

    this.conflictRows = this.tableDataService.templateErrorRows;
  }

  toggleViewErrors() {
    this.isViewingErrors = !this.isViewingErrors;
  }

  buildErrorsConfirmMessage(): string {
    const incompletesErrorsMessage = this.tableDataService.incompleteRows.length
      ? `
            <div>
                ${this.tableDataService.incompleteRows.length} Referrals with missing required information
            </div>
        `
      : '';

    const duplicatesMessage = this.tableDataService.duplicateRows.length
      ? `
            <div>
                ${this.tableDataService.duplicateRows.length} Duplicates within spreadsheet
            </div>
        `
      : '';

    const invalidErrorMessage = this.tableDataService.invalidRows.length
      ? `
            <div>
                ${this.tableDataService.invalidRows.length} Referrals with invalid data
            </div>
       `
      : '';

    return this.conflictRows.length
      ? `
            <div style="padding-bottom:12px;">
                Problems found with imported data:
                <div>
                    ${incompletesErrorsMessage}
                    ${duplicatesMessage}
                    ${invalidErrorMessage}
                </div>
                <div>These ${this.conflictRows.length} Referrals will not be uploaded.</div>
            </div>
        `
      : '';
  }

  onColumnChanged(event: any) {
    this.testMappingCompleteness();
  }

  cleanTopTemplateInstructions(data: string[][]) {
    return data.map((row: string[]) => {
      if (this.isTemplateInstructionRow(row)) {
        return row.map(() => '');
      }
      if (this.newUploadReferral && this.isTemplateIntructionFieldRow(row)) {
        return row.map(() => '');
      }
      return row;
    });
  }

  isTemplateIntructionFieldRow(row: string[]): boolean {
    return Object.keys(REFERRAL_INPUTS_CONFIG).some(key => {
      let tipMessage = REFERRAL_INPUTS_CONFIG[key].tipMessage?.trim();
      if (tipMessage) {
        tipMessage = tipMessage.replace(/\s+/g, '');
      }
      return (
        tipMessage &&
        row.some(cell => {
          if (!cell || cell.length < 5) {
            return false;
          }
          const normalizedCell = cell.replace(/\s+/g, '');
          return normalizedCell.includes(tipMessage);
        })
      );
    });
  }

  isTemplateInstructionRow(row: string[]) {
    return this.tableDataService.templateInstructionRows.includes(
      row.join('').trim(),
    );
  }

  parseRichText(data: string[][]): string[][] {
    return data.map(
      (row: [string]): Array<string> =>
        row.map((cell: any): string =>
          typeof cell !== 'string' ? cell.text() : cell,
        ),
    );
  }

  onCellChanged(event: any) {
    this.tableDataService.importedData = event.newData;
    this.displayData = this.tableDataService.importedData;
    this.hasEditedData = true;
    this.validateImportedReferrals();
    if (this.conflictRows.length === 0) {
      this.isViewingErrors = false;
    }
  }

  downloadEdited() {
    const data =
      this.tableDataService.headerRowIndex > -1
        ? this.tableDataService.importedData.slice(
            this.tableDataService.headerRowIndex + 1,
          )
        : this.tableDataService.importedData;
    this.plSpreadsheet.generateEditedWorkbook(
      data,
      this.newUploadReferral ? this.organizationName : this.locationName,
    );
  }

  onRowClear(event: any) {
    this.plConfirm.show({
      header: 'Clear Row Data',
      content: `<div>Are you sure you want to clear all the data in row ${event.rowNumber}?</div>`,
      primaryLabel: 'Continue',
      secondaryLabel: 'Cancel',
      primaryCallback: () => {
        this.onCellChanged(event);
      },
      secondaryCallback: () => {},
    });
  }
}
