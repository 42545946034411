import { Component, Input, OnChanges } from '@angular/core';
import { PLAssignmentManagerService } from '../pl-assignment-manager.service';
import { PLOrgDemandItem } from '../pl-assignment-manager.model';
import { PLApiUsStatesService } from '@root/src/lib-components';

@Component({
  selector: 'pl-assignment-manager-org-header',
  templateUrl: 'assignment-manager-org-header.component.html',
  styleUrls: ['./assignment-manager-org-header.component.less'],
})
export class AssignmentManagerOrgHeaderComponent implements OnChanges {
  @Input() public orgDemandItem: PLOrgDemandItem;
  fulfillmentPercent = 0;
  state = '';

  constructor(
    private statesService: PLApiUsStatesService,
    private assignmentManagerService: PLAssignmentManagerService,
  ) {}

  ngOnChanges() {
    if (this.orgDemandItem) {
      this.fulfillmentPercent = this.getFulfillmentPercent(this.orgDemandItem);
      const stateEntry = this.statesService.getFromKey(
        'key',
        this.orgDemandItem.state,
      );
      this.state = stateEntry ? stateEntry.name : '';
    }
  }

  getFulfillmentPercent(item: PLOrgDemandItem) {
    const percent = +item?.fulfillmentPercentNormalized?.toFixed(0);
    return percent && !isNaN(percent) ? percent : 0;
  }

  onOpenOrganizationProfile() {
    window.open(`/c/organization/${this.orgDemandItem.uuid}`, '_blank');
  }

  onOpenSFDCRecord() {
    window.open(
      `https://plearn.lightning.force.com/lightning/r/Account/${this.orgDemandItem.salesforceId}/view`,
      '_blank',
    );
  }

  onOpenMetabaseReport() {
    window.open(
      this.assignmentManagerService.getAccountDashboardUrl(
        this.orgDemandItem.orgName,
      ),
      '_blank',
    );
  }
}
