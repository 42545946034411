import { Component, Input, OnInit } from '@angular/core';
import { withLatestFrom, first } from 'rxjs/operators';
import { PLQualification } from '@common/interfaces';
import { PLQualificationsService } from '@common/services';

import { CurrentUserService } from '@modules/user/current-user.service';
import { User } from '@modules/user/user.model';

import { PLMayService } from '@root/index';
import { PLProviderService } from '../pl-provider.service';

interface RequestsResults {
  qualifications: PLQualification[];
  totalCount: number;
}

@Component({
  selector: 'pl-provider-qualifications',
  templateUrl: './pl-provider-qualifications.component.html',
  styleUrls: [
    './pl-provider-qualifications.component.less',
    '../pl-provider-v2/pl-provider-v2.common.less',
  ],
  providers: [PLQualificationsService],
})
export class PlProviderQualificationsComponent implements OnInit {
  @Input() provider: any = {};

  user: User;
  readonly expandedRows: Set<any> = new Set();
  loading = false;
  qualifications: PLQualification[] = [];
  total = 0;
  admin = false;
  canShowData = false;

  constructor(
    private plProviderQualificationRequestService: PLQualificationsService,
    private plProvider: PLProviderService,
    private plMay: PLMayService,
    private plCurrentUserService: CurrentUserService,
  ) {}

  ngOnInit() {
    this.plProvider
      .getFromRoute()
      .pipe(withLatestFrom(this.plCurrentUserService.getCurrentUser()))
      .subscribe(([res, user]: [any, User]) => {
        this.provider = res.provider;

        this.user = user;
        this.admin =
          this.plMay.isAdminType(this.user) ||
          this.plMay.isSuperuser(this.user);

        this.canShowData =
          this.provider.user.id === this.user.uuid ||
          this.plMay.isAdminType(this.user) ||
          this.plMay.isSuperuser(this.user);

        if (this.canShowData) {
          this.loading = true;
          this.plProviderQualificationRequestService
            .getQualificationsRequests(this.provider.user.id)
            .pipe(first())
            .subscribe(results => this.onQueryResults(results));
        }
      });
  }

  private onQueryResults(results: RequestsResults): void {
    if (results) {
      this.qualifications = results['results'];
      this.total = results['count'];
      this.loading = false;
    }
  }
}
