import dayjs from 'dayjs';
import {
  on,
  props,
  Action,
  createAction,
  createReducer,
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';

export interface PLInvoicePeriod {
  start: string;
  end: string;
  dueDate: string | Date;
  submitStatus: string;
  monthName?: string;
  lastRefresh?: string;
  lastRefreshSource?: string;
  userId?: string;
}

interface PLInvoiceState {
  periodLoaded: boolean;
  invoicePeriod?: PLInvoicePeriod;
}

export const featureKey = 'invoice';
const featureNamespace = '[Invoice]';

// #region Actions
export const PLSetInvoicePeriod = createAction(
  `${featureNamespace} Set Invoice Period`,
  props<{
    invoicePeriod: PLInvoicePeriod;
    source?: string;
  }>(),
);
// #endregion

// #region Reducer
const getInvoicePeriod = () => {
  const data = localStorage.getItem('KEY_BILLING_PERIOD');
  if (data) {
    const now = dayjs();
    const invoicePeriod = JSON.parse(data);
    if (invoicePeriod && now.isSame(invoicePeriod.lastRefresh, 'd')) {
      return { invoicePeriod, periodLoaded: true };
    }
  }
  return { periodLoaded: false };
};

export const initialState: PLInvoiceState = {
  ...getInvoicePeriod(),
};

const _reducer = createReducer(
  initialState,
  on(PLSetInvoicePeriod, (state, { invoicePeriod }) => ({
    ...state,
    invoicePeriod: invoicePeriod
      ? {
          ...invoicePeriod,
          monthName: dayjs
            .utc(invoicePeriod.start)
            .add(1, 'days')
            .format('MMMM'),
        }
      : null,
    periodLoaded: true,
  })),
);

export function reducer(state: PLInvoiceState, action: Action) {
  return _reducer(state, action);
}
// #endregion

// #region Selectors
const selectFeatureState = createFeatureSelector<PLInvoiceState>(featureKey);
export const selectInvoicePeriod = createSelector(
  selectFeatureState,
  state => state.invoicePeriod,
);
export const selectInvoicePeriodLoaded = createSelector(
  selectFeatureState,
  state => state.periodLoaded,
);
// #endregion
