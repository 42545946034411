import dayjs from 'dayjs';
import { Injectable } from '@angular/core';

import { PLEvent } from '@modules/schedule/models';

interface DateRange {
  start: dayjs.ConfigType;
  end: dayjs.ConfigType;
}

@Injectable({ providedIn: 'root' })
export class OverlappingEventsService {
  getOverlappingEvents(
    referenceDateRange: DateRange,
    events: PLEvent[],
    excludeId?: string,
  ) {
    // Filter reference event out
    return events.filter(event => {
      const isExcludedEvent = excludeId && this.getEventId(event) === excludeId;

      if (isExcludedEvent) {
        return false;
      }

      return this.isEventOverlapping(referenceDateRange, event);
    });
  }

  isEventOverlapping(reference: DateRange, event: DateRange) {
    const [referenceStart, referenceEnd] =
      this.parseRangeDatesToDayjs(reference);
    const [eventStart, eventEnd] = this.parseRangeDatesToDayjs(event);

    return (
      eventStart.isBefore(referenceEnd) && eventEnd.isAfter(referenceStart)
    );
  }

  getEventId(event: PLEvent) {
    return event.uuid ?? event.event.uuid;
  }

  private parseRangeDatesToDayjs(range: DateRange) {
    return [dayjs(range.start), dayjs(range.end)] as const;
  }
}
