import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';

import { CurrentUserService } from '@modules/user/current-user.service';
import { PLMayService } from '@root/src/lib-components';

import { TimesheetsDataService } from '../../../services';
import * as actions from '../next-timesheet.actions';

@Injectable()
export class FetchNextTimesheetEffects {
  fetchNextTimesheet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.fetchNextTimesheet),
      switchMap(() =>
        this.timesheetsDataService.getPreview().pipe(
          map(res => actions.fetchNextTimesheetSuccess({ nextTimesheet: res })),
          catchError(error => {
            console.warn(actions.fetchNextTimesheetError.type, error);
            return of(actions.fetchNextTimesheetError({ error }));
          }),
        ),
      ),
    ),
  );

  fetchOnAuthenticated$ = createEffect(() =>
    this.onProviderAuthenticated().pipe(
      map(() => actions.fetchNextTimesheet()),
    ),
  );

  private onProviderAuthenticated() {
    return this.currentUserService.getCurrentUser().pipe(
      filter(user => this.plMay.isProvider(user)),
      first(),
    );
  }

  constructor(
    private actions$: Actions,
    private timesheetsDataService: TimesheetsDataService,
    private currentUserService: CurrentUserService,
    private plMay: PLMayService,
  ) {}
}
