import { Pipe, PipeTransform } from '@angular/core';

/**
 * For formatting the timing with the format Xh Ym.
 *   e.g. 13:42:30 would be 13hr 42m
 */
@Pipe({
  name: 'plTiming',
})
export class PLTimingPipe implements PipeTransform {
  transform(stringOrNumber: string | number, includeNegatives = false): string {
    try {
      let input = stringOrNumber.toString();
      const isNegative = input.indexOf('-') >= 0;

      if (includeNegatives && isNegative) {
        input = input.replace('-', '');
      }

      const time = this.formatTime(input);

      if (includeNegatives && isNegative) {
        return `-${time}`;
      }

      return time;
    } catch (error) {
      console.warn('[PLTimingPipe]', error, stringOrNumber);
      return '0m';
    }
  }

  private formatTime(input: string) {
    if (this.isDecimalTime(input)) {
      return this.formatDecimalTime(input);
    }

    return this.formatTimeDurationString(input);
  }

  private isDecimalTime(input: string) {
    return input.indexOf('.') >= 0 && input.indexOf(':') < 0;
  }

  private formatDecimalTime(input: string) {
    const [hoursInput, minutesInput] = input.split('.');

    const hours = this.timeToString(hoursInput, 'h');

    const minutesNumber = parseFloat(`0.${minutesInput}`) * 60;
    const minutes = this.timeToString(minutesNumber.toString(), 'm');

    return this.createTimeString(hours, minutes);
  }

  private formatTimeDurationString(input: string) {
    const splittedValue = input.split(':');
    const hours = this.timeToString(splittedValue[0], 'h');
    const minutes = this.timeToString(splittedValue[1], 'm');

    return this.createTimeString(hours, minutes);
  }

  private createTimeString(hours: string, minutes: string) {
    let result: string[] = [];

    if (hours) {
      result.push(hours);
    }

    if (minutes) {
      result.push(minutes);
    }

    return result.length ? result.join(' ') : '0m';
  }

  private timeToString(time: string, unit: 'h' | 'm') {
    const timeNumber = parseInt(time);

    if (timeNumber > 0) {
      return `${timeNumber}${unit}`;
    }

    return '';
  }
}
