<div class="pl-resources">
  <h3 class="pl-resources-title" *ngIf="!navCollapsed">Resources</h3>
  <pl-side-nav-link
    *ngFor="let link of links"
    [link]="link"
    [navCollapsed]="navCollapsed"
  >
  </pl-side-nav-link>
  <div
    class="help-and-support"
    [ngClass]="{ 'menu-open': isMenuOpen }"
    (click)="toggleMenu()"
    [matTooltip]="navCollapsed ? 'Help & Support' : ''"
    matTooltipPosition="right"
    #trigger
  >
    <div class="icon-and-text">
      <mat-icon class="help-icon">help_outline</mat-icon>
      <span *ngIf="!navCollapsed">Help & support</span>
    </div>
    <mat-icon *ngIf="!navCollapsed">arrow_right</mat-icon>
  </div>
  <div class="screen" (click)="toggleMenu()" *ngIf="isMenuOpen"></div>
</div>

<ng-template #helpAndSupportMenu>
  <div class="pl-help-and-support-menu" *ngIf="isMenuOpen">
    <a
      [href]="
        zendeskSupportLinks
          ? 'http://support.presence.com/'
          : 'https://presencelearning.helpjuice.com/'
      "
      target="_blank"
    >
      <div class="menu-item">
        <mat-icon class="material-icons-outlined">help_center</mat-icon>
        <div>
          <h3 class="menu-item__title">Help Center</h3>
          <p class="menu-item__text">
            Find articles and guides in the Presence provider knowledge base.
          </p>
        </div>
      </div>
    </a>
    <a [href]="learnUponUrl" target="_blank" *ngIf="userType !== 'CUSTOMER'">
      <div class="menu-item">
        <mat-icon class="material-icons-outlined menu-item__icon"
          >school</mat-icon
        >
        <div>
          <h3 class="menu-item__title">Telehealth Institute</h3>
          <p class="menu-item__text">
            Visit the Presence education hub for onboarding and continuing
            education courses.
          </p>
        </div>
      </div>
    </a>
    <a href="javascript:void(0)" (click)="onClickSupportChat()">
      <div id="support-agent" class="menu-item">
        <mat-icon class="material-icons-outlined">support_agent</mat-icon>
        <div>
          <h3 class="menu-item__title">Support chat</h3>
          <p class="menu-item__text">
            Having tech issues? Chat with a real person right now.
          </p>
        </div>
      </div>
    </a>
    <a [href]="techCheckUrl" target="_blank">
      <div class="menu-item">
        <mat-icon class="material-icons-outlined">computer</mat-icon>
        <div>
          <h3 class="menu-item__title">Tech Check</h3>
          <p class="menu-item__text">
            Confirm your computer and equipment are ready to work.
          </p>
        </div>
      </div>
    </a>
    <div class="divider"></div>
    <div class="menu-item-email">
      <h3 class="menu-item__title">Technical Support</h3>
      <a href="mailto:asksupport@presence.com" class="menu-item-email__link">
        asksupport@presence.com
      </a>
    </div>
    <div class="divider"></div>
    <div class="menu-item-email">
      <p class="menu-item__title">Version: {{ version }}</p>
    </div>
  </div>
</ng-template>
