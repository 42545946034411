export enum PLProviderTypeCode {
  SLP = 'slp', // Speech Language Pathologist
  OT = 'ot', // Occupational Therapist
  MHP = 'mhp', // Mental Health Professional
  PA = 'pa', // Psycho-educational Assessors (e.g., school psychologists)
  SPED = 'sped', // Special Education
  RS = 'rs', // FY23 - Reading Specialist
  APE = 'ape', // FY23 - Adapted Physical Education Teacher
  PT = 'pt', // FY23 - Physical Therapist
  BCBA = 'bcba', // FY23 - Board Certified Behavior Analyst
  EDDIAG = 'eddiag', // FY23 - Educational Diagnostician
}

export enum PLProviderTypeShortName {
  SLP = 'SLP', // Speech Language Pathologist
  OT = 'OT', // Occupational Therapist
  MHP = 'MHP', // Mental Health Professional
  SP = 'SP', // Psycho-educational Assessors (e.g., school psychologists)
  SPED = 'SPED', // Special Education
  RS = 'RS', // FY23 - Reading Specialist
  APETEACHER = 'APE TEACHER', // FY23 - Adapted Physical Education Teacher
  PT = 'PT', // FY23 - Physical Therapist
  BCBA = 'BCBA', // FY23 - Board Certified Behavior Analyst
  EDDIAG = 'ED DIAG', // FY23 - Educational Diagnostician
}

/**
 * Mapping of the values of a user group with its provider type code.
 */
export enum PLUserGroupProviderTypeCode {
  OT = PLProviderTypeCode.OT,
  SP = PLProviderTypeCode.PA,
  PT = PLProviderTypeCode.PT,
  MHP = PLProviderTypeCode.MHP,
  SLP = PLProviderTypeCode.SLP,
  BCBA = PLProviderTypeCode.BCBA,
  'APE Teacher' = PLProviderTypeCode.APE,
  Diagnostician = PLProviderTypeCode.EDDIAG,
  'Reading Specialist' = PLProviderTypeCode.RS,
}

// TODO: this enum will be removed on FY23
export enum PLProviderTypeAndServiceCodeFY23 {
  // PT_Type = 'pt',
  RS_Type = 'rs',
  // APE_Type = 'ape',
  RS_Service = 'ri',
  BCBA_Type = 'bcba',
  // EDDIAG_Type = 'eddiag',
}

export enum PLProviderAssignmentTypes {
  ASSESSMENT_DIRECT = 'ASSESSMENT_DIRECT',
  ASSESSMENT = 'ASSESSMENT',
  DIRECT = 'DIRECT',
}
