<div class="org-header" *ngIf="orgDemandItem">
  <div class="org-header-container">
    <div class="org-title">
      <span>{{ orgDemandItem.orgName }}</span>
      <pl-fulfillment-pill
        [fulfillmentPercent]="fulfillmentPercent"
      ></pl-fulfillment-pill>
    </div>
    <div class="org-details">
      <div class="separator"></div>
      <div>{{ state }}</div>
      <ng-container *ngIf="orgDemandItem.organizationType">
        <div class="separator"></div>
        <div>{{ orgDemandItem.organizationType }}</div>
      </ng-container>
    </div>
    <div class="org-details">
      <ng-container *ngIf="orgDemandItem.csmSlpOt">
        <div class="separator"></div>
        <div>
          <span class="bold">CLSM SLP/OT:</span> {{ orgDemandItem.csmSlpOt }}
        </div>
      </ng-container>
      <ng-container *ngIf="orgDemandItem.csmPesMhc">
        <div class="separator"></div>
        <div>
          <span class="bold">CLSM PES/MHC:</span> {{ orgDemandItem.csmPesMhc }}
        </div>
      </ng-container>
      <ng-container *ngIf="orgDemandItem.csm">
        <div class="separator"></div>
        <div>
          <span class="bold">CSM:</span>
          {{ orgDemandItem.csm }}
        </div>
      </ng-container>
    </div>
  </div>
  <div class="actions-container">
    <button
      pl-button
      class="bare-light flex-button"
      [matMenuTriggerFor]="optionsMenu"
    >
      Options <mat-icon inline>expand_more</mat-icon>
    </button>
  </div>
</div>
<mat-menu #optionsMenu="matMenu" class="action-menu" xPosition="before">
  <label class="action-menu-label">View more in…</label>
  <button mat-menu-item (click)="onOpenOrganizationProfile()">
    <span>Organization profile</span>
  </button>
  <button mat-menu-item (click)="onOpenSFDCRecord()">
    <span>SFDC record</span>
  </button>
  <button mat-menu-item (click)="onOpenMetabaseReport()">
    <span>Metabase report</span>
  </button>
</mat-menu>
