<div class="pl-input-radio" [ngClass]="classesContainer" (click)="change()">
  <input
    [id]="id"
    type="radio"
    class="x-qa-{{ value }}"
    [attr.name]="name"
    [name]="name"
    [(ngModel)]="modelMask"
    [value]="valueMask"
    [disabled]="disabled"
  />
  <div class="icon-container">
    <div class="dot"></div>
  </div>
  <label class="label-radio" [for]="id">{{ label }}</label>
</div>
