import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '@root/src/environments/environment';
import { PLHttpService } from '@root/src/lib-components';
import { SchoolYear, SchoolYearStartMonth } from './school-year.model';

@Injectable({ providedIn: 'root' })
export class SchoolYearDataService {
  static get listUrl() {
    return `${environment.apps.apiWorkplace.url}/api/v1/school-years`;
  }

  constructor(private plHttp: PLHttpService) {}

  list(startMonth?: SchoolYearStartMonth): Observable<SchoolYear[]> {
    let url = SchoolYearDataService.listUrl;
    if (startMonth) {
      url += `?start_month=${startMonth}`;
    }
    const httpOpts = {
      url,
      method: 'GET',
    };

    return this.plHttp.go(httpOpts).pipe(first());
  }
}
