import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgChartsModule } from 'ng2-charts';
import {
  PLDotLoaderModule,
  PLIconModule,
  PLInputModule,
  PLLodashService,
  PLButtonModule,
  PLVaryDisplayModule,
} from '@root/index';

import { PLClientsSummaryComponent } from './pl-clients-summary/pl-clients-summary.component';
import { PLCustomerDashboardComponent } from './pl-customer-dashboard/pl-customer-dashboard.component';
import { PLCustomerDashboardService } from './pl-customer-dashboard.service';
import { PLCustomerOnboardingModule } from './pl-customer-onboarding';
import { PLProvidersSummaryComponent } from './pl-providers-summary/pl-providers-summary.component';

import { PLReportsSummaryComponent } from './pl-reports-summary/pl-reports-summary.component';
import { PLSupportSummaryComponent } from './pl-support-summary/pl-support-summary.component';
import { PLAccountModule } from '../accounts';
import { PLISAGoToViewButtonComponent } from '../isa/pl-isa-go-to-view-button/pl-isa-go-to-view-button.component';
import { PLISAService } from '../isa/pl-isa.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PLDotLoaderModule,
    PLIconModule,
    PLInputModule,
    PLButtonModule,
    NgChartsModule,
    PLVaryDisplayModule,
    PLAccountModule,
    PLCustomerOnboardingModule,
    MatIconModule,
    MatTooltipModule,
  ],
  exports: [
    PLCustomerDashboardComponent,
    PLClientsSummaryComponent,
    PLProvidersSummaryComponent,
    PLReportsSummaryComponent,
    PLSupportSummaryComponent,
    PLISAGoToViewButtonComponent,
  ],
  declarations: [
    PLCustomerDashboardComponent,
    PLClientsSummaryComponent,
    PLProvidersSummaryComponent,
    PLReportsSummaryComponent,
    PLSupportSummaryComponent,
    PLISAGoToViewButtonComponent,
  ],
  providers: [PLLodashService, PLCustomerDashboardService, PLISAService],
})
export class PLCustomerDashboardModule {}
