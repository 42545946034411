import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PLMayService } from '@root/index';
import { CurrentUserService } from './current-user.service';

@Injectable()
export class UserCanAccessAssignmentsAuthGuardService implements CanActivate {
  constructor(
    private currentUserService: CurrentUserService,
    private plMay: PLMayService,
    private router: Router,
  ) {}

  canActivate(): Observable<any> {
    return this.currentUserService.getCurrentUser().pipe(
      map(user => {
        if (this.plMay.isProvider(user)) {
          return true;
        }
        return this.router.parseUrl('/');
      }),
    );
  }
}
