import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PLAssignmentProposalItemService } from '../pl-assignment-proposal-item.service';

@Component({
  selector: 'pl-contact-clsm-modal',
  templateUrl: './pl-contact-clsm-modal.component.html',
  styleUrls: ['./pl-contact-clsm-modal.less'],
})
export class PLContactClsmModalComponent implements OnInit {
  selectedOption: string;
  options: Observable<any[]>;
  constructor(private proposalItemService: PLAssignmentProposalItemService) {}
  ngOnInit(): void {
    this.options = this.proposalItemService.getClsmModalOptions();
  }
}
