import { Component, Input } from '@angular/core';

@Component({
  selector: 'pl-stop-assignments-modal',
  templateUrl: './pl-stop-assignments-modal.component.html',
  styleUrls: ['./pl-stop-assignments-modal.component.less'],
})
export class PLStopAssignmentModalComponent {
  @Input() onCancel: Function;
  @Input() onSubmit: Function;
}
