import { Injectable } from '@angular/core';
import { PLHttpService } from '@root/src/lib-components';
import { forkJoin, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable()
export class PLDemandNotesService {
  private service: string = 'demandNotes';

  constructor(private plHttp: PLHttpService) {}

  getNotes(params: {
    demand_uuid: string;
    notes?: string;
  }): Observable<{ notes: PLDemandNote }> {
    return this.plHttp.get(this.service, params);
  }

  /**
   * Creates a bunch of request for each note and wait until all of them are loaded.
   * @param demand_uuids
   * @returns a single observable with all the responses
   */
  getNotesBatch(
    demand_uuids: string[],
  ): Observable<{ [k: string]: PLDemandNote }> {
    let obs$ = [];
    demand_uuids.forEach(uuid => {
      obs$.push(
        this.plHttp.get(this.service, { demand_uuid: uuid }).pipe(
          map((res: { notes: PLDemandNote }) => {
            return { demandUuid: uuid, notes: res?.notes };
          }),
        ),
      );
    });
    return forkJoin(obs$).pipe(
      map((responses: { demandUuid: string; notes: PLDemandNote }[]) => {
        return responses.reduce((acc, val) => {
          acc[val.demandUuid] = val.notes;
          return acc;
        }, {});
      }),
      first(),
    );
  }

  saveNotes(data: { demand_uuid: string; notes?: string }) {
    let fdata = new FormData();
    fdata.append('demand_uuid', data.demand_uuid);
    fdata.append('notes', data.notes);
    return this.plHttp.save(this.service, fdata);
  }
}

export type PLDemandNote = {
  created: string;
  created_by: {
    first_name: string;
    last_name: string;
  };
  modified: string;
  modified_by: {
    first_name: string;
    last_name: string;
  };
  notes: string;
  uuid: string;
};
