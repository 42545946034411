import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PLFormService, PLModalService } from '@root/src/lib-components';
import { PLOpptyDemandItem } from '../pl-assignment-manager.model';
import { PLDemandNote, PLDemandNotesService } from './pl-demand-notes.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'pl-demand-notes',
  templateUrl: 'pl-demand-notes.component.html',
  styleUrls: ['pl-demand-notes.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PLDemandNotesComponent implements OnInit {
  @Input() opptyDemandItem: PLOpptyDemandItem;
  @Input() onCloseModal: (demandUuid: string, note: PLDemandNote) => {};
  @Input() demandNotes: { [k: string]: PLDemandNote };

  loading: boolean = true;
  enableForm: boolean = false;
  isReadOnly: boolean = false;
  inputLabel: string;
  headerHtml: SafeHtml;
  fetchText: string = 'Fetching Demand Notes';
  saveText: string = 'Saving Demand Notes';
  demandNote: PLDemandNote;
  demandNotesForm: FormGroup = new FormGroup({});
  demandNotesFormModel: {
    demand_uuid: string;
    notes?: string;
  } = {
    demand_uuid: '',
    notes: '',
  };

  constructor(
    protected sanitizer: DomSanitizer,
    private ref: ChangeDetectorRef,
    private toastr: ToastrService,
    private plDemandNotesService: PLDemandNotesService,
  ) {}

  ngOnInit() {
    this.headerHtml = this.sanitizer.bypassSecurityTrustHtml(
      `<div style="display:flex;flex-direction:column;">
                <span>Opportunity Details</span>
                <span style="font-size:12px;">${this.opptyDemandItem.serviceGroupName}</span>
            </div>`,
    );

    this.demandNotesFormModel.demand_uuid = this.opptyDemandItem.uuid;
    this.loadNote();
  }

  loadNote() {
    this.enableForm = false;
    this.loading = true;
    this.demandNote = this.demandNotes[this.opptyDemandItem.uuid];
    this.ref.markForCheck();
    this.loading = false;
  }

  fetchNote() {
    this.enableForm = false;
    this.loading = true;
    this.plDemandNotesService
      .getNotes({ demand_uuid: this.opptyDemandItem.uuid })
      .subscribe(
        res => {
          this.demandNote = res.notes;
          this.loading = false;
          this.ref.markForCheck();
        },
        err => {
          this.showErrorFetchToastr();
          this.loading = false;
          this.ref.markForCheck();
        },
      );
  }

  clearNote() {
    this.demandNotesFormModel.notes = '';
  }

  addNote() {
    this.enableForm = true;
    this.inputLabel = 'Add Note';
    this.ref.markForCheck();
  }

  editNote(demandNote: string) {
    this.demandNotesFormModel.notes = demandNote;
    this.enableForm = true;
    this.inputLabel = 'Edit Note';
    this.ref.markForCheck();
  }

  cancelNote() {
    this.enableForm = false;
    this.demandNotesFormModel.notes = '';
  }

  sendNote(form: FormGroup) {
    PLFormService.markAllAsTouched(form);
    if (form.valid) {
      this.plDemandNotesService.saveNotes(this.demandNotesFormModel).subscribe(
        () => {
          this.showSuccessToastr();
          this.demandNotesFormModel.notes = '';
          form.reset();
          this.fetchNote();
        },
        () => {
          this.showErrorToastr();
        },
      );
    }
  }

  getLastModified(demandNote: PLDemandNote) {
    let date = new Date(demandNote.modified);
    return `${demandNote.modified_by.first_name} ${
      demandNote.modified_by.last_name
    } - ${date.toLocaleString()}`;
  }

  showSuccessToastr() {
    this.toastr.success('Notes successfully saved!', 'Notes Saved', {
      positionClass: 'toast-bottom-right',
    });
  }

  showErrorToastr() {
    this.toastr.error(
      'Unable to send note. Please try again in a few moments.',
      'Error Sending Note',
      {
        positionClass: 'toast-bottom-right',
      },
    );
  }

  showErrorFetchToastr() {
    this.toastr.error(
      'Unable to fetch demand notes. Please try again in a few moments.',
      'Error Fetching Notes',
      {
        positionClass: 'toast-bottom-right',
      },
    );
  }

  // Once closed the modal send the demand notes information to be
  // updated in the demands notes list outside the modal
  closeModal() {
    this.onCloseModal(this.opptyDemandItem.uuid, this.demandNote);
  }
}
