import { Pipe, PipeTransform } from '@angular/core';

/*
    capitalizes the first letter of the input string, lower case after.
*/
@Pipe({
  name: 'plCapitalizeFirst',
})
export class PLCapitalizeFirstPipe implements PipeTransform {
  transform(value: any): string {
    return value
      ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
      : value;
  }
}
