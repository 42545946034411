import { Component, Input } from '@angular/core';
import {
  REFERRAL_REASON,
  REFERRAL_REASON_LABEL,
} from '@root/src/app/common/constants';

@Component({
  selector: 'pl-client-referral-delete',
  templateUrl: './pl-client-referral-delete.component.html',
  styleUrls: ['./pl-client-referral-delete.component.less'],
})
export class PLClientReferralDeleteComponent {
  @Input() referral: any = {};
  @Input() onDelete: Function;
  @Input() onCancel: Function;

  public selectOptsReason: any[] = [
    {
      value: REFERRAL_REASON.DUPLICATE_REFERRAL,
      label: REFERRAL_REASON_LABEL.DUPLICATE_REFERRAL,
    },
    {
      value: REFERRAL_REASON.DUPLICATE_OF_SERVICE_IN_PROGRESS,
      label: REFERRAL_REASON_LABEL.DUPLICATE_OF_SERVICE_IN_PROGRESS,
    },
    {
      value: REFERRAL_REASON.INCORRECT_REFERRAL,
      label: REFERRAL_REASON_LABEL.INCORRECT_REFERRAL,
    },
  ];

  delete() {
    if (this.referral.reason) {
      this.onDelete({ reason: this.referral.reason });
    }
  }

  cancel() {
    this.onCancel();
  }
}
