<div
  class="padding-large"
  *ngIf="!providerTypeOptions.length && !referralLoaded"
>
  <pl-dot-loader></pl-dot-loader>
</div>

<div
  class="pl-client-referral-save-referral padding-large"
  *ngIf="providerTypeOptions.length && referralLoaded"
>
  <div class="calculating-services-overlay" *ngIf="isCalculatingServices">
    <pl-dot-loader></pl-dot-loader>
  </div>
  <div class="client-info margin-large-b padding-large rounded">
    <div class="margin-large-b">
      <span class="font-h2 margin-large-r"
        >{{ client.firstName }} {{ client.lastName }}</span
      >
      <button *ngIf="!isEdit" pl-button class="link" (click)="changeClient()">
        Change
        <pl-client-student-display
          [user]="currentUser"
          [capitalize]="true"
        ></pl-client-student-display>
      </button>
      <a *ngIf="isEdit" target="_blank" [routerLink]="['/client', client.id]">
        View
        <pl-client-student-display
          [user]="currentUser"
          [capitalize]="true"
        ></pl-client-student-display>
        Profile
      </a>
    </div>
    <div>
      <span class="margin-large-r"
        ><b>Location:</b>
        <span *ngIf="client.locations && client.locations.length">{{
          client.locations[0].name
        }}</span></span
      >
      <span class="margin-large-r"
        ><b>Organization:</b>
        <span *ngIf="client.locations && client.locations.length">{{
          client.locations[0].parent?.name
        }}</span></span
      >
      <span class="margin-large-r"
        ><b>ID:</b>
        <pl-client-id
          [externalId]="client.externalId"
          [internalId]="client.id"
        ></pl-client-id
      ></span>
      <span class="margin-large-r"
        ><b>Date of Birth:</b> {{ client?.xBirthday }}</span
      >
    </div>
  </div>
  <div class="section-body margin-large-b rounded">
    <div class="flexbox">
      <div class="flex1">
        <form [formGroup]="referralForm">
          <div class="year-input">
            <pl-input-select
              class="form-input x-qa-year-select"
              [required]="!isRsmFieldDisplayed"
              [label]="'School Year'"
              [formCtrl]="referralForm"
              [(model)]="referral.schoolYear.code"
              [options]="schoolYearOpts"
              [highlightSelected]="true"
              [disabled]="isRsmFieldDisplayed"
              (onChange)="onChangeSchoolYear($event)"
            >
            </pl-input-select>
          </div>
          <div class="rsm-start-end-date" *ngIf="isRsmFieldDisplayed">
            <pl-input-datepicker
              class="flex1"
              [disabled]="true"
              [label]="'Start Date'"
              [model]="referral?.startDate"
              [placeholder]="'MM/DD/YYYY'"
              (onChange)="changeFilter()"
            >
            </pl-input-datepicker>
            <pl-input-datepicker
              class="flex1 margin-l"
              [disabled]="true"
              [label]="'End Date'"
              [model]="referral?.endDate"
              [placeholder]="'MM/DD/YYYY'"
              (onChange)="changeFilter()"
            >
            </pl-input-datepicker>
          </div>
          <pl-input-radio-group
            class="form-input x-qa-provider-type"
            [required]="!isRsmFieldDisplayed"
            [label]="'Provider Type'"
            [formCtrl]="referralForm"
            [(model)]="referral.providerType.code"
            [options]="providerTypeOptions"
            [optionWidth]="'30em'"
            (modelChange)="handleProviderTypeChange($event)"
            [disabled]="isRsmFieldDisplayed"
          >
          </pl-input-radio-group>
          <pl-input-radio-group
            class="form-input x-qa-product-type"
            [required]="!isRsmFieldDisplayed"
            [label]="'Referral'"
            [formCtrl]="referralForm"
            [(model)]="referral.productType.code"
            [options]="referralOpts"
            [optionWidth]="'30em'"
            (modelChange)="handleProductReferralChange($event)"
            [disabled]="isRsmFieldDisplayed"
          >
          </pl-input-radio-group>

          <ng-container *ngIf="showGeneralEducationIndicator$ | async">
            <div *ngIf="this.isBMHDirectService">
              <span> <b> General Education </b></span>
              <pl-input-checkbox
                class="form-input"
                [formCtrl]="referralForm"
                [(model)]="referral.generalEducation"
                [label]="'This is a General Education referral'"
                (modelChange)="onChangeGeneralEducation($event)"
                [name]="'generalEducation'"
                [required]="false"
              >
              </pl-input-checkbox>
            </div>
          </ng-container>

          <div class="rsm-service-name" *ngIf="isRsmFieldDisplayed">
            <pl-input-text
              class="form-input service-name-input"
              [type]="'text'"
              [disabled]="true"
              [model]="referral?.rsmServiceType"
              [label]="'RSM Service Name'"
            >
            </pl-input-text>
          </div>

          <div class="traking-type-input">
            <pl-input-select
              class="form-input"
              [formCtrl]="referralForm"
              [(model)]="referral.trackingType"
              [options]="trackingTypeOptions"
              [label]="'Tracking Type'"
              [disabled]="isRsmFieldDisplayed"
              [placeholder]="'-'"
              [required]="!isRsmFieldDisplayed"
            >
            </pl-input-select>
          </div>

          <div
            [hidden]="
              !isDirectServiceOrSupervision() && !isBehaviorOrTraumaGroup()
            "
          >
            <pl-input-select
              class="form-input grade-input"
              [formCtrl]="referralForm"
              [(model)]="referral.grade"
              [options]="gradeOptions"
              [label]="'Grade'"
              [placeholder]="'-'"
              [disabled]="isRsmFieldDisplayed"
            >
            </pl-input-select>

            <div [hidden]="!isDirectServiceOrSupervision()">
              <ng-container *ngTemplateOutlet="durationFreqAndInterval">
              </ng-container>

              <div class="rsm-hours-remaining" *ngIf="isRsmFieldDisplayed">
                <pl-input-text
                  [model]="referral?.hoursRemaining"
                  [type]="'number'"
                  [disabled]="true"
                  [label]="'Hours Remaining'"
                >
                </pl-input-text>
              </div>
            </div>

            <!-- time-inputs for Behavioral and Trauma Produt -->
            <div [hidden]="!isBehaviorOrTraumaGroup()">
              <pl-input-radio-group
                class="form-input x-qa-product-type"
                [formCtrl]="referralForm"
                [label]="'Frequency'"
                [(model)]="frequencyTypeCode"
                [options]="frequencyOpts"
                [optionWidth]="'30em'"
                (modelChange)="updateFrequencyWhenBmhType($event)"
                [disabled]="isRsmFieldDisplayed"
              >
              </pl-input-radio-group>
            </div>

            <pl-input-checkbox-group
              class="form-input input-width"
              [formCtrl]="referralForm"
              [(model)]="referralGrouping"
              (modelChange)="onChangeGrouping($event)"
              [options]="referralGroupingCheckboxOptions"
              [label]="grouping.label"
              [disabled]="grouping.disabled || isRsmFieldDisplayed"
            >
            </pl-input-checkbox-group>

            <!-- Short Term Coverage checkbox -->
            <ng-container
              *ngTemplateOutlet="
                shortTermCoverage;
                context: { displayTemplate: true }
              "
            >
            </ng-container>

            <pl-input-checkbox-group
              class="form-input"
              [formCtrl]="referralForm"
              [(model)]="esy"
              (modelChange)="onChangeEsy($event)"
              [options]="[
                {
                  label:
                    'This referral is for services to be provided during the extended school year',
                  value: 'esy'
                }
              ]"
              [optionWidth]="'30em'"
              [label]="'ESY'"
              [disabled]="isRsmFieldDisplayed"
            >
            </pl-input-checkbox-group>
          </div>

          <div [hidden]="!isEvaluation()">
            <pl-input-datepicker
              class="form-input input-width x-qa-evaluation-due-date"
              [formCtrl]="referralForm"
              [label]="'Evaluation Due Date'"
              [dropdownContainerSelector]="'.pl-service-save'"
              [(model)]="referral.dueDate"
              [disabled]="isRsmFieldDisplayed"
            >
            </pl-input-datepicker>

            <pl-input-datepicker
              class="form-input input-width"
              [formCtrl]="referralForm"
              [label]="'Assessment Plan Signature Date'"
              [dropdownContainerSelector]="'.pl-service-save'"
              [(model)]="referral.assessmentPlanSignedOn"
              [disabled]="isRsmFieldDisplayed"
            >
            </pl-input-datepicker>

            <pl-input-datepicker
              class="form-input input-width"
              [formCtrl]="referralForm"
              [label]="'Meeting Date'"
              [dropdownContainerSelector]="'.pl-service-save'"
              [(model)]="referral.meetingDate"
              [disabled]="isRsmFieldDisplayed"
            >
            </pl-input-datepicker>
          </div>

          <!-- Short Term Coverage checkbox -->
          <ng-container
            *ngTemplateOutlet="
              shortTermCoverage;
              context: { displayTemplate: isEvaluation() }
            "
          >
          </ng-container>

          <div *ngIf="hasSomeDirectServices">
            <pl-input-radio-group
              class="form-input x-qa-match"
              [formCtrl]="referralForm"
              [(model)]="dhConfirmationRadioModel"
              [label]="
                'Is this referral part of the school’s Dedicated Services caseload?'
              "
              [options]="dhConfirmationRadioOpts"
              [optionWidth]="'30em'"
              [required]="hasSomeDirectServices"
              (modelChange)="onChangeDHRadio($event)"
              [name]="'isDHValue'"
            >
            </pl-input-radio-group>
          </div>

          <ng-container *ngIf="dedicatedServicesEnabled$ | async">
            <div *ngIf="this.dedicatedService">
              <span> <b> Dedicated Services </b></span>
              <pl-input-checkbox
                class="form-input"
                [formCtrl]="referralForm"
                [(model)]="referral.isDedicated"
                [label]="
                  'This referral is for services that are part of a Dedicated Services caseload'
                "
                (modelChange)="onChangeDH($event)"
                [disabled]="isDedicatedServicesDisabled"
                [name]="'isDHValue'"
                [required]="false"
              >
              </pl-input-checkbox>
            </div>
          </ng-container>

          <pl-input-radio-group
            class="form-input x-qa-specialties"
            [required]="!isRsmFieldDisplayed"
            [label]="'Specialties'"
            [formCtrl]="referralForm"
            [(model)]="referral.specialty"
            [options]="specialtiesOpts"
            [optionWidth]="'30em'"
            [disabled]="isRsmFieldDisplayed"
          >
          </pl-input-radio-group>

          <pl-input-select
            class="form-input input-width x-qa-primary-language"
            [formCtrl]="referralForm"
            [(model)]="referral.language.code"
            [options]="languagesOptsOther"
            [label]="'Service Language'"
            [placeholder]="'Select Service Language'"
          >
          </pl-input-select>

          <div *ngIf="!isEdit">
            <div [hidden]="!matchingOpts.length">
              <span> <b> Matching Options </b> </span>
              <div *ngFor="let option of matchingOpts">
                <pl-input-radio
                  [label]="option.label"
                  [value]="option.value"
                  [(model)]="referral.matching"
                ></pl-input-radio>
              </div>
            </div>
            <div [hidden]="matchingOpts.length" class="margin-large-tb">
              This referral will be sent to Presence for matching.
            </div>
          </div>
          <div *ngIf="errorMessage" class="margin-large-tb red">
            {{ errorMessage }}
          </div>
        </form>
      </div>
      <div class="flex1">
        <div *ngIf="referralLoaded && isRsmFieldDisplayed">
          <b class="margin-r"> Notes from RSM </b>
          <pl-notes-list
            [currentUser]="currentUser"
            [notes]="rsmNoteAsPLNote"
            [viewMode]="true"
            [openInEditMode]="false"
            [noNotesDefaultText]="'No notes added.'"
          >
          </pl-notes-list>
        </div>

        <br />

        <div>
          <b class="margin-r"> Notes </b>
          <pl-referral-notes
            *ngIf="referralLoaded"
            [currentUser]="currentUser"
            [referralId]="referral.id"
            [clientId]="client.id"
            [locationId]="client.location"
            (noteEditing)="onNoteEditing($event)"
            (noteChange)="onNoteChange($event)"
          >
          </pl-referral-notes>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-fixed-spacer">&nbsp;</div>
  <div class="footer-fixed">
    <div [hidden]="saving">
      <button
        pl-button
        class="success x-qa-client-referral-referral-submit-btn"
        (click)="save()"
      >
        Save Referral
      </button>
      <button
        pl-button
        class="success x-qa-client-referral-referral-convert-btn"
        (click)="saveAndConvert()"
        [disabled]="referral.matching !== 'selfAssign'"
      >
        Save & Convert to Service
      </button>
      <button
        *ngIf="referral.permissions && referral.permissions.deleteReferral"
        pl-button
        class="danger x-qa-delete-btn"
        (click)="showDeleteConfirm()"
      >
        Delete
      </button>
      <button
        type="button"
        pl-button
        class="bare x-qa-client-referral-referral-cancel-btn"
        (click)="cancel()"
      >
        Cancel
      </button>
    </div>
    <div *ngIf="saving" class="padding-large-t">
      <pl-dot-loader [align]="'left'"></pl-dot-loader>
    </div>
  </div>
</div>

<ng-template #shortTermCoverage let-displayTemplate="displayTemplate">
  <pl-input-checkbox-group
    *ngIf="displayTemplate"
    class="form-input"
    [formCtrl]="referralForm"
    [(model)]="isShortTerm"
    (modelChange)="onChangeIsShortTerm($event)"
    [options]="[
      {
        value: 'isShortTerm',
        label:
          'This referral is to cover the student\'s service provision for a short period of time'
      }
    ]"
    [optionWidth]="'30em'"
    [label]="'Short Term Coverage'"
    [disabled]="isRsmFieldDisplayed"
  >
  </pl-input-checkbox-group>
</ng-template>

<ng-template #durationFreqAndInterval>
  <div class="time-inputs">
    <pl-input-text
      class="form-input duration-input"
      [formCtrl]="referralForm"
      [(model)]="referral.duration"
      [type]="'number'"
      [min]="1"
      [label]="'Duration'"
      [wholeNumber]="true"
      [disabled]="isRsmFieldDisplayed"
    >
    </pl-input-text>
    <span>minutes</span>

    <pl-input-text
      class="form-input frequency-input"
      [formCtrl]="referralForm"
      [(model)]="referral.frequency"
      [type]="'number'"
      [min]="1"
      [label]="'Frequency'"
      [wholeNumber]="true"
      [disabled]="isRsmFieldDisplayed"
    >
    </pl-input-text>

    <pl-input-select
      class="form-input interval-input"
      [filter]="false"
      [formCtrl]="referralForm"
      [(model)]="referral.interval"
      [options]="intervalOptions"
      [label]="'Interval'"
      [placeholder]="'-'"
      [disabled]="isRsmFieldDisplayed"
    >
    </pl-input-select>
  </div>
</ng-template>
