import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppStore } from '@app/appstore.model';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { PLSetInvoicePeriod, PLInvoicePeriod } from './invoice.store';
import { PLTimezoneService } from '@root/index';

@Injectable()
export class InvoiceEffects {
  private user$ = this.store.select('currentUser');

  setInvoicePeriod$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PLSetInvoicePeriod),
        withLatestFrom(this.user$),
        tap(([{ invoicePeriod, source }, user]) => {
          let storage: PLInvoicePeriod = invoicePeriod;
          // In case user is not set
          if (invoicePeriod && !invoicePeriod.userId) {
            storage = {
              ...storage,
              userId: user.uuid,
            };
          }
          // In case lastRefresh is not set
          if (invoicePeriod && !invoicePeriod.lastRefresh) {
            const lastRefresh = this.plTimezone.getUserToday(user);
            storage = {
              ...storage,
              lastRefresh,
              lastRefreshSource: source || 'calendar',
            };
          }
          localStorage.setItem('KEY_BILLING_PERIOD', JSON.stringify(storage));
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppStore>,
    private plTimezone: PLTimezoneService,
  ) {}
}
