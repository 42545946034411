import { EventEmitter, Injectable, Output } from '@angular/core';
import { PLHttpService, PLUrlsService } from '../../pl-http';
import { Observable, pipe } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MizarService {
  @Output() userPictureUpdated = new EventEmitter<boolean>();
  constructor(private plUrls: PLUrlsService, private plHttp: PLHttpService) {}

  uploadImage(userUuid: string, imageFile: File) {
    const formData = new FormData();
    formData.append('image', imageFile);
    return new Observable((observer: any) => {
      this.plHttp
        .post(null, formData, this.plUrls.urls.uploadImage + userUuid, {
          suppressError: true,
        })
        .subscribe(
          (res: any) => {
            this.userPictureUpdated.emit(true);
            observer.next(res);
          },
          (err: any) => {
            observer.error(err);
          },
        );
    });
  }
  getUserImage(userUuid: string) {
    const url = `${this.plUrls.urls.getImage}${userUuid}/latest/presigned-url`;

    return new Observable((observer: any) => {
      this.plHttp
        .get(null, {}, url, {
          suppressError: true,
        })
        .subscribe(
          (res: any) => {
            observer.next(res);
          },
          (err: any) => {
            observer.error(err);
          },
        );
    });
  }

  deleteUserImage(userUuid: string) {
    const url = `${this.plUrls.urls.deleteImage}${userUuid}`;
    return new Observable((observer: any) => {
      this.plHttp.delete('', {}, url, { suppressError: true }).subscribe(
        (res: any) => {
          this.userPictureUpdated.emit(true);
          observer.next(res);
        },
        (err: any) => {
          observer.error(err);
        },
      );
    });
  }
}
