import { PLCheckistItemStatus } from './pl-checklist/pl-checklist.types';
import { PLCustomerOnboardingData } from './pl-customer-onboarding.types';

export enum ChecklistItemCodes {
  // Required Information
  INFORMATION_GATHERING = 'INFORMATION_GATHERING',
  SCHOOL_CONTACTS = 'STAFF_CONTACTS',
  SITE_AVAILABILITY = 'SITE_AVAILABILITY',
  IMPORTANT_SCHOOL_DATES = 'SCHOOL_DATES',
  REFERRALS_CASELOAD = 'REFERRALS',
  TECH_CHECK = 'TECH_CHECK',
  PSP_TRAINING = 'PSP_TRAINING',
  // Aditional Information
  MASTER_SCHEDULE = 'MASTER_SCHEDULE',
  ORGANIZATION_HANDBOOK = 'ORGANIZATION_HANDBOOK',
  UPLOAD_SCHOOL_DOCUMENTS = 'SCHOOL_DOCUMENTS',
}

export const DEFAULT_CUSTOMER_ONBOARDING_DATA: PLCustomerOnboardingData = {
  welcomeNewText: `Welcome to Presence! We are glad to be working with you this school year.</br></br>
                     Before we can get our Providers matched and ready to provide services to your students,
                     there are a few things we need from you.</br></br> Please complete the steps below and
                     check them off to let our team know it is ready to review.`,
  welcomeText: `Welcome back! We are glad to be working with you this school year.
                  In order to make sure we have the most up-to-date information to get services started,
                  please review the tasks below and update anything as needed.
                  Check the boxes to mark steps complete or incomplete.`,
  checklists: [
    {
      id: 'required_information',
      title: 'Required Information',
      description: '',
      collapsed: true,
      items: [
        {
          id: '',
          code: ChecklistItemCodes.INFORMATION_GATHERING,
          title: 'Information Gathering',
          description: `To ensure a great start with Presence, we need to gather some information about your
                                  district, students, systems, and policies. This will help ensure that we have what we
                                  need for a successful, on-time therapy start.`,
          linkText: 'Complete Account Information Form',
          infoDescription: '',
          link: 'https://www.tfaforms.com/4998871?tfa_1=SF_ID',
          status: PLCheckistItemStatus.INCOMPLETE,
          collapsed: true,
          value: 14,
          order: 1,
        },
        {
          id: '',
          code: ChecklistItemCodes.SCHOOL_CONTACTS,
          title: 'District/School Contacts',
          description: `To help us prepare to get services started, we need to know the key people who will
                                  be involved in supporting the Presence Program within your district. <b>Note:</b>
                                  If you are renewing services with Presence, you only need to fill out this
                                  form if there are new contacts since last year.`,
          linkText: 'Enter District/School Contacts',
          infoDescription: `After entering your Contacts, they will be added to our database. Later on,
                                      you can optionally give them platform access from the School Staff tab of an
                                      organization/location profile, so they can log in and see certain information
                                      (e.g. student schedules and records).`,
          link: 'https://www.tfaforms.com/4992277?tfa_24=SF_ID',
          status: PLCheckistItemStatus.INCOMPLETE,
          collapsed: true,
          value: 14,
          order: 2,
        },
        {
          id: '',
          code: ChecklistItemCodes.SITE_AVAILABILITY,
          title: 'Site Availability',
          description: `Specify the time blocks in which Providers can regularly meet with students at each
                                  of your schools, and how many computers are available for students to use for
                                  sessions during those blocks.`,
          linkText: 'Update Location Availability',
          infoDescription: `This information helps the Presence Team make Provider assignments for your
                                      organization, by ensuring they assign Providers who align with your schools’
                                      availability. You’ll be able to work with your provider directly to finalize
                                      the schedule once they’ve been assigned.<br><br> To update this information
                                      again later, go to the ‘Locations’ application -> select your organization or
                                      a specific location -> click the ‘Availability’ tab.`,
          link: '/c/organization/ORG_ID/availability',
          status: PLCheckistItemStatus.INCOMPLETE,
          collapsed: true,
          value: 14,
          order: 3,
        },

        {
          id: '',
          code: ChecklistItemCodes.IMPORTANT_SCHOOL_DATES,
          title: 'Important School Dates',
          description: `School Year Dates include the start and end dates for your regular school year and
                                  your extended school year (ESY). Non-Service Dates are any days during the year when
                                  Presence Providers should not be providing services to students in your district
                                  (e.g. holidays, school breaks, testing days, etc.).`,
          linkText: 'Update My School Dates',
          infoDescription: `Presence Providers and Staff need to know these dates to be prepared to work with
                                      your school and ensure that scheduled student sessions do not conflict with any
                                      non-service days for your district.<br><br>To update this information again later,
                                      go to the ‘Locations’ application -> select your organization -> click ‘Edit’
                                      next to your School Year Dates or Non-Service Dates.`,
          link: '/c/organization/ORG_ID/overview',
          status: PLCheckistItemStatus.INCOMPLETE,
          collapsed: true,
          value: 15,
          order: 4,
        },
        {
          id: '',
          code: ChecklistItemCodes.REFERRALS_CASELOAD,
          title: 'Referrals (Presence Caseload)',
          description: `Within Presence, the term ‘Referrals’ refers to the student caseload that Presence
                                  Providers will serve. A referral indicates that you request Presence to provide the
                                  student with the listed service(s). Referrals can be uploaded in bulk using
                                  a spreadsheet to save you time.`,
          linkText: 'Upload Your Presence Caseload',
          infoDescription: `Students must have an active referral in the Presence platform in order to be
                                      matched to a Provider and begin receiving services (i.e. direct therapy or
                                      assessment/evaluation). To upload additional referrals later, go to the ‘Students’
                                      application -> click ‘All Students’ -> click ‘Upload Referrals’.`,
          link: '/c/add-referrals/select-location',
          status: PLCheckistItemStatus.INCOMPLETE,
          collapsed: true,
          value: 14,
          order: 5,
        },
        {
          id: '',
          code: ChecklistItemCodes.TECH_CHECK,
          title: 'Tech Check',
          description: `Running a tech check helps verify that your equipment will work with our platform so
                                  that you can run student sessions. If you’re new to Presence or want to recheck the
                                  computers used for services/assessments, click the link below to send out a
                                  Tech Check request to your on-site contacts.`,
          linkText: 'Send a Tech Check Request',
          infoDescription: `The Tech Check page automatically checks your computer’s operating system,
                                      firewall settings, microphone, and camera. If there are any issues discovered
                                      that you need help troubleshooting, you can email the tech check results or chat
                                      with our Support Services Team.`,
          fn: () => {},
          status: PLCheckistItemStatus.INCOMPLETE,
          collapsed: true,
          value: 14,
          order: 6,
        },
        {
          id: '',
          code: ChecklistItemCodes.PSP_TRAINING,
          title: 'PSP Training',
          description: `A Primary Support Person (PSP) is a dedicated on-site contact who acts as the
                                  connection between the student, clinician, teacher, and school. If you’re new
                                  to Presence or have a new PSP for this year, click the link below to send out
                                  the PSP Training Resources to your on-site contacts.`,
          linkText: 'Send PSP Training Resources',
          infoDescription: `Certifications aren’t required to be the PSP, and they should be someone who can
                                      sit with students for the duration of their sessions to provide troubleshooting
                                      or administrative help as needed. In the online training, the PSP will learn more
                                      about Presence, the therapy room, and their responsibilities.`,
          fn: () => {},
          status: PLCheckistItemStatus.INCOMPLETE,
          collapsed: true,
          value: 15,
          order: 7,
        },
      ],
    },
    {
      id: 'aditional_information',
      title: 'Additional Information',
      description: `In this section, you will find some additional items relating to the Presence Platform.
                          Although it is not required to complete these steps, we suggest reviewing them and
                          completing any information you can to ensure your best success with Presence.`,
      collapsed: true,
      items: [
        {
          id: '',
          code: ChecklistItemCodes.MASTER_SCHEDULE,
          title: 'Review the Master Schedule',
          description: `The Scheduler is a collaborative tool that allows you to view location availability,
                                  provider availability, and student therapy needs all in one place. Working out of the
                                  Scheduler can help you efficiently work together with the Presence Providers and
                                  Staff assigned to your district.`,
          linkText: 'View the Master Scheduler',
          infoDescription: `To access the Master Schedule for any of your schools, go to the ‘Locations’
                                      application -> select the desired location -> click the ‘Scheduler’ tab.`,
          link: '/c/location/list',
          status: PLCheckistItemStatus.INCOMPLETE,
          collapsed: true,
          order: 1,
        },
        {
          id: '',
          code: ChecklistItemCodes.ORGANIZATION_HANDBOOK,
          title: 'Review the Organization Handbook',
          description: `The Organization Handbook contains detailed information about your district’s
                                  policies, procedures, systems, and more. Presence Providers with an active assignment
                                  at your district can access the handbook to reference or update this information.`,
          linkText: 'View the Organization Handbook',
          infoDescription: `To edit or add to the handbook later, locate the ‘Handbook’ card on your
                                      Dashboard -> click ‘View Handbook’. From there, use the Table of Contents
                                      on the left side to navigate, and the Edit button to make changes.`,
          link: '/c/organization/ORG_ID/handbook',
          status: PLCheckistItemStatus.INCOMPLETE,
          collapsed: true,
          order: 2,
        },
        {
          id: '',
          code: ChecklistItemCodes.UPLOAD_SCHOOL_DOCUMENTS,
          title: 'Review/Upload School Documents',
          description: `Within the Presence platform, you can upload additional resources, templates, or forms
                                  for your assigned Providers or other staff to reference. We recommend uploading your
                                  Academic Calendar at the organization (district) level, and individual Bell Schedules
                                  at the location (school) level.`,
          linkText: 'View School Documents',
          infoDescription: `To upload additional documents later, go to the ‘Locations’ application ->
                                      select your organization or a specific location -> click the ‘Documents’ tab.
                                      The maximum file size is 30MB. Accepted file types include
                                      .doc, .docx, .jpg, .jpeg, .gif, .png, and .pdf.`,
          link: '/c/organization/ORG_ID/documents',
          status: PLCheckistItemStatus.INCOMPLETE,
          collapsed: true,
          order: 3,
        },
      ],
    },
  ],
};
