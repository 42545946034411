<section
  class="pl-support-summary"
  *ngIf="_state.initialized"
  [ngClass]="{ 'debug-show-div': util.showDivs(_state) }"
>
  <div class="debug-component-name" *ngIf="util.showDivs(_state)">
    {{ _state.componentName }} {{ _state.ID }}
  </div>
  <div class="training-faq"></div>
  <div class="contact-idea flexbox">
    <div class="flex1 padding-xlarge-r">
      <div class="margin-large-b" *ngIf="canDisplayCsm()">
        <div class="margin-b">Your Customer Success Manager</div>
        <div class="flexbox">
          <div class="icon csm">
            <mat-icon
              svgIcon="user-outline"
              class="gray-dark margin-l"
            ></mat-icon>
          </div>
          <div class="nowrap flex-start flex1">
            {{ _state.model.csm.name }}
            <br />
            <a id="csm-email" [href]="'mailto:' + _state.model.csm.email">
              {{ _state.model.csm.email }}</a
            >

            <br />
            {{ _state.model.csm.phone }}
          </div>
        </div>
      </div>

      <div class="margin-large-b" *ngIf="clinicalSuccessManagerList.length > 0">
        <div class="divider"></div>
        <div id="clinical-success-manager" class="margin-b">
          {{
            clinicalSuccessManagerList.length > 1
              ? 'Your Clinical Success Managers'
              : 'Your Clinical Success Manager'
          }}
        </div>
        <div
          class="flexbox account-customer-succes-manager-container"
          *ngFor="let clinicalSuccessManager of clinicalSuccessManagerList"
        >
          <div class="icon cqm">
            <mat-icon svgIcon="providers" class="gray-dark margin-l"></mat-icon>
          </div>
          <div class="nowrap flex-start flex1">
            {{ clinicalSuccessManager.firstName }}
            {{ clinicalSuccessManager.lastName }}
            <br />
            <a [href]="'mailto:' + clinicalSuccessManager.email">
              {{ clinicalSuccessManager.email }}</a
            >

            <br />
            {{ clinicalSuccessManager.profile.primaryPhone }}
          </div>
        </div>
      </div>
      <div class="margin-large-b" *ngIf="schoolLiaison">
        <div class="divider"></div>
        <div class="margin-b">Your School Liaison</div>
        <div class="flexbox account-customer-succes-manager-container">
          <div class="icon cqm">
            <mat-icon svgIcon="providers" class="gray-dark margin-l"></mat-icon>
          </div>
          <div class="nowrap flex-start flex1">
            {{ schoolLiaison.firstName }} {{ schoolLiaison.lastName }}
            <br />
            <a [href]="'mailto:' + schoolLiaison.email">{{
              schoolLiaison.email
            }}</a>

            <br />
            {{ schoolLiaison.profile.primaryPhone }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex1 padding-xlarge-r">
      <div class="margin-large-b border-bottom">
        <div class="margin-b">Learn</div>
        <div class="flexbox">
          <div class="icon">
            <mat-icon class="gray-dark margin-l" svgIcon="help"></mat-icon>
          </div>
          <div class="nowrap blue-medium flex-start flex1">
            <a [href]="zendeskSupportURL" target="_blank"
              >View all training videos and FAQ</a
            >
          </div>
        </div>
      </div>
      <div class="margin-large-b">
        <div class="margin-b">Contact</div>
        <div class="flexbox">
          <div class="icon">
            <mat-icon
              svgIcon="email-outline"
              class="gray-dark margin-l"
            ></mat-icon>
          </div>
          <div class="nowrap blue-medium flex-start flex1">
            <a href="mailto:asksupport@presencelearning.com"
              >asksupport@presencelearning.com</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
