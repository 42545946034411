import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { LEARN_UPON_DASHBOARD_URL } from '@common/constants';
import { PLGraphQLService } from '@root/index';
import { PLTasksService } from '@root/src/app/common/services';
import { PLProviderService } from '../../providers/pl-provider.service';

const updateTaskOwnerCompleteQuery = require('../../dashboard/pl-dashboard/queries/update-task-owner-complete.graphql');

@UntilDestroy()
@Component({
  selector: 'pl-provider-onboarding-v2',
  templateUrl: './pl-provider-onboarding-v2.component.html',
  styleUrls: ['./pl-provider-onboarding-v2.component.less'],
})
export class PLProviderOnboardingV2Component implements OnInit {
  @Input() currentUser: any;
  @Input() tasks: any[];

  provider: any = null;
  firstName = '';
  fullName = '';
  initials = '';
  profileHref = '';
  availabilityHref = '';
  telehealthHref = '';
  title = '';
  CEMName = '';
  CEMEmail = '';
  CEMInitials = '';
  providerProfileTask: any = null;
  availabilityTask: any = null;
  telehealthTask: any = null;
  techCheckTask: any = null;
  tasksAllComplete = false;
  canExplore = false;

  taskCopy = {
    providerProfile:
      'Get started by completing the basics for your provider profile and preferences, which will allow for Presence to generate appropriate assignment matches.',
    availability:
      'Fill out your availability so we can find the best fits when it comes to assignments.',
    telehealthInstitute:
      'Complete onboarding courses to prepare for your first teletherapy assignment.',
    techCheck:
      'Run through the system tech check to ensure you will be able to conduct remote sessions without any issues.',
  };
  tasksOnboarding: any[] = [];
  tasksOnboardingCheckboxVals = {};
  loaded = false;
  activeTask = null;

  constructor(
    private plGraphQL: PLGraphQLService,
    private plTasksService: PLTasksService,
    private plProvider: PLProviderService,
  ) {
    this.telehealthHref = LEARN_UPON_DASHBOARD_URL;
  }

  ngOnInit(): void {
    const uuid = this.currentUser.uuid;
    this.plProvider
      .getProvider(uuid)
      .pipe(untilDestroyed(this), first())
      .subscribe((res: any) => {
        this.provider = res.provider;
        this.CEMName = `${res.provider.accountOwner.firstName} ${res.provider.accountOwner.lastName}`;
        this.CEMInitials = `${res.provider.accountOwner.firstName[0]}${res.provider.accountOwner.lastName[0]}`;
        this.CEMEmail = res.provider.accountOwner.email;
        this.title = res.provider.title;
      });
    this.firstName = this.currentUser.first_name;
    this.fullName = this.currentUser.display_name;
    this.profileHref = `/c/provider-profile/${this.currentUser.uuid}/basic-information`;
    this.availabilityHref = `/c/provider-profile/${this.currentUser.uuid}/availability`;
    this.initials = `${this.currentUser.first_name[0]}${this.currentUser.last_name[0]}`;
    this.loaded = true;

    this.tasks.forEach(task => {
      let id;
      task.owners.forEach((owner: any) => {
        if (owner.user.id === this.currentUser.uuid) {
          id = owner.id;
        }
      });
      if (task.taskType.code === 'v2onboarding_provider_profile') {
        this.providerProfileTask = Object.assign(task, { id });
      }
      if (task.taskType.code === 'v2onboarding_availability') {
        this.availabilityTask = Object.assign(task, { id });
      }
      if (task.taskType.code === 'v2onboarding_telehealth_institute') {
        this.telehealthTask = Object.assign(task, { id });
      }
      if (task.taskType.code === 'v2onboarding_tech_check') {
        this.techCheckTask = Object.assign(task, { id });
      }
    });
  }

  setActive(taskCode): void {
    this.activeTask = taskCode;
  }

  tasksOnboardingCompleteTask(event, task: any): void {
    event.preventDefault();
    this.activeTask = task.taskType.code;
    if (task.isComplete) return; // don't allow them to uncheck tasks
    let id = task.id;
    this.plGraphQL
      .mutate(
        updateTaskOwnerCompleteQuery,
        { id, isComplete: true, read: false },
        {},
      )
      .subscribe(() => {
        this.plTasksService.refresh();
        task.isComplete = true;
        const incompleteTasks = this.tasks.filter(t => !t.isComplete);
        if (incompleteTasks.length === 0) this.tasksAllComplete = true;
      });
  }
}
