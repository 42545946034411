<div class="pl-header">
  <div class="pl-header-wrapper">
    <div class="pl-header-icon">
      <pl-icon [svg]="iconName" [width]="28" [height]="28"></pl-icon>
    </div>
    <ng-content></ng-content>
    <div class="pl-header-close x-qa-back-button" (click)="close()">
      <pl-icon
        *ngIf="closable"
        class="white margin-small-r"
        [svg]="'close-inverted'"
        [scale]="1.5"
      ></pl-icon>
    </div>
  </div>
</div>
