// feature-flags.reducer.ts
import { createReducer, on } from '@ngrx/store';
import { FeatureFlagAll } from '@common/feature-flags/feature-flags';
import * as FeatureFlagsActions from './feature-flags.actions';

export interface FeatureFlagsState {
  flags: FeatureFlagAll;
  loaded: boolean;
  error: any;
}

export const initialState: FeatureFlagsState = {
  flags: {},
  loaded: false,
  error: null,
};

export const featureFlagsReducer = createReducer(
  initialState,
  on(FeatureFlagsActions.loadFeatureFlags, state => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(
    FeatureFlagsActions.loadFeatureFlagsSuccess,
    (state, { featureFlags }) => ({
      ...state,
      flags: featureFlags,
      loaded: true,
      error: null,
    }),
  ),
  on(FeatureFlagsActions.loadFeatureFlagsFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    error,
  })),
);
