<div class="demand-item-header">
  <div class="title-container">
    <div class="item-title-flex">
      <div class="item-title">
        <ng-container *ngIf="specialties">
          <div>{{ specialties }}</div>
          <div class="separator"></div>
        </ng-container>
        <div>{{ serviceTypeName }}</div>
      </div>
      <div class="item-subtitle flex-row">
        {{
          opptyDemandItem.serviceModel === 'DH'
            ? 'Dedicated Services'
            : 'Hourly'
        }}
        <button (click)="openDetailsOverlay()" class="link-button">
          View details
        </button>
      </div>
    </div>
    <div class="actions-container">
      <button
        pl-button
        (click)="onAddProvider($event)"
        class="bare-light flex-button"
      >
        <mat-icon>add</mat-icon> Add provider
      </button>
    </div>
  </div>
  <div class="hr"></div>
  <div class="detail-container">
    <div class="item-detail">
      <pl-fulfillment-pill
        [light]="true"
        [fulfillmentPercent]="fulfillmentDetails.fulfillmentPercent"
        [matTooltip]="fulfillmentTooltip"
        matTooltipClass="pl-assignment-manager-demand-note-tooltip"
        [isInteractive]="true"
        [isClosedLost]="isClosedLost"
        [customLabel]="
          fulfillmentDetails.supply +
          ' of ' +
          fulfillmentDetails.demand +
          ' hours assigned'
        "
      ></pl-fulfillment-pill>
      <div class="closed-lost" *ngIf="isClosedLost; else notClosedLost">
        <span class="bold">Closed-Lost</span>
        <span>Reassign providers to other opportunities</span>
      </div>
      <ng-template #notClosedLost>
        <div class="bold">
          {{ opptyDemandItem.opportunityStage }} ({{
            opptyDemandItem.probability
          }}%)
        </div>

        <div class="details details-contract-dates">
          <span class="bold">Contract dates: </span>
          {{ opptyDemandItem.contractDates }}
        </div>
        <div class="details details-therapy-start">
          <span class="bold">Therapy start date: </span>
          {{ opptyDemandItem.therapyStartDate }}
        </div>
        <ng-container *ngIf="opptyDemandItem.billingStartDate">
          <div class="details details-billing-date">
            <span class="bold">Billing start date: </span>
            {{ opptyDemandItem.billingStartDate }}
          </div>
        </ng-container>
      </ng-template>
      <div
        class="detail-arrow"
        [matTooltip]="detailsTooltip"
        matTooltipPosition="above"
        matTooltipClass="pl-assignment-manager-demand-note-tooltip"
      >
        <mat-icon class="details-icon">info_outline</mat-icon>
      </div>
    </div>
    <div class="item-detail-actions">
      <button
        pl-button
        (click)="onAddNotes($event)"
        class="bare-light flex-button"
      >
        <ng-container *ngIf="hasNotes">
          <pl-icon [height]="18" [svg]="'note'"></pl-icon>&nbsp;View notes
        </ng-container>
        <ng-container *ngIf="!hasNotes">
          <mat-icon>add</mat-icon> Add notes
        </ng-container>
      </button>
    </div>
  </div>
</div>
