import { Injectable } from '@angular/core';
import { OnInitEffects, Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FeatureFlagsService } from '@common/feature-flags';
import * as FeatureFlagsActions from './feature-flags.actions';

@Injectable()
export class FeatureFlagsEffects implements OnInitEffects {
  loadFeatureFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureFlagsActions.loadFeatureFlags),
      switchMap(() =>
        this.featureFlagsService.getAllFeatures().pipe(
          map(featureFlags =>
            FeatureFlagsActions.loadFeatureFlagsSuccess({ featureFlags }),
          ),
          // we shouldn't ever hit this error, but it's nice to have
          // if we ever change the feature flag implementation or if
          // something goes unexpectedly wrong somewhere.
          catchError(error => {
            console.error('Error loading feature flags:', error);
            return of(FeatureFlagsActions.loadFeatureFlagsFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private featureFlagsService: FeatureFlagsService,
  ) {}

  ngrxOnInitEffects() {
    return FeatureFlagsActions.loadFeatureFlags();
  }
}
