<a
  *ngIf="!link.target; else external"
  class="pl-side-sub-nav-link"
  [routerLink]="link.href"
  routerLinkActive="active-link"
  [class.expanded]="!navCollapsed"
  [class.collapsed]="navCollapsed"
>
  <mat-icon *ngIf="link.icon" class="side-nav-icon material-icons-outlined">
    {{ link.icon }}
  </mat-icon>
  <span>{{ navCollapsed ? '■' : link.label }}</span>
</a>

<ng-template #external>
  <a class="pl-side-sub-nav-link" [href]="link.href" [target]="link.target">
    <mat-icon *ngIf="link.icon" class="side-nav-icon material-icons-outlined">
      {{ link.icon }}
    </mat-icon>
    <span>{{ link.label }}</span>
  </a>
</ng-template>
