import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PLModalModule } from '@root/index';
import { PLChecklistModule } from './pl-checklist';
import { PLCircularProgressModule } from './pl-circular-progress';
import { PLPSPTrainingModalModule } from './pl-psp-training-modal';
import { PLTechCheckModalModule } from './pl-tech-check-modal';
import { PLCustomerOnboardingComponent } from './pl-customer-onboarding.component';
import { PLCustomerOnboardingService } from './pl-customer-onboarding.service';

@NgModule({
  imports: [
    PLModalModule,
    CommonModule,
    PLCircularProgressModule,
    PLChecklistModule,
    PLTechCheckModalModule,
    PLPSPTrainingModalModule,
  ],
  exports: [
    PLCircularProgressModule,
    PLChecklistModule,
    PLTechCheckModalModule,
    PLPSPTrainingModalModule,
    PLCustomerOnboardingComponent,
  ],
  declarations: [PLCustomerOnboardingComponent],
  providers: [PLCustomerOnboardingService],
})
export class PLCustomerOnboardingModule {}
