<pl-modal-header-wrapper [headerText]="'Edit Assignment Type Preference'">
  <div class="pl-provider-assignment-type">
    <div class="assignment-type-options">
      <pl-input-radio
        *ngFor="let assignmentTypeOption of assignmentTypeOptions"
        class="flex1"
        [label]="assignmentTypeOption.label"
        [value]="assignmentTypeOption.id"
        [(model)]="assignmentTypePreference"
      ></pl-input-radio>
    </div>
    <hr />
    <button pl-button class="success" (click)="onClickSave()">Save</button>
  </div>
</pl-modal-header-wrapper>
