<pl-provider-account-settings
  *ngIf="!showPageNewOverView"
></pl-provider-account-settings>
<div class="pl-provider-details" *ngIf="showPageNewOverView">
  <div class="detail-grid">
    <div class="detail-column" *ngIf="provider">
      <pl-provider-details-basic-information
        [provider]="provider"
        [userType]="userType"
      ></pl-provider-details-basic-information>
      <pl-provider-details-contact-information
        [provider]="provider"
        [userType]="userType"
      ></pl-provider-details-contact-information>
      <pl-provider-details-additional-details
        [provider]="provider"
      ></pl-provider-details-additional-details>
      <pl-provider-details-service-details
        *ngIf="userType !== 'CUSTOMER'"
        [provider]="provider"
        [userType]="userType"
      ></pl-provider-details-service-details>
      <pl-provider-details-signed-agreements-details
        *ngIf="userType !== 'CUSTOMER'"
        [provider]="provider"
        [userType]="userType"
      ></pl-provider-details-signed-agreements-details>
    </div>
    <div class="detail-column">
      <pl-provider-details-assignment-preferences
        [provider]="provider"
      ></pl-provider-details-assignment-preferences>
      <pl-provider-details-organization-preferences
        *ngIf="userType !== 'CUSTOMER'"
        [provider]="provider"
        [userType]="userType"
      ></pl-provider-details-organization-preferences>
      <pl-provider-details-room-details
        [provider]="provider"
        [userType]="userType"
        [currentUserEnabledUiFlags]="currentUserEnabledUiFlags"
      ></pl-provider-details-room-details>
      <pl-provider-details-tech-check-details
        [provider]="provider"
        [userType]="userType"
      ></pl-provider-details-tech-check-details>
    </div>
  </div>
</div>
