import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BillingState, BILLING_FEATURE_KEY } from '../billing.state';

const selectBilling = createFeatureSelector<BillingState>(BILLING_FEATURE_KEY);

const selectState = createSelector(
  selectBilling,
  billing => billing.nextTimesheet,
);

export const selectStatus = createSelector(selectState, state => state.status);

export const selectNextTimesheet = createSelector(selectState, state =>
  state.status === 'success' ? state.data : null,
);

export const selectLoaded = createSelector(
  selectStatus,
  status => status === 'success' || status === 'error',
);

export const selectLoading = createSelector(
  selectStatus,
  status => status === 'loading',
);
