import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { map } from 'rxjs/operators';
import { PLAgreement } from '@common/interfaces';
import { PLHttpService, PLUrlsService } from '@root/index';

@Injectable()
export class PLAgreementsService {
  constructor(private plHttp: PLHttpService, private plUrls: PLUrlsService) {}

  getAgreementsRequests(uuid: any): Observable<PLAgreement[]> {
    const url = `${this.plUrls.urls.providerAgreements}?provider_uuid=${uuid}`;
    return this.plHttp.get('', {}, url);
  }

  getPreAgreementW2sRequests(uuid: any): Observable<PLAgreement[]> {
    const url = `${this.plUrls.urls.preagreementW2sList}?provider_uuid=${uuid}`;
    return this.plHttp.get('', {}, url).pipe(
      map((agreements: PLAgreement[]) => {
        return agreements.map(agreement => {
          if (agreement.document_url) {
            agreement.document_url = agreement.document_url.replace(
              '/sign',
              '/status',
            );
          }
          return agreement;
        });
      }),
    );
  }

  getFountainAgreementsRequests(uuid: any): Observable<PLAgreement[]> {
    const url = `${this.plUrls.urls.fountainProviderSignedAgreements}?provider_uuid=${uuid}`;
    return this.plHttp.get('', {}, url).pipe(
      map((res: any) => {
        const todayDate = new Date();
        if (todayDate.getMonth() < 6) {
          todayDate.setFullYear(todayDate.getFullYear() - 1);
        }
        const asOfDate = `${todayDate.getFullYear()}-07-01`;

        return res.map((agreement: any) => ({
          code: agreement.template_title,
          agreed_on: null,
          as_of_date: asOfDate,
          end_date: null,
          document_url: agreement.file_url,
        }));
      }),
    );
  }
}
