<div class="pl-client-referral-match">
  <pl-modal-header-wrapper [headerText]="'Assign provider'">
    <div class="margin-b">
      <h4>
        <b>
          Select an {{ referral.discipline }} provider for this
          {{ productTypeName().toLowerCase() }} referral
        </b>
      </h4>
    </div>
    <div class="margin-b">
      Details below are specific to the organization the selected student
      belongs to.
    </div>
    <div class="providers margin-large-b">
      <div class="header-row padding flexbox">
        <div class="header-col name-col">Provider</div>
        <div class="header-col center">Caseload</div>
        <div class="header-col center">Est. remaining hours</div>
      </div>
      <div class="rows">
        <pl-dot-loader *ngIf="loading" [align]="'center'"></pl-dot-loader>
        <div
          *ngIf="!loading && !providers.length"
          class="padding-nl-tb padding-lr"
        >
          {{ referral.organizationName }} needs an assigned
          {{ referral.discipline }} Provider to serve this referral.
        </div>
        <div
          *ngFor="let provider of providers"
          class="provider-info body-row padding flexbox"
        >
          <div class="name-col flexbox items-center gap-8 flex1">
            <pl-input-radio
              [(model)]="selectedProviderUserId"
              [value]="provider.userId"
              [label]="provider.name"
              [name]="'providerRadio'"
            ></pl-input-radio>
            <mat-icon
              *ngIf="provider.previouslyMatchedWithClient"
              class="provider-reassignment-icon"
              matTooltip="Returning provider"
              matTooltipPosition="right"
              >repeat
            </mat-icon>
          </div>
          <div class="body-col center table">
            <span class="table-cell">{{ provider.caseloadCountByOrg }}</span>
          </div>
          <div class="body-col center table">
            <span class="table-cell">{{
              provider.remainingAvailableHours
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="margin-large-b">
      Need another provider?
      <a href="{{ this.requestLink }}" target="_blank"
        >Request Provider Assignment.</a
      >
    </div>

    <div class="margin-large-b gray-line"></div>
    <div class="padding-small-b">
      <h4>
        <b
          >{{ referral?.client?.firstName }} {{ referral?.client?.lastName }}</b
        >
      </h4>
    </div>
    <div class="margin-large-b margin-b">
      {{ referral.organizationName }}
    </div>

    <div class="flexbox multi-column">
      <div *ngIf="isDirectTherapyProductType()" class="flex1">
        <div class="label-value">
          <div class="label">Duration</div>
          <div class="value">
            <pl-is-available [condition]="referral.duration !== null">
              {{ referral.duration | i18nPlural : durationPluralization }}
            </pl-is-available>
          </div>
        </div>
        <div class="label-value">
          <div class="label">Frequency</div>
          <div class="value">
            <pl-is-available [condition]="referral.frequency !== null">
              {{ referral.frequency }}
              {{ referral.frequency > 1 ? 'times' : 'time' }}
            </pl-is-available>
          </div>
        </div>
        <div class="label-value">
          <div class="label">Interval</div>
          <div class="value">
            <pl-is-available [condition]="referral.interval !== null">
              {{ referral.interval | plOptions : intervalOptions }}
            </pl-is-available>
          </div>
        </div>
        <div class="label-value">
          <div class="label">Group/Individual</div>
          <div class="value">
            <pl-is-available [condition]="referral.grouping !== 'unspecified'">
              {{ referral.grouping | plOptions : groupingOptions }}
            </pl-is-available>
          </div>
        </div>
      </div>
      <div *ngIf="!isDirectTherapyProductType()" class="flex1">
        <div class="label-value">
          <div class="label">Evaluation due date</div>
          <div class="value">
            <pl-is-available [condition]="referral.dueDate !== null">
              {{ referral.dueDate | date : 'longDate' }}
            </pl-is-available>
          </div>
        </div>
        <div class="label-value">
          <div class="label">Signature date</div>
          <div class="value">
            <pl-is-available
              [condition]="referral.assessmentPlanSignedOn !== null"
            >
              {{ referral.assessmentPlanSignedOn | date : 'longDate' }}
            </pl-is-available>
          </div>
        </div>
        <div class="label-value">
          <div class="label">Meeting date</div>
          <div class="value">
            <pl-is-available [condition]="meetingDate !== null">
              {{ referral.meetingDate | date : 'longDate' }}
            </pl-is-available>
          </div>
        </div>
      </div>
      <div class="flex1">
        <div class="label-value">
          <div class="label">Referral</div>
          <div class="value">
            <pl-is-available [condition]="productTypeName() !== null">
              {{ productTypeName() }}
            </pl-is-available>
          </div>
        </div>
        <div class="label-value">
          <div class="label">Dedicated services</div>
          <div class="value">
            <pl-is-available [condition]="referral.isDedicated !== null">
              {{ referral.isDedicated ? 'Yes' : 'No' }}
            </pl-is-available>
          </div>
        </div>
        <div class="label-value">
          <div class="label">Specialty</div>
          <div class="value">
            <pl-is-available [condition]="specialtiesLabel(referral) !== null">
              {{ specialtiesLabel(referral) }}
            </pl-is-available>
          </div>
        </div>
      </div>
    </div>

    <div class="margin-large-tb gray-line"></div>

    <div>
      <div *ngIf="loading || providers.length" class="margin-large-b">
        <b class="margin-r">Notes</b>
        <div class="referral-notes rows margin-small-t">
          <pl-referral-notes
            [currentUser]="currentUser"
            [referralId]="referral.id"
            [clientId]="client.id"
            [locationId]="locationId"
            [mayEditNotes]="false"
          >
          </pl-referral-notes>
        </div>
      </div>
    </div>
    <div class="buttons" [hidden]="submitting">
      <div class="buttons-primary"></div>
      <div class="buttons-secondary">
        <button
          pl-button
          class="x-qa-match-cancel-btn"
          (click)="onCancelClick()"
        >
          Cancel
        </button>
        <button
          id="saveMatchButton"
          *ngIf="loading || providers.length"
          pl-button
          class="x-qa-match-confirm-btn"
          (click)="onSaveMatchClick()"
          [disabled]="loading || !selectedProviderUserId"
        >
          Tentatively match
        </button>
        <button
          id="confirmMatchButton"
          *ngIf="loading || providers.length"
          pl-button
          class="success"
          (click)="onConfirmMatchClick()"
          [disabled]="loading || !selectedProviderUserId"
        >
          Confirm provider
        </button>
      </div>
    </div>
    <pl-dot-loader *ngIf="submitting" [align]="'center'"></pl-dot-loader>
    <div *ngIf="noneSelectedError">
      Whoops, you forgot to select a Provider.
    </div>
  </pl-modal-header-wrapper>
</div>
