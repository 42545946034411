import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  PLHttpService,
  PLMayService,
  PLLodashService,
  PLBrowserService,
  PLApiClientsService,
} from '@root/index';
import { AppStore } from '@app/appstore.model';
import { User } from '../user/user.model';
import { CurrentUserService } from '../user/current-user.service';
import { PLClinicalProductCode } from '../../common/enums/pl-clinical-product-types';

@Injectable()
export class PLClientReferralSaveService {
  client: any = {};

  clientUuid: string = '';
  referralUuid: string = '';
  private view: string = '';
  private currentUser: User = null;
  private userProvider: any = {};
  private clientReferralFormVals: any = {
    client: {},
    location: {},
  };
  private isEdit: boolean = false;
  private providerUserUuid: string = '';
  private loaded: any = {
    currentUser: false,
    routeParams: false,
    client: false,
  };
  private routeParams: any;

  private sharedDataObserver: any;

  constructor(
    private plHttp: PLHttpService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private store: Store<AppStore>,
    private plLodash: PLLodashService,
    private plMay: PLMayService,
    private plBrowser: PLBrowserService,
    private plCurrentUser: CurrentUserService,
    private plApiClients: PLApiClientsService,
  ) {
    this.reset();
  }

  getSharedData() {
    return new Observable((observer: any) => {
      this.sharedDataObserver = observer;
      this.updateSharedData();
    });
  }

  updateSharedData(data1: any = {}) {
    if (this.sharedDataObserver) {
      const dataDefault = {};
      const keys = [
        'clientReferralFormVals',
        'isEdit',
        'client',
        'currentUser',
      ];
      keys.forEach((key: any) => {
        dataDefault[key] = this[key];
      });
      const data = Object.assign({}, dataDefault, data1);
      this.sharedDataObserver.next(data);
    }
  }

  reset() {
    this.client = {};

    this.clientUuid = '';
    this.referralUuid = '';
    this.view = '';
    this.currentUser = null;
    this.userProvider = {};
    this.clientReferralFormVals = {
      client: {},
    };
    this.isEdit = false;
    this.providerUserUuid = '';
    this.loaded = {
      currentUser: false,
      routeParams: false,
      client: false,
    };

    this.sharedDataObserver = null;
  }

  init() {
    this.reset();
    return new Observable((observer: any) => {
      const checkAllLoadedLocal = () => {
        if (this.plLodash.allTrue(this.loaded)) {
          this.onAllLoaded();
          observer.next({ client: this.client });
          this.updateSharedData();
        }
      };
      this.router.events.subscribe((val: any) => {
        this.setView();
      });
      this.store.select('currentUser').subscribe((user: any) => {
        this.currentUser = user;
        if (user && user.uuid) {
          this.userProvider = this.plCurrentUser.getProvider(user);
          this.loaded.currentUser = true;
          checkAllLoadedLocal();
        }
      });
      this.route.queryParams.subscribe((routeParams: any) => {
        this.routeParams = routeParams;
        this.clientUuid = routeParams['client'] || '';
        this.getClient().subscribe((client: any) => {
          checkAllLoadedLocal();
        });
        this.setView();
        this.loaded.routeParams = true;
        checkAllLoadedLocal();
      });
    });
  }

  destroy() {
    this.reset();
  }

  onAllLoaded() {
    const userProviderId = this.userProvider ? this.userProvider.user : '';
    this.providerUserUuid = this.routeParams['provider']
      ? this.routeParams['provider']
      : userProviderId;
  }

  getClient() {
    return new Observable((observer: any) => {
      if (this.clientUuid) {
        this.plApiClients
          .get({ uuid: this.clientUuid })
          .subscribe((res: any) => {
            this.client = res[0];
            this.loaded.client = true;
            observer.next(this.client);
          });
      } else {
        this.loaded.client = true;
        observer.next();
      }
    });
  }

  /**
   * Returns a `Referral` based on the previously selected `Referral`.
   * This is done for OT or SLP providers.
   *
   * @param referralTypeCode The code that identifies the referral option selected.
   * @returns The `referralTypeCode` for selecting a referral option in the HTML.
   */
  getReferralToSelectWhenOtOrSLP(referralTypeCode: string): string {
    const isProductBehaviorOrTrauma =
      referralTypeCode === PLClinicalProductCode.TG ||
      referralTypeCode === PLClinicalProductCode.BIG;

    if (isProductBehaviorOrTrauma) {
      referralTypeCode = PLClinicalProductCode.SV;
    } else {
      const hasInvalidReferralPreselected =
        referralTypeCode !== PLClinicalProductCode.SV &&
        referralTypeCode !== PLClinicalProductCode.EVAL &&
        referralTypeCode !== PLClinicalProductCode.DIR_SVC;

      if (hasInvalidReferralPreselected) {
        // The ternary here handles the special `screening` option for RSM
        referralTypeCode =
          referralTypeCode === PLClinicalProductCode.SC
            ? PLClinicalProductCode.EVAL
            : PLClinicalProductCode.DIR_SVC;
      }
    }

    return referralTypeCode;
  }

  /**
   * Returns a `Referral` based on the previously selected `Referral`.
   * Only for MHP's (Mental Health Professionals)
   *
   * @param referralTypeCode The code of the `Referral`.
   * @returns The new code of the `Referral`.
   */
  getReferralToSelectWhenMHP(referralTypeCode: string): string {
    const hasInvalidReferralPreselected =
      referralTypeCode !== PLClinicalProductCode.DIR_SVC &&
      referralTypeCode !== PLClinicalProductCode.EVAL;

    if (hasInvalidReferralPreselected) {
      referralTypeCode = PLClinicalProductCode.DIR_SVC;
    }

    return referralTypeCode;
  }

  setView() {
    const oldView = this.view;
    this.view = this.plBrowser.getSubRoute();
  }
}
