import { LEARN_UPON_DASHBOARD_URL } from '@common/constants';
import { PLProviderTypeCode } from '@root/src/app/common/enums';

export enum ARCLabel {
  submitARC = 'Submit Goal Writing Exercise Responses',
  attendToARC = 'Register and Attend an ARC Basics A to Z Workshop',
  practiceARC = 'Practice for the ARC through a Companion Activity',
  submissionARC = 'Submit a Redacted Report',
  preparationARC = 'Read this Help Center article on how to prepare for the ARC',
  completionARC = 'Complete Your ARC',
}

export const ARCLink = {
  submitARC: 'https://www.tfaforms.com/4863882',
  practiceARC: 'PLACEHOLDER_FOR_LEARNUPON_COURSE',
  attendToARC:
    'https://presencelearning.zoom.us/meeting/register/tJEuceGvqToqE9YeMmg7O8nwSIhYABbPQQcG',
  attendToSPsARC:
    'https://presencelearning.zoom.us/meeting/register/tJEpc-qvpzsjHd0h3h8YbKTm__Yd_8EVsXTB',
  attendToMHPsARC:
    'https://presencelearning.zoom.us/meeting/register/tJEtdeCqqzsoHdzt4AWkfaAfi0ZaucbMMbcF',
  submissionARC: 'https://www.tfaforms.com/5041225',
  preparationARC:
    'https://presencelearning.helpjuice.com/en_US/61244-technical-support-troubleshooting/1370234-untitled-article',
  preparationARCZendesk:
    'https://support.presence.com/hc/en-us/articles/27203512298899-Complete-the-Assignment-Readiness-Check-ARC',
  completionARC: LEARN_UPON_DASHBOARD_URL,
};

// Unused ones are commented below
// "onboarding-contractor-status": 30,
// 'onboarding-best-practices': 110,
export enum OnboardingTask {
  npin = 'onboarding-npin',
  w9Tax = 'onboarding-w9-tax-form',
  lounge = 'onboarding-provider-lounge',
  orientation = 'onboarding-orientation',
  driverLicense = 'onboarding-driver-license',
  arc = 'onboarding-assignment-readiness-check',
  telehealth = 'onboarding-telehealth-institute-prime',
  liabilityInsurance = 'onboarding-liability-insurance',
  docAndBilling = 'onboarding-documentation-and-billing',
  demographicSurvey = 'onboarding-demographic-survey',
}

export const OnboardingOrderMap = {
  [OnboardingTask.arc]: 7,
  [OnboardingTask.npin]: 10,
  [OnboardingTask.w9Tax]: 20,
  [OnboardingTask.lounge]: 70,
  [OnboardingTask.telehealth]: 5,
  [OnboardingTask.orientation]: 1,
  [OnboardingTask.driverLicense]: 50,
  [OnboardingTask.docAndBilling]: 100,
  [OnboardingTask.demographicSurvey]: 101,
  [OnboardingTask.liabilityInsurance]: 40,
};

export const GENERIC_TASKS_FOR_W2 = [
  OnboardingTask.arc,
  OnboardingTask.npin,
  OnboardingTask.lounge,
  OnboardingTask.telehealth,
  OnboardingTask.orientation,
  OnboardingTask.docAndBilling,
  OnboardingTask.demographicSurvey,
];

export const GENERIC_TASKS_FOR_1099 = [
  ...GENERIC_TASKS_FOR_W2,
  OnboardingTask.w9Tax,
  OnboardingTask.liabilityInsurance,
];

// `ape` and `pt` are not supported for W2 yet.
export const TASKS_FOR_W2_PROVIDER = {
  [PLProviderTypeCode.PA]: GENERIC_TASKS_FOR_W2,
  [PLProviderTypeCode.OT]: GENERIC_TASKS_FOR_W2,
  [PLProviderTypeCode.SLP]: GENERIC_TASKS_FOR_W2,
  [PLProviderTypeCode.MHP]: GENERIC_TASKS_FOR_W2,
  [PLProviderTypeCode.EDDIAG]: GENERIC_TASKS_FOR_W2,
};

export const TASKS_FOR_1099_PROVIDER = {
  [PLProviderTypeCode.PA]: GENERIC_TASKS_FOR_1099,
  [PLProviderTypeCode.OT]: GENERIC_TASKS_FOR_1099,
  [PLProviderTypeCode.PT]: GENERIC_TASKS_FOR_1099,
  [PLProviderTypeCode.SLP]: GENERIC_TASKS_FOR_1099,
  [PLProviderTypeCode.MHP]: GENERIC_TASKS_FOR_1099,
  [PLProviderTypeCode.APE]: GENERIC_TASKS_FOR_1099,
  [PLProviderTypeCode.EDDIAG]: GENERIC_TASKS_FOR_1099,
};
