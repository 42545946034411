import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AssignmentProposal } from '@common/assigment-machine/models';
import { AssignmentProposalDataService } from '@common/assigment-machine/services';
import { AsyncDataService } from '@common/services/async-data.service';
@UntilDestroy()
@Injectable()
export class PLTimesheetProgressAssignmentFilterService extends AsyncDataService<
  AssignmentProposal[]
> {
  constructor(private dataService: AssignmentProposalDataService) {
    super();

    this.fetch();
  }

  private fetch() {
    this.setLoading();
    this.dataService
      .list({ only_payable: true, limit: 1000 })
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          const dedicated_assignments = response.results.filter(
            assignment =>
              assignment.service_model === 'DH' &&
              ['active'].includes(assignment.status),
          );
          this.setData(dedicated_assignments);
        },
        error => {
          console.warn('[Event Assignments Error]', error);
          this.setError(error);
        },
      );
  }
}
