import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PLFormatDelimiterPipe } from '@common/pipes/pl-format-delimiter.pipe';
import { PLServiceLineTitlePipe } from '@common/pipes/pl-service-line-title.pipe';

@NgModule({
  declarations: [PLServiceLineTitlePipe, PLFormatDelimiterPipe],
  imports: [CommonModule],
  exports: [PLServiceLineTitlePipe, PLFormatDelimiterPipe],
})
export class PLPipesModule {}
