import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

const assignmentTypeMap = {
  ASSESSMENT: 'Evaluation',
  ASSESSMENT_DIRECT: 'Therapy and evaluation',
  DIRECT: 'Therapy',
};

@Component({
  selector: 'pl-provider-details-assignment-preferences',
  templateUrl: './pl-provider-details-assignment-preferences.component.html',
  styleUrls: [
    './pl-provider-details.common.less',
    './pl-provider-details-assignment-preferences.component.less',
  ],
})
export class PLProviderDetailsAssignmentPreferencesComponent
  implements OnInit, OnChanges
{
  @Input() provider: any; // replace 'any' with the actual type
  assignmentType: string;

  ngOnInit() {
    this.updateProviderInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.provider) {
      this.updateProviderInfo();
    }
  }

  updateProviderInfo() {
    if (!this.provider) {
      return;
    }
    this.assignmentType = this.provider.assignment_type_preference
      ? assignmentTypeMap[this.provider.assignment_type_preference]
      : undefined;
  }
}
