import { Action, createReducer, on } from '@ngrx/store';
import { NextTimesheetState } from './next-timesheet.state';
import * as actions from './next-timesheet.actions';

const reducer = createReducer<NextTimesheetState>(
  {
    status: 'idle',
  },
  on(
    actions.fetchNextTimesheet,
    (): NextTimesheetState => ({
      status: 'loading',
    }),
  ),
  on(
    actions.fetchNextTimesheetSuccess,
    (_state, action): NextTimesheetState => ({
      status: 'success',
      data: action.nextTimesheet,
    }),
  ),
  on(
    actions.fetchNextTimesheetError,
    (_state, action): NextTimesheetState => ({
      status: 'error',
      error: action.error,
    }),
  ),
);

/**
 * Since we use aot in ng11 we need to wrap our reducer creators in a function
 * to prevent compiler errors. This won't be necessary when we start using ivy
 * See https://v11.ngrx.io/api/store/createReducer#usage-notes
 */
export function nextTimesheetReducer(
  state: NextTimesheetState,
  action: Action,
) {
  return reducer(state, action);
}
