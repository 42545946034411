export enum BillingCodesCode {
  dedicated_services = 'dedicated_services',
  fte = 'fte_contract_services',

  evaluation = 'evaluation',

  cf_slpa_cota_sup_direct = 'cf_slpa_cota_sup_direct',
  cf_slpa_cota_sup_indirect = 'cf_slpa_cota_sup_indirect',
  consultation = 'consultation',
  direct_makeup = 'direct_makeup',
  direct_services = 'direct_services',
  group_bmh = 'group_bmh',
  iep_meeting = 'iep_meeting',

  service_coordination_comms = 'service_coordination_comms',
  service_coord_billable = 'service_coord_billable',
  pa_coordination = 'pa_coordination',

  parent_contact = 'parent_contact',

  unplanned_school_closure = 'unplanned_school_closure',
  canceled_holiday = 'canceled_holiday',
  canceled_tech_issue = 'canceled_tech_issue',
  supervision_indirect_ot = 'supervisionIndirectOT',
  canceled_24_notice = 'canceled_24_notice',
  unplanned_student_absence = 'unplanned_student_absence',
  student_absence_no_notice = 'student_absence_no_notice',
  canceled_by_provider = 'canceled_by_provider',

  personal = 'personal',
}

export enum BillingCodeCreationCategory {
  dedicated_services_block = 'Dedicated Services Block',
  work_with_clients = 'Work with Clients',
}

export const FTE_CODES = [
  BillingCodesCode.fte,
  BillingCodesCode.dedicated_services,
];

export const DIRECT_TIME_CODES = [
  BillingCodesCode.consultation,
  BillingCodesCode.direct_makeup,
  BillingCodesCode.direct_services,
  BillingCodesCode.evaluation,
  BillingCodesCode.group_bmh,
  BillingCodesCode.iep_meeting,
  BillingCodesCode.parent_contact,
  BillingCodesCode.cf_slpa_cota_sup_direct,
  BillingCodesCode.unplanned_school_closure,
  BillingCodesCode.canceled_holiday,
  BillingCodesCode.canceled_tech_issue,
  BillingCodesCode.supervision_indirect_ot,
  BillingCodesCode.cf_slpa_cota_sup_indirect,
  BillingCodesCode.canceled_24_notice,
  BillingCodesCode.unplanned_student_absence,
  BillingCodesCode.student_absence_no_notice,
  BillingCodesCode.canceled_by_provider,
];
