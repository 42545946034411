export const setReferralProposedProviderMutation = /* GraphQL */ `
  mutation clientReferralsSetReferralProposedProvider(
    $proposedReferralMatch: SetReferralProposedProviderInput!
  ) {
    setReferralProposedProvider(input: $proposedReferralMatch) {
      errors {
        code
        field
        message
      }
      referral {
        id
        created
        client {
          id
          firstName
          lastName
          primaryLanguage {
            id
            code
            name
          }
          englishLanguageLearnerStatus
          locations {
            edges {
              node {
                id
                name
                parent {
                  id
                  name
                }
              }
            }
          }
        }
        declinedByProvidersCount
        duration
        frequency
        grade
        grouping
        isScheduled
        isMissingInformation
        interval
        notes
        rsmServiceType
        isRsmSchool
        schoolYear {
          code
          id
        }
        permissions {
          matchProvider
          declineReferral
          deleteReferral
          unmatchReferral
          updateReferral
        }
        provider {
          id
          firstName
          lastName
        }
        providerType {
          id
          shortName
          code
        }
        productType {
          id
          code
        }
        state
      }
      status
    }
  }
`;
