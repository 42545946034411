import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { PLHttpService } from '@root/src/lib-components';
import { PaginatedParams, PaginatedResponse } from '../../interfaces';
import { Timesheet } from '../models';

export interface TimesheetsPreviewResponse extends Timesheet {
  timeblocks: any[];
  catchedError?: boolean; // Only used when server errors are thrown
  generated_invoice?: string;
}

export interface TimesheetListParams extends PaginatedParams {
  status__in?: string;
}

export type TimesheetListResponse = PaginatedResponse<Timesheet>;

@Injectable({ providedIn: 'root' })
export class TimesheetsDataService {
  constructor(private plHttp: PLHttpService) {}

  list(params?: TimesheetListParams): Observable<TimesheetListResponse> {
    const httpOpts = {
      url: `${environment.apps.apiWorkplace.url}/api/v2/timesheet/`,
      method: 'GET',
      params,
    };
    return this.plHttp.go(httpOpts);
  }

  getPreview(): Observable<TimesheetsPreviewResponse> {
    const httpOpts = {
      url: `${environment.apps.apiWorkplace.url}/api/v2/timesheet/preview/`,
      method: 'GET',
    };
    return this.plHttp.go(httpOpts);
  }

  save(): Observable<TimesheetsPreviewResponse> {
    const httpOpts = {
      url: `${environment.apps.apiWorkplace.url}/api/v2/timesheet/`,
      method: 'POST',
    };
    return this.plHttp.go(httpOpts);
  }

  get(uuid: string): Observable<Timesheet> {
    const httpOpts = {
      url: `${environment.apps.apiWorkplace.url}/api/v2/timesheet/${uuid}/`,
      method: 'GET',
    };
    return this.plHttp.go(httpOpts);
  }

  retract(uuid: string): Observable<Timesheet> {
    const httpOpts = {
      url: `${environment.apps.apiWorkplace.url}/api/v2/timesheet/${uuid}/retract/`,
      method: 'PUT',
    };
    return this.plHttp.go(httpOpts);
  }
}
