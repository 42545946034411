module.exports = /* GraphQL */ `
  query UserSelf {
    currentUser {
      id
      username
      firstName
      lastName
      enabledFeatures
      permissions {
        manageCaseload
        viewSchedule
      }
      globalPermissions {
        addReferral
        addReferrals
        exportNotes
        viewProviders
        viewOpenReferrals
        manageReferrals
        manageProposals
        addEvaluation
        addDirectService
        provideServices
        viewCustomers
        mergeClient
        viewPersonnel
      }
      enabledUiFlags
    }
  }
`;
