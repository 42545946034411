import { Component, Input } from '@angular/core';

@Component({
  selector: 'pl-circular-progress',
  templateUrl: 'pl-circular-progress.component.html',
  styleUrls: ['pl-circular-progress.component.less'],
})
export class PLCircularProgressComponent {
  @Input() value: number;
}
