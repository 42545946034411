<div
  *ngIf="isAssumedLogin"
  class="assumed-login-bar"
  [class.expanded]="isExpanded"
>
  <div class="assumed-login-bar-tab" (click)="onTabClick()">
    <div class="assumed-login-bar-tab-visual">
      <pl-icon class="icon white" [svg]="tabIcon" [scale]="0.6"></pl-icon>
    </div>
  </div>
  <div class="assumed-login-bar-body">
    <p>You are currently working on behalf of {{ username }}</p>
    <button
      mat-raised-button
      color="accent"
      type="button"
      (click)="onReleaseClick()"
    >
      Release {{ username }}
    </button>
  </div>
</div>
