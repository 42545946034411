import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PLCustomerOnboardingService } from '../../pl-customer-onboarding.service';
import { PLCheckistItem, PLCheckistItemStatus } from '../pl-checklist.types';

@Component({
  selector: 'pl-checklist-item',
  templateUrl: 'pl-checklist-item.component.html',
  styleUrls: ['pl-checklist-item.component.less'],
})
export class PLChecklistItemComponent implements OnInit {
  @Input() item: PLCheckistItem;
  @Output() onStatusChange: EventEmitter<{
    item: PLCheckistItem;
    newStatus: PLCheckistItemStatus;
  }> = new EventEmitter();

  collapsed: boolean = true;
  infoCollapsed: boolean = true;
  saving: boolean = false;
  isChecked: boolean = false;

  constructor(private customerOnboarding: PLCustomerOnboardingService) {
    this.customerOnboarding.savingData$.subscribe((value: boolean) => {
      this.saving = value;
    });
  }

  ngOnInit() {
    this.isChecked = this.isItemCompleted(this.item);
    this.customerOnboarding.onboardingData$.subscribe(() => {
      this.isChecked = this.isItemCompleted(this.item);
    });
  }

  toggleCollpase(): void {
    this.item.collapsed = !this.item.collapsed;
    this.customerOnboarding.updateItemCollapseStatus(
      this.item.code,
      this.item.collapsed,
    );
  }

  toggleInfo(): void {
    this.infoCollapsed = !this.infoCollapsed;
  }

  toggleStatus() {
    this.onStatusChange.emit({
      item: this.item,
      newStatus: this.isItemCompleted(this.item)
        ? PLCheckistItemStatus.INCOMPLETE
        : PLCheckistItemStatus.COMPLETE,
    });
  }

  itemChange(): void {
    this.toggleStatus();
  }

  isItemCompleted(item: PLCheckistItem): boolean {
    return item.status === PLCheckistItemStatus.COMPLETE;
  }
}
