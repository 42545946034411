<div class="pl-availability-view" [ngClass]="classesWrapper">
  <div
    class="margin-small-b"
    [class.maximized]="state.maximized"
    *ngIf="isScheduleView && state.pageInitialized && fullPage && showTabs"
  >
    <pl-tabs [tabs]="tabs"></pl-tabs>
  </div>

  <div
    [class.maximized]="state.maximized"
    *ngIf="state.pageInitialized"
    class="pl-availability-view__content container"
  >
    <div class="col col1">
      <div class="top" [class.max-off]="state.maximized">
        <h2>Availability</h2>
        <div class="description">
          <p>
            Please mark your weekly availability for direct student interaction
            during school hours. Leave blank for days when you are unavailable.
            Please <b>do not</b> include the times you are available for:
          </p>
          <ol>
            <li>Attending IEP meetings</li>
            <li>Communicating with school sites</li>
            <li>Completing Presence documentation</li>
          </ol>
        </div>

        <div class="divider-line"></div>

        <div [hidden]="!hasAgreed && !isCAMView">
          <div
            class="expand-calendar"
            [class.max-off]="state.maximized"
            *ngIf="isDevDebug('SHOW_ZOOM')"
          >
            <button pl-button class="link" (click)="onClickMaximizeView()">
              <pl-icon [svg]="'new-tab'" [height]="18" [width]="18"></pl-icon>
              <span>Expand Calendar</span>
            </button>
          </div>

          <div *ngIf="state.maximized" class="zoom-scale" id="zoomScale">
            <span class="label">SCALE:</span>
            <span
              [class.active]="state.scale === 'xsmall'"
              (click)="onClickZoomScale('xsmall')"
              >extra small</span
            >
            |
            <span
              [class.active]="state.scale === 'small'"
              (click)="onClickZoomScale('small')"
              >small</span
            >
            |
            <span
              [class.active]="state.scale === 'medium'"
              (click)="onClickZoomScale('medium')"
              >medium</span
            >
            |
            <span
              [class.active]="state.scale === 'large'"
              (click)="onClickZoomScale('large')"
              >large</span
            >
            |
            <span
              [class.active]="state.scale === 'xlarge'"
              (click)="onClickZoomScale('xlarge')"
              >extra large</span
            >
          </div>
          <div class="close-maximized-view" *ngIf="state.maximized">
            <button pl-button class="link" (click)="onClickMaximizeView()">
              <pl-icon
                [svg]="'close-inverted'"
                [height]="18"
                [width]="18"
              ></pl-icon>
              <span>Close</span>
            </button>
          </div>

          <div class="saving-errors" *ngIf="hasSaveErrors()">
            <fieldset>
              <legend>Please provide this error information to support</legend>
              <div
                class="button-copy-to-clipboard"
                *ngIf="util.canCopyToClipboard()"
              >
                <button
                  pl-button
                  class="pl-button info"
                  (click)="copyErrorInfoToClipboard()"
                >
                  Copy to clipboard
                </button>
              </div>
              <div id="errorDataSupport">
                <div class="saving-errors-header">
                  <div class="provider-info" *ngIf="state.user">
                    <span class="username">{{ state.user.username }}</span>
                    <span class="uuid">{{ state.user.uuid }}</span>
                  </div>
                </div>
                <ul class="error-info">
                  <li *ngFor="let err of getSaveErrors()">
                    <ngx-json-viewer [json]="err"></ngx-json-viewer>
                  </li>
                </ul>
              </div>
            </fieldset>
          </div>

          <div
            class="main"
            [class.maximized]="state.maximized"
            [class.scale-view]="state.maximized"
            [class.scale-xsmall]="state.scale === 'xsmall'"
            [class.scale-small]="state.scale === 'small'"
            [class.scale-medium]="state.scale === 'medium'"
            [class.scale-large]="state.scale === 'large'"
            [class.scale-xlarge]="state.scale === 'xlarge'"
          >
            <pl-weekly-time-grid
              [readOnly]="readOnly || isCAMView"
              [timezone]="state.timezone"
              [showWorkstations]="false"
              [blocks]="rawBlocks"
              (hoursChanged)="onHoursChanged($event)"
              [showTotalHours]="showTotalHours()"
            ></pl-weekly-time-grid>
          </div>

          <div class="debug" *ngIf="isDevDebug('DEBUG_INLINE_CONSOLE')">
            <span class="indicator" [style.font-weight]="bold">DEBUG: </span>
            <span
              class="indicator"
              [style.background-color]="'red'"
              [style.color]="'white'"
              *ngIf="state.activeBlock.style"
              >Active Block</span
            >
            <span
              class="indicator"
              [style.background-color]="'green'"
              [style.color]="'white'"
              *ngIf="state.activeBlock.clone"
              >Active Block Edit</span
            >
            <span
              class="indicator"
              [style.background-color]="'blue'"
              [style.color]="'white'"
              *ngIf="getCloneBlock()"
              >Clone Index {{ getCloneBlock().index }}</span
            >
          </div>

          <div
            *ngIf="!state.saving && !isCAMView"
            class="bottom"
            [class.max-off]="state.maximized"
          >
            <div class="available-hours" *ngIf="!isDoneEnteringTime">
              You have
              <span class="total-hours"
                >{{ state.totalAvailableHours }} available hours</span
              >
              so far.
            </div>
            <button
              *ngIf="!isDoneEnteringTime && state.totalAvailableHours > 0"
              pl-button
              (click)="onClickDoneEnteringTime()"
              class="primary"
            >
              I'm done entering time
            </button>
            <div [hidden]="!isDoneEnteringTime">
              <div *ngIf="state.totalAvailableHours >= 1 || model.maxHours > 0">
                <h4 class="margin-xlarge-t margin-b">
                  <b
                    >What is the maximum hours you'd like to work with students
                    each week?</b
                  >
                </h4>
                <span class="max-hours-label">
                  Working with students does not include therapy preparation and
                  documentation time.
                </span>
                <br />
                <div class="max-hours">
                  <mat-form-field
                    appearance="outline"
                    floatLabel="always"
                    class="max-hours-select"
                  >
                    <mat-label>Max Hours</mat-label>
                    <mat-select
                      [(ngModel)]="model.maxHours"
                      (ngModelChange)="onMaxHoursChanged($event)"
                      name="max-hours"
                      required
                    >
                      <mat-option
                        *ngFor="let option of state.maxHoursOptions"
                        [value]="option.value"
                      >
                        {{ option.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div
                    class="x-qa-total-working-hours-text"
                    *ngIf="model.maxHours > 0"
                  >
                    {{ model.maxHours }} hour{{
                      model.maxHours === 1 ? '' : 's'
                    }}
                    with students is about
                    <b
                      >{{ getTotalWorkingHours() }} total working hours per
                      week</b
                    >, including all meetings, direct sessions, and paperwork.
                  </div>
                </div>
              </div>
              <div
                *ngIf="
                  isSchoolPsychologist &&
                  assignmentTypePreference !==
                    assignmentTypePreferences.THERAPY_ONLY
                "
              >
                <h4 class="margin-xlarge-t margin-b">
                  <b>Evaluations*</b>
                </h4>
                <span class="max-hours-label">
                  Within the hours you entered above, what is the total number
                  of student envaluations you would like to do per month on
                  average?
                </span>
                <br />
                <div class="max-hours evaluations-per-month">
                  <mat-form-field
                    appearance="outline"
                    floatLabel="always"
                    class="max-hours-select"
                  >
                    <mat-label>Evals per month</mat-label>
                    <mat-select
                      [(value)]="model.evalsPerMonth"
                      name="evals-per-month"
                      required
                    >
                      <mat-option
                        *ngFor="let option of state.EvalsPerMonthOptions"
                        [value]="option.value"
                      >
                        {{ option.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div>
                <pl-alert-banner
                  type="warning"
                  *ngIf="
                    model.maxHours < previousMaxHours &&
                    !isDecreasedMaxHoursAccepted
                  "
                  class="availability-warning-banner"
                  [title]="closedLostBannerTitle"
                  buttonLabel="I Understand"
                  (buttonClicked)="understoodDecreasedMaxHours()"
                  [showCloseButton]="false"
                  >Avoid decreasing hours once you are assigned to a district.
                  Let your Empowerment Manager know when you do not want
                  additional assignments.</pl-alert-banner
                >
              </div>
            </div>
          </div>
          <div *ngIf="state.saving" class="save">
            <div class="save-message">Saving Your Availability</div>
            <div class="save-dot-loader">
              <pl-dot-loader [align]="'left'"></pl-dot-loader>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showTips" class="col col2" [class.max-off]="state.maximized">
        <div class="tips">
          <div class="header">Quick Tips</div>
          <div class="row tip">
            <div class="icon">
              <pl-icon
                [svg]="'school-building'"
                class="blue-medium"
                [height]="24"
                [width]="24"
              ></pl-icon>
            </div>
            <div class="text">
              Make yourself available during regular "school hours"
            </div>
          </div>
          <div class="row tip">
            <div class="icon">
              <pl-icon
                [svg]="'around-clock'"
                class="blue-medium"
                [height]="24"
                [width]="24"
              ></pl-icon>
            </div>
            <div class="text">Strive to be available for 4-6 hours</div>
          </div>
          <div class="row tip last">
            <div class="icon">
              <pl-icon
                [svg]="'calendar-3'"
                class="blue-medium"
                [height]="24"
                [width]="24"
              ></pl-icon>
            </div>
            <div class="text">
              Make yourself available on alternating days and afternoons
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="availability-form-footer" *ngIf="!isCAMView">
      <button
        mat-stroked-button
        color="primary"
        type="button"
        *ngIf="canSave() || isAvailabilityChanged(); else lastUpdated"
        (click)="onClickCancelForm()"
        [disabled]="(!isAvailabilityChanged() && !canSave()) || state.saving"
      >
        Cancel
      </button>
      <ng-template #lastUpdated>
        <p class="last-updated" #lastUpdated *ngIf="modified">
          Last updated on
          {{ modified }}
        </p>
      </ng-template>
      <button
        mat-stroked-button
        color="primary"
        type="button"
        *ngIf="!canConfirm(); else confirmAvailability"
        class="submit-availability-button"
        (click)="onClickSubmitForm()"
        [disabled]="!canSave() || state.saving"
      >
        <span *ngIf="!state.blocks.length">Submit</span>
        <span *ngIf="state.blocks.length">Save changes</span>
      </button>
      <ng-template #confirmAvailability>
        <!-- Sometimes providers need to updated their availability just to confirm it with no changes. -->
        <button
          mat-stroked-button
          color="primary"
          type="button"
          class="submit-availability-button"
          (click)="onClickSubmitForm()"
          [disabled]="state.saving"
        >
          <span>Confirm Availability</span>
        </button>
      </ng-template>
    </div>
  </div>
</div>
