<div class="card pl-provider-details-contact-information">
  <div class="header">
    <h3>Contact information</h3>
  </div>
  <table *ngIf="provider">
    <tbody>
      <tr *ngIf="provider.email" class="email">
        <th scope="row">Email</th>
        <td class="max-line-size">{{ provider.email }}</td>
      </tr>
      <tr *ngIf="provider.email2 && userType !== 'CUSTOMER'" class="email2">
        <th scope="row">Alternate email</th>
        <td class="max-line-size">{{ provider.email2 }}</td>
      </tr>
      <tr *ngIf="provider.phone" class="phone">
        <th scope="row">Phone number</th>
        <td>{{ provider.phone }}</td>
      </tr>
      <tr
        *ngIf="provider.sms_notification_phone && userType !== 'CUSTOMER'"
        class="sms-phone"
      >
        <th scope="row">SMS number</th>
        <td>{{ provider.sms_notification_phone }}</td>
      </tr>
      <tr
        *ngIf="provider.billing_street && userType !== 'CUSTOMER'"
        class="address"
      >
        <th scope="row">Address</th>
        <td>
          <p class="billing-street">{{ provider.billing_street }}</p>
          <p class="billing-city-state-zip">
            {{ provider.billing_city }}, {{ provider.billing_state }},
            {{ provider.billing_postal_code }}
          </p>
        </td>
      </tr>
    </tbody>
  </table>
</div>
