<div class="modal-header">
  <ng-container *ngIf="headerHtml">
    <div [innerHtml]="headerHtml"></div>
    <pl-icon
      *ngIf="!hideCloseButton"
      (click)="hide()"
      class="close-icon"
      [svg]="'close-inverted'"
    ></pl-icon>
  </ng-container>
  <ng-container *ngIf="headerText">
    <span class="header-text">{{ headerText }}</span>
    <pl-icon
      *ngIf="!hideCloseButton"
      (click)="hide()"
      class="close-icon"
      [svg]="'close-inverted'"
    ></pl-icon>
  </ng-container>
</div>
<div #plModalHeaderWrapperBody class="modal-body" tabindex="-1">
  <ng-content></ng-content>
</div>
