import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppStore } from '@app/appstore.model';

import { selectCurrentUser } from '@common/store';
import { PLApiBillingCodesService, PLHttpService } from '@root/index';
import { BillingCodesCode } from '@root/src/app/common/billing/constants';
import { PLBillingCode } from '@root/src/app/common/interfaces';
import { PLEvent } from '../../../schedule/models';

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

@Component({
  selector: 'pl-schedule-card',
  templateUrl: './pl-schedule-card.component.html',
  styleUrls: ['./pl-schedule-card.component.less'],
})
export class PlScheduleCardComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  @ViewChild('scheduleCard', { static: false }) scheduleCardRef: ElementRef;

  destroyed$ = new Subject<boolean>();
  hours: { label: string; position: number }[] = [];
  majorTicks: number[] = [];
  minorTicks: number[] = [];
  events: any[] = []; // Replace with your event data
  rawEvents: any[] = []; // Replace with your event data
  currentUser: any = {};
  loadingEvents: boolean = true;
  isHovering: boolean = false;
  scheduleCardHeight = 2160; // Assuming the schedule card height is fixed at 1440px (24 hours * 60 minutes)
  minutesInDay = 24 * 60; // Total minutes in a day (24 hours * 60 minutes)
  totalWidth = 0;
  private billingCodes: any[] = [];
  private today: String;
  private colorClasses: any = {
    Absent: 'student_absence',
    'Work with Clients': 'clients',
    'Documentation and Planning': 'documentation_planning',
    Cancelled: 'cancellation',
    Personal: 'personal',
    Other: 'other',
    'Contract Services': 'contract_services',
    'Dedicated Services': 'dedicated_services',
  };

  updateEventWidth() {
    this.totalWidth = this.scheduleCardRef.nativeElement.offsetWidth - 75;
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.scrollToCurrentInterval();
      this.totalWidth = this.scheduleCardRef.nativeElement.offsetWidth - 75;
    }, 0);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    console.log('resize');
    this.updateEventWidth();
  }

  constructor(
    private plHttp: PLHttpService,
    private store: Store<AppStore>,
    private plBillingCodes: PLApiBillingCodesService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.generateHours();
    this.generateTicks();
    this.store
      .select(selectCurrentUser)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((user: any) => {
        this.currentUser = user;
      });

    this.getSchedule();
  }

  private generateTicks(): void {
    const minutesPerPixel = this.minutesInDay / this.scheduleCardHeight;

    for (let hours = 0; hours < 24; hours++) {
      const majorTickPosition = (hours * 60 + 30) / minutesPerPixel;
      this.majorTicks.push(majorTickPosition);

      const minorTick1Position = (hours * 60 + 15) / minutesPerPixel;
      const minorTick2Position = (hours * 60 + 45) / minutesPerPixel;
      this.minorTicks.push(minorTick1Position, minorTick2Position);
    }
  }

  private generateHours(): void {
    const minutesPerPixel = this.minutesInDay / this.scheduleCardHeight;

    for (let hour = 0; hour < 24; hour++) {
      const hourPosition = (hour * 60) / minutesPerPixel;
      const formattedHour = (hour === 12 ? hour : hour % 12)
        .toString()
        .padStart(2, '0');
      this.hours.push({ label: `${formattedHour}:00`, position: hourPosition });
    }
  }
  getEventDuration(event: any): number {
    return (
      (new Date(event.end).getTime() - new Date(event.start).getTime()) /
      (1000 * 60)
    );
  }

  getSchedule() {
    if (this.currentUser.xProvider && this.currentUser.xProvider.timezone) {
      const tz = this.currentUser.xProvider.timezone;

      // get today in local timezone representation
      const now = dayjs();
      const nowLocal = now.tz(tz);

      // get the display string for today in local time
      this.today = nowLocal.format('dddd, MMM D');

      // get the start of today... still in local time
      const startOfToday = nowLocal.startOf('day');

      // get the UTC conversions to use as query start/end date range.
      const schedQueryStart = dayjs
        .utc(startOfToday)
        .add(1, 'seconds')
        .format('YYYY-MM-DDTHH:mm:ss');
      const schedQueryEnd = dayjs
        .utc(startOfToday)
        .add(1, 'days')
        .format('YYYY-MM-DDTHH:mm:ss');

      this.plHttp
        .get('appointments', {
          event_type__in: 'BILLING',
          provider: this.currentUser.uuid,
          calendar_view: true,
          start: schedQueryStart,
          end: schedQueryEnd,
        })
        .subscribe((res: any) => {
          this.loadingEvents = false;
          this.generateEvents(res.results);
        });
    } else {
      this.rawEvents = [];
      this.loadingEvents = false;
    }
  }

  getColorClass(billing_expanded: any) {
    return this.colorClasses[billing_expanded?.event_category?.name] ?? 'other';
  }

  private generateEvents(results: any[]): void {
    const tz = this.currentUser?.xProvider?.timezone;
    this.events = results
      .map(event => {
        const start = dayjs(event.start).tz(tz);
        const end = dayjs(event.end).tz(tz);
        const startMinutes = start.hour() * 60 + start.minute();
        const endMinutes = end.hour() * 60 + end.minute();

        const billing_expanded = this.plBillingCodes.getFromKey(
          'uuid',
          event.billing_code,
        );

        const isPast = dayjs(event.original_start).isBefore(dayjs());

        return {
          title:
            this.createEventTitle(event, billing_expanded) || 'Untitled Event',
          start: start,
          end: end,
          duration: `${start.format('h:mm')} - ${end.format('h:mm')}`,
          isPast,
          top: (startMinutes / this.minutesInDay) * this.scheduleCardHeight,
          height:
            ((endMinutes - startMinutes) / this.minutesInDay) *
            this.scheduleCardHeight,
          colorClass: this.getColorClass(billing_expanded),
          uuid: event.event.uuid,
          clients: event.clients.length
            ? event.clients
                .map(client => `${client.first_name} ${client.last_name[0]}.`)
                .join(' ') + ' - '
            : '',
          locations: event.locations.length
            ? event.locations.map(location => location.name).join(' ') + ' - '
            : '',
        };
      })
      .map(event => {
        const start = event.start;
        const end = event.end;
        const overlappingEvents = results.filter(otherEvent => {
          if (otherEvent === event) {
            return false;
          }
          const otherStart = dayjs(otherEvent.start).tz(tz);
          const otherEnd = dayjs(otherEvent.end).tz(tz);
          return start.isBefore(otherEnd) && end.isAfter(otherStart);
        });

        overlappingEvents.sort((a, b) => {
          const durationA = dayjs(a.end).diff(dayjs(a.start));
          const durationB = dayjs(b.end).diff(dayjs(b.start));
          return durationB - durationA;
        });

        const eventIndex = overlappingEvents.findIndex(
          otherEvent => otherEvent.event.uuid === event.uuid,
        );

        const eventMargin = (eventIndex + 1) * 20;
        const eventWidth = this.totalWidth - eventMargin;

        return {
          ...event,
          marginLeft: eventMargin,
          eventMargin,
          width: eventWidth,
          zIndex: eventIndex,
        };
      })
      .sort((a, b) => b.start.diff(a.start));
  }

  createEventTitle(event: PLEvent, billingCode?: PLBillingCode) {
    const { clients, locations, assignment_proposal_expanded } = event;

    const isDedicatedServicesBlock =
      billingCode?.code === BillingCodesCode.dedicated_services;

    /**
     * As of 2023-08-11 we have dedicated service blocks without assignment proposal
     * due to a workaround of letting sandbox account event bypass the assignment validation
     * TODO: Dedicated services blocks should always have an assignment proposal
     */
    if (isDedicatedServicesBlock) {
      const organizationLabel =
        event.organization_expanded?.name ?? event.locations[0]?.name ?? '';

      if (event.specialty) {
        return `${organizationLabel} - Dedicated (${event.specialty})`;
      }

      return `${organizationLabel} - Dedicated`;
    }

    let label = billingCode.name;

    const clientNames = clients
      .filter(c => c.first_name && c.last_name)
      .map(c => `${c.first_name} ${c.last_name[0]}.`);

    let clientNamesLabel = clientNames.join(', ');

    if (clientNames.length < clients.length) {
      clientNamesLabel = `Not in caseload ${clientNamesLabel}`;
    }

    if (clientNamesLabel) {
      label = `${clientNamesLabel} - ${label}`;
    }

    const locationNamesLabel = locations.map(l => l.name).join(', ');
    if (locationNamesLabel) {
      label = `${locationNamesLabel} - ${label}`;
    }

    return label;
  }

  private scrollToCurrentInterval(): void {
    const currentDate = new Date();
    const tzOffset = currentDate.getTimezoneOffset();
    const currentHour = currentDate.getUTCHours();
    const currentMinute = currentDate.getUTCMinutes();
    const currentSeconds = currentDate.getUTCSeconds();
    const currentTimeInMinutes = currentHour * 60 + currentMinute - tzOffset;
    const scrollPosition =
      (currentTimeInMinutes / this.minutesInDay) * this.scheduleCardHeight;
    this.scrollContainer.nativeElement.scrollTop = scrollPosition;

    // Calculate the seconds until the next 15-minute interval
    const secondsUntilNextInterval =
      15 * 60 - ((currentMinute * 60 + currentSeconds) % (15 * 60));

    // Use setTimeout to call scrollToCurrentInterval after the delay
    setTimeout(() => {
      this.scrollToCurrentInterval();

      // Use setInterval to call scrollToCurrentInterval every 15 minutes
      setInterval(() => {
        this.scrollToCurrentInterval();
      }, 15 * 60 * 1000); // 15 minutes in milliseconds
    }, secondsUntilNextInterval * 1000); // Convert seconds to milliseconds
  }
}
