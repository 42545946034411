import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PaginatedParams, PaginatedResponse } from '@common/interfaces';
import { environment } from '@environments/environment';
import { PLHttpService } from '@root/src/lib-components';

import { AssignmentProposal } from '../models';

export interface AssignmentProposalListParams extends PaginatedParams {
  provider?: string;
  organization?: string;
  school_year?: string;
  service_type?: string;
  status?: string;
  only_payable?: boolean;
}

@Injectable({ providedIn: 'root' })
export class AssignmentProposalDataService {
  constructor(private plHttp: PLHttpService) {}

  list(
    params?: AssignmentProposalListParams,
  ): Observable<PaginatedResponse<AssignmentProposal>> {
    const httpOpts = {
      method: 'GET',
      url: `${environment.apps.apiWorkplace.url}/api/v1/assignmentproposals/`,
      params,
    };

    return this.plHttp.go(httpOpts);
  }
}
