import { createAction, props } from '@ngrx/store';
import { FeatureFlagAll } from '@common/feature-flags/feature-flags';

export const loadFeatureFlags = createAction(
  '[App Component] Load Feature Flags',
);

export const loadFeatureFlagsSuccess = createAction(
  '[Feature Flags Effects] Load Feature Flags Success',
  props<{ featureFlags: FeatureFlagAll }>(),
);

export const loadFeatureFlagsFailure = createAction(
  '[Feature Flags Effects] Load Feature Flags Failure',
  props<{ error: any }>(),
);
