declare var require: any;
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as papaparse from 'papaparse';
import dayjs from 'dayjs';
// import * as xlsxPopulate from 'xlsx-populate/browser/xlsx-populate';

export interface ImportedSheet {
  name: string;
  values: string[][];
  formats: string[][];
}

@Injectable()
export class PLFileImportService {
  Papa: any = papaparse;

  // temporarily using require until typings are created for xlsx-populate
  xlsxPop = require('xlsx-populate/browser/xlsx-populate');

  readonly NO_SHEETS: string = 'No visible sheets!';
  readonly UNSUPPORTED: string = 'Unsupported file type';
  readonly DEPRECTATED_TEMPLATE_FORMAT: string =
    'Deprecated Template Format Detected';

  // the standard number format strings generated by Excel
  readonly standardFormats = [
    'General',
    '0',
    '0.00',
    '#,##0',
    '#,##0.00',
    '0%',
    '0.00%',
    '0.00E+00',
    '# ?/?',
    '# ??/??',
    'm/d/yy',
    'd-mmm-yy',
    'd-mmm',
    'mmm-yy',
    'h:mm AM/PM',
    'h:mm:ss AM/PM',
    'h:mm',
    'h:mm:ss',
    'm/d/yy h:mm',
    '#,##0 ,(#,##0)',
    '#,##0 ,[Red](#,##0)',
    '#,##0.00,(#,##0.00)',
    '#,##0.00,[Red](#,##0.00)',
    'mm:ss',
    '[h]:mm:ss',
    'mmss.0',
    '##0.0E+0',
    '@',
    '"上午/下午 "hh"時"mm"分"ss"秒 "',
  ];

  // the standard number format strings generated by Excel that are *not* dates
  readonly nonDateStandardFormats = [
    'General',
    '0',
    '0.00',
    '#,##0',
    '#,##0.00',
    '0%',
    '0.00%',
    '0.00E+00',
    '#,##0 ,(#,##0)',
    '#,##0 ,[Red](#,##0)',
    '#,##0.00,(#,##0.00)',
    '#,##0.00,[Red](#,##0.00)',
    '##0.0E+0',
    '@',
  ];

  readonly abstime = /\[[HhMmSs]*\]/;

  // borrowing this from SheetJS until xlsx-populate has support
  // parses a number format string and determines whether it is (probably) a date format
  formatIsDate(fmt: string): boolean {
    if (this.nonDateStandardFormats.includes(fmt.trim())) {
      return false;
    }
    let i = 0;
    let c = '';
    let o = '';
    while (i < fmt.length) {
      switch ((c = fmt.charAt(i))) {
        case '"':
          for (; fmt.charCodeAt(++i) !== 34 && i < fmt.length; ) ++i;
          ++i;
          break;
        case '\\':
          i += 2;
          break;
        case '_':
          i += 2;
          break;
        case '@':
          ++i;
          break;
        case 'B':
        case 'b':
          if (fmt.charAt(i + 1) === '1' || fmt.charAt(i + 1) === '2')
            return true;
        /* falls through */
        case 'M':
        case 'D':
        case 'Y':
        case 'H':
        case 'S':
        case 'E':
        /* falls through */
        case 'm':
        case 'd':
        case 'y':
        case 'h':
        case 's':
        case 'e':
        case 'g':
          return true;
        case 'A':
        case 'a':
          if (fmt.substr(i, 3).toUpperCase() === 'A/P') return true;
          if (fmt.substr(i, 5).toUpperCase() === 'AM/PM') return true;
          ++i;
          break;
        case '[':
          o = c;
          while (fmt.charAt(i++) !== ']' && i < fmt.length) o += fmt.charAt(i);
          if (o.match(this.abstime)) return true;
          break;
        case '.':
        /* falls through */
        case '0':
        case '#':
          while (
            i < fmt.length &&
            ('0#?.,E+-%'.indexOf((c = fmt.charAt(++i))) > -1 ||
              (c === '\\' &&
                fmt.charAt(i + 1) === '-' &&
                '0#'.indexOf(fmt.charAt(i + 2)) > -1))
          ) {
            /* empty */
          }
          break;
        case '?':
          while (fmt.charAt(++i) === c) {
            /* empty */
          }
          break;
        case '*':
          ++i;
          if (fmt.charAt(i) === ' ' || fmt.charAt(i) === '*') ++i;
          break;
        case '(':
        case ')':
          ++i;
          break;
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
          while (i < fmt.length && '0123456789'.indexOf(fmt.charAt(++i)) > -1) {
            /* empty */
          }
          break;
        case ' ':
          ++i;
          break;
        default:
          ++i;
          break;
      }
    }
    return false;
  }

  // takes the array of xml nodes generated by the parser when a cell contains rich text, pulls the text
  // from each node, and returns a single plain text string
  richToPlainText(richTextArray: any[]) {
    let text = '';
    richTextArray.forEach((node: any) => {
      if (node.children && node.children.length) {
        node.children.forEach((child: any) => {
          if (
            child.name &&
            child.name === 't' &&
            child.children &&
            child.children.length
          ) {
            text += child.children[0];
          }
        });
      }
    });
    return text;
  }

  // filter out the hidden sheets, then re-map the sheet structure
  // @param sheets - sheets xslx-populate objects
  // @returns - sheets flattened to ImportedSheet interface
  transformSheets(sheets: any) {
    return sheets
      .filter((sheet: any) => {
        return !sheet.hidden();
      })
      .map((sheet: any) => {
        return {
          name: sheet.name(),
          values: sheet.usedRange().value(),
          formats: sheet.usedRange().style('numberFormat'),
        };
      });
  }

  // filter out the hidden sheets, then re-map the sheet structure
  // @param sheets - sheets xslx-populate objects
  // @returns parsedSheets - sheets flattened to ImportedSheet interface with some data massaging
  parseSheets(sheets: any) {
    const parsedSheets: ImportedSheet[] = this.transformSheets(sheets);

    // iterate over every value in every sheet to do a little massaging of some xlsx-populate peculiarities
    parsedSheets.forEach((sheet: any) => {
      sheet.values.forEach((row: any, rowIndex: number) => {
        row.forEach((val: any, colIndex: number) => {
          // a cell value is an array when it is rich text. convert that to plain text
          if (Array.isArray(val)) {
            row[colIndex] = this.richToPlainText(val);
          } else if (typeof val === 'number') {
            // check if the number is meant to be a date, then parse it as such
            const format = sheet.formats[rowIndex][colIndex];
            if (this.formatIsDate(format)) {
              const date = this.xlsxPop.numberToDate(val);
              const dayjsDate = dayjs(date);
              try {
                const formattedDate = dayjsDate.format(format.toUpperCase());
                row[colIndex] = formattedDate;
              } catch (err) {
                console.log('error formatting date: ', err);
              }
            } else {
              // non-date numbers should be cast to strings.
              row[colIndex] = '' + val;
            }
          } else if (typeof val === 'boolean') {
            row[colIndex] = val.toString();
          } else if (!val) {
            // change undefined cell values to empty strings
            row[colIndex] = '';
          }
        });
      });
    });

    return parsedSheets;
  }

  parseXlsFile(file: any): Observable<[ImportedSheet]> {
    return new Observable((observer: any) => {
      this.xlsxPop.fromDataAsync(file).then(
        (workbook: any) => {
          const sheets = workbook.sheets();
          if (sheets.length === 0) {
            observer.error(this.NO_SHEETS);
          } else {
            const parsedSheets: ImportedSheet[] = this.parseSheets(sheets);
            observer.next(parsedSheets);
          }
        },
        (err: any) => {
          console.log('Workbook reading error: ', err);
          observer.error(this.UNSUPPORTED);
        },
      );
    });
  }

  papaParseFile(file: any): Observable<[ImportedSheet]> {
    return new Observable((observer: any) => {
      this.Papa.parse(file, {
        complete: (results: any) => {
          observer.next([{ name: 'Sheet1', values: results.data }]);
        },
        error: (error: any) => {
          console.log('error papaparsing: ', error);
          observer.error(error);
        },
      });
    });
  }

  parseFile(file: any): Observable<[ImportedSheet]> {
    return file.type.includes('csv')
      ? this.papaParseFile(file)
      : this.parseXlsFile(file);
  }
}
