import { DemandSpecialty } from './constants';

export const SPECIALTIES_LONG_NAME: Record<DemandSpecialty, string> = {
  AAC: 'AAC',
  ASL: 'ASL',
  BI: 'Bilingual',
  DHH: 'DHH',
  ST: 'Short-term Leave',
  SU: 'Supervision Hours',
  VI: 'Visually Impaired',
};
