export enum CollapseStatus {
  COLLAPSE = 'Collapse',
  EXPAND = 'Expand',
}

export enum PLCheckistItemStatus {
  COMPLETE = 'Complete',
  INCOMPLETE = 'Incomplete',
}

export type PLCheckistItem = {
  id: string;
  code: string;
  order: number;
  title: string;
  description: string;
  fn?: () => void;
  link?: string;
  linkText: string;
  infoDescription: string;
  status: PLCheckistItemStatus;
  collapsed: boolean;
  value?: number;
};

export type PLChecklist = {
  id: string;
  title: string;
  description: string;
  items: PLCheckistItem[];
  collapsed: boolean;
};
