import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { User } from '@modules/user/user.model';
import dayjs from 'dayjs';

enum PLEmploymentStatus {
  FULL_TIME_EMPLOYEE = 'Full-Time Employee',
  PART_TIME_EMPLOYEE = 'Part-Time Employee',
  SALARIED_EMPLOYEE = 'Salaried Employee',
  INDEPENDENT_CONTRACTOR = 'Independent Contractor',
  SUBCONTRACTOR = 'Subcontractor',
}

@Injectable()
export class PLPendoService {
  user: User = null;
  pendoKey: string;
  visitor: {
    id: string; // Required if user is logged in, default creates anonymous ID
    email: string; // Recommended if using Pendo Feedback, or NPS Email
    full_name: string; // Recommended if using Pendo Feedback
    // role: string // Optional
    // You can add any additional visitor level key-values here,
    // as long as it's not one of the above reserved names.
    // machine_id: string, // TODO: not available for now
    username: string;
    auth_groups: string[];
    years_with_presence: number;
  };

  account: {
    id: string; // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
    // name:         // Optional
    // is_paying:    // Recommended if using Pendo Feedback
    // monthly_value:// Recommended if using Pendo Feedback
    // planLevel:    // Optional
    // planPrice:    // Optional
    // creationDate: // Optional

    // You can add any additional account level key-values here,
    // as long as it's not one of the above reserved names.
  };

  constructor() {
    const envPendo =
      localStorage.getItem('pl-pendo-key') || environment.pendo_key;
    this.pendoKey = envPendo === 'undefined' ? null : envPendo;
    this.loadPendo();
  }

  loadPendo() {
    if (!(this.pendoKey && this.pendoKey.length)) return;
    const loadPendoScriptElement = document.createElement('script');
    loadPendoScriptElement.type = 'text/javascript';
    loadPendoScriptElement.innerHTML = `
      window.loadPendo = function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
      };
    `;
    document.head.appendChild(loadPendoScriptElement);
    globalThis.loadPendo(this.pendoKey);
    globalThis.pendo = globalThis.pendo || [];
  }

  private getPayrollStatus(user: User): 'W2' | '1099' | null {
    let employmentStatus: PLEmploymentStatus = user.xProvider.employmentStatus;
    switch (employmentStatus) {
      case PLEmploymentStatus.FULL_TIME_EMPLOYEE:
      case PLEmploymentStatus.PART_TIME_EMPLOYEE:
      case PLEmploymentStatus.SALARIED_EMPLOYEE:
        return 'W2';
      case PLEmploymentStatus.INDEPENDENT_CONTRACTOR:
      case PLEmploymentStatus.SUBCONTRACTOR:
        return '1099';
      default:
        return null;
    }
  }

  setUser(user: User) {
    if (!(this.pendoKey && this.pendoKey.length)) return;

    const pendo = globalThis.pendo;
    // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
    // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
    // Call this function in your authentication promise handler or callback when your visitor and account id values are available
    // Please use Strings, Numbers, or Bools for value types.

    let providerData: {
      discipline: [];
      billing_state: string;
      payroll_type: string;
      employment_status: string;
      complete_onboarding: boolean;
      sub_status: string;
    };

    if (user?.xProvider) {
      providerData = {
        discipline: user?.xProvider?.providerTypes.map(
          providerType => providerType.long_name,
        ),
        billing_state: user?.xProvider?.billing_state,
        payroll_type: this.getPayrollStatus(user),
        employment_status: user?.xProvider?.employmentStatus,
        complete_onboarding: user?.xProvider?.isOnboardingWizardComplete,
        sub_status: user?.xProvider?.providerSubStatus,
      };
    }

    this.visitor = {
      id: user.uuid,
      email: user.email,
      full_name: `${user.first_name} ${user.last_name}`,
      username: user.username,
      auth_groups: user.groups,
      years_with_presence: dayjs(new Date(user?.last_login)).diff(
        new Date(user?.created),
        'years',
        false,
      ),
      ...providerData,
    };

    this.account = {
      id: this.pendoKey,
    };

    pendo.initialize({
      visitor: this.visitor,
      account: this.account,
    });
  }
}
