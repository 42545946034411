<div class="pl-psp-training-modal">
  <pl-modal-header-wrapper
    [headerText]="'Send Primary Support Person (PSP) Resources'"
  >
    A Primary Support Person (PSP) ensures students have the on-site support and
    resources they need to participate fully in therapy sessions. Online
    training materials are available to help PSPs understand their role and get
    comfortable with the platform.
    <br /><br />
    Use this form to send out a link to the PSP Online Training Resources. You
    can repeat this step as needed to send additional links.
    <br /><br />
    <form [formGroup]="pspTrainingForm">
      <div class="form-content">
        <pl-input-text
          [formCtrl]="pspTrainingForm"
          [label]="'Email Address '"
          [(model)]="pspTrainingFormModel.email"
          [type]="'email'"
          [required]="true"
        >
        </pl-input-text>
        <pl-input-checkbox
          class="checkbox"
          [(model)]="pspTrainingFormModel.sendCopy"
          [label]="'Send a copy of the email to my Presence email account'"
        >
        </pl-input-checkbox>
      </div>
      <br />
      <div class="form-actions">
        <button
          pl-button
          type="button"
          class="primary"
          (click)="sendEmail(pspTrainingForm)"
        >
          Email Request
        </button>
      </div>
    </form>
  </pl-modal-header-wrapper>
</div>
