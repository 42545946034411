import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { map, first, tap, switchMap } from 'rxjs/operators';

import { EMPLOYMENT_STATUS } from '@common/constants';
import { CurrentUserService } from '@modules/user/current-user.service';
import { User } from '@modules/user/user.model';

import { PLMayService, PLGraphQLService } from '@root/index';

import providerQuery from './queries/provider.graphql';

@Injectable()
export class PLProviderService {
  private provider: any = {};
  private providerUserUuid = '';
  private currentUser: User;

  private tabs: any[] = [];
  private mayViewProvider = false;
  private isSelfProvider = false;
  private mayViewPersonnel = false;
  private mayViewPayRates = false;

  private getFromRouteSubject = new ReplaySubject();

  constructor(
    private router: Router,
    private plMay: PLMayService,
    private plGraphQL: PLGraphQLService,
    private plCurrentUserService: CurrentUserService,
  ) {}

  getFromRoute() {
    const curUuid = this.router.url.split('/')[2];
    if (curUuid !== this.providerUserUuid) {
      this.providerUserUuid = curUuid;

      this.plCurrentUserService
        .getCurrentUser()
        .pipe(
          tap((u: User) => (this.currentUser = u)),
          switchMap(() => this.getProvider()),
        )
        .subscribe(() => {
          this.getFromRouteSubject.next({
            provider: this.provider,
            currentUser: this.currentUser,
            mayViewProvider: this.mayViewProvider,
          });
        });
    }

    return this.getFromRouteSubject.asObservable();
  }

  private providerTitle(provider: any): string {
    const providerTypes = (provider && provider.providerTypes) || [];
    return providerTypes.map((type: any) => type.longName).join(', ');
  }

  getProvider(providerUuid?: string) {
    return new Observable((observer: any) => {
      this.getProviderByUserId(providerUuid || this.providerUserUuid).subscribe(
        (providerProfile: any) => {
          this.mayViewProvider = true;

          this.provider = this.formatProvider(providerProfile);
          this.provider.title = this.providerTitle(this.provider);

          observer.next({ provider: this.provider });
        },
      );
    });
  }

  getProviderByUserId(userId: any) {
    return this.plGraphQL.query(providerQuery, { userId }, {}).pipe(
      map((obj: any) => obj.providerProfile),
      first(),
    );
  }

  formatProvider(provider: any) {
    if (!provider.accountOwner) {
      provider.accountOwner = {};
    }
    return provider;
  }

  calculatePermissions(currentUser, provider) {
    let mayViewProvider = false;
    let isAdminType = false;
    let hasViewPersonnelPermission = false;
    let mayViewRates = false;
    let mayViewPersonnel = false;
    let isSelfProvider = false;

    if (provider && provider.user && currentUser) {
      mayViewProvider =
        provider.user.id === currentUser.uuid ||
        provider.user.permissions.viewSchedule;

      isAdminType = this.plMay.isAdminType(currentUser);
      hasViewPersonnelPermission =
        currentUser.xGlobalPermissions &&
        currentUser.xGlobalPermissions.viewPersonnel;

      isSelfProvider = provider.user.id === currentUser.uuid;
      mayViewPersonnel =
        currentUser.xGlobalPermissions &&
        currentUser.xGlobalPermissions.viewPersonnel
          ? true
          : false;
      mayViewRates =
        (isSelfProvider &&
          provider?.employmentStatus !== EMPLOYMENT_STATUS.SALARIED_EMPLOYEE) ||
        isAdminType ||
        hasViewPersonnelPermission;
    }

    return {
      mayViewProvider,
      mayViewPersonnel,
      mayViewRates,
      isSelfProvider,
    };
  }

  getTabs(currentUser: { firstName: string; lastName: string }) {
    const hrefBase = `/provider/${this.providerUserUuid}/`;
    const prefix = `${currentUser.firstName} ${currentUser.lastName} -`;
    const replaceHistory = { replaceHistory: true };

    const isSelf = this.provider.user.id === this.currentUser.uuid;
    const { mayViewProvider, mayViewPersonnel, isSelfProvider, mayViewRates } =
      this.calculatePermissions(this.currentUser, this.provider);
    const isNotSubcontractor =
      this.provider.employmentStatus !== EMPLOYMENT_STATUS.SUBCONTRACTOR;

    this.tabs = [];

    this.tabs.push({
      replaceHistory,
      href: `${hrefBase}overview`,
      label: 'Overview',
      pageTabTitle: `${prefix} Overview`,
    });

    if (isSelf) {
      this.tabs.push({
        replaceHistory,
        href: `${hrefBase}caseload`,
        label: 'Caseload',
        pageTabTitle: `${prefix} Caseload`,
      });
    }

    this.tabs.push({
      replaceHistory,
      href: `${hrefBase}locations`,
      label: 'Locations',
      pageTabTitle: `${prefix} Locations`,
    });

    if (
      isSelf ||
      this.plMay.isAdminType(this.currentUser) ||
      this.plMay.isSuperuser(this.currentUser)
    ) {
      this.tabs.push({
        replaceHistory,
        href: `${hrefBase}qualifications`,
        label: 'Qualifications',
        pageTabTitle: `${prefix} Qualifications`,
      });
    }

    if (mayViewProvider) {
      const providerId =
        this.provider.user.id !== this.currentUser.uuid
          ? this.provider.user.id
          : '';
      this.tabs.push({
        href: `/schedule/calendar/${providerId}`,
        label: 'Schedule',
      });
    }

    const mayViewAssignments =
      this.plMay.isSupport(this.currentUser) ||
      this.plMay.isClinicalAccountManager(this.currentUser);

    if (mayViewAssignments) {
      this.tabs.push({
        href: `${hrefBase}assignments`,
        label: 'Assignments',
        replaceHistory: true,
      });
    }

    const mayViewAvailability =
      this.plMay.isSupport(this.currentUser) ||
      this.plMay.isClinicalAccountManager(this.currentUser) ||
      !this.plMay.isCustomer(this.currentUser);

    if (mayViewAvailability) {
      this.tabs.push({
        href: `${hrefBase}availability`,
        label: 'Availability',
        replaceHistory: true,
      });
    }

    if (
      mayViewProvider &&
      (mayViewPersonnel || isSelfProvider) &&
      mayViewRates &&
      isNotSubcontractor
    ) {
      this.tabs.push({
        href: `${hrefBase}provider-pay-rates`,
        label: 'Provider Pay Rates',
        replaceHistory: true,
      });
    }

    return this.tabs;
  }
}
