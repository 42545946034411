import { Component, Input } from '@angular/core';

import { ChartDataset } from 'chart.js';
import { PLStylesService } from '@root/index';

@Component({
  selector: 'pl-account-student-status-chart',
  templateUrl: './pl-account-student-status-chart.component.html',
  styleUrls: ['./pl-account-student-status-chart.component.less'],
})
export class PLAccountStudentStatusChartComponent {
  @Input() studentStats: any;
  @Input() sideBySide: boolean;

  private backgroundColor = [
    this.getColor('yellow'),
    this.getColor('green'),
    this.getColor('gray'),
  ];
  private borderColor = Array(3).fill(this.getColor('white'));
  private borderHoverColor = Array(3).fill(this.getColor('gray-light'));

  studentStatusChartData: any = {
    datasets: [{ data: [0, 0, 0] }],
    datasetsForChart: [{ data: [0, 0, 0] }],
    labels: [
      SERVICE_STATUS.ONBOARDING.name,
      SERVICE_STATUS.IN_SERVICE.name,
      SERVICE_STATUS.NOT_IN_SERVICE.name,
    ],
    options: {
      responsive: true,
      plugins: { legend: { display: false } },
    },
  };

  constructor(private plStyles: PLStylesService) {}

  ngOnChanges(changes: any) {
    if (changes.studentStats) {
      const onboarding = this.getStatusCount(
        this.studentStats,
        SERVICE_STATUS.ONBOARDING,
      );
      const inService = this.getStatusCount(
        this.studentStats,
        SERVICE_STATUS.IN_SERVICE,
      );
      const notInService = this.getStatusCount(
        this.studentStats,
        SERVICE_STATUS.NOT_IN_SERVICE,
      );

      const datasets: ChartDataset<'doughnut'>[] = [
        {
          data: [onboarding, inService, notInService],
          backgroundColor: this.backgroundColor,
          borderColor: this.borderColor,
          hoverBackgroundColor: this.backgroundColor,
          hoverBorderColor: this.borderHoverColor,
        },
      ];

      if (onboarding === 0 && inService === 0 && notInService === 0) {
        this.studentStatusChartData = null;
      } else {
        this.studentStatusChartData.datasets = datasets;

        // timeout here helps with animation
        setTimeout(() => {
          if (this.studentStatusChartData != null) {
            this.studentStatusChartData.datasetsForChart =
              this.studentStatusChartData.datasets;
          }
        }, 100);
      }
    }
  }

  private getStatusCount(statusCounts: any, status: any) {
    return statusCounts.find((item: any) => item.name === status.name).count;
  }

  private getColor(color: string) {
    return `#${this.plStyles.getColorForName(color)}`;
  }
}

const SERVICE_STATUS = {
  IN_SERVICE: { name: 'In Service' },
  NOT_IN_SERVICE: { name: 'Not In Service' },
  ONBOARDING: { name: 'Onboarding' },
};
