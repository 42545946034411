import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { PLTimezoneService } from '@root/src/lib-components';
import { RootState } from '@app/store';
import { PLSetInvoicePeriod } from '@common/store/invoice';

import * as actions from '../next-timesheet.actions';

@Injectable()
export class SetInvoicePeriodEffects {
  setInvoicePeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.fetchNextTimesheetSuccess),
      concatLatestFrom(() => this.store.select('currentUser')),
      map(([{ nextTimesheet }, user]) => {
        const lastRefresh = this.plTimezone.getUserToday(user);
        let start = '';
        let end = '';
        let dueDate = '';
        let submitStatus = '';
        if (nextTimesheet && !nextTimesheet.catchedError) {
          end = nextTimesheet.work_period_expanded.end_date;
          start = nextTimesheet.work_period_expanded.start_date;
          dueDate = nextTimesheet.work_period_expanded.due_date;
          submitStatus = nextTimesheet.status;
        }
        return PLSetInvoicePeriod({
          invoicePeriod: {
            end,
            start,
            dueDate,
            submitStatus,
            lastRefresh,
            userId: user.uuid,
          },
        });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private plTimezone: PLTimezoneService,
  ) {}
}
