<div
  class="background"
  *ngIf="
    currentUser.xProvider.providerSubStatus === 'Onboarding' &&
    !tasksAllComplete
  "
>
  <div class="pl-provider-onboarding-v2" *ngIf="loaded">
    <div>
      <h2>Hello {{ firstName }}! 👋</h2>

      <p class="steps-text">
        You have the following steps to complete and check off before you can
        start your first assignment. You can review your progress below.
      </p>

      <h3 class="onboarding-heading">Onboarding checklist</h3>

      <div
        class="checkbox-card"
        [ngClass]="{ active: activeTask === providerProfileTask.taskType.code }"
      >
        <div class="checkbox-row">
          <mat-checkbox
            (click)="tasksOnboardingCompleteTask($event, providerProfileTask)"
            [checked]="providerProfileTask.isComplete"
          ></mat-checkbox>
          <div class="checkbox-title">Provider profile</div>
          <a
            mat-button
            [href]="profileHref"
            target="_blank"
            [ngClass]="{
              complete: providerProfileTask.isComplete,
              'mat-primary': !providerProfileTask.isComplete
            }"
            (click)="setActive(providerProfileTask.taskType.code)"
          >
            Edit profile
          </a>
        </div>
        <p class="checkbox-text">{{ taskCopy.providerProfile }}</p>
      </div>

      <div
        class="checkbox-card"
        [ngClass]="{ active: activeTask === availabilityTask.taskType.code }"
      >
        <div class="checkbox-row">
          <mat-checkbox
            (click)="tasksOnboardingCompleteTask($event, availabilityTask)"
            [checked]="availabilityTask.isComplete"
          ></mat-checkbox>
          <div class="checkbox-title">Availability</div>
          <a
            mat-button
            [href]="availabilityHref"
            target="_blank"
            [ngClass]="{
              complete: availabilityTask.isComplete,
              'mat-primary': !availabilityTask.isComplete
            }"
            (click)="setActive(availabilityTask.taskType.code)"
          >
            Fill out availability
          </a>
        </div>
        <p class="checkbox-text">{{ taskCopy.availability }}</p>
      </div>

      <div
        class="checkbox-card"
        [ngClass]="{ active: activeTask === telehealthTask.taskType.code }"
      >
        <div class="checkbox-row">
          <mat-checkbox
            (click)="tasksOnboardingCompleteTask($event, telehealthTask)"
            [checked]="telehealthTask.isComplete"
          ></mat-checkbox>
          <div class="checkbox-title">Telehealth Institute</div>
          <a
            mat-button
            [href]="telehealthHref"
            target="_blank"
            [ngClass]="{
              complete: telehealthTask.isComplete,
              'mat-primary': !telehealthTask.isComplete
            }"
            (click)="setActive(telehealthTask.taskType.code)"
          >
            Start coursework
          </a>
        </div>
        <p class="checkbox-text">{{ taskCopy.telehealthInstitute }}</p>
      </div>

      <div
        class="checkbox-card"
        [ngClass]="{ active: activeTask === techCheckTask.taskType.code }"
      >
        <div class="checkbox-row">
          <mat-checkbox
            (click)="tasksOnboardingCompleteTask($event, techCheckTask)"
            [checked]="techCheckTask.isComplete"
          ></mat-checkbox>
          <div class="checkbox-title">Tech check</div>
          <a
            mat-button
            [href]="techCheckTask.actionUrl"
            target="_blank"
            [ngClass]="{
              complete: techCheckTask.isComplete,
              'mat-primary': !techCheckTask.isComplete
            }"
            (click)="setActive(techCheckTask.taskType.code)"
          >
            Run tech check
          </a>
        </div>
        <p class="checkbox-text">{{ taskCopy.techCheck }}</p>
      </div>
    </div>
    <div>
      <div class="provider-profile-card">
        <div class="initials">
          {{ initials }}
        </div>
        <div>
          <div class="name">{{ fullName }}</div>
          <div class="title">{{ title }}</div>
          <a [href]="profileHref" target="_blank"
            >Edit your profile &#38; preferences</a
          >
        </div>
      </div>
      <div class="clinical-team-card">
        <h4>Have a clinical question?</h4>
        <p class="clinical-text">
          Your clinical team is on hand to provide guidance and answer any
          questions you may have
        </p>

        <div class="contact-card">
          <div class="initials">{{ CEMInitials }}</div>
          <div class="contact-card-info">
            <div class="name">{{ CEMName }}</div>
            <div class="title">Empowerment Manager</div>
            <a [href]="'mailto:' + CEMEmail">{{ CEMEmail }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
