<div class="card pl-provider-details-room-details">
  <div class="header">
    <h3>Room details</h3>
  </div>

  <table>
    <tbody>
      <tr>
        <th scope="row">User name</th>
        <td class="username">{{ provider.username }}</td>
      </tr>
      <tr *ngIf="userType !== 'CUSTOMER'">
        <th scope="row">Room URL</th>
        <td *ngIf="mayViewRoom">
          <a
            href="{{ plUrls.urls.roomFE }}/{{ provider.username }}"
            target="_blank"
            class="room-url"
          >
            {{ plUrls.urls.roomFE }}/{{ provider.username }}
          </a>
        </td>
      </tr>
      <tr *ngIf="userType !== 'CUSTOMER'">
        <th scope="row">Observation mode</th>
        <td *ngIf="mayObserve">
          <a
            href="{{ plUrls.urls.roomFE }}/observe/{{ provider.username }}"
            target="_blank"
            class="observe-url"
          >
            View Room
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
