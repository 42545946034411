export interface TherapySchedule {
  scheduleFirstDate?: string;
  scheduleDays?: string;
  scheduleTime?: string;
  scheduleLocation?: string;
}

export interface TherapySchedulePayload extends TherapySchedule {
  serviceId: string;
  id: string;
}

export enum TherapyScheduleServiceType {
  DIRECT_SERVICE = 'directService',
  EVALUATION_SERVICE = '"evaluationService"',
}
