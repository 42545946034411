import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { PLAssignedLocationsService } from '@common/services';
import { AppStore } from '@root/src/app/appstore.model';
import { PLProviderTypeCode } from '@root/src/app/common/enums';

import {
  selectCurrentUser,
  selectCurrentUserLoaded,
  selectIsW2User,
} from '@root/src/app/common/store';
import {
  PLApiBillingCodesService,
  PLMayService,
} from '@root/src/lib-components';
import { PLProviderService } from '../../../providers/pl-provider.service';

import { PLTimesheetProgressAssignmentFilterService } from '../../../schedule/components/pl-timesheet-progress-bar/services/pl-timesheet-progress-assignment-filter.service';

import { User } from '../../../user/user.model';

@Component({
  selector: 'pl-landing-page',
  templateUrl: './pl-landing-page.component.html',
  styleUrls: ['./pl-landing-page.component.less'],
})
export class PlLandingPageComponent implements OnInit {
  currentUser: any = {};
  organizations: { name: string; id: string }[] = [];
  hasDedicatedAssignments$: Observable<boolean>;
  showOrgHandbooks: boolean;
  private isW2User: boolean;
  private isMHCProvider: boolean;
  private billingCodes: any[] = [];
  accountOwner: any = {};

  public showCEMInfo = false;
  public isProvider = false;

  constructor(
    private store: Store<AppStore>,

    private plMay: PLMayService,

    private router: Router,

    private plBillingCodes: PLApiBillingCodesService,
    private plProviderService: PLProviderService,
    private plAssignedLocationsService: PLAssignedLocationsService,
    private assignmentFilterService: PLTimesheetProgressAssignmentFilterService,
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectCurrentUserLoaded)
      .pipe(
        filter(loadedUser => loadedUser),
        switchMap(() =>
          forkJoin([
            this.store.select(selectCurrentUser).pipe(first()),
            this.store.select(selectIsW2User).pipe(first()),
          ]),
        ),
      )
      .subscribe(([user, isW2]: [User, boolean]) => {
        this.showOrgHandbooks =
          this.plMay.isProvider(user) && !this.plMay.isLead(user);
        this.currentUser = user;
        this.isW2User = isW2;
        this.isMHCProvider =
          user?.xProvider?.providerTypeCode === PLProviderTypeCode.MHP;

        if (user.groups) {
          if (!this.plMay.canAccessProviderLanding(user)) {
            this.router.navigateByUrl('/not-found', {
              skipLocationChange: true,
            });
          }

          this.plBillingCodes.get().subscribe((res: any) => {
            this.billingCodes = res;
          });

          if (this.plMay.isProvider(user)) {
            this.isProvider = true;
            this.plProviderService
              .getProviderByUserId(user?.uuid)
              .subscribe((provider: any) => {
                if (provider) {
                  if (provider.accountOwner) {
                    this.accountOwner = provider.accountOwner;
                    this.showCEMInfo = true;
                  }
                }
              });

            this.hasDedicatedAssignments$ =
              this.assignmentFilterService.data$.pipe(
                map(assignments => assignments?.length > 0),
              );
          }
          this.plAssignedLocationsService.getLocations({}).subscribe(
            (results: { locations: any[]; filteredTotalCount: number }) => {
              this.organizations = results.locations
                .map((location: any) => ({
                  name: location.organizationName,
                  id: location.organizationId,
                }))
                .filter(
                  (org, index, self) =>
                    index ===
                    self.findIndex(t => t.id === org.id && t.name === org.name),
                );
            },
            (error: any) => {
              console.error(`There was an error while querying the locations.`);
              console.error(error);
            },
          );
        }
      });
  }
  hasClinicalContacts(event) {
    this.showCEMInfo = event;
  }
}
