<div class="card pl-provider-details-signed-agreement-details">
  <div class="header">
    <h3>Signed agreements</h3>
  </div>
  <div>
    <table>
      <thead>
        <tr>
          <th>Title</th>
          <th>Signed date</th>
          <th>Effective date</th>
        </tr>
      </thead>
      <tr *ngFor="let agreement of agreements">
        <td>
          <a [href]="agreement.document_url">
            {{ agreement.code }}
            <mat-icon>open_in_new</mat-icon>
          </a>
        </td>
        <td>{{ agreement.agreed_on | date : 'MM/dd/yyyy' }}</td>
        <td>{{ agreement.as_of_date | date : 'MM/dd/yyyy' }}</td>
      </tr>
    </table>
  </div>
</div>
