import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'pl-fulfillment-pill',
  templateUrl: 'fulfillment-pill.component.html',
  styleUrls: ['./fulfillment-pill.component.less'],
})
export class FulfillmentPillComponent implements OnChanges {
  @Input() public light = false;
  @Input() public fulfillmentPercent = 0;
  @Input() public customLabel: string;
  @Input() public isInteractive = false;
  @Input() public isClosedLost = false;

  levelClassName = 'pill-red';

  ngOnChanges() {
    if (this.isClosedLost) {
      this.levelClassName = 'pill-closed-lost';
    } else {
      this.levelClassName = this.getLevelClassName(this.fulfillmentPercent);
    }
  }

  private getLevelClassName(fulfillmentPercent: number) {
    if (fulfillmentPercent >= 68) {
      return 'pill-green';
    }
    if (fulfillmentPercent >= 34) {
      return 'pill-yellow';
    }
    return 'pill-red';
  }
}
