import { PLProviderTypeAndServiceCodeFY23 } from '../enums';

// https://codegolf.stackexchange.com/questions/4707/outputting-ordinal-numbers-1st-2nd-3rd/119563#119563
export const getOrdinalNumber = (n: number) => {
  return `${n}${[, 'st', 'nd', 'rd'][(n % 100 >> 3) ^ 1 && n % 10] || 'th'}`; // eslint-disable-line no-sparse-arrays
};

export const SET_STORAGE = (
  storageKey: string,
  content: any,
  minutesToExpire?: number,
) => {
  let expiration;
  if (minutesToExpire) {
    expiration = new Date();
    expiration.setTime(expiration.getTime() + minutesToExpire * 60000);
  }
  sessionStorage.setItem(
    storageKey,
    JSON.stringify({
      content,
      expiration,
    }),
  );
};

export const GET_STORAGE = (storageKey: string) => {
  const storageString = sessionStorage.getItem(storageKey);
  if (!storageString) {
    return null;
  }
  const storage = JSON.parse(storageString);
  const expiration = new Date(storage.expiration);
  if (expiration) {
    const now = new Date();
    if (now.getTime() > expiration.getTime()) {
      sessionStorage.removeItem(storageKey);
      return null;
    }
  }
  return storage.content;
};

export const REMOVE_STORAGE = (storageKey: string) => {
  const storageExists = sessionStorage.getItem(storageKey);
  if (storageExists) {
    sessionStorage.removeItem(storageKey);
  }
};

export const NORMALIZE_TEXT = (text: string) => {
  // Remove accents and apostrophes
  return text.normalize('NFD').replace(/[\u0300-\u036f-\u0027]/g, '');
};

/**
 * TODO: this filter will be removed on FY23
 *
 * FY23 provider types must not be shown in the app.
 *
 * This function helps to tell if a provider is FY23 or not.
 *
 * @param providerType The object having the code of the provider type.
 * @returns A boolean telling is the sent provider is FY22 or not.
 */
export const IS_NOT_FY23_PROVIDER = (providerType: any) => {
  let { code } = providerType;
  const FY23Codes = Object.values(PLProviderTypeAndServiceCodeFY23);

  if (!code) code = providerType.providerType.code;

  return !FY23Codes.includes(code);
};
