<div class="pl-provider-preagreement-w2">
  <div class="nav-container">
    <pl-icon svg="logo-color-no-tm" [width]="155" [height]="40"></pl-icon>
  </div>
  <div class="content">
    <pl-dot-loader *ngIf="loading"></pl-dot-loader>
    <div *ngIf="isAgreed" class="margin-large-b">
      <br />
      <h4><pl-icon class="green" [svg]="'check'"></pl-icon> You're all set!</h4>
      <br />
      <p *ngIf="mode === 'renewal'">
        Your agreement is complete. Please contact
        <a href="mailto:asksupport@presencelearning.com"
          >asksupport@presencelearning.com</a
        >
        if you have any questions about your agreement.
      </p>
      <p *ngIf="mode === 'new'">
        Your agreement is complete. Please contact your Presence representative
        to continue.
      </p>
    </div>
    <div *ngIf="!isAgreed && !loading" class="margin-large-b">
      <div class="frame" id="eversign-container"></div>
    </div>
  </div>

  <div class="rate-message" *ngIf="!isSalaried && !isAgreed">
    <a
      target="_blank"
      [routerLink]="PROVIDER_RATES_LINK"
      [queryParams]="PROVIDER_RATES_LINK_QPARAMS"
      >Click here</a
    >
    for the full list of your available hourly rates for clinical services in
    different states.
  </div>

  <div class="footer">
    <div class="contact-info" *ngIf="!isAgreed || mode !== 'renewal'">
      Questions? Contact
      <a href="mailto:provider.success@presencelearning.com"
        >provider.success@presencelearning.com</a
      >
      or call Support Services at 844-415-4592.
    </div>
    <div class="small">&copy; {{ currentYear }} All Rights Reserved</div>
  </div>
</div>
