import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class PLAddReferralsNavigationService {
  uploadComplete = false;
  showNavigation = true;

  private navigateRequested = new Subject<number>();
  private navigateConfirmed = new Subject<number>();

  private cancelRequested = new Subject<boolean>();
  private cancelConfirmed = new Subject<boolean>();

  private finishRequested = new Subject<boolean>();
  private finishConfirmed = new Subject<boolean>();

  private disableCancelBtn = new BehaviorSubject<boolean>(false);
  private disableNextBtn = new BehaviorSubject<boolean>(false);

  navigateRequested$ = this.navigateRequested.asObservable();
  navigateConfirmed$ = this.navigateConfirmed.asObservable();

  cancelRequested$ = this.cancelRequested.asObservable();
  cancelConfirmed$ = this.cancelConfirmed.asObservable();

  finishRequested$ = this.finishRequested.asObservable();
  finishConfirmed$ = this.finishConfirmed.asObservable();

  disableCancelBtn$ = this.disableCancelBtn.asObservable();
  disableNextBtn$ = this.disableNextBtn.asObservable();

  requestNavigate(stepIndex: number) {
    this.navigateRequested.next(stepIndex);
  }
  confirmNavigate(stepIndex: number) {
    this.navigateConfirmed.next(stepIndex);
  }

  requestCancel() {
    this.cancelRequested.next(true);
  }
  confirmCancel() {
    this.cancelConfirmed.next(true);
  }

  requestFinish() {
    this.finishRequested.next(true);
  }
  confirmFinish() {
    this.finishConfirmed.next(true);
  }

  disableCancelButton() {
    this.disableCancelBtn.next(true);
  }

  enableCancelButton() {
    this.disableCancelBtn.next(false);
  }

  disableNextButton() {
    this.disableNextBtn.next(true);
  }

  enableNextButton() {
    this.disableNextBtn.next(false);
  }
}
