import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureFlagName, TextValueName } from './feature-flags';
import { FeatureFlagsService } from './feature-flags.service';
import { User } from '../../modules/user/user.model';

@Injectable()
export class FeatureFlagsTestingService extends FeatureFlagsService {
  private flags$: BehaviorSubject<Record<string, boolean>>;
  private textValues$: BehaviorSubject<Record<string, string>>;

  constructor(initialValues: Record<string, boolean | string> = {}) {
    super();
    const booleanValues = Object.entries(initialValues).reduce(
      (acc, [key, value]) => {
        if (typeof value === 'boolean') {
          acc[key] = value;
        }
        return acc;
      },
      {},
    );
    const textValues = Object.entries(initialValues).reduce(
      (acc, [key, value]) => {
        if (typeof value === 'string') {
          acc[key] = value;
        }
        return acc;
      },
      {},
    );
    this.flags$ = new BehaviorSubject<Record<string, boolean>>(booleanValues);
    this.textValues$ = new BehaviorSubject<Record<string, string>>(textValues);
  }

  identifyUser(_user: User): void {}
  getAllFeatures() {
    return this.flags$.asObservable();
  }

  isFeatureEnabled(key: FeatureFlagName) {
    return this.getAllFeatures().pipe(map(flags => flags?.[key] ?? false));
  }

  getTextValue(_key: TextValueName) {
    return this.textValues$.pipe(map(values => values?.[_key] ?? ''));
  }

  overrideFeature(key: FeatureFlagName, value: boolean) {
    const flags = this.flags$.value;
    this.flags$.next({
      ...flags,
      [key]: value,
    });
  }

  overrideTextValue(key: TextValueName, value: string) {
    const values = this.textValues$.value;
    this.textValues$.next({
      ...values,
      [key]: value,
    });
  }
}
