import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PLGraphQLService } from '@root/index';

const referralMatchProvidersQuery = require('./queries/referral-match-providers.graphql');

interface GetProviderResults {
  referral: {
    providerCandidates: {
      caseloadCount: number;
      caseloadCountByOrg: number;
      user: {
        id: string;
        firstName: string;
        lastName: string;
      };
      remainingAvailableHours: number;
      previouslyMatchedWithClient?: boolean;
    }[];
  };
}

export interface Provider {
  caseloadCount: number;
  caseloadCountByOrg: number;
  name: string;
  userId: string;
  remainingAvailableHours: number;
  previouslyMatchedWithClient?: boolean;
}

@Injectable()
export class PLClientReferralMatchReferralService {
  constructor(private plGraphQL: PLGraphQLService) {}

  getProviders(referralId: string): Observable<Provider[]> {
    return this.plGraphQL
      .query(referralMatchProvidersQuery, { id: referralId })
      .pipe(
        map(({ referral }: GetProviderResults) => {
          const providers = (referral && referral.providerCandidates) || [];

          return providers.map(provider => ({
            userId: provider.user.id,
            caseloadCount: provider.caseloadCount,
            caseloadCountByOrg: provider.caseloadCountByOrg,
            name: `${provider.user.firstName} ${provider.user.lastName}`,
            remainingAvailableHours:
              Math.round(provider.remainingAvailableHours * 10) / 10,
            previouslyMatchedWithClient: provider.previouslyMatchedWithClient,
          }));
        }),
      );
  }
}
