import {
  Output,
  EventEmitter,
  Component,
  ElementRef,
  ViewChild,
  HostBinding,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PLInputDropdownService } from './pl-input-dropdown.service';

@Component({
  selector: 'pl-input-dropdown',
  templateUrl: './pl-input-dropdown.component.html',
  styleUrls: [
    './pl-input-shared.component.less',
    './pl-input-dropdown.component.less',
  ],
  inputs: [
    'hidden',
    'maxHeight',
    'minWidth',
    'containerSelector',
    'margin',
    'margins',
    'refreshPosition',
  ],
  host: {
    '(document:click)': 'onClick($event)',
    '[style]': 'stylesHostString',
  },
})
export class PLInputDropdownComponent implements OnInit, AfterViewInit {
  @Output() onBlur = new EventEmitter<any>();

  @HostBinding('class.hidden') hidden: boolean = false;

  @ViewChild('dropdown', { static: false }) dropdown: ElementRef;

  // hidden: boolean = false;
  containerSelector = 'body';
  margin = 10;
  margins: any = {};
  maxHeight = 300;
  minWidth: any = 0;

  private stylesHost: any = {};
  private stylesHostString: any;

  // Below vars are key for dropdown orientation/position
  private isDropdownDefined = false;

  private isDropdownParentNodeNearBodyFound = false;

  private dropdownParentNodeReference: any;

  constructor(
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    private plInputDropdown: PLInputDropdownService,
  ) {}

  ngOnInit(): void {
    this.setStylesAndMargins();
  }

  ngAfterViewInit(): void {
    this.isDropdownDefined = this.dropdown?.nativeElement?.parentNode;

    if (this.isDropdownDefined && !this.isDropdownParentNodeNearBodyFound) {
      this.checkParentNodeRecursively(this.dropdown.nativeElement.parentNode);
    }
  }

  setStylesAndMargins(): void {
    this.setStyles();
  }

  setStyles(): void {
    this.stylesHost['max-height'] = `${this.maxHeight}px`;
    if (this.minWidth) this.stylesHost['min-width'] = `${this.minWidth}px`;
    this.updateStyles({});
  }

  updateStyles(newStyles: any): void {
    let stylesString = ``;
    const styles = Object.assign({}, this.stylesHost, newStyles);

    for (let ss in styles) {
      stylesString += `${ss}: ${styles[ss]}; `;
    }

    this.stylesHostString =
      this.sanitizer.bypassSecurityTrustStyle(stylesString);
  }

  onClick(evt: any): void {
    // Sometimes (e.g. mini calendar) we get a null parent node so the `contains` check gives a false negative.
    // So we check the position as well in case the click actualy IS in the dropdown.
    if (
      !this.hidden &&
      !this.elementRef.nativeElement.contains(evt.target) &&
      !this.plInputDropdown.clickInRect(evt, this.elementRef.nativeElement) &&
      this.onBlur
    ) {
      this.updateDropdownOrientation();
      this.onBlur.emit({ blurred: true, evt: evt });
    }
  }

  /**
   * Gets the parent node closer to the `body` of our dropdown.
   * Sets the node found to `dropdownParentNodeReference` variable.
   *
   * @param elementNode The HTML element to iterate up
   */
  private checkParentNodeRecursively(elementNode: {
    className: any;
    parentNode: any;
    nodeName: any;
    offsetTop: any;
  }): void {
    if (elementNode?.className) {
      if (elementNode.parentNode)
        return this.checkParentNodeRecursively(elementNode.parentNode);
      return;
    }

    this.dropdownParentNodeReference = elementNode;
    this.isDropdownParentNodeNearBodyFound = true;
  }

  /**
   * The orientation of the drop-down can go down or up.
   * If the space below the drop-down isn't enough; the drop-down will go up.
   *
   * The objective is to take advantage of the window frame the user has.
   * So that scrolling for seeing the options can be reduced; hence have a better UX.
   */
  private updateDropdownOrientation(): void {
    this.updateStyles({ visibility: 'hidden' }); // Hiding before taking any decission of showing.

    const newStyle: { visibility: string; top?: string } = {
      visibility: 'visible',
    };

    if (this.shouldDropdownOpenUp()) {
      newStyle.top = `-${this.dropdown.nativeElement.parentNode.offsetHeight}px`;
    }

    this.updateStyles(newStyle);
  }

  /**
   * Based on the window frame that the user has; the dropdown must open down or up.
   *
   * @returns true when orientation has to go Up
   */
  private shouldDropdownOpenUp(): boolean {
    const windowHeight = window.innerHeight; // Window frame that the user sees.
    const documentScrollTop = document.documentElement.scrollTop;
    const dropdownHeight = this.dropdown.nativeElement.parentNode.offsetHeight;
    const dropdownTop = this.plInputDropdown.getCoords(
      this.dropdownParentNodeReference,
    ).top;

    const pixelsToSubstract = (0.9 * dropdownHeight) / 1;

    return dropdownTop >= windowHeight + documentScrollTop - pixelsToSubstract;
  }
}
