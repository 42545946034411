import { Component, Input, OnChanges } from '@angular/core';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PLHttpService } from '@root/src/lib-components';

@Component({
  selector: 'pl-provider-details-organization-preferences',
  templateUrl: './pl-provider-details-organization-preferences.component.html',
  styleUrls: [
    './pl-provider-details.common.less',
    './pl-provider-details-organization-preferences.component.less',
  ],
})
export class PLProviderDetailsOrganizationPreferencesComponent
  implements OnChanges
{
  @Input() provider: any;
  @Input() userType: string;
  preferences$: Observable<Record<string, any>>;

  constructor(private plHttpService: PLHttpService) {}

  groupAssignments(assignments: any[]) {
    const grouped = assignments.reduce((acc, assignment: any) => {
      const { providerpreference, organizationpreference, ...item } =
        assignment;
      const preferences = [
        ...(providerpreference
          ? [{ ...providerpreference, type: 'Provider' }]
          : []),
        ...(organizationpreference
          ? [{ ...organizationpreference, type: 'Organization' }]
          : []),
      ];

      const existingOrganization = acc.find(
        (org: any) => org.organization === item.organization,
      );

      if (existingOrganization) {
        existingOrganization.preferences.push(...preferences);
      } else {
        acc.push({
          ...item,
          dateRange: this.formatDateRange(item.start_date, item.end_date),
          preferences,
        });
      }

      return acc;
    }, []);

    return grouped;
  }

  formatDateRange(start: string, end: string): string {
    const sameYear = dayjs(start).year() === dayjs(end).year();
    const startDate = dayjs(start).format(sameYear ? 'MMMM D' : 'MMMM D, YYYY');
    const endDate = dayjs(end).format('MMMM D, YYYY');
    return `${startDate} - ${endDate}`;
  }

  findEarliestStartDate(assignments) {
    let earliestDate = new Date(); // Initialize to current date

    assignments.forEach(assignment => {
      const startDate = new Date(assignment.start_date);
      if (startDate < earliestDate) {
        earliestDate = startDate;
      }
    });

    return earliestDate;
  }

  findLatestEndDate(assignments) {
    let latestDate = new Date(0); // Initialize to earliest possible date

    assignments.forEach(assignment => {
      const endDate = new Date(assignment.end_date);
      if (endDate > latestDate) {
        latestDate = endDate;
      }
    });

    return latestDate;
  }

  findYearRange(assignments: any[]) {
    const earliest = this.findEarliestStartDate(assignments);
    const latest = this.findLatestEndDate(assignments);

    return `${dayjs(earliest).format('YYYY')} - ${dayjs(latest).format(
      'YYYY',
    )}`;
  }

  findMostRecentModified(assignments: any[]) {
    if (assignments.length === 0) return '';
    let mostRecentDate = new Date(0); // Initialize to earliest possible date

    assignments.forEach(assignment => {
      if (
        assignment.providerpreference &&
        assignment.providerpreference.modified
      ) {
        const modifiedDate = new Date(assignment.providerpreference.modified);
        if (modifiedDate > mostRecentDate) {
          mostRecentDate = modifiedDate;
        }
      }

      if (
        assignment.organizationpreference &&
        assignment.organizationpreference.modified
      ) {
        const modifiedDate = new Date(
          assignment.organizationpreference.modified,
        );
        if (modifiedDate > mostRecentDate) {
          mostRecentDate = modifiedDate;
        }
      }
    });

    return `Last modified on ${dayjs(mostRecentDate).format('MMMM D, YYYY')}`;
  }

  ngOnChanges(): void {
    if (this.provider.user) {
      this.preferences$ = this.plHttpService
        .get('submittedAssignmentPreferences', {
          provider_uuid: this.provider.user,
        })
        .pipe(
          map((assignments: any[]) => {
            return {
              organizations: this.groupAssignments(assignments),
              numberOfAssignments: assignments.length,
              yearRange: this.findYearRange(assignments),
              lastModified: this.findMostRecentModified(assignments),
            };
          }),
        );
    }
  }
}
