import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgChartsModule } from 'ng2-charts';
// Angular Material

import {
  PLInputModule,
  PLIconModule,
  PLModalModule,
  PLVaryDisplayModule,
  PLDotLoaderModule,
} from '@root/index';

import {
  PLFloatingLoaderComponent,
  PLSchoolyearSelectComponent,
  PLClientIdComponent,
  PLDateTimeRangeComponent,
  PLHelpSupportComponent,
  PLIsAvailableComponent,
  PLNavLeftStepsComponent,
  PLStatusDotComponent,
  PLStatusHelpComponent,
  PLTimeGridBlockComponent,
  PLTimeGridColumnComponent,
  PLTimeGridSchedulerComponent,
  PLWeeklyTimeGridComponent,
  PLSimpleMeterComponent,
  PLReferralFiltersComponent,
  PLAlertComponent,
  PLAlertBannerComponent,
  PLDoughnutChartComponent,
  PLLegendsComponent,
  PLDataLoaderComponent,
  PLGenericModalComponent,
  PLEditableTableComponent,
  PLPopoverComponent,
  PLConfirmDialog2Component,
  PLNotesListComponent,
  PLNoteEditorComponent,
  PLGalleryModalComponent,
  PLReferralNotesComponent,
  PLClientContactSaveComponent,
  PLSideNavComponent,
  PLSideNavLinkComponent,
  PLSideSubNavLinkComponent,
  PLResourcesComponent,
} from './components';

import {
  PLPhonePipe,
  PLStatePipe,
  PLTruncatePipe,
  PLCapitalizeFirstPipe,
  PLAssigmentStatusPipe,
  PLCurrencyPipe,
  PLYesNoEmptyPipe,
  PLYesNoPipe,
  PLOptionsPipe,
  PLTimingPipe,
  PLSafeurlPipe,
  PLSafeHtmlPipe,
  PLDayjsFormatPipe,
} from './pipes';
import {
  ProviderTypesResolver,
  CurrentUserResolver,
  CurrentSchoolYearResolver,
  AssignedLocationsResolver,
  ProviderResolver,
  UserAssigmentsResolver,
  BillingCodesResolver,
  PLMaterialDesignResolver,
  SchoolYearsResolver,
} from './resolvers';
import {
  PLAccountsService,
  PLAssignedLocationsService,
  PLExpirationService,
  PLGetProviderService,
  PLLocationsService,
  PLProvidersListService,
  PLProviderProfileService,
  PLNotesReportService,
  PLAISummaryService,
  PLSchoolYearsService,
  PLUserAssignmentsService,
  PLUtilService,
  PLRouteIdService,
  PLEventMessageBus,
  PLTimeGridService,
  PLTasksService,
  PLReferralNotesService,
} from './services';

import { PLCommonStoreModule } from './store';
import { ValidOptionValidatorDirective } from './validators';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    // 3rd Party
    NgChartsModule,
    // Angular Material
    DragDropModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    MatDatepickerModule,
    MatIconModule,
    MatNativeDateModule,
    ScrollingModule,
    // PL Comps
    PLInputModule,
    PLIconModule,
    PLModalModule,
    PLVaryDisplayModule,
    PLCommonStoreModule,
    PLDotLoaderModule,
    EditorModule,
    MatIconModule,
  ],
  exports: [
    PLFloatingLoaderComponent,
    PLClientIdComponent,
    PLHelpSupportComponent,
    PLPhonePipe,
    PLStatePipe,
    PLCapitalizeFirstPipe,
    PLAssigmentStatusPipe,
    PLCurrencyPipe,
    PLSchoolyearSelectComponent,
    PLIsAvailableComponent,
    PLNavLeftStepsComponent,
    PLStatusDotComponent,
    PLStatusHelpComponent,
    PLTruncatePipe,
    PLYesNoEmptyPipe,
    PLYesNoPipe,
    PLOptionsPipe,
    PLTimingPipe,
    PLSafeurlPipe,
    PLSafeHtmlPipe,
    PLTimeGridBlockComponent,
    PLTimeGridColumnComponent,
    PLTimeGridSchedulerComponent,
    PLWeeklyTimeGridComponent,
    PLSimpleMeterComponent,
    PLDateTimeRangeComponent,
    PLReferralFiltersComponent,
    PLAlertComponent,
    PLAlertBannerComponent,
    PLDoughnutChartComponent,
    PLDataLoaderComponent,
    PLLegendsComponent,
    MatTooltipModule,
    PLGenericModalComponent,
    PLEditableTableComponent,
    PLPopoverComponent,
    PLConfirmDialog2Component,
    ValidOptionValidatorDirective,
    PLNotesListComponent,
    PLNoteEditorComponent,
    PLReferralNotesComponent,
    PLGalleryModalComponent,
    PLClientContactSaveComponent,
    PLDayjsFormatPipe,
    PLSideNavComponent,
    PLSideNavLinkComponent,
    PLSideSubNavLinkComponent,
    PLResourcesComponent,
  ],
  declarations: [
    PLFloatingLoaderComponent,
    PLClientIdComponent,
    PLDateTimeRangeComponent,
    PLHelpSupportComponent,
    PLPhonePipe,
    PLStatePipe,
    PLCapitalizeFirstPipe,
    PLAssigmentStatusPipe,
    PLCurrencyPipe,
    PLSchoolyearSelectComponent,
    PLIsAvailableComponent,
    PLNavLeftStepsComponent,
    PLStatusDotComponent,
    PLStatusHelpComponent,
    PLTruncatePipe,
    PLYesNoEmptyPipe,
    PLYesNoPipe,
    PLOptionsPipe,
    PLSafeurlPipe,
    PLSafeHtmlPipe,
    PLTimingPipe,
    PLTimeGridBlockComponent,
    PLTimeGridColumnComponent,
    PLTimeGridSchedulerComponent,
    PLWeeklyTimeGridComponent,
    PLSimpleMeterComponent,
    PLReferralFiltersComponent,
    PLAlertComponent,
    PLAlertBannerComponent,
    PLDoughnutChartComponent,
    PLLegendsComponent,
    PLDataLoaderComponent,
    PLGenericModalComponent,
    PLEditableTableComponent,
    PLPopoverComponent,
    PLConfirmDialog2Component,
    ValidOptionValidatorDirective,
    PLNotesListComponent,
    PLNoteEditorComponent,
    PLReferralNotesComponent,
    PLGalleryModalComponent,
    PLClientContactSaveComponent,
    PLDayjsFormatPipe,
    PLSideNavComponent,
    PLSideNavLinkComponent,
    PLSideSubNavLinkComponent,
    PLResourcesComponent,
  ],
  providers: [
    PLAccountsService,
    PLAssignedLocationsService,
    PLNotesReportService,
    PLAISummaryService,
    PLExpirationService,
    PLGetProviderService,
    PLProvidersListService,
    PLProviderProfileService,
    PLLocationsService,
    PLSchoolYearsService,
    PLUserAssignmentsService,
    PLUtilService,
    PLRouteIdService,
    PLEventMessageBus,
    PLTimeGridService,
    PLTasksService,
    // Resolvers
    ProviderTypesResolver,
    CurrentUserResolver,
    CurrentSchoolYearResolver,
    AssignedLocationsResolver,
    ProviderResolver,
    UserAssigmentsResolver,
    SchoolYearsResolver,
    BillingCodesResolver,
    PLMaterialDesignResolver,
    PLReferralNotesService,
  ],
})
export class PLCommonModule {}
