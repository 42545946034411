import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { CurrentUserService } from '@modules/user/current-user.service';
import { User } from '../user/user.model';

const PERM_MANAGE_PROPOSALS = 'manageProposals';
@Injectable()
export class PLAssignmentManagerRouteGuardService implements CanActivate {
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router,
  ) {}

  canActivate() {
    return this.currentUserService.getCurrentUser().pipe(
      map(user => {
        if (user.is_superuser || this.hasPermission(user)) {
          return true;
        }
        return this.router.parseUrl('/');
      }),
    );
  }

  private hasPermission(user: User) {
    return user.xGlobalPermissions?.[PERM_MANAGE_PROPOSALS] ?? false;
  }
}
