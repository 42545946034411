<div class="pl-quick-links-title">
  <span class="title-text">Quick links</span>
</div>
<div class="pl-quick-links-container">
  <div
    *ngIf="showOrgHandbooks && organizations.length > 0"
    class="pl-links-align"
  >
    <div class="pl-link-icon handbook">
      <mat-icon fontSet="material-symbols-outlined">menu_book</mat-icon>
    </div>
    <div class="pl-handbook-link-info">
      <span class="pl-links-title-text">Organization Handbooks</span>
      <span class="text"
        >Access relevant org handbooks
        <ul>
          <li *ngFor="let organization of organizations">
            <a
              id="handbook-link-text"
              [routerLink]="['/organization', organization.id, 'handbook']"
              target="_blank"
            >
              {{ organization.name }}
            </a>
          </li>
        </ul>
      </span>
    </div>
  </div>
  <div class="pl-links-align">
    <a href="{{ equipmentOrderUrl }}" target="_blank"
      ><div class="pl-link-icon equipment">
        <mat-icon fontSet="material-symbols-outlined">headphones</mat-icon>
      </div></a
    >
    <div class="pl-equipment-link-info">
      <span class="pl-links-title-text">Equipment</span>
      <span class="text"
        >Need stimulus or hardware equipment?
        <a
          id="equipment-link-text"
          href="{{ equipmentOrderUrl }}"
          target="_blank"
        >
          Equipment order form
        </a>
      </span>
    </div>
  </div>
  <div class="pl-links-align">
    <a href="https://go.ernestco.co/Login/assessmentspl" target="_blank">
      <div class="pl-link-icon online-store">
        <mat-icon fontSet="material-symbols-outlined">store</mat-icon>
      </div></a
    >
    <div class="pl-equipment-link-info">
      <span class="pl-links-title-text">Online store</span>
      <span class="text"
        >Looking to order assessment materials?
        <a
          id="online-store-link"
          href="https://go.ernestco.co/Login/assessmentspl"
          target="_blank"
        >
          Browse now
        </a></span
      >
    </div>
  </div>
  <div class="pl-links-align">
    <div class="pl-link-icon external-tools">
      <mat-icon fontSet="material-symbols-outlined">link</mat-icon>
    </div>
    <div class="pl-equipment-link-info">
      <span class="pl-links-title-text">External tools</span>
      <span class="text">Commonly accessed third-party assessment tools</span>
      <a
        href="https://qglobal.pearsonassessments.com/qg/login.seam"
        target="_blank"
      >
        Q-Global
      </a>
      <a href="https://www.wjscore.com/WJIV/Home/Index/2" target="_blank">
        WJ Score
      </a>
      <a href="https://platform.wpspublish.com/account/login" target="_blank">
        Western Psychological Services
      </a>
    </div>
  </div>
</div>
