<div class="padding">
  <div class="center margin-b">
    <pl-icon
      class="logo"
      [svg]="'logo-color-no-tm'"
      [width]="155"
      [height]="40"
    ></pl-icon>
  </div>
  <pl-liveagent-prechat
    [firstName]="firstName"
    [lastName]="lastName"
    [email]="email"
  ></pl-liveagent-prechat>
</div>
