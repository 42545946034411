// TODO: stop using the below and use `edu-clients/src/app/common/enums/pl-clinical-product-types.ts` instead
import { PLProviderAssignmentTypes } from '@root/src/app/common/enums/pl-provider-types';
import { Option } from '@common/interfaces';

export const CLINICAL_PRODUCT_TYPE = {
  CODE: {
    BIG: 'groupbmh_bi',
    DIR_SVC: 'direct_service',
    EVAL: 'evaluation_with_assessments',
    SV: 'supervision',
    TG: 'groupbmh_ti',
    BMH_GROUP: 'group_bmh',
    CONS: 'consultation',
    REC_REV: 'records_review',
    SC: 'screening',
    GROUP_READING: 'group_reading',
    SOCIAL_WELLNESS: 'prosocial_group',
    RSM_MEETING: 'rsm_meeting',
  },
  NAME: {
    BIG: 'Behavior Intervention Group',
    DIR_TE: 'Direct Therapy',
    EVAL: 'Evaluation',
    SV: 'Supervision',
    TE: 'Therapy',
    TG: 'Trauma-informed Group',
    TG_CAPITALIZE: 'Trauma Informed Group',
    BIG_LOWER_CASE: 'big',
    BIG_LOWER_CASE2: 'behavior intervention group',
    TG_LOWER_CASE: 'trauma informed group',
    TG_LOWER_CASE2: 'trauma-informed group',
    BIG_UPPER_CASE: 'BIG',
    TG_UPPER_CASE: 'TRAUMA INFORMED GROUP',
    GROUP_READING: 'Group Reading Service',
    SOCIAL_WELLNESS: 'Social Wellness',
    CONS: 'Consultation',
    RSM_MEETING: 'Meeting',
  },
};

export const BMH_THERAPY_FREQUENCY = {
  ONCE_WEEKLY: { value: 'frequency_once', label: '60 minutes once a week' },
  TWICE_WEEKLY: { value: 'frequency_twice', label: '30 minutes twice a week' },
};

/**
 * Use it in the `data.title` property of the routing modules.
 * Either set `SKIP` and set the default `PL` title to a page tab.
 * Or set it `DYNAMIC` and make `pl-tabs` to set the title based on sub navigation with dynamic names.
 *   e.g. $USER - $SCHOOL - Presence.
 */
export const ROUTING = {
  SKIP: 'SKIPHISTORY',
  DYNAMIC: 'USE_DYNAMIC_SUBNAVIGATION',
};

export const PROVIDER_ASSIGNMENT_TYPE_OPTIONS = [
  {
    id: PLProviderAssignmentTypes.ASSESSMENT_DIRECT,
    label: 'Direct Therapy and Assessments',
  },
  { id: PLProviderAssignmentTypes.ASSESSMENT, label: 'Assessment Only' },
  { id: PLProviderAssignmentTypes.DIRECT, label: 'Direct Therapy Only' },
];

export const EMPLOYMENT_STATUS = {
  FULL_TIME: 'Full-Time Employee',
  PART_TIME: 'Part-Time Employee',
  IC: 'Independent Contractor',
  SUBCONTRACTOR: 'Subcontractor',
  SALARIED_EMPLOYEE: 'Salaried Employee',
};

export const TRACKING_TYPE = {
  REGULAR: 'regular',
  EXTENDED_SCHOOL_YEAR: 'extended_school_year',
  COMPENSATORY_TIME: 'compensatory_time',
};

export const TRACKING_TYPE_LABEL = {
  [TRACKING_TYPE.REGULAR]: 'Regular',
  [TRACKING_TYPE.EXTENDED_SCHOOL_YEAR]: 'Extended School Year',
  [TRACKING_TYPE.COMPENSATORY_TIME]: 'Compensatory Time',
};

export const TRACKING_TYPE_OPTIONS: Option[] = [
  { value: TRACKING_TYPE.REGULAR, label: 'Regular' },
  { value: TRACKING_TYPE.EXTENDED_SCHOOL_YEAR, label: 'Extended School Year' },
  { value: TRACKING_TYPE.COMPENSATORY_TIME, label: 'Compensatory Time' },
];

export const K12_RSM_CANT_BE_MODIFIED_MSG =
  'K12 Referrals/Services cannot be modified';

export const REFERRAL_REASON_LABEL: { [key: string]: string } = {
  SCHEDULING_CONFLICT: 'Scheduling Conflict',
  CLINICAL_REASON: 'Clinical Reason',
  GROUPING: 'Grouping',
  LANGUAGE: 'Language',
  DUPLICATE_REFERRAL: 'Duplicate Referral',
  DUPLICATE_OF_SERVICE_IN_PROGRESS: 'Duplicate of Service in Progress',
  INCORRECT_REFERRAL: 'Incorrect Referral',
  REASSIGNED: 'Reassigned Referral',
};

export const REFERRAL_REASON: { [key: string]: string } = {
  SCHEDULING_CONFLICT: 'scheduling_conflict',
  CLINICAL_REASON: 'clinical_reason',
  GROUPING: 'grouping',
  LANGUAGE: 'language',
  DUPLICATE_REFERRAL: 'duplicate_referral',
  DUPLICATE_OF_SERVICE_IN_PROGRESS: 'duplicate_of_service_in_progress',
  INCORRECT_REFERRAL: 'incorrect_referral',
  REASSIGNED: 'reassigned_referral',
};

export const LEARN_UPON_DASHBOARD_URL =
  'https://presence.learnuponus.com/saml/init?redirect_uri=/dashboard';
