import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BILLING_FEATURE_KEY } from './billing.state';
import { billingEffects, billingStore } from './billing.store';

@NgModule({
  imports: [
    StoreModule.forFeature(BILLING_FEATURE_KEY, billingStore),
    EffectsModule.forFeature(billingEffects),
  ],
})
export class BillingStoreModule {}
