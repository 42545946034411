<div class="card pl-provider-details-service-details">
  <div class="header">
    <h3>Service details</h3>
  </div>
  <table>
    <tbody>
      <tr>
        <th scope="row">Clinical contact</th>
        <td class="cem-info">
          {{ CEMName }}
        </td>
      </tr>
      <tr>
        <th scope="row">Base pay rate</th>
        <td class="hourly-rate">${{ hourlyRate | number : '1.2-2' }}</td>
      </tr>
      <tr>
        <th scope="row">Provider pay rates</th>
        <td class="pay-rates">
          <a [routerLink]="[payRatesLink]"
            >View all in provider pay rates <mat-icon>open_in_new</mat-icon></a
          >
        </td>
      </tr>
    </tbody>
  </table>
</div>
