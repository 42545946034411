export enum DemandSpecialty {
  aac = 'AAC',
  asl = 'ASL',
  bi = 'BI',
  dhh = 'DHH',
  st = 'ST',
  su = 'SU',
  vi = 'VI',
}

export const demandSpecialtyLongNames = {
  [DemandSpecialty.aac]: 'Augmentative and alternative communication (AAC)',
  [DemandSpecialty.asl]: 'American Sign Language (ASL)',
  [DemandSpecialty.bi]: 'Bilingual',
  [DemandSpecialty.dhh]: 'Deaf or hard-of-hearing (DHH)',
  [DemandSpecialty.st]: 'Short-term coverage',
  [DemandSpecialty.su]: 'Supervision',
  [DemandSpecialty.vi]: 'Visually impaired (VI)',
};
