<table mat-table [dataSource]="assignmentList" class="mat-elevation-z0">
  <!-- Provider Column -->
  <ng-container matColumnDef="provider">
    <th mat-header-cell *matHeaderCellDef>Provider</th>
    <td mat-cell *matCellDef="let assignment">
      <span>
        {{ assignment.provider }}
      </span>

      <pl-popover
        [template]="returningReasoning"
        *ngIf="assignment.isReturning"
      >
        <span class="is-returning"> Returning </span>
      </pl-popover>

      <ng-template #returningReasoning>
        <pl-assignment-manager-returning-details
          [assignment]="assignment"
          [orgName]="orgName"
        ></pl-assignment-manager-returning-details>
      </ng-template>

      <div *ngIf="assignment.separationDate" class="separating">
        <span *ngIf="assignment.isSeparating">Separating</span>
        <span *ngIf="assignment.isSeparated">Separated</span>
        &middot; {{ assignment.separationDate }}
      </div>
    </td>
  </ng-container>

  <!-- Hours Column -->
  <ng-container matColumnDef="hours">
    <th mat-header-cell *matHeaderCellDef>Hours</th>
    <td mat-cell *matCellDef="let assignment">
      {{ assignment.supplyHours | number : '1.1' }}
    </td>
  </ng-container>

  <!-- First Work Day Column -->
  <ng-container matColumnDef="first-work-day">
    <th mat-header-cell *matHeaderCellDef>First work day</th>
    <td mat-cell *matCellDef="let assignment">
      {{ assignment.startDate }}
    </td>
  </ng-container>

  <!-- Missing Quals Column -->
  <ng-container matColumnDef="missing-qualifications">
    <th mat-header-cell *matHeaderCellDef># of missing qualifications</th>
    <td mat-cell *matCellDef="let assignment">
      <span *ngIf="assignment.unmetRequirementsCount === null; else hasCount">
        UNKNOWN - CONTACT TECH
      </span>
      <ng-template #hasCount>
        <a
          style="color: black; text-decoration: underline"
          [matMenuTriggerFor]="reqsMenu"
          [matMenuTriggerData]="{assignment}"
          (click)="updateRequirements($event, assignment)"
        >
          {{
            assignment.unmetRequirementsCount
              ? assignment.unmetRequirementsCount
              : 'None'
          }}
        </a>
      </ng-template>
    </td>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let assignment">
      <div class="status-cell">
        <div class="status-info">
          <div class="status-info-top">
            <span
              *ngIf="!assignment.showEndDate"
              class="status-label"
              [ngClass]="assignment.statusCode"
            >
              {{ assignment.statusLabel }}
            </span>
            <span *ngIf="assignment.showEndDate">
              <span *ngIf="!assignment.showCompletingOnEndDate">
                Completed
              </span>
              <span *ngIf="assignment.showCompletingOnEndDate">
                Completing on
              </span>
              {{ assignment.endDate }}
            </span>
            <span *ngIf="!assignment.showEndDate"
              >&middot; {{ assignment.modified }}</span
            >
          </div>
          <div class="status-info-bottom">
            <div
              *ngIf="assignment.statusLabelDetail"
              class="assignment-status-detail"
            >
              {{ assignment.statusLabelDetail }}
            </div>
            <div
              *ngIf="assignment?.blockAssignmentTimeReason"
              class="assignment-status-detail"
            >
              {{ assignment.blockAssignmentTimeReason }}
            </div>
          </div>
        </div>
        <button
          class="action-menu-button"
          [matMenuTriggerFor]="actionMenu"
          [matMenuTriggerData]="{assignment}"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row class="header" *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<!-- Requirements popup - implemented as a mat-menu, but it's a non-clickable popup -->
<mat-menu class="reqs-menu" #reqsMenu="matMenu">
  <ng-template matMenuContent let-assignment="assignment">
    <div class="requirements-popup">
      <div
        class="reqs-loader"
        *ngIf="assignment._loading; else assignmentLoaded"
      >
        <pl-dot-loader></pl-dot-loader>
      </div>
      <ng-template #assignmentLoaded>
        <div *ngIf="assignment.unmetRequirements?.length">
          <div class="requirement-label">
            <span>{{ assignment.unmetRequirements.length }} missing</span>
            <span *ngIf="assignment.unmetRequirements.length === 1"
              >qualification</span
            >
            <span *ngIf="assignment.unmetRequirements.length > 1"
              >qualifications</span
            >
          </div>
          <ul
            class="requirement-list"
            *ngFor="let item of assignment.unmetRequirements"
          >
            <div class="requirement-item">
              <div class="requirement-item-qualification">
                {{ item.options[0] }}
              </div>
              <div class="requirement-item-note">
                <ng-container *ngIf="item.pending; else notPending">
                  <mat-icon class="yellow" inline>warning</mat-icon>
                  <span>Pending</span>
                  <span *ngIf="item.estimated_met_date"
                    >– est. completion {{ item.estimated_met_date }}</span
                  >
                </ng-container>
                <ng-template #notPending>
                  <mat-icon class="red" inline>error</mat-icon>
                  <span>Not started</span>
                </ng-template>
              </div>
            </div>
          </ul>
        </div>
        <div *ngIf="assignment.metRequirements?.length">
          <div class="requirement-label">
            <span
              >{{ assignment.metRequirements.length }} active qualification{{
                assignment.metRequirements.length === 1 ? '' : 's'
              }}
            </span>
          </div>
          <ul
            class="requirement-list"
            *ngFor="let item of assignment.metRequirements"
          >
            <div class="requirement-item">
              <div class="requirement-item-qualification">
                {{ item.options[0] }}
              </div>
              <div class="unmet-item-note">
                <mat-icon class="green" inline>check_circle</mat-icon>
                <span>Active</span>
              </div>
            </div>
          </ul>
        </div>
      </ng-template>
    </div>
  </ng-template>
</mat-menu>

<!-- Action Menu for an assignment proposal -->
<!-- TODO - extract this Action Menu as a reusable component -->
<mat-menu #actionMenu="matMenu" class="action-menu" xPosition="before">
  <ng-template matMenuContent let-assignment="assignment">
    <label class="action-menu-label">View more in…</label>
    <button mat-menu-item (click)="openProviderProfile(assignment)">
      <span>Provider profile</span>
    </button>
    <button mat-menu-item (click)="openSFDCRecord(assignment)">
      <span>SFDC record</span>
    </button>
    <button mat-menu-item (click)="openMetabaseReport(assignment)">
      <span>Metabase report</span>
    </button>
    <div class="divider-line"></div>
    <button
      mat-menu-item
      (click)="openEditAssignment(assignment)"
      *ngIf="editableStatuses.includes(assignment.statusCode)"
    >
      <span>Edit assignment</span>
    </button>
    <button
      mat-menu-item
      (click)="acceptProposal(assignment)"
      *ngIf="assignment.statusCode === 'proposed'"
    >
      <span>Accept proposal</span>
    </button>
    <button
      mat-menu-item
      style="color: red"
      (click)="rejectProposal(assignment)"
      *ngIf="assignment.statusCode === 'proposed'"
    >
      <span>Reject proposal</span>
    </button>
  </ng-template>
</mat-menu>
