<div>
  <div
    class="pl-input-radio-group"
    [ngClass]="classesContainer"
    (keydown)="onKeyDown($event)"
  >
    <pl-input-label [label]="label" [required]="required"></pl-input-label>
    <div #optionsContainer class="options">
      <div
        *ngFor="let opt of optionsUse"
        class="option"
        [ngStyle]="stylesOption"
      >
        <pl-input-radio
          [(model)]="model"
          [value]="opt.value"
          [label]="opt.label"
          (change)="changeOpt(opt)"
          [disabled]="opt.disabled"
          [name]="name"
        ></pl-input-radio>
      </div>
    </div>
  </div>
  <pl-input-errors
    *ngIf="formCtrl"
    [formCtrl]="formCtrl"
    [name]="name"
    [messages]="validationMessages"
  ></pl-input-errors>
</div>
