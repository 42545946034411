<div class="pl-stop-assignments-modal">
  <div class="heading">
    <span>Stop receiving new requests?</span>
    <button mat-icon-button (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="note">
    Are you sure? Your total hours desired will be reduced and you will not
    receive additional assignment requests.
  </div>

  <div class="form-actions">
    <button
      mat-stroked-button
      type="button"
      color="primary"
      (click)="onCancel()"
    >
      Cancel
    </button>
    <button mat-flat-button color="primary" type="button" (click)="onSubmit()">
      Confirm
    </button>
  </div>
</div>
