import { Component, Input } from '@angular/core';

interface Link {
  href: string;
  target?: string;
  label: string;
}

@Component({
  selector: 'pl-side-sub-nav-link',
  templateUrl: './pl-side-sub-nav-link.component.html',
  styleUrls: ['./pl-side-sub-nav-link.component.less'],
})
export class PLSideSubNavLinkComponent {
  @Input() link: Link;
  @Input() navCollapsed: boolean;
}
