import { Store } from '@ngrx/store';
import { MizarService } from '../../api/mizar/mizar.service';
import { ActivatedRoute } from '@angular/router';
import { AppStore } from '@root/src/app/appstore.model';
import { selectCurrentUser } from '@root/src/app/common/store';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

export abstract class PLUserPhoto {
  userPicture: any;
  userHasPicture: boolean;
  userUuid: string;

  constructor(
    private mizarService: MizarService,
    private route: ActivatedRoute,
    private store: Store<AppStore>,
  ) {
    this.userUuid = this.route.snapshot.parent?.paramMap.get('id');
    this.mizarService.userPictureUpdated.subscribe(() => this.getUserPicture());
    this.getUserPicture();
  }

  getUserUuid() {
    return new Promise(resolve => {
      if (!this.userUuid) {
        this.userUuid = this.route.snapshot.parent?.paramMap.get('id');
        if (!this.userUuid) {
          this.store.select(selectCurrentUser).subscribe((user: any) => {
            this.userUuid = user.uuid;
            resolve(this.userUuid);
          });
        } else {
          resolve(this.userUuid);
        }
      } else {
        resolve(this.userUuid);
      }
    });
  }

  getUserPicture() {
    if (!this.userUuid) {
      this.getUserUuid().then(() => {
        this.getUserImageWithMizar();
      });
    } else {
      this.getUserImageWithMizar();
    }
  }

  getUserImageWithMizar() {
    this.mizarService
      .getUserImage(this.userUuid)
      .pipe(
        catchError(error => {
          console.error('Error fetching user image:', error);
          this.userHasPicture = false;
          return of(error);
        }),
      )
      .subscribe((res: any) => {
        this.userPicture = res?.image_presigned_url;
        this.userHasPicture = !!this.userPicture;
      });
  }

  uploadUserImage(file: File) {
    return this.mizarService.uploadImage(this.userUuid, file).pipe(
      tap((response: any) => {
        if (response?.status === 'success') this.getUserPicture();
      }),
    );
  }

  deleteUserImage() {
    return this.mizarService
      .deleteUserImage(this.userUuid)
      .pipe(tap(() => this.getUserPicture()));
  }
}
