import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '@root/src/environments/environment';
import { PLHttpService } from '@root/src/lib-components';

export interface ProviderDedicatedOverages {
  assigned_time: string;
  scheduled_time: string;
  remaining_time: string;
  overage_time: string;
  regular_end: string;
}

export interface OveragesResponse {
  overages: Record<string, ProviderDedicatedOverages>;
}

@Injectable({ providedIn: 'root' })
export class DedicatedOveragesDataService {
  constructor(private plHttp: PLHttpService) {}

  getOverages(date?: string): Observable<OveragesResponse> {
    let url = `${environment.apps.apiWorkplace.url}/api/v1/provider-dedicated-overages/`;
    if (date) {
      url += `?date=${date}`;
    }
    const httpOpts = {
      url,
      method: 'GET',
    };

    return this.plHttp.go(httpOpts).pipe(first());
  }
}
