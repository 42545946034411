import { Component, Input } from '@angular/core';
import { FeatureFlagName, FeatureFlagsService } from '@common/feature-flags';
import { PLProviderProfileService } from '@common/services';
import { Option } from '@root/src/lib-components/common/interfaces';

@Component({
  selector: 'pl-provider-notification-preferences',
  templateUrl: './pl-provider-notification-preferences.component.html',
  styleUrls: ['./pl-provider-notification-preferences.component.less'],
})
export class PLProviderNotificationPreferencesComponent {
  @Input() userId: string;
  @Input() selectedNotificationPreferences: string[] = [];
  @Input() phoneNumber?: string;
  @Input() onSave: Function;

  notificationPreferencesOptions: Option[] = [];
  notificationPreferences: any[];
  featureFlagShowNotificationPreferencesWithPhoneNumber: boolean;
  isSMSPreferenceSelected: boolean;
  unmodifiedPhoneNumber: string;

  constructor(
    private plProviderProfileService: PLProviderProfileService,
    private featureFlagsService: FeatureFlagsService,
  ) {}

  ngOnInit() {
    this.unmodifiedPhoneNumber = this.phoneNumber;

    this.featureFlagsService
      .isFeatureEnabled(
        FeatureFlagName.showNotificationPreferencesWithPhoneNumber,
      )
      .subscribe(enabled => {
        this.featureFlagShowNotificationPreferencesWithPhoneNumber = enabled;
      });

    this.notificationPreferencesOptions =
      this.plProviderProfileService.getNotificationPreferences();

    this.notificationPreferences = this.notificationPreferencesOptions.map(
      (opt: Option) => {
        let optObj = {
          ...opt,
          disabled: opt.value === 'EMAIL',
          selected: this.selectedNotificationPreferences.includes(
            opt.value.toString(),
          ),
        };

        if (optObj.value === 'SMS') {
          this.isSMSPreferenceSelected = optObj.selected;
        }

        return optObj;
      },
    );
  }

  evaluateNotificationPreferences() {
    const selectedNotifications = this.notificationPreferences
      .filter(n => n.selected)
      .map(n => n.value);

    this.isSMSPreferenceSelected = selectedNotifications.includes('SMS');
  }

  onClickSave() {
    if (this.phoneNumber.length < 9) return;

    const selectedNotifications = this.notificationPreferences
      .filter(n => n.selected)
      .map(n => n.value);

    this.plProviderProfileService
      .setNotificationPreferences(
        this.userId,
        selectedNotifications,
        this.phoneNumber !== this.unmodifiedPhoneNumber &&
          this.isSMSPreferenceSelected
          ? this.phoneNumber
          : undefined,
      )
      .subscribe(() => {
        this.onSave(selectedNotifications);
      });
  }
}
