import { Component, Input } from '@angular/core';

@Component({
  selector: 'pl-provider-details-contact-information',
  templateUrl: './pl-provider-details-contact-information.component.html',
  styleUrls: [
    './pl-provider-details.common.less',
    './pl-provider-details-contact-information.component.less',
  ],
})
export class PLProviderDetailsContactInformationComponent {
  @Input() provider: any;
  @Input() userType: string;
}
