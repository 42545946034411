module.exports = /* GraphQL */ `
  query ReferralMatchProviders($id: ID!) {
    referral(id: $id) {
      id
      providerCandidates {
        edges {
          node {
            id
            caseloadCount
            caseloadCountByOrg
            user {
              id
              firstName
              lastName
            }
            remainingAvailableHours
            previouslyMatchedWithClient
          }
        }
      }
    }
  }
`;
