import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { User } from '@common/auth/user.model';
import { tap } from 'rxjs/operators';
import { FeatureFlagsService } from '../feature-flags.service';

@Injectable()
export class FeatureFlagsIdentifyUserEffects {
  identifyUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<{ type: 'UPDATE_CURRENT_USER'; payload: User }>(
          'UPDATE_CURRENT_USER',
        ),
        tap(({ payload: user }) => {
          this.featureFlagsService.identifyUser(user);
        }),
      ),
    {
      dispatch: false,
    },
  );

  constructor(
    private actions$: Actions,
    private featureFlagsService: FeatureFlagsService,
  ) {}
}
