import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PLIconComponent } from './pl-icon.component';

@NgModule({
  imports: [CommonModule],
  exports: [PLIconComponent],
  declarations: [PLIconComponent],
})
export class PLIconModule {}
