import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PLButtonModule, PLInputModule, PLModalModule } from '@root/index';

import { PLPSPTrainingModalComponent } from './pl-psp-training-modal.component';

@NgModule({
  imports: [
    CommonModule,
    PLModalModule,
    FormsModule,
    ReactiveFormsModule,
    PLInputModule,
    PLButtonModule,
  ],
  exports: [PLPSPTrainingModalComponent],
  declarations: [PLPSPTrainingModalComponent],
})
export class PLPSPTrainingModalModule {}
