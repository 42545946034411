import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  EMPTY,
  Observable,
  Subject,
  combineLatest,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  first,
  map,
  switchMap,
  filter,
} from 'rxjs/operators';

import { AppStore } from '@app/appstore.model';
import { selectCurrentUser } from '@app/common/store';
import { Option } from '@common/interfaces';
import {
  SchoolYear,
  SchoolYearStartMonth,
} from '@common/school-year/school-year.model';
import { SchoolYearsService } from '@common/school-year/school-years.service';
import { PLApiUsStatesService, PLToastService } from '@root/index';
import {
  PLProviderPastPayRatesService,
  PastPayRateResponse,
} from './pl-provider-past-pay-rate.service';
import {
  PLProviderPayRateService,
  PayGridResponse,
  PayRateType,
  ProviderPayRate,
  SchoolYearChoiceType,
} from './pl-provider-pay-rate.service';
import { User } from '../../user/user.model';

const DEFAULT_ERROR_MESSAGE = 'Unable to retrieve data';

@UntilDestroy()
@Component({
  selector: 'pl-provider-pay-rates',
  templateUrl: 'pl-provider-pay-rates.component.html',
  styleUrls: [
    './pl-provider-pay-rates.component.less',
    '../pl-provider-v2/pl-provider-v2.common.less',
  ],
})
export class PLProviderPayRatesComponent implements OnInit {
  statesOptions: Option[] = [];
  selectedStates = [];
  ratesDownloadUrl: string;

  tableData: {
    id: string;
    label: string;
    provider_type: string;
    pay_rate: string;
  }[] = [];

  loading = true;
  isw2 = false;
  responseData: PayGridResponse;
  errorMessage: string;
  providerType: string;
  providerTypeCode: string;

  currentUser$: Observable<User>;
  isInternal$: Observable<boolean>;
  queryParams$: Observable<Params>;
  routeParams$: Observable<Params>;

  loadNotifier$ = new Subject<void>();
  schoolYearAvailableChoice$ = new Subject<SchoolYearChoiceType | null>();

  schoolYearsOptions: Option<SchoolYearChoiceType>[] = [];

  schoolYearChoice: SchoolYearChoiceType = 'current';
  schoolYearsLabels: Record<SchoolYearChoiceType, string> = {
    current: '',
    next: '',
  };

  pastPayRatesLinks: PastPayRateResponse[] = [];

  constructor(
    private route: ActivatedRoute,
    private plToast: PLToastService,
    private statesService: PLApiUsStatesService,
    private schoolYearsService: SchoolYearsService,
    private store: Store<AppStore>,
    private payRateService: PLProviderPayRateService,
    private pastPayRateService: PLProviderPastPayRatesService,
  ) {}

  ngOnInit() {
    this.currentUser$ = this.store.select(selectCurrentUser);
    this.isInternal$ = this.currentUser$.pipe(map(user => Boolean(user?.uuid)));
    this.queryParams$ = this.route.queryParams.pipe(first());
    this.routeParams$ = this.route.parent.params.pipe(first());

    this.loadSchoolYearOptions();
    this.loadPastPayRates();
    this.loadPayGrid();
    this.loadNotifier$.next();
  }

  loadSchoolYearOptions() {
    const schoolYears$ = this.schoolYearsService
      .getCurrentAndNext(SchoolYearStartMonth.july)
      .pipe(filter(currentAndNext => !!currentAndNext));

    combineLatest([
      this.currentUser$,
      this.schoolYearAvailableChoice$,
      schoolYears$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([user, schoolYearAvailableChoice, schoolYears]) => {
        const hasAdjustedHourlyRate = Boolean(
          user?.xProvider?.adjustedHourlyRate,
        );
        const [current, next] = schoolYears;

        this.schoolYearsLabels = {
          current: current.name.replace(' School Year', ''),
          next: next.name.replace(' School Year', ''),
        };

        if (schoolYearAvailableChoice) {
          this.schoolYearsOptions = [
            {
              label: this.schoolYearsLabels[schoolYearAvailableChoice],
              value: schoolYearAvailableChoice,
            },
          ];
          this.schoolYearChoice = schoolYearAvailableChoice;
          return;
        }

        if (hasAdjustedHourlyRate) {
          this.schoolYearsOptions = [
            {
              label: this.schoolYearsLabels.current,
              value: 'current',
            },
            {
              label: this.schoolYearsLabels.next,
              value: 'next',
            },
          ];
          return;
        }

        this.schoolYearsOptions = [
          {
            label: this.schoolYearsLabels.current,
            value: 'current',
          },
        ];
      });
  }

  loadPastPayRates() {
    combineLatest([this.routeParams$, this.queryParams$])
      .pipe(
        switchMap(([routeParams, queryParams]) => {
          const preagreement_uuid = queryParams['preagreement_uuid'];
          const applicant = queryParams['applicant'];
          const provider_uuid = routeParams['id'];
          if (provider_uuid && !preagreement_uuid && !applicant) {
            return this.pastPayRateService.fetchPastPayRates(provider_uuid);
          }
          return of([]);
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            // Provider has no past pay rates
            return EMPTY;
          }
          return throwError(err);
        }),
        untilDestroyed(this),
      )
      .subscribe(res => {
        res.forEach(rateLink => {
          rateLink.school_year = rateLink.school_year.replace(
            ' School Year',
            '',
          );
        });
        this.pastPayRatesLinks = res;
      });
  }

  loadPayGrid() {
    combineLatest([this.routeParams$, this.queryParams$, this.loadNotifier$])
      .pipe(
        debounceTime(300),
        switchMap(([routeParams, queryParams, _]) => {
          const preagreement_uuid = queryParams['preagreement_uuid'];
          const applicant = queryParams['applicant'];
          const provider_uuid = routeParams['id'];

          let rateType: PayRateType = 'applicant';

          if (preagreement_uuid) {
            rateType = 'preagreement';
          } else if (provider_uuid) {
            rateType = 'provider';
          }

          const payRateUuid = provider_uuid || preagreement_uuid || applicant;

          this.ratesDownloadUrl = this.payRateService.getDownloadUrl(
            rateType,
            payRateUuid,
          );

          if (payRateUuid) {
            return this.payRateService.fetchPayGrid(
              rateType,
              payRateUuid,
              this.schoolYearChoice,
            );
          }

          return this.currentUser$.pipe(
            switchMap(user =>
              this.payRateService.fetchPayGrid(
                'provider',
                user.uuid,
                this.schoolYearChoice,
              ),
            ),
          );
        }),
        catchError((err: HttpErrorResponse) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 410) {
              this.plToast.show(
                'error',
                'This agreement has expired, please reach out to the recruiter.',
              );
            }
          }
          this.errorMessage = DEFAULT_ERROR_MESSAGE;
          return of<PayGridResponse | null>(null);
        }),
        untilDestroyed(this),
      )
      .subscribe(res => {
        this.loading = false;

        if (!res) return;
        // Exclude rates for other provider types than the default one
        this.responseData = {
          ...res,
          providerpayrate: res.providerpayrate.filter(
            this.filterByProviderType,
          ),
        };
        this.isw2 = res.isw2;
        this.statesOptions = this.mapStatesToOptions(res);
        this.tableData = this.mapToData(this.responseData);

        this.schoolYearAvailableChoice$.next(res.school_year);

        this.updateTableData();

        if (res.providerpayrate.length) {
          this.providerType = res.providerpayrate[0].provider_type;
          this.providerTypeCode = res.providerpayrate[0].provider_type_code;
        } else {
          this.providerType = '';
          this.providerTypeCode = '';
        }
      });
  }

  onChangeSchoolYear() {
    this.loadNotifier$.next();
  }

  onChangeSelect() {
    this.updateTableData();
  }

  mapStatesToOptions(response: PayGridResponse): Option[] {
    // Eliminate duplicate states
    const states = [
      ...new Set(response.providerpayrate.map(rate => rate.state)),
    ];

    return states
      .sort((a, b) => (a > b ? 1 : -1))
      .map(state => ({
        value: state,
        label: this.statesService.getFromKey('key', state).name,
      }));
  }

  mapToData(
    response: PayGridResponse,
  ): { id: string; label: string; provider_type: string; pay_rate: string }[] {
    return response.providerpayrate.map(rate => ({
      id: rate.state,
      label: this.statesService.getFromKey('key', rate.state).name,
      provider_type: rate.provider_type,
      pay_rate: rate.pay_rate,
    }));
  }

  updateTableData() {
    const data = { ...this.responseData };

    if (this.selectedStates.length > 0) {
      data.providerpayrate = data.providerpayrate.filter(this.filterByState);
    }

    this.tableData = this.mapToData(data);
  }

  filterByState = (rate: ProviderPayRate) => {
    return this.selectedStates.length > 0
      ? this.selectedStates.includes(rate.state)
      : true;
  };

  filterByProviderType = (rate: ProviderPayRate) => {
    if (!this.providerTypeCode) return true;
    return rate.provider_type_code === this.providerTypeCode;
  };
}
