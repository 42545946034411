<div class="pl-provider-pay-rates">
  <div>
    <h2>Pay rates</h2>
    <div class="description">
      <p>
        Your hourly rate is variable and will be based on your qualifications,
        years of experience, and location of the assignments you choose to
        accept.
      </p>
    </div>
    <div class="divider"></div>
    <div class="content">
      <div class="loader" *ngIf="loading">
        <span class="loader-message">Loading data</span>
        <pl-dot-loader></pl-dot-loader>
      </div>
    </div>

    <div class="error" *ngIf="errorMessage">{{ errorMessage }}</div>

    <ng-container *ngIf="!loading && !errorMessage && tableData.length">
      <div class="logo" *ngIf="(isInternal$ | async) === false">
        <pl-icon svg="logo-color-no-tm" [width]="155" [height]="40"></pl-icon>
      </div>

      <div class="rates">
        <aside class="sidebar">
          <div class="sidebar__group">
            <pl-input-radio-group
              label="School Year"
              [(model)]="schoolYearChoice"
              [options]="schoolYearsOptions"
              (onChange)="onChangeSchoolYear($event)"
            >
            </pl-input-radio-group>
          </div>

          <div class="sidebar__group" *ngIf="pastPayRatesLinks.length">
            <pl-input-label label="Archived School Years"></pl-input-label>
            <div class="links">
              <div *ngFor="let link of pastPayRatesLinks" class="link">
                <a [href]="link.download_url" download>
                  <pl-icon
                    class="blue-medium"
                    [svg]="'download'"
                    [scale]="0.8"
                  ></pl-icon>
                  <span>{{ link.school_year }}</span>
                </a>
              </div>
            </div>
          </div>

          <div class="sidebar__group">
            <pl-input-multi-select
              [label]="'Assignment Locations'"
              [(model)]="selectedStates"
              [options]="statesOptions"
              [placeholder]="'Search Location(s)'"
              (onChange)="onChangeSelect($event)"
            >
            </pl-input-multi-select>
          </div>
        </aside>

        <table class="rates-table">
          <thead>
            <tr>
              <th>Location</th>
              <th class="rates-table__rate-column">
                <span>Your Rate</span>
                <a
                  *ngIf="ratesDownloadUrl"
                  class="rates-table__download-rates"
                  download
                  href="{{ ratesDownloadUrl }}&school_year={{
                    schoolYearsLabels[schoolYearChoice]
                  }}&states={{ selectedStates }}"
                >
                  <pl-icon
                    class="blue-medium"
                    [svg]="'download'"
                    [scale]="0.8"
                  ></pl-icon>
                  <span>Download Rates</span>
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let state of tableData">
              <td>{{ state.label }}</td>
              <td>${{ state.pay_rate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</div>
