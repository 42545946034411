import { PLModalService } from './pl-modal.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'pl-modal-header-wrapper',
  templateUrl: './pl-modal-header-wrapper.component.html',
  styleUrls: ['./pl-modal-header-wrapper.component.less'],
})
export class PLModalHeaderWrapperComponent {
  @Input() headerText = '';
  @Input() headerHtml: SafeHtml;
  @Input() hideCloseButton: boolean;
  @Output() onCloseModal: EventEmitter<any> = new EventEmitter();
  @ViewChild('plModalHeaderWrapperBody', { static: false })
  plModalHeaderWrapperBody: ElementRef;

  constructor(private modalService: PLModalService) {}

  hide() {
    this.onCloseModal.emit(true);
    // TODO - hide just this instance?
    this.modalService.destroyAll();
  }
}
