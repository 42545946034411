import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pl-assignment-manager-returning-details',
  templateUrl: './pl-assignment-manager-returning-details.component.html',
  styleUrls: ['./pl-assignment-manager-returning-details.component.less'],
})
export class PLAssignmentManagerReturningDetailsComponent implements OnInit {
  @Input() assignment: any;
  @Input() orgName: string;
  private _schoolYearName: string;
  private _mainServiceLine: string;

  ngOnInit() {
    this._schoolYearName =
      this.assignment?.organizationPreferences?.[0]?.school_year_name ??
      this.assignment?.providerPreferences?.[0]?.school_year_name;
    this._mainServiceLine =
      this.assignment?.organizationPreferences?.[0]?.main_service_line ??
      this.assignment?.providerPreferences?.[0]?.main_service_line;
  }
  public get schoolYearName(): string {
    return this._schoolYearName;
  }

  public get mainServiceLine(): string {
    return this._mainServiceLine;
  }
}
