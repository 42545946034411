import { Component, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Option } from '@common/interfaces';
import { PLTimesheetProgressOrganizationFilterService } from '../services/pl-timesheet-progress-organization-filter.service';

@Component({
  selector: 'pl-timesheet-progress-organization-filter',
  templateUrl: './pl-timesheet-progress-organization-filter.component.html',
  styleUrls: ['./pl-timesheet-progress-organization-filter.component.less'],
})
export class PLTimesheetProgressOrganizationFilterComponent {
  @Output() changed = new EventEmitter<string>();

  options$: Observable<Option<string>[]>;

  selected: string = '';
  organizationName: string = '';

  constructor(
    private filterService: PLTimesheetProgressOrganizationFilterService,
  ) {
    this.options$ = this.filterService.data$.pipe(
      tap(data => {
        if (data && data.length === 1) {
          this.organizationName = data[0].name;
          this.selected = data[0].uuid;
        } else {
          this.organizationName = '';
          this.selected = '';
        }
      }),
      map(orgs =>
        orgs?.length > 1
          ? [
              { value: '', label: 'All organizations' },
              ...orgs.map(org => {
                return { value: org.uuid, label: org.name };
              }),
            ]
          : [],
      ),
    );
  }

  selectionChanged() {
    this.changed.emit(this.selected);
  }
}
