<div class="nav-item" [ngClass]="{ active: isActive }">
  <a
    *ngIf="!link.target; else external"
    class="pl-side-nav-link"
    [routerLink]="link.href"
    [ngClass]="{ 'active-link': isActive }"
    [matTooltip]="navCollapsed ? link.label : ''"
    matTooltipPosition="right"
  >
    <mat-icon *ngIf="link.icon" class="side-nav-icon material-icons-outlined">
      {{ link.icon }}
    </mat-icon>
    <span *ngIf="!navCollapsed">{{ link.label }}</span>
  </a>

  <div
    class="sub-nav-list"
    *ngIf="!navCollapsed && link.children?.length && isActive"
  >
    <pl-side-sub-nav-link
      *ngFor="let link of link.children"
      [link]="link"
      [navCollapsed]="navCollapsed"
    >
    </pl-side-sub-nav-link>
  </div>
</div>

<ng-template #external>
  <a
    class="pl-side-nav-link"
    [href]="link.href"
    [target]="link.target"
    [matTooltip]="navCollapsed ? link.label : ''"
    matTooltipPosition="right"
  >
    <mat-icon *ngIf="link.icon" class="side-nav-icon material-icons-outlined">
      {{ link.icon }}
    </mat-icon>
    <span *ngIf="!navCollapsed">{{ link.label }}</span>
  </a>
</ng-template>
