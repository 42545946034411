import { envToBoolean } from '@environments/environment.utils';
import { ENV } from '../types/internal';

export const environment: ENV = {
  env_key: 'staging',
  production: false,
  debug: false,
  app_name: 'eduClients',
  cookie_domain: process.env.COOKIE_DOMAIN || 'presence.com',
  heap_key: '',
  sentry_key:
    process.env.SENTRY_DSN ??
    'https://f91530b785234c35986ac9257684221e@o7007.ingest.sentry.io/4504758422863872',
  pendo_key: '',
  git_sha: process.env.GIT_SHA || '',
  login_url: 'https://staging-login.presence.com',
  apps: {
    apiWorkplace: {
      url: 'https://staging-api.presence.com/workplace',
    },
    auth: {
      url: 'https://staging-api.presence.com/login',
    },
    mizar: {
      url: 'https://staging-api.presence.com/mizar',
    },
    platform: {
      url: 'https://staging-api.presence.com/platform',
    },
    library: {
      url: 'https://staging-library.presence.com',
    },
    room: {
      url: 'https://staging-room.presence.com',
    },
    therapy: {
      url: 'https://staging-therapy.presence.com',
    },
    learnupon: {
      url: 'presencedev.learnuponus.com',
    },
    edgeGateway: {
      url: 'https://staging-api.presence.com',
    },
    chiron: {
      url: 'https://staging-chiron.presence.com',
    },
  },
  support_email: 'asksupport@presencelearning.com',
  support_phone: '18444154592',
  inactiveMinutes: 0,
  SESSION_INACTIVITY_TIME: 3600,
  TINY_MCE_KEY: 'vq8s8y8chx9jrysjh3vr7ygxw11w7m5roh6pvt8xisgi5kwx',
  TINY_TIMEOUT_TIME: 900000,
  TINY_INTERVAL_TIME: 240000,
  inactiveReloadMinutes: 240,
  configCatSdkKey: 'DgLbCHEjckGEDa0uS7QeUQ/6h74ZCbcyUClN5FUSZzn5Q',
  datadogEnvName: 'staging',
  datadogApiKey: 'pub625b830405a881534e138208e8dbc423',
  datadogAppId: '05778ca9-fd17-4a12-98d4-c2394343a5fc',
  datadogRumEnabled: envToBoolean(process.env.DATADOG_RUM_ENABLED, false),
  datadogLogsEnabled: envToBoolean(process.env.DATADOG_LOGS_ENABLED, false),
};
