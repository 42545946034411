<div class="pl-checklist">
  <div class="header">
    <h2 class="title">
      {{ checklist.title }}
    </h2>
    <a class="toggle-items" (click)="toggleItems(checklist.id)">{{
      toggleMsg
    }}</a>
  </div>
  <p class="description">
    {{ checklist.description }}
  </p>
  <div *ngFor="let item of checklist.items" class="item">
    <pl-checklist-item
      [item]="item"
      (onStatusChange)="changeItemStatus($event)"
    >
    </pl-checklist-item>
  </div>
</div>
