import { Component, Input } from '@angular/core';
import { PLProviderService } from '@modules/providers/pl-provider.service';

@Component({
  selector: 'pl-provider-details-service-details',
  templateUrl: './pl-provider-details-service-details.component.html',
  styleUrls: [
    './pl-provider-details.common.less',
    './pl-provider-details-service-details.component.less',
  ],
  providers: [PLProviderService],
})
export class PLProviderDetailsServiceDetailsComponent {
  @Input() provider: any;
  @Input() currentUser: any;
  @Input() userType: string;
  CEMName: string = '';
  hourlyRate: string = '';
  payRatesLink: string = '';
  constructor(private plProvider: PLProviderService) {}

  ngOnInit() {
    this.plProvider.getFromRoute().subscribe((res: any) => {
      this.provider = res.provider;

      let hourlyRate = this.provider.hourlyRate;
      if (hourlyRate) {
        this.hourlyRate = hourlyRate;
      }

      let accountOwner = this.provider.accountOwner;
      if (accountOwner) {
        this.CEMName = accountOwner.firstName + ' ' + accountOwner.lastName;
      }
      let user = this.provider.user;
      if (user) {
        this.payRatesLink = `/provider/${this.provider.user?.id}/provider-pay-rates`;
      }
    });
  }
}
