import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plFormatDelimiter',
})
export class PLFormatDelimiterPipe implements PipeTransform {
  transform(value: any): any {
    if (typeof value === 'string') {
      return value.replace(/(;|,)/g, ', ');
    } else {
      return '\u254C';
    }
  }
}
