export enum SchoolYearStartMonth {
  august = 8,
  july = 7,
}

export interface SchoolYear {
  uuid: string;
  code: string;
  name: string;
  year_type: string;
  start_year: number;
  start_date: string;
  end_date: string;
  is_current: boolean;
}
