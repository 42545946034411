import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PLUrlsService } from '@root/index';
import { PLHttpService } from '@root/src/lib-components/pl-http/pl-http.service';
import {
  ASSIGNMENT_STATUS,
  PLAssignmentProposalItem,
  PLAssignmentProposalRaw,
  PLAssignmentStatusEnum,
  PLOpptyDemandItem,
  PLOrgDemandItem,
  STATUS_TRANSITION_MAP,
  StatusUpdateResults,
} from './pl-assignment-manager.model';

/**
 * Pure functions for PLAssignmentProposalItem and PLOrgDemandItem
 */

@Injectable()
export class PLAssignmentProposalItemService {
  constructor(private plHttp: PLHttpService, private plUrls: PLUrlsService) {}

  shouldProposalAffectCommittedFulfillment(item: PLAssignmentProposalItem) {
    const status = item.statusCode;
    return (
      status === PLAssignmentStatusEnum.RESERVED ||
      status === PLAssignmentStatusEnum.INITIATED ||
      status === PLAssignmentStatusEnum.PENDING ||
      status === PLAssignmentStatusEnum.ACTIVE ||
      this.isCompletingAfterPendingCompletionDate(
        status,
        item.endDateRaw,
        item.demandPendingCompleteDateRaw,
      )
    );
  }

  shouldProposalAffectFulfillment(item: PLAssignmentProposalItem) {
    const status = item.statusCode;
    return (
      status === PLAssignmentStatusEnum.PROPOSED ||
      status === PLAssignmentStatusEnum.LOCKED ||
      status === PLAssignmentStatusEnum.RESERVED ||
      status === PLAssignmentStatusEnum.INITIATED ||
      status === PLAssignmentStatusEnum.PENDING ||
      status === PLAssignmentStatusEnum.ACTIVE ||
      status === PLAssignmentStatusEnum.CAPACITY_PLANNING_LOCKED ||
      this.isCompletingAfterPendingCompletionDate(
        status,
        item.endDateRaw,
        item.demandPendingCompleteDateRaw,
      )
    );
  }

  private isCompletingAfterPendingCompletionDate(
    status: string,
    endDate: any,
    demandPendingCompletionDate: any,
  ) {
    if (!endDate || !demandPendingCompletionDate) return false;

    // completed, and proposal's end date after the demand's pending end date
    return (
      status === PLAssignmentStatusEnum.COMPLETED &&
      endDate.diff(demandPendingCompletionDate) >= 0
    );
  }

  proposalMatchesFilter(p: PLAssignmentProposalItem, statuses: string) {
    if (statuses.includes(p.statusCode)) {
      return true;
    }

    // include pending complete when filtering for "active"
    if (
      statuses.includes(PLAssignmentStatusEnum.ACTIVE) &&
      this.isCompletingAfterPendingCompletionDate(
        p.statusCode,
        p.endDateRaw,
        p.demandPendingCompleteDateRaw,
      )
    ) {
      return true;
    }

    return false;
  }

  upsertProviderSupplyItem(
    item: PLAssignmentProposalItem,
    opptyDemandItem: PLOpptyDemandItem,
  ) {
    const lists = [
      opptyDemandItem.providerSupplyList,
      opptyDemandItem.providerSupplyListOrig,
    ];

    lists.forEach((supplyList: PLAssignmentProposalItem[]) => {
      const index = supplyList.findIndex(
        (_: PLAssignmentProposalItem) => _.uuid === item.uuid,
      );

      if (index === -1) {
        supplyList.push(item);
      } else {
        supplyList[index] = item;
      }
    });
  }

  rejectSingleProposal(
    orgDemandList: any[],
    proposalItem: PLAssignmentProposalItem,
    reason?: { pl_rejected_reason: string; pl_rejected_other_reason?: string },
  ): Observable<StatusUpdateResults> {
    return this.updateProposal(
      orgDemandList,
      proposalItem,
      PLAssignmentStatusEnum.PL_REJECTED,
      reason,
    );
  }

  // Used for Accept/Reject bulk updates
  updateProposal(
    orgDemandList: any[],
    proposalItem: PLAssignmentProposalItem,
    status: PLAssignmentStatusEnum,
    reason?: { pl_rejected_reason: string; pl_rejected_other_reason?: string },
  ): Observable<StatusUpdateResults> {
    // NOTE: the api does not accept a list, so we'll throttle the requests
    // and tally the responses
    const saved: any[] = [];
    const failed: any[] = [];
    const ignored: any[] = [];
    const isDone = () => saved.length + failed.length + ignored.length === 1;
    let count = 0;

    return new Observable((observer: any) => {
      const finalizeIfDone = () => {
        if (isDone()) {
          const results: StatusUpdateResults = { saved, failed, ignored };
          observer.next(results);
          observer.complete();
        }
      };
      // restrict updates to valid transitions
      if (!this.canUpdateProposal(proposalItem.statusCode, status)) {
        ignored.push(proposalItem);
        finalizeIfDone();
      } else {
        // ORG-DEMAND / OPPTY-DEMAND / PROVIDER-SUPPLY
        let orgDemand: PLOrgDemandItem;
        let opptyDemand: PLOpptyDemandItem;

        orgDemand = orgDemandList.find((_org: PLOrgDemandItem) => {
          return _org.opptyDemandList.find((_oppty: PLOpptyDemandItem) => {
            return _oppty.providerSupplyList.find(
              (_supply: PLAssignmentProposalItem) => {
                if (_supply.uuid === proposalItem.uuid) {
                  opptyDemand = _oppty; // FYI: side-effect
                  return true;
                }
              },
            );
          });
        });
        // batch 20 at a time, separate batches by 1s.
        const ms = Math.floor(count++ / 20) * 1000;
        setTimeout(() => {
          this.updateProposalStatus(
            proposalItem.uuid,
            status,
            reason,
          ).subscribe(
            (res: any) => {
              saved.push(proposalItem);
              proposalItem.statusCode = status;
              proposalItem.statusLabel = ASSIGNMENT_STATUS[status];
              const supplyHours: number = Number(proposalItem.supplyHours);
              if (!this.shouldProposalAffectFulfillment(proposalItem)) {
                proposalItem.supplyHours = '0';
              }
              this.updateOrgDemandTallies(orgDemand, opptyDemand.uuid);
              finalizeIfDone();
            },
            (err: any) => {
              failed.push({ err, item: proposalItem });
              finalizeIfDone();
            },
          );
        }, ms);
      }
    });
  }

  updateProposalStatus(
    uuid: string,
    status: PLAssignmentStatusEnum,
    reason?: any,
  ) {
    return this.saveProposal({ uuid, status, ...reason });
  }

  saveProposal(data?: PLAssignmentProposalRaw) {
    return this.plHttp.save('assignmentProposals', data, '', {
      suppressError: true,
    });
  }

  contactClsm(data) {
    return this.plHttp.save('assignmentProposals', data, '', {
      suppressError: true,
    });
  }

  canUpdateProposal(
    currentStatus: PLAssignmentStatusEnum,
    newStatus: PLAssignmentStatusEnum,
  ) {
    return !!STATUS_TRANSITION_MAP[currentStatus][newStatus];
  }

  updateOrgDemandTallies(orgDemand: PLOrgDemandItem, opptyDemandUuid: string) {
    // recalc proposed and committed
    const opptyDemandItem = orgDemand.opptyDemandList.find(
      (x: PLOpptyDemandItem) => x.uuid === opptyDemandUuid,
    );

    if (!opptyDemandItem) return;

    const totalProposedList = opptyDemandItem.providerSupplyListOrig.filter(
      (x: PLAssignmentProposalItem) => this.shouldProposalAffectFulfillment(x),
    );

    const totalProposed =
      totalProposedList.length === 0
        ? 0
        : totalProposedList
            .map((x: PLAssignmentProposalItem) => Number(x.supplyHours))
            .reduce((hours: any, x: any) => hours + Number(x));
    const totalCommittedList = opptyDemandItem.providerSupplyListOrig.filter(
      (x: PLAssignmentProposalItem) =>
        this.shouldProposalAffectCommittedFulfillment(x),
    );

    const totalCommitted =
      totalCommittedList.length === 0
        ? 0
        : totalCommittedList
            .map((x: PLAssignmentProposalItem) => Number(x.supplyHours))
            .reduce((hours: any, x: any) => hours + Number(x));
    opptyDemandItem.totalHoursProposed = `${totalProposed}`;
    opptyDemandItem.totalHoursCommitted = `${totalCommitted}`;
  }

  setOrgDemandItemSupplyTotal(
    orgDemand: PLOrgDemandItem,
    includeProposedInTotals: boolean,
    includeCapacityInTotals: boolean,
  ) {
    const hoursByServiceDemand = orgDemand.hoursByServiceDemand;
    const hoursByServiceSupply = this.getSupplyHours(
      orgDemand.opptyDemandList,
      includeProposedInTotals,
      includeCapacityInTotals,
    );

    orgDemand.hoursByServiceSupply = hoursByServiceSupply;
    orgDemand.hoursTotalSupply = this.getTotalHours(hoursByServiceSupply);
    orgDemand.fulfillmentPercentNormalized =
      this.getFulfillmentPercentNormalized(
        hoursByServiceDemand,
        hoursByServiceSupply,
      );
  }
  private getSupplyHours(
    opptyDemandList: PLOpptyDemandItem[],
    includeProposed: boolean,
    includeCapacity: boolean,
  ): any {
    const hours = {};
    opptyDemandList.forEach((opptyDemandItem: PLOpptyDemandItem) => {
      let supply = includeProposed
        ? Number(opptyDemandItem.totalHoursProposed)
        : Number(opptyDemandItem.totalHoursCommitted);

      if (includeCapacity) {
        supply += Number(opptyDemandItem.totalHoursCapacityPlanning);
      }

      hours[opptyDemandItem.uuid] = this.roundTwoDecimalPlaces(supply);
    });
    return hours;
  }

  getTotalHours(hoursMap: any): number {
    const total = Object.values(hoursMap).reduce<number>(
      (sum: number, h: string) => sum + Number(h),
      0,
    );
    return this.roundTwoDecimalPlaces(total);
  }

  // return a whole number value weighted/normalized/capped at 100%
  private getFulfillmentPercentNormalized(
    hoursByServiceDemand: any,
    hoursByServiceSupply: any,
  ) {
    let totalSupply = 0;
    let totalDemand = 0;
    const keys = Object.keys(hoursByServiceDemand);
    keys.forEach((serviceGroupUuid: string) => {
      const demandHours = hoursByServiceDemand[serviceGroupUuid];
      const supplyHours = hoursByServiceSupply[serviceGroupUuid] || 0;
      const normalizedSupply = Math.min(supplyHours, demandHours);
      totalSupply += normalizedSupply;
      totalDemand += demandHours;
    });
    const percent = (totalSupply / totalDemand) * 100;

    // round up if the float represents 100.
    if (100 - percent < 0.9999) {
      return 100;
    }
    // otherwise round down (not completely fulfilled)
    return Math.floor(percent);
  }

  roundTwoDecimalPlaces(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }
  getClsmModalOptions() {
    const url = `${this.plUrls.urls.assignmentProposals}on-hold-reasons`;
    return this.plHttp
      .get('', {}, url, {
        suppressError: true,
      })
      .pipe(
        map((res: any) => {
          return res.results.map(item => ({
            label: item.description,
            value: item.code,
          }));
        }),
      );
  }
}
