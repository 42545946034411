<div
  class="fulfillment-pill"
  [ngClass]="[
    levelClassName,
    light ? 'light' : '',
    isInteractive ? 'pointer' : ''
  ]"
>
  <mat-icon *ngIf="isClosedLost" class="icon">error</mat-icon>
  <ng-container *ngIf="customLabel; else defaultLabel">
    {{ customLabel }}
  </ng-container>
  <ng-template #defaultLabel> {{ fulfillmentPercent }}% fulfilled </ng-template>
</div>
