import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { noop } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { PLClinicalProductCode } from '@common/enums';
import { Option } from '@common/interfaces';

import {
  referralGradeOptions,
  referralIntervalOptions,
  referralGroupingSpreadsheetValues,
  referralTrackingTypeSpreadsheetValues,
} from '@common/services/pl-client-referral';

import { PLProviderTypesService } from '@common/services/pl-provider-types.service';

import { FTE_IMPORT_VALUE } from './pl-add-referrals-table-data.service';

export type StudentReferralUploadObject = {
  rowIndex: number; // 1;
  location?: string; // 'School Name';
  lastName: string; // 'Sheen';
  firstName: string; // 'Charlie';
  externalId: string; // '1';
  birthday: string; // '1954-04-06';
  grade: string; // 'Kindergarten';
  providerTypeCode: string; // 'slp';
  productTypeCode: string; // 'direct_service';
  duration: string | number; // '10';
  frequency: string; // '4';
  interval: string; // 'daily';
  grouping: string; // 'Either';
  primaryLanguageCode: string; // 'en';
  shortTermLeave: string; // 'No';
  esy: string; // 'No';
  isFte: string | FTE_IMPORT_VALUE; // '';
  dedicated: string; // 'Yes';
  trackingType: string; // 'REG';
  AAC: string; // 'Yes';
  ASL: string; // 'No';
  DHH: string; // 'No';
  visuallyImpaired: string; // 'No';
  notes: string; // 'All my notes are here';
  original?: {
    rowIndex: number; // 1;
    location?: string; // 'School Name';
    lastName: string; // 'Sheen';
    firstName: string; // 'Charlie';
    externalId: string; // '1';
    birthday: string; // '04/06/1954';
    grade: string; // 'Kindergarten';
    providerTypeCode: string; // 'Speech-Language Pathologist';
    productTypeCode: string; // 'Direct Therapy';
    duration: string | number; // '10';
    frequency: string; // '4';
    interval: string; // 'Daily';
    grouping: string; // 'Either';
    primaryLanguageCode: string; // 'English';
    shortTermLeave: string; // 'No';
    esy: string; // 'No';
    isFte: string; // '';
    dedicated: string; // 'Yes';
    trackingType: string; // 'REG';
    AAC: string; // 'Yes';
    ASL: string; // 'No';
    DHH: string; // 'No';
    visuallyImpaired: string; // 'No';
    notes: string; // 'All my notes are here';
  };
  /* Dynamic fields added post-upload */
  missingFields?: string[]; // ['FTE'];
  errorReason?: string; // 'Missing required fields: FTE';
  invalidFields?: string[];

  isValid?: boolean;
  englishLanguageLearnerStatus?: string; // TODO - UNSURE
  secondaryLanguageCode?: string; // TODO - UNSURE
  clientId?: string; // TODO - UNSURE
  ignoreDuplicateWarning?: boolean; // TODO - UNSURE
  assessmentPlanSignature?: string; // TODO - UNSURE
  assessmentDueDate?: string; // TODO - UNSURE
  meetingDate?: string; // TODO - UNSURE
};

export type StudentReferralFormat = {
  lastName: string; // 'General';
  firstName: string; // 'General';
  externalId: string; // '@';
  birthday: string; // 'mm/dd/yyyy';
  grade: string; // 'General';
  providerTypeCode: string; // 'General';
  productTypeCode: string; // 'General';
  duration: string; // 'General';
  frequency: string; // '0_);\\(0\\)';
  interval: string; // '0';
  grouping: string; // 'General';
  primaryLanguageCode: string; // 'General';
  shortTermLeave: string; // 'General';
  esy: string; // 'General';
  isFte: string; // 'General';
  dedicated: string; // 'General';
  trackingType: string; // 'General';
  AAC: string; // 'General';
  ASL: string; // 'General';
  DHH: string; // 'General';
  visuallyImpaired: string; // 'General';
  notes: string; // 'General';
};

export interface Referral {
  providerTypeCode: string; // slp | ot | mhp | sped | pa
  productTypeCode: string;
  providerUsername?: string;
  schoolYearCode: string;
  notes: string;
  esy: boolean;
  grade: string;
  frequency: string;
  interval: string;
  duration: number;
  grouping: string;
  ignoreDuplicateWarning: boolean;
  isShortTerm?: boolean;
  languageId?: string; // TODO PRO-445 - maybe doesn't work, was set as boolean
  assessmentPlanSignedOn?: string;
  dueDate?: string;
  meetingDate?: string;
  isFte?: boolean;
  isAac?: boolean;
  isAsl?: boolean;
  isDhh?: boolean;
  isVi?: boolean;
  isDedicated?: boolean;
  trackingType?: string;
}

export interface Client {
  id?: string;
  firstName: string;
  lastName: string;
  birthday: string;
  locationIds: string[];
  externalId: string;
  englishLanguageLearnerStatus: string;
  primaryLanguageCode: string;
  secondaryLanguageCode: string;
}

export interface ClientReferral {
  client: Client;
  referral: Referral;
}

const toValues = (options: Option[]): string[] =>
  options.map(({ value }) => value.toString());
const toLabels = (options: Option[]): string[] =>
  options.map(({ label }) => label.toString());
const toLowerCase = (values: string[]): string[] =>
  values.map(value => value.toLowerCase());

@Injectable()
export class PLClientReferralDataModelService {
  languageCodes: string[] = [
    'en',
    'es',
    'ar',
    'zh-tw',
    'zh-cn',
    'fr',
    'de',
    'it',
    'ko',
    'ru',
    'tl',
    'vi',
  ];
  languageCrosswalkTable: any = {
    english: 'en',
    spanish: 'es',
    arabic: 'ar',
    'chinese (cantonese)': 'zh-tw',
    cantonese: 'zh-tw',
    'chinese (mandarin)': 'zh-cn',
    chinese: 'zh-cn',
    mandarin: 'zh-cn',
    french: 'fr',
    german: 'de',
    italian: 'it',
    korean: 'ko',
    russian: 'ru',
    tagalog: 'tl',
    vietnamese: 'vi',
  };

  productTypes: string[] = [
    PLClinicalProductCode.DIR_SVC,
    PLClinicalProductCode.EVAL,
    PLClinicalProductCode.SV,
  ];
  productTypesCrosswalkTable: any = {
    evaluation: PLClinicalProductCode.EVAL,
    'direct therapy': PLClinicalProductCode.DIR_SVC,
  };

  ellStatuses: string[] = [
    'never_identified',
    'currently_identified',
    'previously_identified',
  ];
  ellStatusesCrosswalkTable: any = {
    never: 'NEVER_IDENTIFIED',
    current: 'CURRENTLY_IDENTIFIED',
    previous: 'PREVIOUSLY_IDENTIFIED',
  };

  validateValues = {
    primaryLanguageCode: this.languageCodes,
    secondaryLanguageCode: this.languageCodes,
    englishLanguageLearnerStatus: this.ellStatuses,
    providerTypeCode: [], // Filled in setProviderTypeCodesForDataValidation
    productTypeCode: this.productTypes,
    esy: ['yes', 'no'],
    shortTermLeave: ['yes', 'no'],
    grade: toLowerCase(toValues(referralGradeOptions)),
    interval: toLowerCase(toLabels(referralIntervalOptions)),
    grouping: toLowerCase(referralGroupingSpreadsheetValues),
    isFte: ['yes', 'no'],
    trackingType: toLowerCase(referralTrackingTypeSpreadsheetValues),
  };

  crosswalkTables = {
    primaryLanguageCode: this.languageCrosswalkTable,
    secondaryLanguageCode: this.languageCrosswalkTable,
    englishLanguageLearnerStatus: this.ellStatusesCrosswalkTable,
    productTypeCode: this.productTypesCrosswalkTable,
    providerTypeCode: {}, // Filled in setProviderTypeCodesForDataValidation.
  };

  constructor(private plProviderTypesSvc: PLProviderTypesService) {
    this.setProviderTypeCodesForDataValidation();
  }

  // if the value is valid, empty, or not a string, returns the value
  // if the value is an invalid value:
  // - if crosswalk is false or no crosswalk exists for that fieldName, returns false
  // - if crosswalk is true, attempts to crosswalk:
  // -- if crosswalk succeeds, returns crosswalked value
  // -- if crosswalk fails, returns false
  validateField(fieldName: string, value: string, crosswalk: boolean) {
    if (typeof value !== 'string') {
      return value;
    }

    const lowerCaseVal = value.toLowerCase().trim();
    const validValuesForField = this.validateValues[fieldName];
    const indexOfLowerCaseVal = validValuesForField.indexOf(lowerCaseVal);

    if (
      value.length === 0 ||
      !validValuesForField ||
      indexOfLowerCaseVal > -1
    ) {
      let actualValue: any = value;

      if (fieldName === 'interval' && indexOfLowerCaseVal > -1) {
        actualValue = referralIntervalOptions[indexOfLowerCaseVal].value;
      }

      return actualValue;
    } else {
      if (crosswalk && this.crosswalkTables[fieldName]) {
        const crosswalked = this.crosswalkTables[fieldName][lowerCaseVal];

        if (crosswalked) {
          return crosswalked;
        }
      }

      return false;
    }
  }

  // Microsoft Excel date formats are lowercase, which does not parse, so we uppercase here when parsing.
  // This will fail for times, only use on dates
  private validateDate(date: string, format: string) {
    if (date.length === 0) {
      return false;
    }
    // Microsft Excel format is '@' for plain Text
    if (!format || format.trim() === '@') {
      format = '';
    }
    const dayjsDate = dayjs(date, format.toUpperCase());
    // two digit years shouldn't be used, but if they are, dayjs assumes this century, and prepends a '20'.
    // so if the year is in the future, bump it back one century.
    if (dayjsDate.year() > dayjs().year()) {
      dayjsDate.year(dayjsDate.year() - 100);
    } else if (dayjsDate.year() < dayjs().year() - 100) {
      return false;
    }
    let formattedDate;
    try {
      formattedDate = dayjsDate.format('YYYY-MM-DD');
    } catch (err) {
      return false;
    }
    // dayjs does not necessarily throw an error when it fails to parse a
    // date. instead it returns 'Invalid Date'
    if (formattedDate === 'Invalid date') {
      return false;
    } else {
      return formattedDate;
    }
  }

  // data is any object containing Client and/or Referral fields. Any fields that we
  // have validation checks for will be validated. those that also have crosswalks
  // will be crosswalked if 'crosswalk' is true. this validation is *not* a requirements
  // check. it only does validation on validatable fields, which may or may not be required,
  // and not all required fields are validated.
  validateClientReferralData(
    data: StudentReferralUploadObject,
    crosswalk: boolean,
    format: StudentReferralFormat,
  ) {
    const invalidFields: string[] = [];
    const dateFields = [
      'birthday',
      'assessmentPlanSignature',
      'meetingDate',
      'assessmentDueDate',
    ];

    for (const field of Object.keys(this.validateValues)) {
      const valResult = this.validateField(field, data[field], crosswalk);
      if (valResult === false) {
        invalidFields.push(field);
      } else {
        data[field] = valResult;
      }
    }

    // Duration must be a positive whole number
    if (data.duration) {
      const pattern = /^\d+$/;
      const valResult = pattern.test(data.duration as string);

      if (!valResult) {
        invalidFields.push('duration');
      }
    }

    dateFields.forEach(field => {
      if (data[field]) {
        const valResult = this.validateDate(data[field], format[field]);
        if (valResult === false) {
          invalidFields.push(field);
        } else {
          data[field] = valResult;
        }
      }
    });

    if (data.frequency) {
      const pattern = /^\d+$/;
      const valResult = pattern.test(data.frequency);
      if (!valResult) {
        invalidFields.push('frequency');
      }
    }

    const MAX_NOTES_LENGTH = 2000;

    if (data.notes) {
      data.notes = data.notes.slice(0, MAX_NOTES_LENGTH);
    }

    return { data, invalidFields };
  }

  /**
   * Method that helps for consistent usage across the app regarding the Provider Types.
   * A transformations is performed on 'em for using 'em as data validation.
   * The transformations is assigned to two objects that help with validation.
   */
  private setProviderTypeCodesForDataValidation(): void {
    this.plProviderTypesSvc.formProviderTypeOptions();
    this.plProviderTypesSvc.providerTypesSubject
      .pipe(
        filter((options: any) => options.length),
        first(),
        map((providerTypeOptions: Option[]) => {
          const providerTypes = {};
          providerTypeOptions.forEach(
            (option: Option) =>
              (providerTypes[option.label.toLowerCase()] = option.value),
          );
          return providerTypes;
        }),
      )
      .subscribe((providerTypes: any) => {
        this.validateValues.providerTypeCode = Object.values(providerTypes);
        this.crosswalkTables.providerTypeCode = providerTypes;
      }, noop);
  }
}

export function createStudentReferralUploadObject(): StudentReferralUploadObject {
  return {
    rowIndex: 0,
    lastName: '',
    firstName: '',
    externalId: '',
    birthday: '',
    grade: '',
    providerTypeCode: '',
    productTypeCode: '',
    duration: '',
    frequency: '',
    interval: '',
    grouping: '',
    primaryLanguageCode: '',
    shortTermLeave: '',
    esy: '',
    isFte: '',
    dedicated: '',
    trackingType: '',
    AAC: '',
    ASL: '',
    DHH: '',
    visuallyImpaired: '',
    notes: '',
    original: {
      rowIndex: 0,
      lastName: '',
      firstName: '',
      externalId: '',
      birthday: '',
      grade: '',
      providerTypeCode: '',
      productTypeCode: '',
      duration: '',
      frequency: '',
      interval: '',
      grouping: '',
      primaryLanguageCode: '',
      shortTermLeave: '',
      esy: '',
      isFte: '',
      dedicated: '',
      trackingType: '',
      AAC: '',
      ASL: '',
      DHH: '',
      visuallyImpaired: '',
      notes: '',
    },
    missingFields: [],
    errorReason: '',
  };
}

export function createStudentReferralFormat(): StudentReferralFormat {
  return {
    lastName: '',
    firstName: '',
    externalId: '',
    birthday: '',
    grade: '',
    providerTypeCode: '',
    productTypeCode: '',
    duration: '',
    frequency: '',
    interval: '',
    grouping: '',
    primaryLanguageCode: '',
    shortTermLeave: '',
    esy: '',
    isFte: '',
    dedicated: '',
    trackingType: '',
    AAC: '',
    ASL: '',
    DHH: '',
    visuallyImpaired: '',
    notes: '',
  };
}
