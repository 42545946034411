<div class="pl-clinical-contacts-container">
  <div class="pl-clinical-contacts-title">
    <span class="title-text">Have a clinical question?</span>
  </div>
  <div class="pl-clinical-contacts-description">
    <span
      >Your clinical team is on hand to provide guidance and answer any
      questions you may have</span
    >
  </div>
  <div class="manager-info-align-container">
    <ng-container *ngIf="showClinicalSuccessManagersList; else shortList">
      <div *ngFor="let clinicalSuccessManager of clinicalSuccessManagersList">
        <div class="manager-content">
          <div class="initials">{{ clinicalSuccessManager.initials }}</div>
          <div class="manager-info-align">
            <span
              class="text name"
              [matTooltip]="clinicalSuccessManager.organizationName"
              matTooltipClass="tooltip"
              matTooltipPosition="below"
              >{{ clinicalSuccessManager.name }}</span
            >
            <span
              class="text"
              [matTooltip]="clinicalSuccessManager.organizationName"
              matTooltipClass="tooltip"
              matTooltipPosition="below"
              >Clinical Success Manager</span
            >
            <span class="manager-email">
              <a [href]="'mailto:' + clinicalSuccessManager.email">{{
                clinicalSuccessManager.email
              }}</a></span
            >
          </div>
        </div>
      </div>
    </ng-container>
    <div class="manager-content">
      <div class="initials">{{ empowermentManager.initials }}</div>
      <div class="manager-info-align">
        <span class="text name">{{ empowermentManager.name }}</span>
        <span class="text">Empowerment Manager</span>
        <span class="manager-email">
          <a [href]="'mailto:clinicalsupport@presence.com'">{{
            'clinicalsupport@presence.com'
          }}</a></span
        >
      </div>
    </div>
  </div>
  <ng-template #shortList>
    <div
      *ngIf="this.clinicalSuccessManagersList.length > 3"
      class="manager-content"
    >
      <div class="initials">
        <mat-icon>account_circle</mat-icon>
      </div>
      <div class="manager-info-align">
        <span class="text name">Clinical Success Managers</span>
        <span
          >View all Clinical Success Managers on your
          <a routerLink="/assignments">assignments page</a></span
        >
      </div>
    </div>
  </ng-template>
</div>
