import { Component, Input } from '@angular/core';

@Component({
  selector: 'pl-location-banner',
  template: `<div class="location-banner">
    <pl-icon [svg]="'location'" [scale]="2.0"></pl-icon>
    <span>{{ locationName }}</span>
  </div>`,
  styleUrls: ['./pl-location-banner.component.less'],
})
export class PLLocationBannerComponent {
  @Input() locationName = '';
}
