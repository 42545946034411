import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { filter as loFilter, isEmpty } from 'lodash';
import { noop } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { AppStore } from '@app/appstore.model';
import { PLEditableTableInputConfig } from '@common/components/pl-editable-table/pl-editable-table.component';
import { PLClinicalProductCode, PLClinicalProductName } from '@common/enums';
import { Option } from '@common/interfaces';
import { PLProviderTypesService } from '@common/services/pl-provider-types.service';
import { PLAddReferralsLocationYearService } from '@modules/add-referrals/pl-add-referrals-location-year.service';
import { User } from '@modules/user/user.model';
import {
  FeatureFlagName,
  FeatureFlagsService,
} from '@root/src/app/common/feature-flags';
import { REFERRAL_INPUTS_CONFIG } from './constants/referral-inputs-config';
import {
  createStudentReferralFormat,
  createStudentReferralUploadObject,
  PLClientReferralDataModelService,
  StudentReferralFormat,
  StudentReferralUploadObject,
} from './pl-client-referral-data-model.service';
import { ImportedSheet } from './pl-upload-referrals/pl-file-import.service';

import { PLAssignmentManagerService } from '../assignment-manager/pl-assignment-manager.service';

const specialties = ['AAC', 'ASL', 'DHH', 'visuallyImpaired'];

export interface Field {
  value: string;
  label: string;
  // Any symbols to prefix the label to indicate footnotes
  labelPrefixSymbol: string;
  preserveWhitespace: boolean;
  required: boolean;
  dupecheck: boolean;
  inputConfig?: PLEditableTableInputConfig;
}

const FIELD_DEFAULTS = {
  dupecheck: false,
  preserveWhitespace: false,
  required: false,
  labelPrefixSymbol: '',
};

export enum FTE_IMPORT_VALUE {
  YES = 'Yes',
  NO = 'No',
  EMPTY = '',
}

@Injectable()
export class PLAddReferralsDataTableService {
  constructor(
    private store: Store<AppStore>,
    private plProviderTypesSvc: PLProviderTypesService,
    private dataModelService: PLClientReferralDataModelService,
    private locationService: PLAddReferralsLocationYearService,
    private plAssignmentManagerService: PLAssignmentManagerService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    store.select('currentUser').subscribe((user: any) => {
      this.currentUser = user;
      this.formProviderMatchingChoices();
    });
    this.setProviderTypeOptionsForInputConfigs();

    this.featureFlagsService
      .isFeatureEnabled(FeatureFlagName.newUploadReferral)
      .subscribe(enabled => {
        this.newReferralUpload = enabled;
      });
  }

  newReferralUpload: boolean = false;

  currentUser: User;

  importedFile: any = null;
  importedData: string[][] = [];
  importedDataFormats: any[] = [];
  finalImportedData: any[] = [];
  importedMutatedData: StudentReferralUploadObject[] = [];
  mappedData: StudentReferralUploadObject[] = [];
  locationsMapping: any = {};

  multiSheet = false;
  private sheets: ImportedSheet[] = [];
  private sheetIndex: any = {};
  sheetChoices: any[] = [];
  currentSheetName = '';

  duplicateRows: any[];
  incompleteRows: any[];
  invalidRows: any[] = [];
  templateErrorRows: any[];

  readonly DUPLICATE_REASON: string = 'Duplicate within spreadsheet';
  readonly EXISTING_REASON: string = 'Duplicate with existing referral';
  readonly MISSING_REQUIRED_REASON: string = 'Missing required fields: ';
  readonly INVALID_REASON_STUB: string = 'Invalid data in fields: ';
  readonly MISMATCH_PROVIDER_REFERRAL: string =
    'Mismatch between provider type and referral';

  get targetFields(): Field[] {
    const fields = [
      {
        ...FIELD_DEFAULTS,
        value: 'lastName',
        label: 'Last Name',
        required: true,
        inputConfig: REFERRAL_INPUTS_CONFIG['lastName'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'firstName',
        label: 'First Name',
        required: true,
        inputConfig: REFERRAL_INPUTS_CONFIG['firstName'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'externalId',
        label: 'Student ID',
        required: true,
        dupecheck: true,
        inputConfig: REFERRAL_INPUTS_CONFIG['externalId'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'grade',
        label: 'Grade',
        inputConfig: REFERRAL_INPUTS_CONFIG['grade'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'birthday',
        label: 'Birth Date',
        required: true,
        inputConfig: REFERRAL_INPUTS_CONFIG['birthday'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'providerTypeCode',
        label: 'Provider Type',
        required: true,
        dupecheck: true,
        inputConfig: REFERRAL_INPUTS_CONFIG.providerTypeCode, // REFERRAL_INPUTS_CONFIG.providerTypeCode Filled in setProviderTypeOptionsForInputConfigs()
      },
      {
        ...FIELD_DEFAULTS,
        value: 'productTypeCode',
        label: 'Referral Type',
        required: true,
        dupecheck: true,
        inputConfig: REFERRAL_INPUTS_CONFIG['productTypeCode'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'duration',
        label: 'Duration',
        dupecheck: true,
        inputConfig: REFERRAL_INPUTS_CONFIG['duration'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'frequency',
        label: 'Frequency',
        dupecheck: true,
        inputConfig: REFERRAL_INPUTS_CONFIG['frequency'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'interval',
        label: 'Interval',
        dupecheck: true,
        inputConfig: REFERRAL_INPUTS_CONFIG['interval'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'grouping',
        label: 'Individual/Group',
        dupecheck: true,
        inputConfig: REFERRAL_INPUTS_CONFIG['grouping'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'primaryLanguageCode',
        label: 'Service Language',
        inputConfig: REFERRAL_INPUTS_CONFIG['primaryLanguageCode'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'shortTermLeave',
        label: 'Short Term Coverage',
        inputConfig: REFERRAL_INPUTS_CONFIG['shortTermLeave'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'esy',
        label: 'ESY',
        inputConfig: REFERRAL_INPUTS_CONFIG['esy'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'isFte',
        label: 'FTE',
        inputConfig: REFERRAL_INPUTS_CONFIG.isFte,
      },
      {
        ...FIELD_DEFAULTS,
        value: 'dedicated',
        label: 'Dedicated',
        required: true,
        inputConfig: REFERRAL_INPUTS_CONFIG.dedicated,
      },
      {
        ...FIELD_DEFAULTS,
        value: 'trackingType',
        label: 'Tracking Type',
        inputConfig: REFERRAL_INPUTS_CONFIG.trackingType,
      },
      {
        ...FIELD_DEFAULTS,
        value: 'assessmentPlanSignature',
        label: 'Assessment Plan Signature Date',
        inputConfig: REFERRAL_INPUTS_CONFIG['assessmentPlanSignature'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'assessmentDueDate',
        label: 'Evaluation Due Date',
        inputConfig: REFERRAL_INPUTS_CONFIG['assessmentDueDate'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'meetingDate',
        label: 'Meeting Date',
        inputConfig: REFERRAL_INPUTS_CONFIG['meetingDate'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'AAC',
        label: 'AAC',
        inputConfig: REFERRAL_INPUTS_CONFIG['AAC'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'ASL',
        label: 'ASL',
        inputConfig: REFERRAL_INPUTS_CONFIG['ASL'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'DHH',
        label: 'DHH',
        inputConfig: REFERRAL_INPUTS_CONFIG['DHH'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'visuallyImpaired',
        label: 'Visually Impaired',
        inputConfig: REFERRAL_INPUTS_CONFIG['visuallyImpaired'],
      },
      {
        ...FIELD_DEFAULTS,
        value: 'notes',
        label: 'Notes',
        preserveWhitespace: true,
        labelPrefixSymbol: '†',
        inputConfig: REFERRAL_INPUTS_CONFIG['notes'],
      },
    ];
    return loFilter(
      this.newReferralUpload
        ? [
            {
              ...FIELD_DEFAULTS,
              value: 'location',
              label: 'Location',
              required: true,
              inputConfig: REFERRAL_INPUTS_CONFIG['location'],
            },
            ...fields,
          ]
        : fields,
      o => !isEmpty(o),
    );
  }

  evaluationDupeFields: any = [
    'firstName',
    'lastName',
    'externalId',
    'birthday',
    'providerTypeCode',
    'productTypeCode',
  ];

  templateInstructionRows = [
    'DIRECTIONS:',
    'Dark blue fields are required. Light Blue fields are strongly encouraged to be filled out in order to match providers and students more expeditiously.',
    `Each school in the same district will need it's own separate Excel file to create student referrals.   Please label the file with the school and district names.`,
  ];

  mappings: string[] = [];

  // -1 means no header
  headerRowIndex = -1;
  endRow = 0;
  endCol = 0;

  providerMatching: any = {
    selection: 'do_not_use_previous',
    choices: [],
  };

  fteCalculatedValue: FTE_IMPORT_VALUE = FTE_IMPORT_VALUE.NO;

  formProviderMatchingChoices() {
    this.providerMatching.choices = [
      {
        value: 'do_not_use_previous',
        label: 'Send to Presence for Matching.',
      },
    ];
  }

  setSheets(sheets: ImportedSheet[]) {
    this.sheets = sheets;
    if (sheets.length > 1) {
      this.handleMultiSheetBook(sheets);
    }
  }

  selectSheet(name: string) {
    return this.sheetIndex[name];
  }

  handleMultiSheetBook(sheets: ImportedSheet[]) {
    this.multiSheet = true;
    this.sheets = sheets;
    this.sheetIndex = {};
    this.sheetChoices = [];
    for (const sheet of sheets) {
      this.sheetIndex[sheet.name] = sheet;
      const nextChoice = {
        value: sheet.name,
        label: sheet.name,
      };
      this.sheetChoices.push(nextChoice);
    }
    this.currentSheetName = this.sheets[0].name;
  }

  reset() {
    this.importedFile = null;
    this.importedData = [];
    this.finalImportedData = [];
    this.headerRowIndex = -1;
    this.multiSheet = false;
    this.sheets = [];
    this.sheetIndex = {};
    this.sheetChoices = [];
    this.mappings = [];
    this.duplicateRows = [];
    this.incompleteRows = [];
    this.invalidRows = [];
    this.templateErrorRows = [];
  }

  getFieldLabelForKey(key: string) {
    for (const field of this.targetFields) {
      if (field.value === key) {
        return field.label;
      }
    }
    return key;
  }

  getHeaderRow() {
    return this.importedData[this.headerRowIndex];
  }

  getImportedFileName = () => {
    const name = this.importedFile ? this.importedFile.name : '';
    return name;
  };

  // the number of rows in the imported spreadsheet that we will attempt to import
  getReferralAttemptCount = () => {
    let count = 0;
    const firstRow = this.headerRowIndex + 1;
    for (let i = firstRow; i < this.importedData.length; i++) {
      const row = this.importedData[i];
      if (!this.isEmptyRow(row)) {
        count++;
      }
    }
    return count;
  };

  isEmptyRow(row: string[]) {
    for (let i = 0; i < row.length; i++) {
      const cell = row[i];
      if (cell.trim().length) {
        return false;
      }
    }
    return true;
  }

  setData(data: string[][]) {
    this.mappings = data[0].map(_ => null);
    this.endRow = this.findEndRow(data);
    this.endCol = this.findEndCol(data);
    this.importedData = data;
    const autoHeader = this.findIndexOfHeaderInRows();
    if (autoHeader >= 0) {
      this.headerRowIndex = autoHeader;
      this.autoMap();
    }
  }

  setDataFormats(formats: any[][]) {
    this.importedDataFormats = formats;
  }

  // TODO - move to plLodashService
  intersection(a: any[], b: any[]) {
    const aSet: any = new Set(a);
    const bSet: any = new Set(b);
    const intersection: any = new Set([...aSet].filter(x => bSet.has(x)));
    return [...intersection];
  }

  findIndexOfHeaderInRows(): number {
    const requiredLabels = this.targetFields
      .filter(({ required }) => required)
      .map(({ label }) => label);
    return this.importedData
      .slice(0, 10)
      .findIndex(row =>
        row.some(cell => requiredLabels.some(label => cell === label)),
      );
  }

  findEndRow(data: string[][]) {
    let endRow = 0;
    for (let i = 0; i < data.length; i++) {
      const dataRow = data[i].join('').trim();
      if (dataRow !== '') {
        endRow = i;
      }
    }
    return endRow;
  }

  findEndCol(data: string[][]) {
    let endCol = 0;
    for (let i = 0; i < this.endRow + 1; i++) {
      const row = data[i];
      for (let j = 0; j < row.length; j++) {
        if (row[j] !== '' && endCol < j) {
          endCol = j;
        }
      }
    }
    return endCol;
  }

  autoMap() {
    const headerRowData = this.getHeaderRow();
    if (!headerRowData) {
      return;
    }
    const newMappings = new Array(this.mappings.length);
    for (let i = 0; i < headerRowData.length; i++) {
      const header: string = headerRowData[i];
      const targetField = this.targetFields.find(field => {
        return field.label.toLowerCase() === header.toLowerCase();
      });
      newMappings[i] = targetField ? targetField.value : null;
    }
    this.mappings = newMappings;
  }

  // create list of imported columns
  // which were not mapped via autoMap -
  // - and therefore were not expected (are invalid)
  // for each target field
  // - ONLY if required
  findUnmappedFields() {
    const unmappedFields = [];
    for (let i = 0; i < this.targetFields.length; i++) {
      const nextField = this.targetFields[i];
      if (nextField.required) {
        if (this.mappings.indexOf(nextField.value) === -1) {
          unmappedFields.push(nextField.label);
        }
      }
    }
    return unmappedFields;
  }

  // create list of columns which have 1+ empty cells
  findUnmappedColumn() {
    const unmappedCols: any[] = [];
    this.importedData[0].forEach((_, index: number) => {
      if (this.columnHasData(index) && !this.mappings[index]) {
        unmappedCols.push(index);
      }
    });
    return unmappedCols;
  }

  columnHasData(col: number) {
    const data = this.importedData;
    const colData = data.map((row: any) => row[col]);
    return colData.some(d => d !== '');
  }

  isBlankRow(row: string[]) {
    for (let i = 0; i < row.length; i++) {
      if (row[i].trim().length > 0) {
        return false;
      }
    }
    return true;
  }

  getFields() {
    return this.targetFields.map(field => field);
  }

  getColumnDataByLabel(
    columnLabel: string,
    ignoreInvalidRows: boolean = false,
    ignoreEmptyRows: boolean = false,
  ) {
    const headerRow = this.getHeaderRow();

    if (!headerRow) return [];

    const columnIndex = headerRow.indexOf(columnLabel);

    if (columnIndex === -1) {
      return [];
    }

    let dataRows = [];

    this.importedData.forEach((row, index) => {
      // Skip the first rows if they are not data rows
      if (index <= 2) return;
      if (ignoreInvalidRows) {
        // Check if the current index is in this.invalidRows
        const isInvalidRow = this.invalidRows.some(
          invalidRow => invalidRow.rowIndex === index,
        );
        if (isInvalidRow) return;
      }
      // Skip empty rows
      if (ignoreEmptyRows && row[columnIndex].trim() === '') return;

      dataRows.push({ uploadedValue: row[columnIndex].trim(), index: index });
    });

    return dataRows;
  }

  getRequiredFieldKeys() {
    return this.getFields()
      .filter(field => field.required)
      .map(field => field.value);
  }

  getRequiredFieldLabels() {
    return this.getFields()
      .filter(field => field.required)
      .map(field => field.label);
  }

  // ensure that all required fields are non-empty strings
  // TODO - validate validatable fields
  // Populates a list of incomplete rows and and error rows
  // and returns all data

  // Ensure no empty required fields
  // Attach errorReason list to each row object
  // sets this.incompleteRows
  validateRequiredFields(data: StudentReferralUploadObject[]) {
    const tested: StudentReferralUploadObject[] = [];
    const incompletes: StudentReferralUploadObject[] = [];
    const requiredFields = this.getRequiredFieldKeys();

    for (const row of data) {
      // 1. get missing fields
      const missingFields = requiredFields
        .filter((field: string) => {
          return !row[field] || row[field].trim().length === 0;
        })
        .map((field: string) => this.getFieldLabelForKey(field));
      // 2. attach missing error messages to rows
      if (missingFields.length > 0) {
        row.missingFields = [...missingFields];
        const errorMessage =
          this.MISSING_REQUIRED_REASON + row.missingFields.join(', ');
        row.errorReason = row.errorReason
          ? `${row.errorReason} - ${errorMessage}`
          : errorMessage;
        incompletes.push(row);
      }
      tested.push(row);
    }
    this.incompleteRows = incompletes;
    return tested;
  }

  getDupeCheckFieldKeys(productType?: string) {
    if (productType === PLClinicalProductName.EVAL) {
      return this.evaluationDupeFields;
    }
    return this.targetFields
      .filter(field => {
        return field.dupecheck;
      })
      .map(field => {
        return field.value;
      });
  }

  private getPreserveWhitespaceFieldKeys(): string[] {
    return this.targetFields
      .filter(f => f.preserveWhitespace)
      .map(f => f.value);
  }

  /**
   * If dupes are found, use the first, and move the subsequent ones to the errors array
   *   Before (DEV-1373) "Within a sheet upload, a referral is considered unique when Student ID, Provider Type, and
   *   Referral Type are different
   * Now according to PL-2323 we can have more than one referral related with the same provider
   * This function works along the dupecheck prop of the targetFields array from getDupeCheckFieldKeys
   */
  deDupeData(data: StudentReferralUploadObject[]) {
    const tested: StudentReferralUploadObject[] = [];
    const dupes: StudentReferralUploadObject[] = [];
    const dupeDictionary: any = {};

    for (const row of data) {
      const dupeFields = this.getDupeCheckFieldKeys(row['productTypeCode']);
      // generate a rowKey by taking the row value for each dupe check field, trimming whitespace, and joining
      // e.g. {id: 1234, first: john, last: brecht} becomes key: '1234-john-brecht'
      // Evaluation referrals get its key generated shorter, since eval doesn't accept interval timing differences.
      const rowKey = dupeFields
        .map((field: string) => (row[field] ? row[field].trim() : ''))
        .join('-');

      // test for uniqueness using a Dictionary. If the key is already in the Dictionary, the row is a dupe
      if (dupeDictionary[rowKey]) {
        const errorMessage = this.DUPLICATE_REASON;
        row.errorReason = row.errorReason
          ? `
                    ${row.errorReason} -
                    ${errorMessage}
                `
          : errorMessage;
        dupes.push(row);
      } else {
        dupeDictionary[rowKey] = true;
      }
      tested.push(row);
    }

    this.duplicateRows = dupes;

    return tested;
  }

  // validates referral data for
  // combination of productTypeCode and providerTypeCode
  // sets an invaidRows array on each row object
  validateData(
    data: StudentReferralUploadObject[],
    formats: StudentReferralFormat[],
  ) {
    const tested: StudentReferralUploadObject[] = [];
    const invalidData: StudentReferralUploadObject[] = [];

    for (let i = 0; i < data.length; i++) {
      let isBmhProduct = false;
      let isProductReferralMismatch = false;

      const rowData = data[i];
      const rowFormat = formats[i];
      const valResult = this.dataModelService.validateClientReferralData(
        rowData,
        true,
        rowFormat,
      );
      const validatedRow = Object.assign({}, rowData, valResult.data);
      const isSupervisionProduct =
        valResult.data.productTypeCode === PLClinicalProductName.SV;

      if (valResult.data.productTypeCode) {
        isBmhProduct =
          valResult.data.productTypeCode.toUpperCase() ===
            PLClinicalProductName.BIG_UPPER_CASE ||
          valResult.data.productTypeCode.toUpperCase() ===
            PLClinicalProductName.TG_UPPER_CASE;
      }

      validatedRow.invalidFields = validatedRow.invalidFields || [];
      for (const field of valResult.invalidFields) {
        validatedRow.invalidFields.push(this.getFieldLabelForKey(field));
      }

      if (isSupervisionProduct || isBmhProduct) {
        // Supervision product can only be bound to SL or OT provider
        // BMH product can only be bound to PA or MHP
        const providerTypeCode = valResult.data.providerTypeCode;
        const isSupervisionProviderType =
          providerTypeCode === 'slp' || providerTypeCode === 'ot';
        const isBmhProviderType =
          providerTypeCode === 'pa' || providerTypeCode === 'mhp';

        const validCombination =
          (isSupervisionProduct && isSupervisionProviderType) ||
          (isBmhProduct && isBmhProviderType);

        if (!validCombination) {
          isProductReferralMismatch = true;
          validatedRow.invalidFields.push(
            this.getFieldLabelForKey('providerTypeCode'),
          );
        }
      }

      validatedRow.isValid =
        valResult.invalidFields.length === 0 && !isProductReferralMismatch;

      if (!validatedRow.isValid) {
        let errorMessage;
        if (isProductReferralMismatch) {
          errorMessage = this.MISMATCH_PROVIDER_REFERRAL;
        } else {
          errorMessage =
            this.INVALID_REASON_STUB + validatedRow.invalidFields.join(', ');
        }
        validatedRow.errorReason = validatedRow.errorReason
          ? `${validatedRow.errorReason} - ${errorMessage}`
          : errorMessage;

        invalidData.push(validatedRow);
      }
      tested.push(validatedRow);
    }
    this.invalidRows = [...this.invalidRows, ...invalidData];
    return tested;
  }

  /**
   * BMH product only accepts two combinations for timing of a referral:
   *   30 minutes twice a week or 60 minutes once a week
   *   Both of the above only in group mode
   * If the timing is not as the above; leave those 4 fields empty
   *
   * @param data An array with the bulk of products the user wants to load
   */
  updateReferralTimingForBmhProduct(data: any[]): any[] {
    for (let i = 0; i < data.length; i++) {
      let isBmhProduct = false;
      if (data[i].productTypeCode) {
        isBmhProduct =
          data[i].productTypeCode.toUpperCase() ===
            PLClinicalProductName.BIG_UPPER_CASE ||
          data[i].productTypeCode.toUpperCase() ===
            PLClinicalProductName.TG_UPPER_CASE;
      }

      if (isBmhProduct) {
        const rawData = data[i];

        if (rawData.grouping.toLowerCase() === 'either') {
          // Either grouping must be Group grouping type
          rawData.grouping = 'Group';
        }

        const validTiming30 =
          rawData.duration === 30 && rawData.frequency === '2';
        const validTiming60 =
          rawData.duration === 60 && rawData.frequency === '1';
        const validInterval = rawData.interval.toLowerCase() === 'weekly';
        const validGroup = rawData.grouping.toLowerCase() === 'group';
        const validTiming =
          (validTiming30 || validTiming60) && validInterval && validGroup;

        if (!validTiming) {
          rawData.duration = '';
          rawData.frequency = '';
          rawData.interval = '';
          rawData.grouping = '';
        }
      }
    }

    return data;
  }

  /**
   * The BMH products have a specific code in the spread sheet for bulkupload.
   * Unfortunately the product code that the BE accepts for BMH products is a different one.
   * Therefore in this function; that code is being overriden, only for BMH products.
   *
   * @param data An array with the bulk of products the user wants to load
   */
  updateReferralTypeCodeForBmhProduct(data: any[]): any[] {
    for (let i = 0; i < data.length; i++) {
      let isBmhProduct = false;
      if (data[i].productTypeCode) {
        isBmhProduct =
          data[i].productTypeCode.toUpperCase() ===
            PLClinicalProductName.BIG_UPPER_CASE ||
          data[i].productTypeCode.toUpperCase() ===
            PLClinicalProductName.TG_UPPER_CASE;
      }

      if (isBmhProduct) {
        data[i].productTypeCode =
          data[i].productTypeCode.toUpperCase() ===
          PLClinicalProductName.BIG_UPPER_CASE
            ? PLClinicalProductCode.BIG
            : PLClinicalProductCode.TG;
      }
    }

    return data;
  }

  // trim leading and trailing spaces from all string fields;
  // for internal spaces, ensure only one space separates tokens for fields
  // with preserveWhitespace = false;
  private trimData(data: any[], preserveWhitespaceFields: string[] = []) {
    const trimmed: any[] = [];
    const reg = /\S+/g; // match some number of non-whitespace characters
    for (let i = 0; i < data.length; i++) {
      const rowData = data[i];
      Object.keys(rowData).forEach(val => {
        if (typeof rowData[val] === 'string') {
          const tokens = rowData[val].match(reg);
          if (
            tokens &&
            tokens.length &&
            !preserveWhitespaceFields.includes(val)
          ) {
            rowData[val] = tokens.join(' ');
          } else {
            rowData[val] = rowData[val].trim();
          }
        }
      });
      trimmed.push(rowData);
    }
    return trimmed;
  }

  /**
   * For the moment, our database only supports the Latin1 character set. This
   * strips out all characters not in that set.
   */
  stripUnicode(
    data: StudentReferralUploadObject[],
  ): StudentReferralUploadObject[] {
    // Remove characters outside Latin1 range, 0-255.
    // Convert to normalized form decomposed, meaning compound characters
    // are decomposed into any more basic forms + modifier characters
    // (ñ -> lowercase n + tilde).
    // See https://en.wikipedia.org/wiki/Unicode_equivalence
    const strip = (s: string) =>
      s.normalize('NFD').replace(/[^\x00-\xFF]/g, ''); // eslint-disable-line no-control-regex

    return data.map((row: StudentReferralUploadObject) => {
      const fields = Object.keys(row);

      return fields.reduce((strippedRow: any, field: any) => {
        const value = row[field];
        const strippedValue = typeof value === 'string' ? strip(value) : value;

        return { ...strippedRow, [field]: strippedValue };
      }, {});
    });
  }

  // NOTE: `stashOriginalEntries` creates new prop `original` which
  // is later used in `pl-spreadsheet.service.ts` within `generateErrorSummary`
  // we only want to stash it once, not every iteration, so we check if
  // original is already on the row object.
  stashOriginalEntries(rows: StudentReferralUploadObject[]) {
    return rows.map((row: StudentReferralUploadObject) => ({
      ...row,
      original: row.original ? row.original : { ...row },
    }));
  }

  parseDataAndFormatsFromInput(input: string[][]): {
    data: StudentReferralUploadObject[];
    formats: StudentReferralFormat[];
  } {
    let data: StudentReferralUploadObject[] = [];
    const formats: StudentReferralFormat[] = [];

    const firstRow = this.headerRowIndex + 1;

    for (let i = firstRow; i < input.length; i++) {
      const nextRow = input[i];
      const nextFormatRow =
        this.importedDataFormats && this.importedDataFormats[i]
          ? this.importedDataFormats[i]
          : '';
      if (!this.isBlankRow(nextRow)) {
        const nextRowFinal: StudentReferralUploadObject =
          createStudentReferralUploadObject();
        const nextFormatRowFinal: StudentReferralFormat =
          createStudentReferralFormat();
        for (let j = 0; j < this.mappings.length; j++) {
          if (this.mappings[j] && this.mappings[j] !== 'unused') {
            nextRowFinal['rowIndex'] = i;
            nextRowFinal[this.mappings[j]] = nextRow[j];
            nextFormatRowFinal[this.mappings[j]] = nextFormatRow[j];
          }
        }
        data.push(nextRowFinal);
        formats.push(nextFormatRowFinal);
      }
    }
    return { data, formats };
  }

  importedDataHasAllRequiredHeaders(): boolean {
    const headers = this.getHeaderRow();
    const requiredHeaders = this.getRequiredFieldLabels();
    const filteredHeaders = headers.filter(header => !!header);
    return requiredHeaders.every(requiredHeader =>
      filteredHeaders.includes(requiredHeader),
    );
  }

  validateSpecialties(
    rows: StudentReferralUploadObject[],
  ): StudentReferralUploadObject[] {
    return rows.reduce(
      (
        acc: StudentReferralUploadObject[],
        row: StudentReferralUploadObject,
      ) => {
        const specialtyPropertiesOfRow = Object.entries(row)
          .filter(([key]) => specialties.includes(key))
          .filter(([_, value]) => value === 'Yes');
        return [
          ...acc,
          specialtyPropertiesOfRow.length > 1
            ? {
                ...row,
                invalidFields: [
                  ...(row.invalidFields || []),
                  ...specialtyPropertiesOfRow.map(([key]) => key),
                ],
                errorReason: `Row ${
                  row.rowIndex + 1
                } has more than one specialty selected. Please select only one specialty per row.`,
              }
            : row,
        ];
      },
      [],
    );
  }

  async validateServiceModelForDemand(
    rows: StudentReferralUploadObject[],
  ): Promise<StudentReferralUploadObject[]> {
    const { id: schoolYearId } = this.locationService.getYearForCode(
      this.locationService.selectedSchoolYearCode,
    );
    const organizationId = this.newReferralUpload
      ? this.locationService.selectedOrganizationID
      : this.locationService.selectedRateHolder.id;

    const {
      acceptable_referral_values: { dedicated, fte },
    } = await this.plAssignmentManagerService
      .getOrganizationServiceModel(schoolYearId, organizationId)
      .toPromise();

    return rows.reduce(
      (
        acc: StudentReferralUploadObject[],
        row: StudentReferralUploadObject,
      ) => {
        const invalidFields = [];

        const hasInvalidDedicatedServiceValue =
          (row.dedicated === 'No' && !dedicated.includes(false)) ||
          (row.dedicated === 'Yes' && !dedicated.includes(true));
        const hasInvalidHourlyServiceValue =
          (row.isFte === 'Yes' && !fte.includes(true)) ||
          (row.isFte === 'No' && !fte.includes(false));

        if (hasInvalidDedicatedServiceValue)
          invalidFields.push(this.getFieldLabelForKey('dedicated'));
        if (hasInvalidHourlyServiceValue)
          invalidFields.push(this.getFieldLabelForKey('isFTE'));

        return [
          ...acc,
          hasInvalidDedicatedServiceValue || hasInvalidHourlyServiceValue
            ? {
                ...row,
                invalidFields,
                errorReason: `The ${
                  invalidFields.length > 1 ? 'selections' : 'selection'
                } for ${invalidFields
                  .map(fieldName => {
                    let _fieldName = fieldName;
                    if (fieldName === 'isFTE') {
                      _fieldName = 'FTE';
                    }
                    return `'${_fieldName}'`;
                  })
                  .join(', ')} in row ${row.rowIndex + 1} ${
                  invalidFields.length > 1 ? 'do' : 'does'
                } not match account's products.`,
              }
            : row,
        ];
      },
      [],
    );
  }

  async validateRecordServiceModelValues(
    rows: StudentReferralUploadObject[],
  ): Promise<StudentReferralUploadObject[]> {
    return rows.reduce(
      (
        acc: StudentReferralUploadObject[],
        row: StudentReferralUploadObject,
      ) => {
        const isConflicting = row.dedicated === 'Yes' && row.isFte === 'Yes';
        return [
          ...acc,
          isConflicting
            ? {
                ...row,
                invalidFields: [
                  ...(row.invalidFields || []),
                  ...[this.getFieldLabelForKey('dedicated')],
                ],
                errorReason: `The selection for 'Dedicated' in row ${
                  row.rowIndex + 1
                } conflicts with the selection for 'FTE'.`,
              }
            : row,
        ];
      },
      [],
    );
  }

  async importData() {
    this.invalidRows = [];
    const { data, formats } = this.parseDataAndFormatsFromInput(
      this.importedData,
    );

    this.mappedData = [...data];

    const stashed = this.stashOriginalEntries(data);
    const validatedRequiredFields = this.validateRequiredFields(stashed);
    const deduped = this.deDupeData(validatedRequiredFields);
    const validated = this.validateData(deduped, formats);
    const withInvalidSpecialties = this.validateSpecialties(validated);
    const withInvalidServiceModelsForDemand =
      await this.validateServiceModelForDemand(withInvalidSpecialties);

    const withInvalidServiceModelValuesForRecord =
      await this.validateRecordServiceModelValues(
        withInvalidServiceModelsForDemand,
      );

    this.invalidRows = [
      ...this.invalidRows,
      ...withInvalidServiceModelValuesForRecord,
    ].filter(row => !!row.errorReason);

    const withReferralTimingForBmh = this.updateReferralTimingForBmhProduct(
      withInvalidServiceModelValuesForRecord,
    );
    const withReferralTypeCodeForBmh = this.updateReferralTypeCodeForBmhProduct(
      withReferralTimingForBmh,
    );
    const trimmed = this.trimData(
      withReferralTypeCodeForBmh,
      this.getPreserveWhitespaceFieldKeys(),
    );
    const stripped = this.stripUnicode(trimmed);

    this.importedMutatedData = stripped;
    this.finalImportedData = stripped.filter(row => !row.errorReason);
    this.templateErrorRows = stripped.filter(row => row.errorReason);
  }

  setIsFteFieldProperties(): void {
    const isFteTargetColumnIndex = this.targetFields.findIndex(
      ({ value }) => value === 'isFte',
    );
    this.targetFields[isFteTargetColumnIndex].required = true;
    this.targetFields[isFteTargetColumnIndex].inputConfig.isEditable = false;
  }

  /**
   * Method that enforces consistent usage across the app regarding Provider Types.
   * A transformation is performed on them for using them as input configs.
   * The transformations are assigned so that those configs don't have to be hard-coded anymore.
   */
  private setProviderTypeOptionsForInputConfigs(): void {
    this.plProviderTypesSvc.formProviderTypeOptions();
    this.plProviderTypesSvc.providerTypesSubject
      .pipe(
        filter((options: any) => options.length),
        first(),
        map((providerTypeOptions: Option[]) => {
          let tipMessage = '\n';

          providerTypeOptions.forEach(providerType => {
            tipMessage += `${providerType.label}  \n`;
            providerType.value = providerType.label;
          });

          return {
            tipMessage,
            providerTypeOptions,
          };
        }),
      )
      .subscribe(({ tipMessage, providerTypeOptions }) => {
        REFERRAL_INPUTS_CONFIG.providerTypeCode.tipMessage = tipMessage;
        REFERRAL_INPUTS_CONFIG.providerTypeCode.options = providerTypeOptions;
      }, noop);
  }
}
