import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AsyncState } from '@root/src/types';
import { SchoolYearDataService } from './school-year-data.service';
import { SchoolYear, SchoolYearStartMonth } from './school-year.model';

export type SchoolYearsState = Record<
  SchoolYearStartMonth,
  AsyncState<{
    current: SchoolYear;
    next: SchoolYear;
    schoolYears: SchoolYear[];
  }>
>;

const schoolYearsInitialState: SchoolYearsState = {
  [SchoolYearStartMonth.july]: { status: 'idle' },
  [SchoolYearStartMonth.august]: { status: 'idle' },
};

@Injectable({ providedIn: 'root' })
export class SchoolYearsService {
  state$ = new BehaviorSubject(schoolYearsInitialState);
  constructor(private dataService: SchoolYearDataService) {
    this.load(SchoolYearStartMonth.july);
    this.load(SchoolYearStartMonth.august);
  }

  list(startMonth: SchoolYearStartMonth = SchoolYearStartMonth.august) {
    return this.state$
      .asObservable()
      .pipe(map(state => state[startMonth], distinctUntilChanged()));
  }

  current(startMonth?: SchoolYearStartMonth) {
    return this.list(startMonth).pipe(
      map(state => (state.status === 'success' ? state.data?.current : null)),
      distinctUntilChanged(),
    );
  }

  next(startMonth?: SchoolYearStartMonth) {
    return this.list(startMonth).pipe(
      map(state => (state.status === 'success' ? state.data?.next : null)),
      distinctUntilChanged(),
    );
  }

  getCurrentAndNext(startMonth?: SchoolYearStartMonth) {
    return this.list(startMonth).pipe(
      map(state =>
        state.status === 'success'
          ? ([state.data.current, state.data.next] as const)
          : null,
      ),
      distinctUntilChanged(),
    );
  }

  private load(startMonth: SchoolYearStartMonth): void {
    const currentState = this.state$.value;
    this.state$.next({
      ...currentState,
      [startMonth]: { status: 'loading' },
    });

    this.dataService.list(startMonth).subscribe(
      data => {
        const currentIndex = data.findIndex(s => s.is_current);
        const [current] = data.splice(currentIndex, 1);
        const nextIndex = data.findIndex(
          s => s.start_year === current.start_year + 1,
        );
        const [next] = data.splice(nextIndex, 1);
        data.sort((a, b) => a.start_year - b.start_year);

        const schoolYears = [next, current, ...data];

        this.state$.next({
          ...currentState,
          [startMonth]: {
            status: 'success',
            data: {
              current,
              next,
              schoolYears,
            },
          },
        });
      },
      error => {
        this.state$.next({
          ...currentState,
          [startMonth]: { status: 'error', error },
        });
      },
    );
  }
}
