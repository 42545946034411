import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { SPECIALTIES_LONG_NAME } from '@root/src/app/common/assigment-machine/specialties.constants';
import { PLClinicalServiceTypeLongName } from '@root/src/app/common/enums';
import { PLOpportunityLineItemProductCode } from '@root/src/app/common/enums/pl-opportunity-line-item-product-code.enum';
import { PLModalService } from '@root/src/lib-components';
import { PlAssignmentManagerDetailsModalComponent } from '../pl-assignment-manager-details-modal.component';
import {
  PLOpptyDemandItem,
  PLOrgDemandItem,
} from '../pl-assignment-manager.model';
import { PLDemandNotesComponent } from '../pl-demand-notes/pl-demand-notes.component';
import { PLDemandNote } from '../pl-demand-notes/pl-demand-notes.service';

@Component({
  selector: 'pl-demand-item-header',
  templateUrl: 'demand-item-header.component.html',
  styleUrls: ['./demand-item-header.component.less'],
})
export class DemandItemHeaderComponent implements OnChanges {
  @Input() public opptyDemandItem: PLOpptyDemandItem;
  @Input() public orgDemandItem: PLOrgDemandItem;
  @Input() public demandNotes: { [k: string]: PLDemandNote } = {};
  @Input() public schoolYear: string;
  @Output() public addEdit = new EventEmitter<void>();

  specialties = '';
  fulfillmentDetails = {
    fulfillmentPercent: 0,
    supply: 0,
    demand: 0,
  };
  salesforceUrl = '';
  hasNotes = false;
  fulfillmentTooltip = '';
  detailsTooltip = '';
  serviceTypeName = '';
  isClosedLost: boolean;
  overlayRef: OverlayRef;

  readonly CLOSED_LOST_MESSAGE = `This opportunity status has changed to Closed-Lost. Any providers assigned to this opportunity should be reassigned.`;

  constructor(
    private plModal: PLModalService,
    private cdr: ChangeDetectorRef,
    private overlay: Overlay,
  ) {}

  ngOnChanges() {
    if (this.opptyDemandItem && this.orgDemandItem) {
      this.specialties = this.getSpecialtiesFromDemand(this.opptyDemandItem);
      this.getFulfillmentDetails();
      this.salesforceUrl = `https://plearn.lightning.force.com/lightning/r/Opportunity/${this.opptyDemandItem.opportunitySalesforceId}/view`;
      this.hasNotes = Boolean(
        this.demandNotes?.[this.opptyDemandItem.uuid]?.notes,
      );

      let unassignedHours =
        this.fulfillmentDetails.demand - this.fulfillmentDetails.supply;
      if (unassignedHours < 0) {
        unassignedHours = 0;
      }
      const hasAssignments = this.opptyDemandItem.providerSupplyList.length;
      this.isClosedLost =
        hasAssignments &&
        this.opptyDemandItem.opportunityStage === 'Closed Lost';
      this.fulfillmentTooltip = this.isClosedLost
        ? this.CLOSED_LOST_MESSAGE
        : `
          ${this.fulfillmentDetails.fulfillmentPercent}% fulfilled
          ${this.fulfillmentDetails.demand} Required hours
          ${this.fulfillmentDetails.supply} Assigned hours
          ${unassignedHours} Unassigned hours
        `;

      this.detailsTooltip = [
        `Contract dates: ${this.opptyDemandItem.contractDates}`,
        `Therapy start date: ${this.opptyDemandItem.therapyStartDate}`,
        this.opptyDemandItem.billingStartDate &&
          `Billing start date: ${this.opptyDemandItem.billingStartDate}`,
      ]
        .filter(Boolean)
        .join('\n');

      this.getServiceTypeName();
    }
  }

  onAddProvider(ev) {
    ev.preventDefault();
    this.addEdit.emit();
  }

  onAddNotes(ev) {
    ev.preventDefault();
    const params = {
      demandNotes: this.demandNotes,
      opptyDemandItem: this.opptyDemandItem,
      onCloseModal: (demandUuid: string, note: PLDemandNote) => {
        this.demandNotes[demandUuid] = note;
        this.cdr.markForCheck();
      },
    };
    this.plModal.create(PLDemandNotesComponent, params);
  }

  openDetailsOverlay() {
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });

    this.overlayRef = this.overlay.create(overlayConfig);

    const assignmentManagerDetailsModal = new ComponentPortal(
      PlAssignmentManagerDetailsModalComponent,
    );

    const componentRef = this.overlayRef.attach(assignmentManagerDetailsModal);
    componentRef.instance.salesforceUrl = this.salesforceUrl;
    componentRef.instance.salesforceId = this.orgDemandItem.salesforceId;
    componentRef.instance.serviceModel = this.opptyDemandItem.serviceModel;
    componentRef.instance.opportunityName = this.opptyDemandItem.opptyName;
    componentRef.instance.schoolYear = this.schoolYear;
    componentRef.instance.closeDetailsOverlay =
      this.closeDetailsOverlay.bind(this);
    componentRef.instance.demand = this.opptyDemandItem.uuid;
  }

  closeDetailsOverlay() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  private getSpecialtiesFromDemand(demandItem: PLOpptyDemandItem) {
    // We are showing supervision on the long service type name
    const specialties = [...demandItem.specialties].filter(s => s !== 'SU');
    const longSpecialties = specialties.map(
      specialty => SPECIALTIES_LONG_NAME[specialty],
    );

    return longSpecialties.join('/');
  }

  private getFulfillmentDetails() {
    this.fulfillmentDetails.supply =
      this.orgDemandItem.hoursByServiceSupply[this.opptyDemandItem.uuid];
    this.fulfillmentDetails.demand =
      this.orgDemandItem.hoursByServiceDemand[this.opptyDemandItem.uuid];
    this.fulfillmentDetails.fulfillmentPercent =
      this.fulfillmentDetails.demand === 0
        ? 0
        : Math.floor(
            (this.fulfillmentDetails.supply / this.fulfillmentDetails.demand) *
              100,
          );
  }

  private getServiceTypeName() {
    this.serviceTypeName = this.opptyDemandItem.longServiceTypeName;
    if (this.opptyDemandItem.isAssessment) {
      switch (this.opptyDemandItem.longServiceTypeName) {
        case PLClinicalServiceTypeLongName.BMH:
          this.serviceTypeName = PLClinicalServiceTypeLongName.BHM_ASSESSMENT;
          break;
        case PLClinicalServiceTypeLongName.OT:
          this.serviceTypeName = PLClinicalServiceTypeLongName.OT_ASSESSMENT;
          break;
        case PLClinicalServiceTypeLongName.SLT:
          this.serviceTypeName = PLClinicalServiceTypeLongName.SLT_ASSESSMENT;
          break;
        default:
          this.serviceTypeName = this.opptyDemandItem.longServiceTypeName;
      }
    } else if (this.opptyDemandItem.specialties.includes('SU')) {
      switch (this.opptyDemandItem.longServiceTypeName) {
        case PLClinicalServiceTypeLongName.OT:
          this.serviceTypeName = PLClinicalServiceTypeLongName.OT_SV;
          break;
        case PLClinicalServiceTypeLongName.SLT:
          this.serviceTypeName = PLClinicalServiceTypeLongName.SLT_SV;
          break;
        default:
          this.serviceTypeName = this.opptyDemandItem.longServiceTypeName;
      }
    }
    if (
      this.opptyDemandItem.opportunityLineitemProductCode ===
      PLOpportunityLineItemProductCode.CODE_104_SP_DH
    ) {
      this.serviceTypeName = PLClinicalServiceTypeLongName.SPDH;
    }
  }
}
