import { Pipe, PipeTransform } from '@angular/core';

/*
    If the input is true, returns Yes, otherwhise returns No
*/
@Pipe({
  name: 'plYesNo',
})
export class PLYesNoPipe implements PipeTransform {
  transform(value: any): string {
    switch (value) {
      case 'true':
      case true:
        return 'Yes';
      default:
        return 'No';
    }
  }
}
