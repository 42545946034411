<div class="pl-provider-profile-header">
  <pl-header
    [iconName]="'user-outline'"
    [closable]="backLink"
    (onClose)="onClose()"
  >
    <div class="pl-provider-profile-header-content" content>
      <div class="main">
        <div class="name">
          <h1 *ngIf="provider.user">
            {{ provider.user.firstName }} {{ provider.user.lastName }}
          </h1>
          <div>{{ provider.title }}</div>
        </div>
        <ul class="fields">
          <li *ngIf="provider.email" class="field">
            <span class="label">Email:</span>
            <span class="value">
              <a
                href="mailto:{{ provider.email }}"
                title="Email {{ provider.user.firstName }} {{
                  provider.user.lastName
                }}"
              >
                {{ provider.email }}
              </a>
            </span>
          </li>
          <li class="field">
            <span class="label">Phone Number:</span>
            <span class="value">{{ provider.phone | plPhone }}</span>
          </li>
          <li class="field" *ngIf="mayViewProvider">
            <span class="label">Sub-Status:</span>
            <span class="value">{{ provider.providerSubStatus }}</span>
          </li>
        </ul>
        <ul class="fields fields2">
          <li class="field">
            <span class="label">Empowerment Manager:</span>
            <span class="value" *ngIf="provider.accountOwner"
              >{{ provider.accountOwner.firstName }}
              {{ provider.accountOwner.lastName }}</span
            >
          </li>
          <li class="field">
            <span class="label">Time Zone:</span>
            <span class="value">{{ provider.timezone }}</span>
          </li>
        </ul>
      </div>
      <div class="buttons">
        <pl-provider-assume-login-button
          *ngIf="showAssumeButton"
          [label]="provider.user.username"
          [email]="provider.email"
        ></pl-provider-assume-login-button>
      </div>
    </div>
  </pl-header>
</div>
