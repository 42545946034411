<div class="dedicated-services-hours-container">
  <div class="dedicated-services-text-align">
    <div class="text">Dedicated service hours</div>
    <div class="text">
      <ng-container>
        <div class="point-separation text">
          •
          <pl-timesheet-progress-organization-filter
            (changed)="organizationFilterChanged($event)"
          ></pl-timesheet-progress-organization-filter>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="separator"></div>
  <div *ngIf="hasData" class="bar-align">
    <div class="bars-container">
      <div class="scheduled-label" [style.left]="scheduledPercentage + '%'">
        <div class="progress-label" [class.empty]="scheduledPercentage === 0">
          <div>{{ scheduledHoursFixed | number : '1.0-2' }} hrs</div>
          <div class="label-style">Scheduled</div>
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
      </div>

      <div class="pl-bar assigned"></div>
      <div
        class="pl-bar scheduled"
        [class.empty]="scheduledPercentage === 0"
        [style.width]="scheduledPercentage + '%'"
      ></div>
      <div
        class="pl-bar documented"
        [class.empty]="documentedPercentage === 0"
        [style.width]="documentedPercentage + '%'"
      ></div>

      <div class="documented-label" [style.left]="documentedPercentage + '%'">
        <div class="progress-label" [class.empty]="documentedPercentage === 0">
          <div>{{ documentedHoursFixed | number : '1.0-2' }} hrs</div>
          <div class="label-style">Documented</div>
        </div>
      </div>
    </div>
    <div class="progress-label assigned total-assigned">
      <div>{{ assignedHoursFixed | number : '1.0-2' }} hrs</div>
      <div class="label-style">Total Assigned</div>
    </div>
    <div class="status-align">
      <ng-container
        *ngIf="{
          status: status$ | async,
          timesheetId: timesheetId$ | async
        } as vmCta"
      >
        <span class="title">Status: {{ vmCta.status | titlecase }}</span>
        <div
          class="title"
          *ngIf="{ days: daysUntilDueDate$ | async } as dueDaysVm"
          [class.past-due]="dueDaysVm.days < 0"
        >
          <ng-container *ngIf="dueDaysVm.days === 0">
            <span>Due</span>
            <span class="title">Today</span>
            <span>at 5pm PT</span>
          </ng-container>
          <ng-container *ngIf="dueDaysVm.days > 0">
            <span>Due in</span>
            <span class="title">{{ dueDaysVm.days }}</span>
            <span>Day{{ dueDaysVm.days === 1 ? '' : 's' }}</span>
          </ng-container>
          <ng-container *ngIf="dueDaysVm.days < 0">
            <span>Past Due</span>
            <span class="title">{{ dueDaysVm.days * -1 }}</span>
            <span>Day{{ dueDaysVm.days === -1 ? '' : 's' }}</span>
          </ng-container>
        </div>

        <a
          class="action-button"
          mat-raised-button
          *ngIf="vmCta.status === 'submitted'; else openCta"
          [routerLink]="['/billing', 'timesheets', vmCta.timesheetId]"
        >
          View Timesheet
        </a>
        <ng-template #openCta>
          <a
            class="action-button"
            mat-raised-button
            *ngIf="isTimesheetValid$ | async; else daButton"
            [routerLink]="['/billing', 'submit-timesheet']"
          >
            Preview & Submit
          </a>
          <ng-template #daButton>
            <a
              class="action-button"
              mat-raised-button
              [routerLink]="['/documentation-assistant']"
              >Documentation Assistant</a
            >
          </ng-template>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>
