import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { PLProgressBarComponent } from './pl-progress-tracker/pl-progress-tracker.component';

import { PLTimesheetProgressBarComponent } from './pl-timesheet-progress-bar.component';
import { PLTimesheetProgressOrganizationFilterComponent } from './pl-timesheet-progress-organization-filter/pl-timesheet-progress-organization-filter.component';
import { PLTimesheetProgressStatusComponent } from './pl-timesheet-progress-status/pl-timesheet-progress-status.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
  exports: [
    PLTimesheetProgressBarComponent,
    PLTimesheetProgressOrganizationFilterComponent,
  ],
  declarations: [
    PLTimesheetProgressBarComponent,
    PLProgressBarComponent,
    PLTimesheetProgressOrganizationFilterComponent,
    PLTimesheetProgressStatusComponent,
  ],
  providers: [],
})
export class PLTimesheetProgressBarModule {}
