import { Component, Input } from '@angular/core';

enum TECHCHECK_STATUS {
  PASSED = 'Tech Check Successful',
  WARNING = 'Passed with Warnings',
  FAILED = 'Setup Tool Failed',
}
@Component({
  selector: 'pl-provider-details-tech-check-details',
  templateUrl: './pl-provider-details-tech-check-details.component.html',
  styleUrls: [
    './pl-provider-details.common.less',
    './pl-provider-details-tech-check-details.component.less',
  ],
})
export class PLProviderDetailsTechCheckDetailsComponent {
  @Input() provider: any; // replace 'any' with the actual type
  @Input() userType: string;

  getTechCheckStatus(): string {
    switch (this.provider.techcheck?.status) {
      case TECHCHECK_STATUS.PASSED:
        return 'passed';
      case TECHCHECK_STATUS.FAILED:
        return 'failed';
      default:
      case TECHCHECK_STATUS.WARNING:
        return 'warning';
    }
  }

  getTechCheckIcon(): string {
    switch (this.provider.techcheck?.status) {
      case TECHCHECK_STATUS.PASSED:
        return 'done';
      case TECHCHECK_STATUS.FAILED:
        return 'close';
      default:
      case TECHCHECK_STATUS.WARNING:
        return 'horizontal_rule';
    }
  }
}
