export enum PLOpportunityLineItemProductCode {
  CODE_101 = '101',
  CODE_102 = '102',
  CODE_103 = '103',
  CODE_103_SLP_DH = '103-SLP-DH',
  CODE_103_SLP_DH_AAC = '103-SLP-DH-AAC',
  CODE_SLP = 'SLP',
  CODE_103_SLP_SH_BI = '103-SLP-SH-BI',
  CODE_104_SP_DH = '104-SP-DH',
}
