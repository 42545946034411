import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';

import { PLUtilService, PLComponentStateInterface } from '@common/services';
import { PLToastService, PLUrlsService } from '@root/index';
import { AccountManager } from '../interfaces/account-manager.interface';
import { PLCustomerDashboardService } from '../pl-customer-dashboard.service';

@Component({
  selector: 'pl-support-summary',
  templateUrl: './pl-support-summary.component.html',
  styleUrls: ['./pl-support-summary.component.less'],
})
export class PLSupportSummaryComponent implements OnInit, OnDestroy, OnChanges {
  componentName = 'PLSupportSummaryComponent';
  _state: PLComponentStateInterface;
  zendeskSupportURL: string;
  clinicalSuccessManagerList: AccountManager[] = [];
  schoolLiaison: AccountManager;

  @Input() uuid: string;

  constructor(
    private plToast: PLToastService,
    private util: PLUtilService,
    private dashboardService: PLCustomerDashboardService,
    private plUrls: PLUrlsService,
  ) {
    this.zendeskSupportURL = this.plUrls.urls.helpDocsFE;
  }

  // --------------------------
  // lifecycle methods
  // --------------------------
  ngOnInit() {
    this._state = this.util.initComponent({
      name: this.componentName,
      params: {
        flags: {
          // COMPONENT_INIT: 1,
          // RUN_TEST: 1,
        },
        mocks: [
          // 'MOCK_CSM',
          // 'MOCK_CQM'
        ],
      },
      afterDoneFn: (state: PLComponentStateInterface) => {
        state.init = true;
      },
      fn: (state: PLComponentStateInterface, done) => {
        state.asyncCount = 1;

        if (this.uuid) this.getStats(state, done);
        else done();
      },
    });
  }

  ngOnDestroy() {
    this.util.destroyComponent(this._state);
  }

  ngOnChanges(changes: any) {
    if (!this._state || !this._state.init) return;

    this.getStats(this._state, () => {});
  }

  // --------------------------
  // public methods
  // --------------------------

  canDisplayCsm() {
    const obj = this._state.model.csm;
    return obj && (obj.name || obj.email || obj.phone);
  }

  // --------------------------
  // private methods
  // --------------------------
  private getStats(state: PLComponentStateInterface, done: any) {
    const vars = {
      id: this.uuid,
    };

    this.dashboardService
      .getOrganizationOverview$(vars, state)

      .subscribe((res: any) => {
        state.model.data.organizationOverview = res.organization;
        if (res.organization.accountCsmPesMhc) {
          this.clinicalSuccessManagerList.push(
            res.organization.accountCsmPesMhc,
          );
        }
        if (res.organization.accountCsmSlpOt) {
          this.clinicalSuccessManagerList.push(
            res.organization.accountCsmSlpOt,
          );
        }

        this.schoolLiaison = res.organization.accountCam;

        if (this.util.flag(state, 'MOCK_CSM')) {
          this.mockOrgCSM(state);
        }
        this.initCsmValues(state);
        if (this.util.flag(state, 'MOCK_CQM')) {
          this.mockOrgCQM(state);
        }
        this.initCqmValues(state);
        done();
      });
  }

  private initCsmValues(state: PLComponentStateInterface) {
    const csmRaw = (state.model.data.csmRaw =
      (state.model.data.organizationOverview &&
        state.model.data.organizationOverview.accountOwner) ||
      {});
    const csm = (state.model.csm = {
      name: `${csmRaw.firstName || ''} ${csmRaw.lastName || ''}`.trim(),
      email: csmRaw.email || '',
      phone: (csmRaw.profile && csmRaw.profile.primaryPhone) || '',
    });
    if (!(csm.name || csm.email || csm.phone)) {
      state.model.csm = undefined;
    }
  }

  private initCqmValues(state: PLComponentStateInterface) {
    const cqmRaw = (state.model.data.cqmRaw =
      (state.model.data.organizationOverview &&
        state.model.data.organizationOverview.accountCqm) ||
      {});
    const cqm = (state.model.cqm = {
      name: `${cqmRaw.firstName || ''} ${cqmRaw.lastName || ''}`.trim(),
      email: cqmRaw.email || '',
      phone: (cqmRaw.profile && cqmRaw.profile.primaryPhone) || '',
    });
    if (!(cqm.name || cqm.email || cqm.phone)) {
      state.model.cqm = undefined;
    }
  }

  private mockOrgCSM(state: PLComponentStateInterface) {
    state.model.data.organizationOverview.accountOwner = {
      email: 'csm@email.com',
      firstName: 'First',
      lastName: 'Last',
      profile: {
        primaryPhone: '000-000-0000',
      },
    };
    this.util.mockLog(
      'MOCK_CSM',
      state.model.data.organizationOverview.accountOwner,
      state,
    );
  }

  private mockOrgCQM(state: PLComponentStateInterface) {
    state.model.data.organizationOverview.accountCqm = {
      email: 'cqm@email.com',
      firstName: 'First',
      lastName: 'Last',
      profile: {
        primaryPhone: '000-000-0000',
      },
    };
    this.util.mockLog(
      'MOCK_CQM',
      state.model.data.organizationOverview.accountCqm,
      state,
    );
  }
}
