import { createAction, props } from '@ngrx/store';
import { TimesheetsPreviewResponse } from '../../services/timesheets-data.service';

export const fetchNextTimesheet = createAction(
  '[FetchNextTimesheet Effects] fetch',
);

export const fetchNextTimesheetSuccess = createAction(
  '[FetchNextTimesheet Effects] fetchSuccess',
  props<{ nextTimesheet: TimesheetsPreviewResponse }>(),
);

export const fetchNextTimesheetError = createAction(
  '[FetchNextTimesheet Effects] fetchError',
  props<{ error?: any }>(),
);
