import { Component, Input } from '@angular/core';

import { PLAssumeLoginService } from '@root/index';

@Component({
  selector: 'pl-provider-assume-login-button',
  templateUrl: './pl-provider-assume-login-button.component.html',
})
export class PLProviderAssumeLoginButtonComponent {
  @Input() email: string;
  @Input() label: string;

  constructor(private assumeLoginService: PLAssumeLoginService) {}

  onClick() {
    this.assumeLoginService.assume(this.email);
  }
}
