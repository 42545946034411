import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PLChecklistComponent } from './pl-checklist.component';
import { PLChecklistItemComponent } from './pl-checklist-item/pl-checklist-item.component';
import { PLIconModule, PLInputModule } from '@root/src/lib-components';

@NgModule({
  imports: [CommonModule, PLIconModule, PLInputModule],
  exports: [PLChecklistComponent],
  declarations: [PLChecklistComponent, PLChecklistItemComponent],
  providers: [],
})
export class PLChecklistModule {}
