import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { CurrentUserService } from '@modules/user/current-user.service';

@Injectable()
export class PLProviderCaseloadRouteGuardService implements CanActivate {
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    let providerUuidFromURI = route?.parent?.params?.id;

    return this.currentUserService.getCurrentUser().pipe(
      map(user => {
        if (user.uuid === providerUuidFromURI) {
          return true;
        }

        this.router.navigate(['/landing']);
        return false;
      }),
    );
  }
}
