import { Component, Input, OnInit } from '@angular/core';
import { PLAssignmentRequirement } from '@root/src/app/common/assigment-machine/models';
import { PLAssignmentStatusEnum } from '../pl-assignment-manager.model';

@Component({
  selector: 'pl-missing-requirements-message',
  templateUrl: './pl-missing-requirements-message.component.html',
  styleUrls: ['./pl-missing-requirements-message.less'],
})
export class PLMissingRequirementsMessageComponent implements OnInit {
  @Input() public status: string;
  @Input() public unmetRequirements: PLAssignmentRequirement[] = [];
  public message: string;
  public messageTittle: string;
  public iconType: string;
  public iconColor: string;
  public assigmentStatusEnum = PLAssignmentStatusEnum;
  public showMessage: boolean = false;
  public assignmentStatusClasses = {
    [this.assigmentStatusEnum.INITIATED]: 'proposed',
    [this.assigmentStatusEnum.ACTIVE]: 'active',
    [this.assigmentStatusEnum.PENDING]: 'pending',
    [this.assigmentStatusEnum.RESERVED]: 'reserved',
  };

  ngOnInit(): void {
    this.showMessage = this.canShowMessage();
    this.iconType = this.getIconType();
    this.message = this.getMessage();
    this.messageTittle = this.getMessageTittle();
  }

  private getIconType(): string {
    switch (this.status) {
      case this.assigmentStatusEnum.INITIATED:
        this.iconColor = '#F7901E';
        return 'warning';
      case this.assigmentStatusEnum.PENDING:
        this.iconColor = '#FF7B80';
        return 'dangerous_outline';
      default:
        this.iconColor = '#379CFF';
        return 'error_outline';
    }
  }

  private canShowMessage(): boolean {
    if (
      this.status === this.assigmentStatusEnum.INITIATED &&
      this.unmetRequirements.length > 0
    ) {
      return true;
    }
    if (
      this.status === this.assigmentStatusEnum.PENDING &&
      this.unmetRequirements.length > 0
    ) {
      return true;
    }
    if (this.status === this.assigmentStatusEnum.RESERVED) {
      return true;
    }
    return false;
  }

  private getMessage(): string {
    switch (this.status) {
      case this.assigmentStatusEnum.INITIATED:
        return 'If you accept this assignment, you will need the following additional credentials.';
      case this.assigmentStatusEnum.PENDING:
        return 'Please contact your Empowerment Manager if you need assistance in fulfilling these requirements.';
      case this.assigmentStatusEnum.RESERVED:
        return 'You will be notified of any changes, including the confirmed start date.';
    }
  }
  private getMessageTittle(): string {
    switch (this.status) {
      case this.assigmentStatusEnum.RESERVED:
        return 'You are reserved for this Assignment';
      case this.assigmentStatusEnum.ACTIVE:
        return 'They love you and want you back!';
      default:
        return 'Missing requirements';
    }
  }

  checkInstruction(
    requirement: PLAssignmentRequirement,
    option: string,
  ): boolean {
    return requirement.instructions_for_requirement.some(
      instruction => instruction[option],
    );
  }

  getInstructionUrl(
    requirement: PLAssignmentRequirement,
    option: string,
  ): string {
    return requirement.instructions_for_requirement.find(
      instruction => instruction[option],
    )[option];
  }
}
