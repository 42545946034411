<div class="location-header">
  <mat-icon svgIcon="location-white" class="location-icon"></mat-icon>
  <div class="location-header-title">Location Mapping</div>
</div>

<div *ngIf="matchedLocationsOpts?.length > 0" class="pl-location-matching">
  <div class="location-prompt">
    Confirm or update the location fields to continue.
  </div>

  <div class="location-loading" *ngIf="model.loadingLocations">
    <pl-dot-loader [align]="'left'"></pl-dot-loader>
    <div>Loading Organizations and Locations</div>
  </div>

  <div *ngIf="!model.loadingLocations" class="matching-container">
    <div class="location-matching-row-header">
      <div class="uploaded-location-label">Uploaded Location:</div>
      <div class="suggested-location-label">Suggested Location:</div>
    </div>

    <div
      class="location-matching-row"
      *ngFor="let location of matchedLocationsOpts"
    >
      <div class="uploaded-location-row">
        <div class="location-value">{{ location.label }}</div>
      </div>
      <div class="match-location-row">
        <div class="location-input-row">
          <pl-input-select
            class="form-input x-qa-location-select"
            [(model)]="matchedLocations[location.label]['matchedLocation']"
            [options]="locationOpts"
            (onChange)="checkValidity()"
          >
          </pl-input-select>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="unmatchedLocationsOpts?.length > 0" class="pl-location-matching">
  <div class="location-prompt">
    The following location(s) does/do not match anything under this
    organization. <br />
    You may manually select a location or opt to not include these entries in
    your upload.
  </div>

  <div *ngIf="!model.loadingLocations" class="matching-container">
    <div class="location-matching-row-header">
      <div class="uploaded-location-label">Uploaded Location:</div>
      <div class="suggested-location-label">Suggested Location:</div>
    </div>

    <div
      class="location-matching-row"
      *ngFor="let location of unmatchedLocationsOpts"
    >
      <div class="uploaded-location-row">
        <div class="location-value">{{ location.label }}</div>
      </div>
      <div class="match-location-row">
        <div class="location-input-row">
          <pl-input-select
            class="form-input x-qa-location-select"
            [(model)]="
              this.unmatchedLocations[location.label]['matchedLocation']
            "
            [options]="locationOpts"
            (onChange)="checkValidity()"
          >
          </pl-input-select>
        </div>
      </div>
    </div>
  </div>
</div>
