<div class="parent">
  <div class="user-welcoming">Welcome {{ currentUser.first_name }}! 👋</div>
  <div class="pl-task-card cards-border-style">
    <pl-task-card></pl-task-card>
  </div>
  <div class="right-column">
    <div class="pl-room-card cards-border-style">
      <pl-room-card></pl-room-card>
    </div>
    <div class="pl-schedule-card cards-border-style">
      <pl-schedule-card></pl-schedule-card>
    </div>
  </div>
  <div
    class="pl-dedicated-service-hours-card cards-border-style"
    *ngIf="isProvider && (hasDedicatedAssignments$ | async)"
  >
    <pl-dedicated-service-hours-card
      class="dedicated-services-hours-width"
    ></pl-dedicated-service-hours-card>
  </div>
  <div
    class="pl-quick-links-card cards-border-style"
    [ngClass]="{ bumped: isProvider && (hasDedicatedAssignments$ | async) }"
  >
    <pl-quick-links-card
      [organizations]="organizations"
      [showOrgHandbooks]="showOrgHandbooks"
      [userEmail]="currentUser.email"
    ></pl-quick-links-card>
  </div>
  <div
    *ngIf="showCEMInfo"
    class="pl-quick-links-card cards-border-style"
    [ngClass]="{ bumped: isProvider && (hasDedicatedAssignments$ | async) }"
  >
    <pl-clinical-contacts-card
      [currentUserId]="currentUser.uuid"
      (hasClinicalContacts)="hasClinicalContacts($event)"
    ></pl-clinical-contacts-card>
  </div>
</div>
