import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import dayjs from 'dayjs';

@Component({
  selector: 'pl-provider-details-additional-details',
  templateUrl: './pl-provider-details-additional-details.component.html',
  styleUrls: [
    './pl-provider-details.common.less',
    './pl-provider-details-additional-details.component.less',
  ],
})
export class PLProviderDetailsAdditionalDetailsComponent
  implements OnInit, OnChanges
{
  @Input() provider: any; // replace 'any' with the actual type

  modified: string;

  systemsExperienced: string[];

  ngOnInit() {
    this.updateProviderInfo();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.provider) {
      this.updateProviderInfo();
    }
  }

  updateProviderInfo() {
    if (!this.provider) {
      return;
    }
    if (this.provider.timezone && this.provider.modified) {
      this.modified = dayjs(this.provider.modified)
        .tz(this.provider.timezone)
        .format('MMMM D, YYYY');
    }
    this.systemsExperienced = (this.provider.iep_systems_experience || [])
      .concat(this.provider.si_systems_experience || [])
      .concat(this.provider.medicaid_systems_experience || []);
  }
}
