import { Component, Input } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { PLAgreement } from '@common/interfaces';
import { PLAgreementsService } from '@common/services';
import { PLProvider } from '@modules/locations/models';
import { PLProviderService } from '@modules/providers/pl-provider.service';
import { CurrentUserService } from '@modules/user/current-user.service';
import { User } from '@modules/user/user.model';
import { PLMayService } from '@root/src/lib-components';
@Component({
  selector: 'pl-provider-details-signed-agreements-details',
  templateUrl: './pl-provider-details-signed-agreements-details.component.html',
  styleUrls: [
    './pl-provider-details-signed-agreements-details.component.less',
    './pl-provider-details.common.less',
  ],
  providers: [PLAgreementsService, PLProviderService, CurrentUserService],
})
export class PLProviderSignedAgreementsDetailsComponent {
  @Input() provider: PLProvider;
  @Input() userType: string;
  private subscription: Subscription = new Subscription();
  agreements: PLAgreement[];
  loading = true;

  constructor(
    private plAgreementsService: PLAgreementsService,
    private plProvider: PLProviderService,
    private plCurrentUserService: CurrentUserService,
    private plMayService: PLMayService,
  ) {}

  ngOnInit() {
    this.plProvider
      .getFromRoute()
      .pipe(withLatestFrom(this.plCurrentUserService.getCurrentUser()))
      .subscribe(([res, user]: [any, User]) => {
        if (!this.plMayService.isCustomer(user)) {
          this.provider = res.provider;

          forkJoin([
            this.plAgreementsService.getAgreementsRequests(
              this.provider.user.id,
            ),
            this.plAgreementsService.getFountainAgreementsRequests(
              this.provider.user.id,
            ),
            this.plAgreementsService.getPreAgreementW2sRequests(
              this.provider.user.id,
            ),
          ]).subscribe(results => {
            this.agreements = results[0].concat(results[1]).concat(results[2]);
            this.loading = false;
          });
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
