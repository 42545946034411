import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { PLCommonModule } from '@common/index';
import {
  PLDotLoaderModule,
  PLIconModule,
  PLInputModule,
  PLTabsModule,
  PLButtonModule,
} from '@root/index';


import { PLAvailabilityViewComponent } from './availability/pl-availability-view.component';

@NgModule({
  imports: [
    CommonModule,
    PLDotLoaderModule,
    PLCommonModule,
    PLIconModule,
    PLInputModule,
    PLButtonModule,
    PLTabsModule,
    NgxJsonViewerModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
  ],
  exports: [PLAvailabilityViewComponent],
  declarations: [PLAvailabilityViewComponent],
  providers: [],
})
export class PLScheduleAvailabilityComponentsModule {}
