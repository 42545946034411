import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppStore } from '@app/appstore.model';

import { PLHttpService } from '@root/index';
dayjs.extend(objectSupport);

interface ChironSummarizeParams {
  text: string;
}

interface ChironSummarizeResult {
  message: string;
  status: string;
  summary: string;
}

/**
 * PLAISummaryService - generates AI Summary reports.
 */
@Injectable()
export class PLAISummaryService {
  constructor(private store: Store<AppStore>, private plHttp: PLHttpService) {}

  generateSummary(
    summarizeParams: ChironSummarizeParams,
  ): Observable<ChironSummarizeResult> {
    return this.plHttp.save('summarize', summarizeParams);
  }

  generateSummaryForClient(
    clientUuid,
    startDate,
    endDate,
  ): Observable<ChironSummarizeResult> {
    let text = '';
    return this.plHttp
      .get('records', {
        client: clientUuid,
        ordering: 'appointment__start',
        expand: 'appointment',
      })
      .pipe(
        switchMap((res: any) => {
          for (const record of res.results) {
            const recordDate = dayjs(record.appointment_expanded.start);
            if (recordDate.isBefore(startDate) || recordDate.isAfter(endDate)) {
              continue;
            }
            text += `Session on ${recordDate.format('MMMM D, YYYY')}:\n`;
            let recordNotes = JSON.parse(record.notes);
            if (recordNotes.subjective) {
              text += 'Subjective: ';
              text += recordNotes.subjective + '\n';
            }
            if (recordNotes.objective) {
              text += 'Objective:';
              text += recordNotes.objective + '\n';
            }
            if (recordNotes.assessment) {
              text += 'Assessment:';
              text += recordNotes.assessment + '\n';
            }
            if (recordNotes.plan) {
              text += 'Plan:';
              text += recordNotes.plan + '\n';
            }
            if (recordNotes.notes) {
              text += 'General Notes:';
              text += recordNotes.notes + '\n';
            }
          }
          return this.generateSummary({ text });
        }),
      );
  }
}
