<div class="card pl-provider-details-organization-preferences">
  <ng-container
    *ngTemplateOutlet="template; context: { $implicit: (preferences$ | async) }"
  >
  </ng-container>
</div>

<ng-template #template let-preferences>
  <div class="header">
    <h3>Organization preferences</h3>
    <span class="last-updated">{{ preferences?.lastModified }}</span>
  </div>
  <div class="content">
    <div class="preference-header">
      <div>
        Showing {{ preferences?.numberOfAssignments }} preferences from
        {{ preferences?.yearRange }}
      </div>
      <div>
        <a
          href="https://metabase.presencelearning.com/question/1426-provider-assignment-preference?school_year=2023-2024%20School%20Year"
          >View all in Metabase<mat-icon>open_in_new</mat-icon></a
        >
      </div>
    </div>
    <div
      class="orginization-preference"
      *ngFor="let organization of preferences?.organizations"
    >
      <div>
        <div class="organization">{{ organization.account_name }}</div>
        <div class="details">
          {{ organization.service_line | plServiceLineTitle }} •
          {{ organization.dateRange }}
        </div>
      </div>
      <div *ngFor="let preference of organization.preferences">
        <div class="feedback">
          <span class="feedback-label">{{ preference.type }} feedback:</span>
          <div
            class="preference-icon"
            [class.yes]="preference?.preference === 'yes'"
            [class.no]="preference?.preference === 'no'"
          >
            <mat-icon>{{
              preference?.preference === 'yes'
                ? 'check'
                : preference?.preference === 'no'
                ? 'close'
                : 'remove'
            }}</mat-icon>
          </div>
          <div class="preference" *ngIf="preference?.preference === 'yes'">
            <strong>Yes</strong>, I would return
          </div>
          <div class="preference" *ngIf="preference?.preference === 'no'">
            <strong>No</strong>, I would not return
          </div>
          <div
            class="preference"
            *ngIf="
              preference?.preference !== 'yes' &&
              preference?.preference !== 'no'
            "
          >
            I don't have a preference
          </div>
        </div>
        <div class="comments-wrapper">
          <div class="border"></div>
          <div *ngIf="preference.comments" class="comments">
            {{ preference.comments }}
          </div>
          <div *ngIf="!preference.comments" class="comments">
            <span class="no-comments">No comments.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
