<div
  class="pl-custom-assignment-modal"
  [ngClass]="{ 'edit-mode': proposalItem, 'new-mode': !proposalItem }"
>
  <pl-modal-header-wrapper [headerText]="headerText">
    <div class="modal-content" *ngIf="saveErrors.length" [@fadeIn]>
      <h2 class="margin-small-b nowrap">Save errors</h2>

      <ul class="margin-large-l">
        <li *ngFor="let err of saveErrors">
          <b>{{ err.key }}</b
          >: {{ err.text }}
        </li>
      </ul>
      <div class="margin-large-t">
        <button
          mat-stroked-button
          color="primary"
          type="button"
          (click)="saveErrors = []"
        >
          Back
        </button>
      </div>
    </div>
    <div *ngIf="loading">
      <pl-dot-loader align="left"></pl-dot-loader>
    </div>
    <div class="modal-content" [hidden]="saveErrors.length || loading">
      <div class="header">
        <h2 class="nowrap">
          <span class="org-name">{{ orgDemandItem.orgName }}</span>
        </h2>
        <h4 class="oppty-line">
          <span class="service-type">{{
            opptyDemandItem.serviceGroupName
          }}</span>
        </h4>
      </div>
      <div class="provider-name" *ngIf="proposalItem && assignmentProvider">
        <h3>
          <a
            class="provider-name-v2"
            href="${{ getProviderDashboardUrl() }}"
            target="_blank"
            >{{ assignmentProvider.firstName }}
            {{ assignmentProvider.lastName }}</a
          >
        </h3>
        <div class="provider-remaining-hours-as-of">
          {{ assignmentProvider.remainingHours }} remaining hours as of
          {{ datePipe.transform(model.startDate, 'MM/dd/YYYY') }}
        </div>
      </div>
      <form [formGroup]="assignmentFormGroup">
        <div class="form-container">
          <div *ngIf="!proposalItem && proposeNonQualifiedProvider">
            <div class="warning">
              <pl-icon class="yellow" [svg]="'caution'"></pl-icon>
              <span
                >You are proposing a provider who may not meet the necessary
                qualifications, and could prevent another qualified provider
                from taking this spot. Make sure you know what you're
                doing!</span
              >
            </div>
          </div>
          <div class="form-input">
            <pl-input-datepicker
              [label]="'First work day'"
              [formCtrl]="assignmentFormGroup"
              [model]="model.startDate"
              (onChange)="onDateChange($event, inputFieldName.STARTDATE)"
              [required]="true"
            >
            </pl-input-datepicker>
          </div>
          <div
            id="last-work-day-field"
            *ngIf="showLastWorkDayField"
            class="form-input"
          >
            <pl-input-datepicker
              [label]="'Last work day'"
              [formCtrl]="assignmentFormGroup"
              [model]="model.endDate"
              (onChange)="onDateChange($event, inputFieldName.ENDDATE)"
              [required]="true"
            >
            </pl-input-datepicker>
          </div>
          <div class="form-input">
            <pl-input-text
              [label]="'Avg. weekly hours'"
              [disabled]="!allowLockOrReserve"
              [model]="model.weeklyHours"
              (change)="onWeeklyHoursChange($event)"
              [required]="true"
              [pattern]="maxHoursPattern"
              [validationMessages]="{
                pattern: 'Between 1 and 40 hours by 0.25 hour increments'
              }"
              [formCtrl]="assignmentFormGroup"
              placeholder="Hrs."
            >
            </pl-input-text>
          </div>
          <div
            *ngIf="
              allowLockOrReserve &&
              model.weeklyHours &&
              model.startDate &&
              !proposalItem &&
              !proposeNonQualifiedProvider
            "
            class="providers-list form-input"
          >
            <pl-table-wrapper (onQuery)="onQuery($event)">
              <pl-table-header class="sticky-header">
                <pl-table-header-cell [orderKey]="'name'" style="height: 35px">
                  <div>
                    <svg
                      style="float: left"
                      width="16"
                      viewBox="0 0 20 20"
                      class="gray-darkest"
                      (click)="onSearchClick($event)"
                    >
                      <use xlink:href="assets/search-icons.svg#search" />
                    </svg>
                    <input
                      class="search-input"
                      [class.visible]="showNameSearch"
                      (click)="onSearchInputClick($event)"
                      (input)="onSearchInputChange($event)"
                    />
                    &nbsp;
                    <div class="name" [class.hidden]="showNameSearch">Name</div>
                  </div>
                </pl-table-header-cell>
                <pl-table-header-cell [orderKey]="'hours'"
                  >Remaining availability</pl-table-header-cell
                >
                <pl-table-header-cell
                  [orderKey]="'match'"
                  [orderDirection]="'ascending'"
                  >Fitness rank</pl-table-header-cell
                >
                <pl-table-header-cell [orderKey]="'other'"
                  >Other</pl-table-header-cell
                >
              </pl-table-header>
              <pl-table-row *ngFor="let p of availableProviderOpts">
                <pl-table-cell>
                  <pl-input-radio
                    [disabled]="!allowLockOrReserve"
                    [style.visibility]="
                      p.remainingHours > 0 || opptyDemandItem.isESY
                        ? 'visible'
                        : 'hidden'
                    "
                    [value]="p.uuid"
                    [(model)]="model.provider"
                    (onChange)="onProviderSelected(p.groups)"
                  >
                  </pl-input-radio>
                  {{ p.name }}
                  <br />
                  <div *ngIf="p.separationDate" class="separation">
                    Separation date: {{ p.separationDate }}
                  </div>
                </pl-table-cell>
                <pl-table-cell
                  >{{ p.remainingHours }} hour{{
                    p.remainingHours === 1 ? '' : 's'
                  }}</pl-table-cell
                >
                <pl-table-cell>
                  <b *ngIf="p.rankColor" [ngClass]="p.rankColor">{{
                    p.rankDescription
                  }}</b>
                  <span *ngIf="!p.rankColor && p.rankDescription">{{
                    p.rankDescription
                  }}</span>
                </pl-table-cell>
                <pl-table-cell class="other-cell">
                  <div>
                    <pl-popover
                      [template]="returningReasoning"
                      *ngIf="p.isReturning"
                    >
                      <span class="is-returning"> Returning </span>
                    </pl-popover>

                    <ng-template #returningReasoning>
                      <pl-assignment-manager-returning-details
                        [assignment]="p"
                        [orgName]="orgDemandItem.orgName"
                      ></pl-assignment-manager-returning-details>
                    </ng-template>
                    <div *ngIf="p.isOnboarding">
                      <b class="yellow">* Onboarding *</b>
                    </div>
                    <div *ngIf="p.hasPendingReqs">
                      <b class="yellow">* Pending Qualification *</b>
                    </div>
                    <div *ngIf="p.removedReasons">
                      <b>Previously rejected:</b> {{ p.removedReasons }}
                    </div>
                    <div *ngIf="p.hasPendingSpecialty">
                      <b>Provider specialty pending</b>
                    </div>
                  </div>
                  <div>
                    <button
                      class="action-menu-button"
                      [matMenuTriggerFor]="actionMenu"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu
                      #actionMenu="matMenu"
                      class="action-menu"
                      xPosition="before"
                    >
                      <label class="action-menu-label">View more in…</label>
                      <button mat-menu-item (click)="openProviderProfile(p)">
                        <span>Provider profile</span>
                      </button>
                      <button mat-menu-item (click)="openSFDCRecord(p)">
                        <span>SFDC record</span>
                      </button>
                      <button mat-menu-item (click)="openMetabaseReport(p)">
                        <span>Metabase report</span>
                      </button>
                    </mat-menu>
                  </div>
                </pl-table-cell>
              </pl-table-row>
            </pl-table-wrapper>
          </div>
          <pl-input-select
            *ngIf="!proposalItem && proposeNonQualifiedProvider"
            [label]="'Provider'"
            [options]="nonQualifiedProviders"
            [(model)]="model.provider"
            [placeholder]="'Select Provider'"
            [bigFilter]="false"
            [filter]="true"
          >
          </pl-input-select>
          <div
            class="form-input"
            *ngIf="!proposalItem && !proposeNonQualifiedProvider"
          >
            <div class="flexbox" [style.width]="'200px'">
              <pl-input-radio
                class="flex1"
                [disabled]="!allowLockOrReserve"
                [label]="'Locked'"
                [value]="'locked'"
                [(model)]="model.status"
                [formCtrl]="assignmentFormGroup"
              ></pl-input-radio>
              <pl-input-radio
                class="flex1"
                [disabled]="!allowLockOrReserve"
                [label]="'Reserved'"
                [value]="'reserved'"
                [(model)]="model.status"
                [formCtrl]="assignmentFormGroup"
              ></pl-input-radio>
            </div>
          </div>
          <div *ngIf="proposalItem">
            <div class="">
              <pl-input-select
                [label]="'Status'"
                [placeholder]="'Select Status'"
                [(model)]="model.status"
                [options]="assignmentStatusOpts"
                [required]="true"
                [ignoreDeactivateScroll]="true"
                [formCtrl]="assignmentFormGroup"
              >
              </pl-input-select>
            </div>
            <div class="" [hidden]="!isStatusRejectedPL()">
              <pl-input-select
                [label]="'Reason'"
                [placeholder]="'Select a reason'"
                [(model)]="model.reason"
                [options]="rejectedReasonsOpts"
                [required]="true"
                [disabled]="!isStatusRejectedPL()"
                [ignoreDeactivateScroll]="true"
                [formCtrl]="assignmentFormGroup"
              >
              </pl-input-select>
            </div>
            <div class="" [hidden]="!isStatusDeclinedByProvider()">
              <pl-input-select
                [label]="'Reason'"
                [placeholder]="'Select a reason'"
                [(model)]="model.reason"
                [options]="declinedReasonsOpts"
                [required]="true"
                [disabled]="!isStatusDeclinedByProvider()"
                [ignoreDeactivateScroll]="true"
                [formCtrl]="assignmentFormGroup"
              >
              </pl-input-select>
            </div>
            <div class="" [hidden]="!isStatusRemoved()">
              <pl-input-select
                [label]="'Reason'"
                [placeholder]="'Select a reason'"
                [(model)]="model.reason"
                [options]="removedReasonsOpts"
                [required]="true"
                [disabled]="!isStatusRemoved()"
                [ignoreDeactivateScroll]="true"
                [formCtrl]="assignmentFormGroup"
              >
              </pl-input-select>
            </div>
            <div
              [hidden]="!isReasonOther()"
              [ngClass]="isReasonOther() ? 'notes-content' : ''"
            >
              <div>
                <pl-input-textarea
                  [height]="'120px'"
                  [label]="'Notes'"
                  [(model)]="model.notes"
                  [maxlength]="250"
                  [required]="true"
                  [disabled]="!isReasonOther()"
                  [formCtrl]="assignmentFormGroup"
                >
                </pl-input-textarea>
              </div>
              <div class="notes-textarea-info" *ngIf="!getNotesChars()">
                250 character limit
              </div>
              <div class="notes-textarea-info" *ngIf="getNotesChars()">
                <b>{{ 250 - getNotesChars() }}</b> / 250 characters left
              </div>
            </div>
          </div>
          <div class="form-input" *ngIf="isCamDemoNotesDisplayed">
            <pl-input-select
              [label]="'Empowerment Manager demo reason'"
              [placeholder]="'Select a reason'"
              [(model)]="model.cemDemoReason"
              [options]="cemDemoReasonOpts"
              [required]="isCamDemoNotesDisplayed"
              [disabled]="false"
              [formCtrl]="assignmentFormGroup"
            >
            </pl-input-select>
          </div>
          <div class="notes-content form-input">
            <div>
              <pl-input-textarea
                [height]="'100px'"
                [label]="'Assignment proposal notes'"
                [(model)]="model.blockAssignmentTimeReason"
                [maxlength]="250"
                [required]="isCamDemoNotesDisplayed"
                [disabled]="false"
                [formCtrl]="assignmentFormGroup"
              >
              </pl-input-textarea>
            </div>
          </div>
          <div class="notes-textarea-info" *ngIf="!getCamDemoNotesChars()">
            250 character limit
          </div>
          <div class="notes-textarea-info" *ngIf="getCamDemoNotesChars()">
            <b>{{ 250 - getCamDemoNotesChars() }}</b> / 250 characters left
          </div>
        </div>

        <div class="form-buttons">
          <button mat-stroked-button type="button" (click)="onClickCancel()">
            Cancel
          </button>
          <button
            mat-stroked-button
            *ngIf="!proposalItem"
            type="button"
            (click)="onClickProposeNonQualifiedProvider()"
          >
            View
            {{ proposeNonQualifiedProvider ? 'suggested' : 'all' }}
            providers
          </button>
          <button
            mat-stroked-button
            type="button"
            [disabled]="
              inFlight ||
              !this.assignmentFormGroup.valid ||
              !model.status ||
              !model.provider
            "
            (click)="onClickSave()"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </pl-modal-header-wrapper>
</div>
