<div class="pl-tech-check-modal">
  <pl-modal-header-wrapper [headerText]="'Tech Check Request'">
    To ensure an optimal experience, we recommend running a Tech Check on any
    computer or other device you plan to use for student sessions. This process
    is designed to test various hardware, software, and network capabilities.
    <br /><br />
    Use this form to send out a link to the Tech Check. You can repeat this step
    as needed to send additional Tech Check links.
    <br /><br />
    <form [formGroup]="techCheckForm">
      <div class="form-content">
        <pl-input-select
          [formCtrl]="techCheckForm"
          [label]="'Which location is this Tech Check for? '"
          [(model)]="techCheckFormModel.location"
          [options]="locationOptions"
          [required]="true"
        >
        </pl-input-select>
        <br />
        <pl-input-text
          [formCtrl]="techCheckForm"
          [label]="'Email Address '"
          [(model)]="techCheckFormModel.email"
          [type]="'email'"
          [required]="true"
        ></pl-input-text>
        <pl-input-checkbox
          class="checkbox"
          [(model)]="techCheckFormModel.sendCopy"
          [label]="'Send a copy of the email to my Presence email account'"
        >
        </pl-input-checkbox>
      </div>
      <br />
      <div class="form-actions">
        <button
          pl-button
          type="button"
          class="primary"
          (click)="sendEmail(techCheckForm)"
        >
          Email Request
        </button>
      </div>
    </form>
  </pl-modal-header-wrapper>
</div>
