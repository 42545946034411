<div *ngIf="loading">Loading..</div>
<div *ngIf="!loading && isClientContact">
  <h2 class="margin-large-t">Welcome to Presence!</h2>
  <p class="margin-t">We're happy you're here...</p>
  <br />
  <h2 class="margin-large-t">Get the app!</h2>
  <div class="margin-t">
    <img src="assets/ios.png" />

    <img src="assets/android.png" />
  </div>
</div>
