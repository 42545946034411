import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PLCircularProgressComponent } from './pl-circular-progress.component';

@NgModule({
  imports: [CommonModule],
  exports: [PLCircularProgressComponent],
  declarations: [PLCircularProgressComponent],
  providers: [],
})
export class PLCircularProgressModule {}
