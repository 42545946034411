import {
  Input,
  Renderer2,
  Component,
  ElementRef,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'pl-alert',
  templateUrl: './pl-alert.component.html',
  styleUrls: ['./pl-alert.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PLAlertComponent {
  @Input() type: 'default' | 'warn' | 'error' | 'info' | 'success' = 'default';
  @Input() icon: string;
  @Input() header: string;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (!this.icon) {
      const iconMap = {
        default: 'info_outline',
        warn: 'warning',
        error: 'error',
        info: 'info_outline',
        success: 'check_circle_outline',
      };
      this.icon = iconMap[this.type];
    }
    this.renderer.addClass(this.elementRef.nativeElement, this.type);
  }
}
