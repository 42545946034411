import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { PLHttpService, PLUrlsService } from '@root/src/lib-components';

export type PayRateType = 'provider' | 'preagreement' | 'applicant';
export type SchoolYearChoiceType = 'current' | 'next';

export interface ProviderPayRate {
  state: string;
  pay_rate: string;
  year_experience: number;
  is_w2: boolean;
  provider_type: string;
  provider_type_code: string;
}

export interface PayGridResponse {
  count: number;
  isw2: boolean;
  providerpayrate: ProviderPayRate[];
  school_year: 'current' | 'next' | null;
}

@Injectable({ providedIn: 'root' })
export class PLProviderPayRateService {
  constructor(private plHttp: PLHttpService, private plUrls: PLUrlsService) {}

  fetchPayGrid(
    type: PayRateType,
    uuid: string,
    schoolYearChoice: SchoolYearChoiceType,
  ): Observable<PayGridResponse> {
    const paramKey = this.getParamKey(type);
    const urlKey = this.getUrlKey(type);
    return this.plHttp
      .get(urlKey, { [paramKey]: uuid, school_year: schoolYearChoice })
      .pipe(first());
  }

  getDownloadUrl(type: PayRateType, uuid: string) {
    const paramKey = this.getParamKey(type);
    const url = this.getBaseDownloadUrl(type);
    return `${url}?${paramKey}=${uuid}`;
  }

  private getBaseDownloadUrl(type: PayRateType) {
    if (type === 'provider') {
      return this.plUrls.urls.providerRateGridDownload;
    }

    if (type === 'preagreement') {
      return this.plUrls.urls.preagreementRateGridDownload;
    }

    return this.plUrls.urls.applicantRateGridDownload;
  }

  private getUrlKey(type: PayRateType) {
    if (type === 'provider') {
      return 'providerrategrid';
    }

    if (type === 'preagreement') {
      return 'preagreementpaygrid';
    }

    return 'applicantPaygrid';
  }

  private getParamKey(type: PayRateType) {
    if (type === 'provider') {
      return 'provider_uuid';
    }

    if (type === 'preagreement') {
      return 'preagreement_uuid';
    }

    return 'applicant';
  }
}
