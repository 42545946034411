import { ActionReducerMap } from '@ngrx/store';
import { BillingState } from './billing.state';
import {
  FetchNextTimesheetEffects,
  SetInvoicePeriodEffects,
} from './next-timesheet/effects';
import { nextTimesheetReducer } from './next-timesheet/next-timesheet.reducer';

export const billingStore: ActionReducerMap<BillingState> = {
  nextTimesheet: nextTimesheetReducer,
};

export const billingEffects = [
  FetchNextTimesheetEffects,
  SetInvoicePeriodEffects,
];
