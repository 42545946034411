import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '@root/src/environments/environment';
import { PLHttpService } from '@root/src/lib-components';
import { Timesheet } from '../models';

export interface TimesheetProgress {
  period_start: string;
  period_end: string;
  assigned_hours: number;
  scheduled_hours: number;
  documented_hours: number;
  timesheet?: Timesheet;
}

export interface TimesheetProgressFilter {
  work_period?: string;
  organization?: string;
}

@Injectable({ providedIn: 'root' })
export class TimesheetProgressDataService {
  constructor(private plHttp: PLHttpService) {}

  getTimesheetProgress(
    filter: TimesheetProgressFilter = {},
  ): Observable<TimesheetProgress> {
    const queryParams = Object.keys(filter)
      .filter(key => filter[key])
      .map(key => `${key}=${filter[key]}`)
      .join('&');

    const httpOpts = {
      method: 'GET',
      url: `${environment.apps.apiWorkplace.url}/api/v1/timesheet-progress/?${queryParams}`,
    };

    return this.plHttp.go(httpOpts).pipe(first());
  }
}
