import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as fromNextTimesheet from '../store/next-timesheet/next-timesheet.selectors';

@Injectable({ providedIn: 'root' })
export class NextTimesheetLoadedResolver implements Resolve<boolean> {
  constructor(private store: Store) {}

  resolve(): Observable<boolean> {
    return this.store.select(fromNextTimesheet.selectLoaded).pipe(
      filter(isLoaded => isLoaded),
      first(),
    );
  }
}
