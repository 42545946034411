import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { PLCommonStoreModule } from '@common/store';
import { FeatureFlagsIdentifyUserEffects } from '@common/feature-flags/store';
import { BillingStoreModule } from '@common/billing/store/billing-store.module';

import { appStore } from './root.store';

@NgModule({
  imports: [
    PLCommonStoreModule,
    BillingStoreModule,
    StoreModule.forRoot(appStore),
    EffectsModule.forRoot([FeatureFlagsIdentifyUserEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 250, // Retains last 25 states
    }),
  ],
})
export class RootStoreModule {}
