import { Injectable, SecurityContext } from '@angular/core';
import { BehaviorSubject , Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import {
  PLCheckistItemStatus,
  PLChecklist,
  PLCheckistItem,
} from './pl-checklist/pl-checklist.types';
import {
  ItemsCollapseStatus,
  OnboardingStepEmailData,
  OnboardingStepsEmailResponseData,
  OnboardingStepsResponseData,
  PLCustomerOnboardingData,
} from './pl-customer-onboarding.types';
import {
  ChecklistItemCodes,
  DEFAULT_CUSTOMER_ONBOARDING_DATA,
} from './pl-customer-onboarding.constants';
import { ToastrService } from 'ngx-toastr';
import { PLTechCheckModalComponent } from './pl-tech-check-modal/pl-tech-check-modal.component';
import { PLPSPTrainingModalComponent } from './pl-psp-training-modal/pl-psp-training-modal.component';
import { PLGraphQLService, PLModalService } from '@root/src/lib-components';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class PLCustomerOnboardingService {
  private userId: string;
  private orgId: string;
  customerOnboardingData: PLCustomerOnboardingData =
    DEFAULT_CUSTOMER_ONBOARDING_DATA;

  private onboardingData = new BehaviorSubject<PLCustomerOnboardingData>(null);
  onboardingData$ = this.onboardingData.asObservable();

  private percentageCompleted = new BehaviorSubject<number>(0);
  percentageCompleted$ = this.percentageCompleted.asObservable();

  private savingData = new BehaviorSubject<boolean>(false);
  savingData$ = this.savingData.asObservable();

  constructor(
    private toastr: ToastrService,
    private plModal: PLModalService,
    private plGraphQL: PLGraphQLService,
    private sanitizer: DomSanitizer,
  ) {}

  setUserId(userId: string) {
    this.userId = userId;
  }

  setOrgId(orgId: string) {
    this.orgId = orgId;
  }

  fetchData() {
    this.plGraphQL
      .query(GQL_QUERY_ONBOARDING_STEPS, {
        organizationId: this.orgId,
      })
      .pipe(first())
      .subscribe((rawData: OnboardingStepsResponseData) => {
        if (rawData.onboardingSteps.length > 0) {
          this.pushData(
            this.transformData(this.customerOnboardingData, rawData),
          );
        } else {
          this.toastr.error(
            'Could not fetch customer onboarding steps. Please try again in a few moments.',
            'Error Fetching Onboarding Steps',
            {
              positionClass: 'toast-bottom-right',
            },
          );
        }
      });
  }

  transformData(
    currentData: PLCustomerOnboardingData,
    rawData?: OnboardingStepsResponseData,
  ): PLCustomerOnboardingData {
    let storageData: ItemsCollapseStatus = this.getFromStorage();

    currentData.checklists.forEach((checklist: PLChecklist) => {
      let itemsExpanded = 0;
      checklist.items.forEach((item: PLCheckistItem) => {
        // Update data from BE
        if (rawData) {
          rawData.onboardingSteps.forEach(step => {
            if (item.code === step.code) {
              item.id = step.id;
              item.status = step.isComplete
                ? PLCheckistItemStatus.COMPLETE
                : PLCheckistItemStatus.INCOMPLETE;
              if (item?.link) {
                let newLink = item.link.replace('ORG_ID', step.organization.id);
                newLink = newLink.replace(
                  'SF_ID',
                  step.organization.salesforceId,
                );
                item.link = this.sanitizer.sanitize(
                  SecurityContext.URL,
                  newLink,
                );
              }
            }
          });
        }

        item.collapsed = storageData[item.code];

        // Update counter of expanded items
        if (!item.collapsed) {
          itemsExpanded++;
        }

        // Transform Links
        if (item?.fn) {
          switch (item.code) {
            case ChecklistItemCodes.TECH_CHECK:
              item.fn = () => {
                this.openTechCheckModal(this.sendEmail.bind(this), item.id);
              };
              break;
            case ChecklistItemCodes.PSP_TRAINING:
              item.fn = () => {
                this.openPSPTrainingModal(this.sendEmail.bind(this), item.id);
              };
              break;
            default:
              break;
          }
        }
      });

      if (itemsExpanded === checklist.items.length) {
        checklist.collapsed = false;
      }

      // Sort list items
      checklist.items = this.getSortedList(checklist);
    });

    return currentData;
  }

  changeItemStatus(
    item: PLCheckistItem,
    newStatus: PLCheckistItemStatus,
  ): void {
    const { code, title } = item;
    this.savingData.next(true);

    let payload = {
      input: {
        onboardingStep: {
          id: item.id,
          isComplete: newStatus === PLCheckistItemStatus.COMPLETE,
        },
      },
    };

    this.plGraphQL.mutate(GQL_MUTATE_ONBOARDING_STEP, payload).subscribe(
      res => {
        this.updateItemStatus(code, newStatus);
        this.updatePercentage(this.customerOnboardingData);
        this.pushData(this.transformData(this.customerOnboardingData));
        this.toastr.success(
          `Marked <i>${title}</i> step ${newStatus.toLowerCase()}`,
          'Status Updated',
          {
            positionClass: 'toast-bottom-right',
            enableHtml: true,
          },
        );
        this.savingData.next(false);
      },
      err => {
        this.updateItemStatus(
          code,
          newStatus !== PLCheckistItemStatus.COMPLETE
            ? PLCheckistItemStatus.COMPLETE
            : PLCheckistItemStatus.INCOMPLETE,
        );
        this.pushData(this.transformData(this.customerOnboardingData));
        this.toastr.error(
          'Could not update completion status. Please try again in a few moments.',
          'Error Updating Status',
          {
            positionClass: 'toast-bottom-right',
          },
        );
        this.savingData.next(false);
      },
    );
  }

  updateItemStatus(
    code: PLCheckistItem['code'],
    newStatus: PLCheckistItemStatus,
  ) {
    this.customerOnboardingData.checklists.forEach((checklist: PLChecklist) => {
      checklist.items.forEach((item: PLCheckistItem) => {
        if (item.code === code) {
          item.status = newStatus;
        }
      });
    });
  }

  collapseList(
    listId: PLChecklist['id'],
    collapsed: PLCheckistItem['collapsed'],
  ): void {
    this.customerOnboardingData.checklists.forEach((checklist: PLChecklist) => {
      if (checklist.id === listId) {
        checklist.items.forEach((item: PLCheckistItem) => {
          item.collapsed = collapsed;
          this.updateItemCollapseStatus(item.code, collapsed);
        });
      }
    });
    this.pushData(this.customerOnboardingData);
  }

  updateItemCollapseStatus(itemKey: string, newStatus: boolean) {
    let data: ItemsCollapseStatus = this.getFromStorage();
    data[itemKey] = newStatus;
    this.saveInStorage(data);
  }

  sendEmail(
    data: OnboardingStepEmailData,
  ): Observable<OnboardingStepsEmailResponseData> {
    const payload = {
      input: {
        onboardingStepEmail: data,
      },
    };
    return this.plGraphQL.mutate(GQL_MUTATE_SEND_EMAIL, payload);
  }

  // Private functions

  private pushData(data: PLCustomerOnboardingData): void {
    this.updatePercentage(data);
    this.onboardingData.next(data);
  }

  private calculatePercentage(checklist: PLChecklist): number {
    return checklist.items.reduce((acc: number, item: PLCheckistItem) => {
      return item.status === PLCheckistItemStatus.COMPLETE
        ? acc + item.value
        : acc;
    }, 0);
  }

  private sortByOrder(a: PLCheckistItem, b: PLCheckistItem): number {
    return a.order > b.order ? 1 : b.order > a.order ? -1 : 0;
  }

  private getSortedList(checklist: PLChecklist): PLCheckistItem[] {
    // Move completed items to bottom of list
    const completed = checklist.items
      .filter((item: PLCheckistItem) => {
        return item.status === PLCheckistItemStatus.COMPLETE;
      })
      .sort(this.sortByOrder);

    const incompleted = checklist.items
      .filter((item: PLCheckistItem) => {
        return item.status === PLCheckistItemStatus.INCOMPLETE;
      })
      .sort(this.sortByOrder);

    return incompleted.concat(completed);
  }

  private updatePercentage(data: PLCustomerOnboardingData) {
    this.percentageCompleted.next(this.calculatePercentage(data.checklists[0]));
  }

  // Modals

  private openTechCheckModal = (
    sendMailFn: (
      data: OnboardingStepEmailData,
    ) => Observable<OnboardingStepsEmailResponseData>,
    stepId: string,
  ): void => {
    this.plModal.create(PLTechCheckModalComponent, { sendMailFn, stepId });
  };

  private openPSPTrainingModal = (
    sendMailFn: (
      data: OnboardingStepEmailData,
    ) => Observable<OnboardingStepsEmailResponseData>,
    stepId: string,
  ): void => {
    this.plModal.create(PLPSPTrainingModalComponent, { sendMailFn, stepId });
  };

  // Storage functions

  private getStorageId(): string {
    return `onboarding-items-status-${this.userId}`;
  }

  private createStorage(): void {
    this.saveInStorage(this.getDefaultStorage());
  }

  private saveInStorage(data: ItemsCollapseStatus): void {
    localStorage.setItem(this.getStorageId(), JSON.stringify(data));
  }

  private getDefaultStorage(): ItemsCollapseStatus {
    let status: ItemsCollapseStatus = {};
    Object.keys(ChecklistItemCodes).forEach((k: string) => {
      status[ChecklistItemCodes[k]] = true;
    });
    return status;
  }

  private getFromStorage(): ItemsCollapseStatus {
    let storageContent: ItemsCollapseStatus = JSON.parse(
      localStorage.getItem(this.getStorageId()),
    ) as ItemsCollapseStatus;
    if (storageContent) {
      return storageContent;
    } else {
      this.createStorage();
      return this.getFromStorage();
    }
  }
}

const GQL_QUERY_ONBOARDING_STEPS = `
    query onboardingSteps(
        $organizationId: String
    ) {
        onboardingSteps(organizationId: $organizationId) {
            edges {
                node {
                    organization {
                        id
                        salesforceId
                    }
                    id
                    name
                    isComplete
                    code
                }
            }
        }
    } 
`;

const GQL_MUTATE_ONBOARDING_STEP = `
    mutation ($input: UpdateOnboardingStepInput!) {
        updateOnboardingStep(input: $input) {
            onboardingStep {
                id
            }
            errors {
                code
                message
                field
            }
        }
    }
`;

const GQL_MUTATE_SEND_EMAIL = `
mutation ($input: CreateOnboardingStepEmailInput!){
    createOnboardingStepEmail(input: $input){
        onboardingStepEmail {
            id
            email
        }
        errors {
            code
            message
            field
        }
    }
}
`;
