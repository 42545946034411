import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { PLHttpService, PLUrlsService, PLToastService } from '@root/index';

import { eversign } from '@root/src/assets/eversign';

@Component({
  selector: 'pl-provider-preagreement-w2',
  templateUrl: './pl-provider-preagreement-w2.component.html',
  styleUrls: ['./pl-provider-preagreement-w2.component.less'],
})
export class PLProviderPreagreementW2Component implements OnInit {
  mode = 'new';
  loading = true;
  isAgreed = false;
  currentYear = new Date().getFullYear();
  private url = '';
  private frameHeight = '0px';
  private iFrame: any = null;

  PROVIDER_RATES_LINK = '/pay-rates';
  PROVIDER_RATES_LINK_QPARAMS = {};

  isSalaried;

  constructor(
    private route: ActivatedRoute,
    private plHttp: PLHttpService,
    private plUrls: PLUrlsService,
    private plToast: PLToastService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((routeParams: any) => {
      if (routeParams.mode) {
        this.mode = routeParams.mode;
      }
    });

    this.route.params.pipe(first()).subscribe((params: any) => {
      const id = params['id'];

      this.url = `${this.plUrls.urls.preagreementW2s}${id}/`;

      this.plHttp
        .get('', {}, this.url)
        .pipe(first())
        .subscribe((res: any) => {
          this.loading = false;

          // 200 with no data means "already agreed"
          if (res === null) {
            this.handleComplete(this);
          } else {
            this.setFrameHeight();
            this.isSalaried = res.salaryed;
            if (this.mode === 'renewal') {
              this.PROVIDER_RATES_LINK = '/provider-pay-rates';
              this.PROVIDER_RATES_LINK_QPARAMS = {
                start_date: res.as_of_date,
              };
            } else {
              this.PROVIDER_RATES_LINK_QPARAMS = {
                preagreement_uuid: id,
              };
            }

            setTimeout(() => {
              const that = this;

              this.iFrame = eversign.open({
                url: res.document_url,
                containerID: 'eversign-container',
                width: '100%',
                height: this.frameHeight,
                events: {
                  signed() {
                    const data: any = {
                      uuid: '-', // need this to get PATCH instead of PUT...
                      agree: true,
                    };
                    that.loading = true;
                    that.plHttp
                      .save('', data, that.url)
                      .pipe(first())
                      .subscribe(() => {
                        that.handleComplete(that);
                      });
                  },
                },
              });
            }, 1000);
          }
        });
    });
  }

  @HostListener('window:resize', ['$event']) onResize() {
    this.setFrameHeight();
  }

  private setFrameHeight() {
    const headlineHeight = 75;
    const lowerAreaHeight = 75;
    this.frameHeight =
      window.innerHeight - headlineHeight - lowerAreaHeight + 'px';

    if (this.iFrame) this.iFrame.height = this.frameHeight;
  }

  private handleComplete(obj: any) {
    obj.isAgreed = true;
    obj.loading = false;
  }
}
