<div
  *ngIf="showMessage"
  class="qualifications"
  [ngClass]="assignmentStatusClasses[status]"
>
  <div class="missing-requirements-header">
    <mat-icon
      id="icon"
      aria-hidden="false"
      aria-label="warning"
      [ngStyle]="{ color: iconColor }"
      [ngClass]="{ 'material-icons-outlined': iconType.includes('outline') }"
      >{{ iconType }}</mat-icon
    >

    <div>
      <span class="qualifications-header-tittle">{{ messageTittle }}</span>
    </div>
  </div>
  <div id="message" class="qualifications-message">
    {{ message }}
  </div>
  <ng-container *ngIf="unmetRequirements.length > 0">
    <ul *ngFor="let item of unmetRequirements">
      <div>
        <div class="columns">
          <div class="col right">
            <div
              *ngFor="let option of item.options"
              class="gray-darker margin-b unmet-requirement"
            >
              <ng-container
                *ngIf="!checkInstruction(item, option); else instructions"
              >
                <li class="option-align">{{ option }}</li>
              </ng-container>
              <ng-template #instructions>
                <li class="option-align">
                  <a [href]="getInstructionUrl(item, option)">{{ option }}</a>
                </li>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </ul>
  </ng-container>
</div>
