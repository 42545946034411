<div class="pl-client-referral-reassign">
  <pl-modal-header-wrapper headerText="Assign provider">
    <div class="padding-b">
      <h4>
        <b>
          Select {{ discipline }} provider to assign the referrals and services
        </b>
      </h4>
    </div>
    <div class="margin-b">
      Caseload and hour information below is specific to the organization to
      which the selected students belong.
    </div>
    <div class="providers margin-large-b">
      <div class="header-row padding flexbox">
        <div class="header-col name-col">Provider</div>
        <div class="header-col center">Caseload</div>
        <div class="header-col center">Est. remaining hours</div>
      </div>
      <div class="rows">
        <pl-dot-loader *ngIf="loading" [align]="'center'"></pl-dot-loader>
        <div
          *ngIf="!loading && !providers.length"
          class="padding-nl-tb padding-lr"
        >
          {{ organizationName() }} needs an assigned {{ discipline }} Provider
          to serve this referral.
        </div>
        <div
          *ngFor="let provider of providers"
          class="provider-info body-row padding flexbox pointer"
        >
          <div class="body-col name-col">
            <pl-input-radio
              [(model)]="selectedProviderUserId"
              [value]="provider.userId"
              [label]="provider.name"
              [name]="'providerRadio'"
            ></pl-input-radio>
          </div>
          <div class="body-col center table">
            <span class="table-cell">{{ provider.caseloadCountByOrg }}</span>
          </div>
          <div class="body-col center table">
            <span class="table-cell">{{
              provider.remainingAvailableHours
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="margin-large-b">
      Need another provider?
      <a href="{{ this.requestLink }}" target="_blank"
        >Request Provider Assignment.</a
      >
    </div>

    <div class="referral-rows">
      <div *ngFor="let referral of referrals">
        <div class="padding-nl margin-large-b referral">
          <div class="referral-header">
            <div>
              <div class="padding-small-b">
                <h4>
                  <b>
                    {{ referral?.client?.firstName }}
                    {{ referral?.client?.lastName }}
                  </b>
                </h4>
              </div>
              <div>
                {{ referral.organizationName }}
              </div>
            </div>
            <div class="referral-header-item">
              <div class="referral-header-icon">
                <pl-icon
                  *ngIf="getReferralDetailStat(referral)"
                  class="gray-dark"
                  [svg]="'chevron-down'"
                  [scale]="0.6"
                  [verticalAlign]="'-2px'"
                  (click)="toggleReferralDetailStat(referral)"
                ></pl-icon>
                <pl-icon
                  *ngIf="!getReferralDetailStat(referral)"
                  class="gray-dark"
                  [svg]="'chevron-left'"
                  [scale]="0.6"
                  [verticalAlign]="'-2px'"
                  (click)="toggleReferralDetailStat(referral)"
                ></pl-icon>
              </div>
            </div>
          </div>

          <div
            *ngIf="getReferralDetailStat(referral)"
            class="flexbox multi-column margin-t"
          >
            <div *ngIf="isDirectTherapyProductType(referral)" class="flex1">
              <div class="label-value">
                <div class="label">Duration</div>
                <div class="value">
                  <pl-is-available [condition]="referral.duration !== null">
                    {{ referral.duration | i18nPlural : durationPluralization }}
                  </pl-is-available>
                </div>
              </div>
              <div class="label-value">
                <div class="label">Frequency</div>
                <div class="value">
                  <pl-is-available [condition]="referral.frequency !== null">
                    {{ referral.frequency }}
                    {{ referral.frequency > 1 ? 'times' : 'time' }}
                  </pl-is-available>
                </div>
              </div>
              <div class="label-value">
                <div class="label">Interval</div>
                <div class="value">
                  <pl-is-available [condition]="referral.interval !== null">
                    {{ referral.interval | plOptions : intervalOptions }}
                  </pl-is-available>
                </div>
              </div>
              <div class="label-value">
                <div class="label">Group/Individual</div>
                <div class="value">
                  <pl-is-available
                    [condition]="referral.grouping !== 'unspecified'"
                  >
                    {{ referral.grouping | plOptions : groupingOptions }}
                  </pl-is-available>
                </div>
              </div>
            </div>
            <div *ngIf="!isDirectTherapyProductType(referral)" class="flex1">
              <div class="label-value">
                <div class="label">Evaluation due date</div>
                <div class="value">
                  <pl-is-available [condition]="referral.dueDate !== null">
                    {{ referral.dueDate | date : 'longDate' }}
                  </pl-is-available>
                </div>
              </div>
              <div class="label-value">
                <div class="label">Signature date</div>
                <div class="value">
                  <pl-is-available
                    [condition]="referral.assessmentPlanSignedOn !== null"
                  >
                    {{ referral.assessmentPlanSignedOn | date : 'longDate' }}
                  </pl-is-available>
                </div>
              </div>
              <div class="label-value">
                <div class="label">Meeting date</div>
                <div class="value">
                  <pl-is-available [condition]="referral.meetingDate !== null">
                    {{ referral.meetingDate | date : 'longDate' }}
                  </pl-is-available>
                </div>
              </div>
            </div>
            <div class="flex1">
              <div class="label-value">
                <div class="label">Referral</div>
                <div class="value">
                  <pl-is-available
                    [condition]="productTypeName(referral) !== null"
                  >
                    {{ productTypeName(referral) }}
                  </pl-is-available>
                </div>
              </div>
              <div class="label-value">
                <div class="label">Dedicated services</div>
                <div class="value">
                  <pl-is-available [condition]="referral.isDedicated !== null">
                    {{ referral.isDedicated ? 'Yes' : 'No' }}
                  </pl-is-available>
                </div>
              </div>
              <div class="label-value">
                <div class="label">Specialty</div>
                <div class="value">
                  <pl-is-available
                    [condition]="
                      specialtiesLabel(referral) !== null &&
                      specialtiesLabel(referral) !== ''
                    "
                  >
                    {{ specialtiesLabel(referral) }}
                  </pl-is-available>
                </div>
              </div>
              <div class="label-value">
                <div class="label">Current provider</div>
                <div class="value">
                  <pl-is-available [condition]="referral.provider !== null">
                    {{ referral?.provider?.firstName }}
                    {{ referral?.provider?.lastName }}
                  </pl-is-available>
                </div>
              </div>
              <ng-container
                *ngIf="
                  (showGeneralEducationIndicator$ | async) &&
                  isBMHReferral(referral) &&
                  isPartOfDirectServices(referral)
                "
              >
                <div class="label-value">
                  <div class="label">Gen Ed</div>
                  <div class="value">
                    {{ referral.generalEducation | plYesNo }}
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="buttons">
      <div class="buttons-primary"></div>
      <div class="buttons-secondary">
        <button (click)="cancel()" pl-button class="x-qa-reassign-cancel-btn">
          Cancel
        </button>
        <button
          pl-button
          class="success x-qa-reassign-confirm-btn"
          [disabled]="!selectedProviderUserId || loading || submiting"
          (click)="reassign()"
        >
          Confirm provider
        </button>
      </div>
    </div>
  </pl-modal-header-wrapper>
</div>
