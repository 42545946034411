<div class="card pl-provider-details-basic-information">
  <div class="header">
    <h3>Basic information</h3>
  </div>
  <table *ngIf="provider">
    <tbody>
      <tr class="name" *ngIf="!!name">
        <th scope="row">Name</th>
        <td>{{ name }}</td>
      </tr>
      <tr class="pronunciation" *ngIf="!!pronunciation">
        <th scope="row">Name pronunciation</th>
        <td>{{ pronunciation }}</td>
      </tr>
      <tr class="birthday" *ngIf="!!birthDay && userType !== 'CUSTOMER'">
        <th scope="row">Birthday</th>
        <td>{{ birthDay }}</td>
      </tr>
      <tr class="timezone" *ngIf="!!timeZone">
        <th scope="row">Timezone</th>
        <td>{{ timeZone }}</td>
      </tr>
    </tbody>
  </table>
</div>
