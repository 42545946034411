<div class="card pl-provider-details-tech-check-details">
  <div class="header">
    <h3>Tech Check details</h3>
  </div>

  <table>
    <tbody>
      <tr>
        <th scope="row">Completion date</th>
        <td class="completion_date">
          {{ provider.techcheck?.completion_date | date : 'MM/dd/yy' }}
        </td>
      </tr>
      <tr>
        <th scope="row">Status</th>
        <td class="status">
          <div [ngClass]="getTechCheckStatus()" class="icon-wrapper">
            <mat-icon>{{ getTechCheckIcon() }}</mat-icon>
          </div>
          {{ provider.techcheck?.status }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
