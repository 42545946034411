export const reassignEvaluationMutation = /* GraphQL */ `
  mutation reassignEvaluation(
    $reassignEvaluationInput: ReassignEvaluationInput!
  ) {
    reassignEvaluation(input: $reassignEvaluationInput) {
      errors {
        code
        field
        message
      }
      status
      referral {
        id
      }
      evaluation {
        id
      }
    }
  }
`;
