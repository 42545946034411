import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { PLTimesheetProgressBarService } from '../../../schedule/components/pl-timesheet-progress-bar/services/pl-timesheet-progress-bar.service';
import { PLTimesheetProgressStatusService } from '../../../schedule/components/pl-timesheet-progress-bar/services/pl-timesheet-progress-status.service';

@Component({
  selector: 'pl-dedicated-service-hours-card',
  templateUrl: './pl-dedicated-service-hours-card.component.html',
  styleUrls: ['./pl-dedicated-service-hours-card.component.less'],
})
export class PlDedicatedServiceHoursCardComponent implements OnInit {
  scheduledPercentage = 0;
  documentedPercentage = 0;
  documentedHoursFixed = 0;
  assignedHoursFixed = 0;
  scheduledHoursFixed = 0;
  startDate: string;
  endDate: string;
  hasData = false;
  daysUntilDueDate$ = this.statusService.daysUntilDueDate$;
  status$ = this.statusService.status$;
  isTimesheetValid$ = this.statusService.isTimesheetValid$;
  timesheetId$ = this.statusService.timesheetId$;
  constructor(
    private progressService: PLTimesheetProgressBarService,
    private statusService: PLTimesheetProgressStatusService,
  ) {}

  ngOnInit() {
    this.progressService.progressData$
      .pipe(
        tap(data => {
          this.hasData = true;
          this.assignedHoursFixed = data.assigned_hours;
          this.scheduledHoursFixed = data.scheduled_hours;
          this.documentedHoursFixed = data.documented_hours;
          this.startDate = data.period_start;
          this.endDate = data.period_end;
          this.scheduledPercentage = this.getPercentage(data.scheduled_hours);
          this.documentedPercentage = this.getPercentage(data.documented_hours);
        }),
      )
      .subscribe();
  }

  private getPercentage(hours: number) {
    if (hours >= this.assignedHoursFixed) {
      return 100;
    }

    return Math.round((hours / this.assignedHoursFixed) * 100);
  }
  organizationFilterChanged(organization: string) {
    this.progressService.filter({ organization });
  }
}
