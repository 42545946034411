import { Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { PLUrlsService } from '@root/index';

@Injectable({
  providedIn: 'root',
})
export class PLLearnUponLauncherService {
  constructor(
    private router: Router,
    private serializer: UrlSerializer,
    private plUrls: PLUrlsService,
  ) {}

  getDashboardUrl(full?: boolean) {
    let path = this.serializer.serialize(
      this.router.createUrlTree(['launch', 'learnupon']),
    );
    if (full) {
      path = `${window.location.origin}/c${path}`;
    }
    return path;
  }

  getCourseUrl(courseId: string, full?: boolean) {
    let path = this.serializer.serialize(
      this.router.createUrlTree(['launch', 'learnupon'], {
        queryParams: {
          course: courseId,
        },
      }),
    );
    if (full) {
      path = `${window.location.origin}/c${path}`;
    }
    return path;
  }
}
