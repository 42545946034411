<div *ngIf="provider" class="card pl-provider-details-additional-details">
  <div class="header">
    <h3>Additional details</h3>
    <span *ngIf="modified" class="last-updated"
      >Last updated {{ modified }}</span
    >
  </div>

  <table *ngIf="provider">
    <tbody>
      <tr
        class="specialties"
        *ngIf="
          provider.areas_of_specialty && provider.areas_of_specialty.length
        "
      >
        <th scope="row">Specialties</th>
        <td>
          <div
            *ngIf="
              provider.areas_of_specialty && provider.areas_of_specialty.length
            "
            class="chips"
          >
            <mat-chip-list>
              <mat-chip
                class="specialties"
                disableRipple
                matRipple
                *ngFor="let specialty of provider.areas_of_specialty"
                >{{ specialty.name }}</mat-chip
              >
            </mat-chip-list>
          </div>
        </td>
      </tr>
      <tr
        class="languages"
        *ngIf="provider.languages && provider.languages.length"
      >
        <th scope="row">Languages</th>
        <td>
          <div
            *ngIf="provider.languages && provider.languages.length"
            class="chips"
          >
            <mat-chip-list>
              <mat-chip
                class="languages"
                disableRipple
                matRipple
                *ngFor="let language of provider.languages"
                >{{ language.name }}</mat-chip
              >
            </mat-chip-list>
          </div>
        </td>
      </tr>
      <tr
        class="systems"
        *ngIf="systemsExperienced && systemsExperienced.length"
      >
        <th scope="row">System experience</th>
        <td>
          <div
            *ngIf="systemsExperienced && systemsExperienced.length"
            class="chips"
          >
            <mat-chip-list>
              <mat-chip
                disableRipple
                matRipple
                class="systems"
                *ngFor="let experience of systemsExperienced"
                >{{ experience.name }}</mat-chip
              >
            </mat-chip-list>
          </div>
        </td>
      </tr>
      <tr class="about-me" *ngIf="provider?.about_me">
        <th scope="row">Comments from {{ provider?.first_name }}</th>
        <td>
          <ng-container
            *ngTemplateOutlet="aboutMeTemplate; context: { provider: provider }"
          ></ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #aboutMeTemplate let-provider="provider">
  <p class="about-me" *ngIf="provider?.about_me">
    {{ provider?.about_me }}
  </p>
</ng-template>
