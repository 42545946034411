module.exports = /* GraphQL */ `
  query tasks {
    tasks {
      edges {
        node {
          owners {
            edges {
              node {
                id
                read
                firstReadDate
                isComplete
                user {
                  username
                  id
                }
              }
            }
          }
          message
          severity
          priority
          created
          taskType {
            code
            id
          }
          actionUrl
          completedOn
          age
          dueDate
        }
      }
    }
  }
`;
