<div class="header">
  <div class="title">Upcoming events</div>
  <mat-icon class="calendar-icon" [routerLink]="['/schedule/calendar']"
    >calendar_month</mat-icon
  >
</div>
<div class="schedule-card" #scheduleCard>
  <div class="scroll-container" #scrollContainer>
    <div class="hours-column">
      <div
        *ngFor="let hour of hours"
        class="hour-label"
        [style.top.px]="hour.position"
      >
        {{ hour.label }}
      </div>
      <div
        *ngFor="let tick of majorTicks"
        class="tick-line major"
        [style.top.px]="tick"
      ></div>
      <div
        *ngFor="let tick of minorTicks"
        class="tick-line"
        [style.top.px]="tick"
      ></div>
    </div>
    <div class="events-column">
      <div
        *ngFor="let event of events"
        class="event-block"
        [ngClass]="event.colorClass"
        [class.overlapping]="event.inServiceBlock"
        [class.translucent]="isHovering"
        [style.top.px]="event.top"
        [style.height.px]="event.height"
        [style.marginLeft.px]="event.marginLeft"
        [style.width.px]="totalWidth - event.marginLeft"
        [style.zIndex]="event.zIndex"
        [routerLink]="['/schedule/calendar']"
        [queryParams]="{ view: 'day', start: currentDate, end: currentDate }"
        (mouseenter)="isHovering = true"
        (mouseleave)="isHovering = false"
      >
        <div *ngIf="getEventDuration(event) > 15; else shortEvent">
          <div class="title">
            {{ event.title }}
          </div>
          <div class="start-end">
            {{ event.duration }}
          </div>
        </div>
        <ng-template #shortEvent>
          <div class="title">
            {{ event.title }}
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
