import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppStore } from '@app/appstore.model';
import {
  BMH_THERAPY_FREQUENCY,
  CLINICAL_PRODUCT_TYPE,
  TRACKING_TYPE_OPTIONS,
} from '@common/constants/index';
import { PLClinicalProductCode, PLProviderTypeCode } from '@common/enums';
import { PLClinicalServiceTypeLongName } from '@common/enums/pl-clinical-service-types.enum';
import { Option } from '@common/interfaces';
import { BMHTherapyFrequency } from '@common/interfaces/pl-referral';
import {
  ClinicalTalkFrequency,
  toClinicalTalkFrequency,
  isUpdatedClinicalTalkFrequency,
} from '@common/services/pl-client-referral';
import { serviceIntervalOptions } from '@common/services/pl-client-service';
import { CurrentUserService } from '@modules/user/current-user.service';
import { User } from '@modules/user/user.model';
import { PLConfirmDialogService, PLMayService } from '@root/index';
import { PLServiceSaveService } from '../pl-service-save.service';

@Component({
  selector: 'pl-service-save-identify',
  templateUrl: './pl-service-save-identify.component.html',
  styleUrls: ['./pl-service-save-identify.component.less'],
})
export class PLServiceSaveIdentifyComponent implements OnInit, OnDestroy {
  areServiceTimesRequired = false;
  serviceFormVals: any;
  referral: any = {};
  isEdit = false;
  isLoading = true;
  revalidate = false;
  serviceOpts: any[] = [];
  providerTypeName: string;

  CLINIC_PROD = CLINICAL_PRODUCT_TYPE;

  bmhServiceFrequency: string = null; // The frequency of the services for Behavior and Trauma
  bmhFrequencyOptions: BMHTherapyFrequency[] = [
    BMH_THERAPY_FREQUENCY.TWICE_WEEKLY,
    BMH_THERAPY_FREQUENCY.ONCE_WEEKLY,
  ];

  currentUser: User;
  confirmedClinicalTalkFrequencies: ClinicalTalkFrequency[] = [];

  formCtrl: FormGroup = new FormGroup({});
  formCtrlDirect: FormGroup = new FormGroup({});
  formCtrlEval: FormGroup = new FormGroup({});

  evaluationTypeOpts: any[] = [
    { value: 'INITIAL', label: 'Initial' },
    { value: 'PARENT_REQUEST', label: 'Parent Request' },
    { value: 'IEP_TEAM_REQUEST', label: 'IEP Team Request' },
    { value: 'RE_EVALUATION', label: 'Re-Evaluation' },
    { value: 'TRIENNIAL', label: 'Triennial' },
    { value: 'TRANSFER', label: 'Transfer / Transition' },
    { value: 'DISMISSAL_EXIT', label: 'Dismissal / Exit' },
    // { value: 'IEE', label: 'IEE' },
  ];
  intervalOpts = serviceIntervalOptions;

  productType: string = null;
  isShortTerm: string[] = [];

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private valid = false;

  isRsmFieldDisplayed = false;

  trackingTypeOptions: Option[] = TRACKING_TYPE_OPTIONS;

  calculateMinutesLink =
    'https://presencelearning.helpjuice.com/71381-clinical-support/calculating-therapy-minutes';

  constructor(
    private confirmDialogService: PLConfirmDialogService,
    private currentUserService: CurrentUserService,
    private plMayService: PLMayService,
    private plServiceSave: PLServiceSaveService,
    private store: Store<AppStore>,
  ) {}

  ngOnInit() {
    this.store.select('featureFlags').subscribe((lookup: any) => {
      if (lookup.flags.zendeskSupportLinks) {
        this.calculateMinutesLink =
          'https://support.presence.com/hc/en-us/articles/27203673200531-Calculate-Therapy-Minutes';
      }
    });
    combineLatest([
      this.currentUserService.getCurrentUser(),
      // this might emit multiple times
      this.plServiceSave.getSharedData(),
      this.plServiceSave.getReferral(),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([user, data, referral]: any) => {
        this.currentUser = user;

        // Update the list of options to diplay in the Service list in the UI
        this.processServiceOptsAndUpdate(data, referral, user);
        this.isShortTerm = data.serviceFormVals.isShortTerm
          ? ['isShortTerm']
          : [];
        this.isEdit = data.isEdit;
        this.revalidate = data.revalidateStep.identify;
        this.isRsmFieldDisplayed = Boolean(data.serviceFormVals?.isRsmSchool);

        this.validate();
        this.isLoading = false;
      });

    this.plServiceSave.nextStepConfirmationRequested
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.confirmNextStep());
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnChanges() {
    this.validate();
  }

  /**
   * Toggling between BMH frequencies and any other product type frequencies
   * Frequency refers to the timing of the service that will be given
   * else checks for an edit if it's BMH product type.
   */
  bmhOrNotBmhFrequency(data: any, referral: any, user: any) {
    if (referral) {
      const isBmhProduct =
        referral &&
        referral.productType &&
        (referral.productType.code === this.CLINIC_PROD.CODE.BIG ||
          referral.productType.code === this.CLINIC_PROD.CODE.TG);

      if (isBmhProduct) {
        this.updateBmhRadioButtonSelected(referral);
      } else {
        // pl-input fields [required] input doesn't update in a straightforward way, so
        // include setting this value inside implicit isLoading guard obviates the
        // need to update the form components, since this value will not change dynamically.
        this.areServiceTimesRequired =
          this.plMayService.isValidatingServiceTimes(user);
      }
    } else {
      if (data.isEdit) {
        for (let index = 0; index < this.serviceOpts.length; index++) {
          const serviceOpt = this.serviceOpts[index];
          const behaviorId = serviceOpt.value === this.serviceFormVals.service;
          const serviceOptLowerCase = serviceOpt.label.toLowerCase();

          const bmhOption =
            serviceOptLowerCase === this.CLINIC_PROD.NAME.BIG_LOWER_CASE2 ||
            serviceOptLowerCase === this.CLINIC_PROD.NAME.TG_LOWER_CASE2;

          if (behaviorId && bmhOption) {
            this.productType =
              serviceOptLowerCase === this.CLINIC_PROD.NAME.TG_LOWER_CASE2
                ? this.CLINIC_PROD.CODE.TG
                : this.CLINIC_PROD.CODE.BIG;

            this.areServiceTimesRequired = false;

            this.updateBmhRadioButtonSelected({
              duration: this.serviceFormVals.directServiceSession.duration,
            });

            break;
          }
        }
      }
    }
  }

  formReferralLabel(referral: any) {
    if (referral.id) {
      referral.xLabel =
        `${referral.providerType.longName} - ${referral.xType} @ ` +
        `${referral.xLocation} - Created ${referral.xCreated}`;
    }
    return referral;
  }

  getUpdates(data: any, referral: any = null, user: any = null) {
    const keys = [
      'confirmedClinicalTalkFrequencies',
      'serviceFormVals',
      'serviceOpts',
      'referral',
      'providerTypeName',
    ];

    keys.forEach((key: string) => {
      if (data[key]) {
        this[key] = data[key];
      }
    });

    this.formReferralLabel(this.referral);

    // Toggles between frequencies for Behavioral or other types of products
    this.bmhOrNotBmhFrequency(data, referral, user);
  }

  /**
   * In charge of displaying certain HTML sections of this component
   */
  isBehaviorOrTraumaGroup(): boolean {
    const isBehaviorOrTrauma =
      this.productType === this.CLINIC_PROD.CODE.BIG ||
      this.productType === this.CLINIC_PROD.CODE.TG;

    return isBehaviorOrTrauma;
  }

  onChangeServiceWrapper(evt: any) {
    const params = Object.assign(
      {},
      {
        stepValid: this.valid,
        stepKey: 'identify',
      },
      evt,
    );
    this.plServiceSave.onChangeService(params).subscribe((data: any) => {
      this.getUpdates(data);
      this.validate();
    });
  }

  onChangeEvalTypeWrapper(evt: any) {
    this.plServiceSave.onChangeEvalType(evt);
    this.validate();
  }

  onChangeIsShortTerm(evt: any) {
    this.serviceFormVals.isShortTerm = evt.includes('isShortTerm');
  }

  /**
   * In the future this functionality will be overriden (or removed) since the BE will handle this
   * For now the direction from business was to hard code the 'Service' drop-down options in the FE
   *
   * When the Product Type is Supervision, Trauma or Big; display only these options in the Service list.
   * When the Product Type is anything else; remove Supervision, Truma or Big accordingly from the Service list
   *
   * @param data Where the Service options are.
   * @param referral Where the Product Type code is; either supervision, trauma, big, or any other.
   */
  // TODO: this method needs a refactor
  processServiceOptsAndUpdate(data: any, referral: any, user: any): void {
    if (data?.serviceOpts.length) {
      if (referral?.productType) {
        // Means that we are converting a Referral to a Service
        this.productType = referral.productType.code; // Assignation needed for other places in this class

        const isDirSvcProductType =
          referral.productType.code === PLClinicalProductCode.DIR_SVC;
        const svIndex = data.serviceOpts.findIndex(
          (option: Option) => option.label === this.CLINIC_PROD.NAME.SV,
        );

        if (isDirSvcProductType) {
          switch (referral.providerType.code) {
            case PLProviderTypeCode.MHP:
              data.serviceOpts = data.serviceOpts.filter(
                ({ label }) =>
                  label === PLClinicalServiceTypeLongName.BMH_SVCS ||
                  label === PLClinicalServiceTypeLongName.BMH_CONSULTATION_SVCS,
              );
              break;

            case PLProviderTypeCode.PA:
              data.serviceOpts = data.serviceOpts.filter(
                ({ label }) =>
                  label === PLClinicalServiceTypeLongName.BMH_SVCS ||
                  label === PLClinicalServiceTypeLongName.BMH_CONSULTATION_SVCS,
              );
              break;

            default:
              const traumaIndex = data.serviceOpts.findIndex(
                (option: Option) => option.label === this.CLINIC_PROD.NAME.TG,
              );
              const behaviorIndex = data.serviceOpts.findIndex(
                (option: Option) => option.label === this.CLINIC_PROD.NAME.BIG,
              );

              data.serviceOpts = data.serviceOpts.filter(({ label }) => {
                return (
                  label !== PLClinicalServiceTypeLongName.OT_SV &&
                  label !== PLClinicalServiceTypeLongName.SLT_SV
                );
              });

              if (svIndex >= 0 || behaviorIndex >= 0) {
                const startIndex =
                  svIndex >= behaviorIndex ? svIndex : behaviorIndex;
                const endIndex = traumaIndex > 1 ? traumaIndex : 1; // For removing Trauma

                data.serviceOpts.splice(startIndex, endIndex);
                data.serviceOpts = data.serviceOpts;
              }
          }
        } else {
          const therapySvIndex = data.serviceOpts.findIndex(({ label }) => {
            return (
              label === PLClinicalServiceTypeLongName.OT_SV ||
              label === PLClinicalServiceTypeLongName.SLT_SV
            );
          });
          const isSvProductType =
            referral.productType.code === PLClinicalProductCode.SV;

          // TODO: refactor below conditions to switch statements
          if (isSvProductType && therapySvIndex >= 0) {
            data.serviceOpts = [data.serviceOpts[therapySvIndex]];
          } else if (isSvProductType && svIndex >= 0) {
            data.serviceOpts = [data.serviceOpts[svIndex]];
          }
        }
      }

      this.getUpdates(data, referral, user);
      if (this.serviceOpts?.length === 1) {
        this.displayDefaultServiceOption(this.serviceOpts[0].value);
      }
    }
  }

  private confirmNextStep(): void {
    const confirmStep = () =>
      this.plServiceSave.confirmNextStep({ currentStepKey: 'identify' });

    const clinicalTalkFrequency = toClinicalTalkFrequency(
      this.serviceFormVals.directServiceSession,
    );

    const isConfirmationRequired = isUpdatedClinicalTalkFrequency(
      this.confirmedClinicalTalkFrequencies,
      clinicalTalkFrequency,
    );

    if (isConfirmationRequired) {
      this.confirmDialogService.show({
        header: 'Changing Scheduling Information',
        content: `You are changing information that affects scheduling. Do you want to proceed with this change?`,
        primaryLabel: 'Yes',
        secondaryLabel: 'No',
        primaryCallback: () => {
          // Add the current frequency values to the list of confirmed values. If the
          // user returns to this step before saving the service, only force another
          // confirmation if the newest form values differ from those confirmed previously.
          this.confirmedClinicalTalkFrequencies.push(clinicalTalkFrequency);

          confirmStep();
        },
      });
    } else {
      confirmStep();
    }
  }

  /**
   * Display the first `Service` of the list of services.
   * Display it in the `Service` select box.
   *
   * @param serviceValueID The unique value that identifies a `Service`.
   */
  displayDefaultServiceOption(serviceValueID: string): void {
    this.serviceFormVals.service = serviceValueID;
    this.onChangeServiceWrapper({
      model: serviceValueID,
      oldVal: '',
    });
  }

  /**
   * TODO: same logic exists at `PLClientReferralSaveReferralComponent.loadForEditReferralWhenBmhType` consider merging.
   * Updates the selected radio button of the Behavioral/Trauma product frequency.
   */
  updateBmhRadioButtonSelected(referral: any): void {
    let freqTypeCode: any = null;

    if (referral.duration) {
      freqTypeCode =
        this.referral.duration === 30
          ? BMH_THERAPY_FREQUENCY.TWICE_WEEKLY.value
          : BMH_THERAPY_FREQUENCY.ONCE_WEEKLY.value;

      this.bmhServiceFrequency = freqTypeCode; // Select the radio button in the UI
    }

    this.updateFrequencyWhenBmhType(freqTypeCode);
  }

  /**
   * Updating manually the referral object.
   * Manually updated 'cause in this scenario instead of three select boxes there are two radio buttons.
   * Update made based on fixed options that hold the values of the select boxes for Duration, Frequency and Interval.
   */
  updateFrequencyWhenBmhType(freqTypeCode: string): void {
    if (freqTypeCode) {
      const twiceWeekly = { duration: 30, frequency: 2 };
      const onceWeekly = { duration: 60, frequency: 1 };
      const frequency: any =
        freqTypeCode === BMH_THERAPY_FREQUENCY.TWICE_WEEKLY.value
          ? twiceWeekly
          : onceWeekly;

      this.serviceFormVals.directServiceSession.interval = 'weekly';
      this.serviceFormVals.directServiceSession.duration = frequency.duration;
      this.serviceFormVals.directServiceSession.frequency = frequency.frequency;
    } else {
      this.bmhServiceFrequency = null;

      if (this.serviceFormVals && this.serviceFormVals.directServiceSession) {
        delete this.serviceFormVals.directServiceSession.duration;
        delete this.serviceFormVals.directServiceSession.frequency;
        delete this.serviceFormVals.directServiceSession.interval;
      }
    }
  }

  validate() {
    // Need timeout for form valid state to update.
    setTimeout(() => {
      let valid = false;
      // Disabled fields make form invalid.. Need to fix form inputs to use .valid on form.
      if (this.serviceFormVals?.service) {
        valid = true;
        if (
          this.serviceFormVals.serviceCategory === 'evaluation_with_assessment'
        ) {
          if (
            !this.formCtrlEval.valid &&
            this.formCtrlEval.status !== 'DISABLED'
          ) {
            valid = false;
          }

          if (
            this.isRsmFieldDisplayed &&
            this.serviceFormVals.evaluationServiceSession.endDate &&
            this.serviceFormVals.evaluationServiceSession.startDate &&
            this.serviceFormVals.evaluationServiceSession.endDate <
              this.serviceFormVals.evaluationServiceSession.startDate
          ) {
            valid = false;
          }
        } else if (this.serviceFormVals.serviceCategory === 'therapy') {
          if (
            !this.formCtrlDirect.valid &&
            this.formCtrlDirect.status !== 'DISABLED'
          ) {
            valid = false;
          }
          if (
            this.serviceFormVals.directServiceSession.endDate &&
            this.serviceFormVals.directServiceSession.startDate &&
            this.serviceFormVals.directServiceSession.endDate <
              this.serviceFormVals.directServiceSession.startDate
          ) {
            valid = false;
          }
        }

        if (this.isRsmFieldDisplayed && !this.serviceFormVals.rsmServiceType) {
          valid = false;
        }

        if (!this.serviceFormVals.trackingType) {
          valid = false;
        }
      }

      this.valid = valid;
      this.plServiceSave.onChangeStepValid({ valid, stepKey: 'identify' });
    }, 250);
  }
}
