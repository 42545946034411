import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { map } from 'rxjs/operators';
import { getInvoiceErrorCount } from '@common/billing/utils/errors.utils';
import { PLTimesheetProgressBarService } from './pl-timesheet-progress-bar.service';

export type TimesheetProgressStatus = 'open' | 'submitted';

@Injectable({ providedIn: 'root' })
export class PLTimesheetProgressStatusService {
  timesheet$ = this.timesheetBarService.progressData$.pipe(
    map(data => data.timesheet),
  );
  daysUntilDueDate$ = this.timesheet$.pipe(
    map(timesheet => {
      const dueDateRaw = timesheet?.work_period_expanded.due_date;
      if (!dueDateRaw) {
        return null;
      }
      const dueDate = dayjs(dueDateRaw, 'YYYY-MM-DD');
      const todayRaw = dayjs().format('YYYY-MM-DD');
      const today = dayjs(todayRaw, 'YYYY-MM-DD');

      return dueDate.diff(today, 'day');
    }),
  );
  status$ = this.timesheet$.pipe(
    map(timesheet => {
      return timesheet?.status === 'submitted' ? 'submitted' : 'open';
    }),
  );

  timesheetId$ = this.timesheet$.pipe(map(timesheet => timesheet?.uuid));

  isTimesheetValid$ = this.timesheet$.pipe(
    map(timesheet => {
      const errorCount = getInvoiceErrorCount(timesheet.errors);
      return timesheet ? !errorCount : true;
    }),
  );

  constructor(private timesheetBarService: PLTimesheetProgressBarService) {}
}
