module.exports = /* GraphQL */ `
  query providerProfile($userId: ID) {
    providerProfile(userId: $userId) {
      id
      user {
        id
        firstName
        lastName
        username
      }
      email
      email2
      phone
      providerTypes {
        edges {
          node {
            id
            isActive
            code
            shortName
            longName
          }
        }
      }
      timezone
      caseloadCount
      billingAddress {
        street
        city
        postalCode
        state
        country
      }
      hourlyRate
      hourlyRateTier1
      hourlyRateTier2
      contractServicesRate
      adjustedHourlyRate
      providerSubStatus
      isOnboardingWizardComplete
      salesforceId
      isW2
      employmentStatus
    }
  }
`;
