<div
  #plModalContentWrapper
  class="pl-modal"
  [ngClass]="classesCont"
  [style.z-index]="zIndex"
  (click)="clickBackground($event)"
  (keydown)="onKeyDown($event)"
>
  <div class="pl-modal-center">
    <div #plModalContent class="pl-modal-content" (click)="handleClick($event)">
      <div #plModalPlaceholder></div>
    </div>
  </div>
</div>
