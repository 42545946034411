import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import {
  PLCheckistItem,
  PLCheckistItemStatus,
} from './pl-checklist/pl-checklist.types';
import { PLCustomerOnboardingService } from './pl-customer-onboarding.service';
import { PLCustomerOnboardingData } from './pl-customer-onboarding.types';

@Component({
  selector: 'pl-customer-onboarding',
  templateUrl: 'pl-customer-onboarding.component.html',
  styleUrls: ['pl-customer-onboarding.component.less'],
})
export class PLCustomerOnboardingComponent {
  @Input() hideChecklists: boolean;

  customerOnboardingData: PLCustomerOnboardingData;
  protected destroyed$ = new Subject<boolean>();

  constructor(private onboardingService: PLCustomerOnboardingService) {
    this.onboardingService.onboardingData$.subscribe(
      (data: PLCustomerOnboardingData) => {
        this.customerOnboardingData = data;
      },
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onItemStatusChange(event: {
    item: PLCheckistItem;
    newStatus: PLCheckistItemStatus;
  }): void {
    this.onboardingService.changeItemStatus(event.item, event.newStatus);
  }
}
