<div
  class="pl-side-nav"
  [class.collapsed]="!mouseInside && navCollapsed"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <div>
    <pl-side-nav-link
      *ngFor="let link of links"
      [link]="link"
      [navCollapsed]="!mouseInside && navCollapsed"
    >
    </pl-side-nav-link>
  </div>
  <div>
    <pl-resources
      [userType]="userType"
      [navCollapsed]="!mouseInside && navCollapsed"
    ></pl-resources>
  </div>
</div>
