import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgProgressModule } from 'ngx-progressbar';
import { PLCommonModule } from '@root/src/app/common';
import {
  PLModalModule,
  PLInputModule,
  PLButtonModule,
} from '@root/src/lib-components';
import { PLDemandNotesComponent } from './pl-demand-notes.component';
import { PLDemandNotesService } from './pl-demand-notes.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgProgressModule,
    PLButtonModule,
    PLCommonModule,
    PLInputModule,
    PLModalModule,
    ReactiveFormsModule,
  ],
  exports: [PLDemandNotesComponent],
  declarations: [PLDemandNotesComponent],
  providers: [PLDemandNotesService],
})
export class PLDemandNotesModule {}
