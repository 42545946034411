<div class="room-card">
  <div class="header">
    <div class="title">Your Room</div>
    <a [href]="roomUrl">
      <mat-icon class="copy-icon">link</mat-icon>
    </a>
  </div>
  <div class="content">
    <div class="room-url">
      <div>{{ roomBase }}/</div>
      <div>{{ currentUser?.username }}</div>
    </div>
    <div class="copy-container" (click)="copyRoomUrl()">
      <mat-icon class="copy-icon">content_copy</mat-icon>
    </div>
  </div>
</div>
