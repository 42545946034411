import { featureFlagsReducer } from '@app/common/store/feature-flags/feature-flags.reducer';
import { app } from '@app/stores/app.store';
import { backLink } from '@app/stores/back-link.store';
import { clientsList } from '@app/stores/clients-list.store';
import { currentClientUser } from '@app/stores/current-client-user.store';
import { currentClient } from '@app/stores/current-client.store';
import { providerOnboardingStore } from '@app/stores/provider-onboarding.store';
import { notesReportDownloads } from '@common/services/reports/pl-notes-report-download.store';
import { handbookRoutingActionStore } from '@modules/handbook/handbook-routing-action.store';
import { currentUser } from '@modules/user/current-user.store';

export const appStore = {
  currentUser,
  currentClient,
  currentClientUser,
  clientsList,
  backLink,
  app,
  notesReportDownloads,
  providerOnboardingStore,
  handbookRoutingActionStore,
  featureFlags: featureFlagsReducer,
};
