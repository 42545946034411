<div class="modal-container">
  <div class="modal-header">
    <div class="header-content">
      <span class="header-tittle">Contact your CLSM</span>
      <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="separator"></div>
  <div class="modal-body">
    <span class="body-tittle"
      >Select what you need to contact your CLSM about:</span
    >
    <div class="radio-buttons-container">
      <mat-radio-group aria-labelledby="options" [(ngModel)]="selectedOption">
        <mat-radio-button
          class="radio-buttons"
          *ngFor="let option of options | async"
          [value]="option"
        >
          {{ option.label }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="divider"></div>
  <div mat-dialog-actions class="action-buttons">
    <button
      [mat-dialog-close]="true"
      mat-stroked-button
      class="pl-action-button action-button"
    >
      Cancel
    </button>
    <button
      id="confirm-button"
      [mat-dialog-close]="selectedOption"
      [disabled]="!selectedOption"
      mat-flat-button
      color="primary"
    >
      Confirm
    </button>
  </div>
</div>
