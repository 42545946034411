<div class="pl-provider-availability">
  <div
    class="pl-provider-availability-wrapper"
    *ngIf="!loading"
    [ngClass]="{ 'aling-new-view': showPageNewOverView }"
  >
    <pl-availability-view
      [showTabs]="showTabs"
      [isCAMView]="true"
      [blockReload]="false"
      [provider]="provider"
    >
    </pl-availability-view>
    <!-- HOURS CARDS -->
    <div class="cards">
      <ng-container *ngFor="let item of cardItems">
        <ng-container
          *ngTemplateOutlet="cardTemplate; context: { item: item }"
        ></ng-container>
      </ng-container>
    </div>
  </div>
  <div *ngIf="loading" class="padding-xlarge-tb">
    <pl-dot-loader></pl-dot-loader>
  </div>
</div>

<!-- CARD TEMPLATE -->
<ng-template #cardTemplate let-item="item">
  <div class="card">
    <span class="value">{{ item.value }}</span>
    <div class="body">
      <span class="body-title">{{ item.title }}</span>
      <span class="body-description">{{ item.description }}</span>
    </div>
  </div>
</ng-template>
