import { NgModule } from '@angular/core';
// NgRx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { featureKey, reducer } from './invoice.store';
import { InvoiceEffects } from './invoice.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(featureKey, reducer),
    EffectsModule.forFeature([InvoiceEffects]),
  ],
})
export class PLInvoiceStoreModule {}

export {
  selectInvoicePeriod,
  selectInvoicePeriodLoaded,
  PLSetInvoicePeriod,
} from './invoice.store';
