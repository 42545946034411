import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { PLClientIdService } from '@common/services/pl-client-id.service';

@Component({
  selector: 'pl-client-id',
  templateUrl: './pl-client-id.component.html',
  styleUrls: ['./pl-client-id.component.less'],
})
export class PLClientIdComponent implements OnInit, OnChanges {
  @Input() externalId: string;
  @Input() internalId: string;
  @Input() linkTarget: string = '_self';

  mode: string = '';

  ngOnInit() {
    this.updateMode();
  }

  ngOnChanges() {
    this.updateMode();
  }

  updateMode() {
    this.mode = PLClientIdService.getModeFromId(this.externalId);
  }
}
