import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { PLHttpService } from '@root/src/lib-components';

export interface PastPayRateResponse {
  school_year: string;
  download_url: string;
}

@Injectable({ providedIn: 'root' })
export class PLProviderPastPayRatesService {
  constructor(private plHttp: PLHttpService) {}

  fetchPastPayRates(uuid: string): Observable<PastPayRateResponse[]> {
    return this.plHttp
      .get('pastproviderrategrid', { provider_uuid: uuid }, null, {
        suppressError: true,
      })
      .pipe(first());
  }
}
