import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import dayjs from 'dayjs';

@Component({
  selector: 'pl-provider-details-basic-information',
  templateUrl: './pl-provider-details-basic-information.component.html',
  styleUrls: [
    './pl-provider-details.common.less',
    './pl-provider-details-basic-information.component.less',
  ],
})
export class PLProviderDetailsBasicInformationComponent
  implements OnInit, OnChanges
{
  @Input() provider: any;
  @Input() userType: string;

  name: string;
  pronunciation: string;
  birthDay: string;
  timeZone: string;

  ngOnInit(): void {
    this.updateBasicInformation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.provider) {
      this.updateBasicInformation();
    }
  }

  private updateBasicInformation(): void {
    if (!this.provider) {
      return;
    }
    this.pronunciation = this.provider.name_pronunciation;
    this.timeZone = this.provider.timezone;
    if (this.provider.first_name && this.provider.last_name) {
      this.name = this.provider.first_name + ' ' + this.provider.last_name;
    }
    if (this.provider.birth_date) {
      const birthDate = dayjs(this.provider.birth_date);
      this.birthDay = birthDate.format('MMMM DD');
    }
  }
}
