import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgInlineNgPluginService } from '@root/src/build/svg-inline-ng-plugin.service';

@Injectable({ providedIn: 'root' })
export class CustomMatIconsService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer,
    private svgInlineNgPluginService: SvgInlineNgPluginService,
  ) {}

  register() {
    for (const iconName of Object.keys(this.svgInlineNgPluginService.svgs)) {
      const svg = this.svgInlineNgPluginService.svgs[iconName];
      this.matIconRegistry.addSvgIconLiteral(
        iconName,
        this.domSanitzer.bypassSecurityTrustHtml(svg.html),
      );
    }
  }
}
