import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppStore } from '@app/appstore.model';
import { selectCurrentUser } from '@common/store';
import { PLUrlsService } from '@root/index';

@Component({
  selector: 'pl-room-card',
  templateUrl: './pl-room-card.component.html',
  styleUrls: ['./pl-room-card.component.less'],
})
export class PlRoomCardComponent implements OnDestroy {
  roomUrl = '';
  roomBase = '';
  currentUser: any = { username: '' };
  destroyed$ = new Subject<boolean>();

  constructor(
    private _snackBar: MatSnackBar,
    private store: Store<AppStore>,
    private plUrls: PLUrlsService,
  ) {
    this.store
      .select(selectCurrentUser)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((user: any) => {
        this.currentUser = user;
        this.roomBase = this.plUrls.urls.roomFE;
        this.roomUrl = `${this.roomBase}/${this.currentUser.username}`;
      });
  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  copyRoomUrl() {
    navigator.clipboard.writeText(this.roomUrl).then(() => {
      this._snackBar.open('Room URL copied to clipboard', 'Close', {
        duration: 5000,
      });
    });
  }
}
