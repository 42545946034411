import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pl-quick-links-card',
  templateUrl: './pl-quick-links-card.component.html',
  styleUrls: ['./pl-quick-links-card.component.less'],
})
export class PlQuickLinksCardComponent implements OnInit {
  @Input() userEmail: string;
  @Input() organizations: { name: string; id: string }[];
  @Input() showOrgHandbooks: boolean;
  equipmentOrderUrl: string;

  ngOnInit(): void {
    this.equipmentOrderUrl = `https://www.tfaforms.com/4846346?email=${encodeURIComponent(
      this.userEmail,
    )}`;
  }
}
