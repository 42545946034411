<div class="pl-demand-notes">
  <pl-modal-header-wrapper
    [headerHtml]="headerHtml"
    (onCloseModal)="closeModal()"
  >
    <pl-data-loader [loading]="loading"> </pl-data-loader>
    <ng-container *ngIf="!loading">
      <ng-container *ngIf="!enableForm">
        <ng-container *ngIf="demandNote?.notes; else emptyState">
          <ng-container *ngTemplateOutlet="notes"></ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="enableForm">
        <ng-container *ngTemplateOutlet="form"></ng-container>
      </ng-container>
    </ng-container>
  </pl-modal-header-wrapper>
</div>

<!-- Empty State -->
<ng-template #emptyState>
  <div class="pl-demand-notes-empty-state">
    <span class="message">No notes have been added yet</span>
    <span class="separator"></span>
    <div class="button-actions">
      <button pl-button type="button" class="info" (click)="addNote()">
        Add Note
      </button>
    </div>
  </div>
</ng-template>

<!-- Notes -->
<ng-template #notes>
  <div class="pl-demand-notes-item">
    <span class="modified"> {{ getLastModified(demandNote) }} </span>
    <pre class="message">{{ demandNote?.notes }}</pre>
  </div>
  <span class="separator"></span>
  <div class="button-actions">
    <button
      pl-button
      type="button"
      class="info"
      (click)="editNote(demandNote?.notes)"
    >
      Edit
    </button>
  </div>
</ng-template>

<!-- Form -->
<ng-template #form>
  <form [formGroup]="demandNotesForm">
    <div class="form-content">
      <pl-input-textarea
        [formCtrl]="demandNotesForm"
        [label]="inputLabel"
        [(model)]="demandNotesFormModel.notes"
        [height]="'200px'"
      >
      </pl-input-textarea>
      <a class="clear" (click)="clearNote()">Clear</a>
    </div>
    <br />
    <div class="button-actions">
      <button
        pl-button
        type="button"
        class="info"
        (click)="sendNote(demandNotesForm)"
      >
        Save
      </button>
      <button pl-button type="button" class="secondary" (click)="cancelNote()">
        Cancel
      </button>
    </div>
  </form>
</ng-template>
