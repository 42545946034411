import { Injectable, Renderer2, Inject, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PLUserNavigationService } from './facade';

@Injectable({ providedIn: 'root' })
export class PLUserGuidingService {
  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    public facade: PLUserNavigationService,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  runScript(text: string, inBody = true) {
    this.createScript({ text }, inBody);
  }

  createScript(
    properties: { text?: string; src?: string; async?: boolean },
    inBody = true,
  ) {
    const script = this.renderer.createElement('script');
    if (properties.text) {
      script.text = properties.text;
    } else {
      script.src = properties.src;
      if (properties.async) {
        script.async = true;
      }
    }
    this.renderer.appendChild(
      inBody ? this._document.body : this._document.head,
      script,
    );
  }
}
