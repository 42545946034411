import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PLCustomerOnboardingService } from '../pl-customer-onboarding.service';
import {
  PLCheckistItemStatus,
  PLChecklist,
  PLCheckistItem,
  CollapseStatus,
} from './pl-checklist.types';

@Component({
  selector: 'pl-checklist',
  templateUrl: 'pl-checklist.component.html',
  styleUrls: ['pl-checklist.component.less'],
})
export class PLChecklistComponent implements OnInit {
  @Input() checklist: PLChecklist;
  @Output() onItemStatusChange: EventEmitter<{
    item: PLCheckistItem;
    newStatus: PLCheckistItemStatus;
  }> = new EventEmitter();

  collapsed: PLCheckistItem['collapsed'] = true;
  toggleMsg: string = 'Expand all';

  constructor(private onboardingService: PLCustomerOnboardingService) {}

  ngOnInit() {
    this.collapsed = this.checklist.collapsed;
    this.toggleMsg = this.getToggleMessage();
  }

  changeItemStatus(event: {
    item: PLCheckistItem;
    newStatus: PLCheckistItemStatus;
  }) {
    this.onItemStatusChange.emit(event);
  }

  toggleItems(id: PLChecklist['id']) {
    this.collapsed = !this.collapsed;
    this.onboardingService.collapseList(id, this.collapsed);
    this.toggleMsg = this.getToggleMessage();
  }

  private getToggleMessage() {
    return `${
      this.collapsed ? CollapseStatus.EXPAND : CollapseStatus.COLLAPSE
    } all`;
  }
}
