import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PLIconModule } from '../pl-icon/index';

import { PLHeaderComponent } from './pl-header.component';

@NgModule({
  imports: [PLIconModule, CommonModule],
  exports: [PLHeaderComponent],
  declarations: [PLHeaderComponent],
  providers: [],
})
export class PLHeaderModule {}
