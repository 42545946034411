import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { first, switchMap, defaultIfEmpty, catchError } from 'rxjs/operators';
import { Observable, Subject, of, forkJoin, throwError } from 'rxjs';
import { CLINICAL_PRODUCT_TYPE, REFERRAL_REASON } from '@common/constants';
import { FeatureFlagName, FeatureFlagsService } from '@common/feature-flags';
import { Option } from '@common/interfaces';
import { DIRECT_SERVICES_PRODUCTS, PL_REFERRAL_STATE } from '@common/enums';
import {
  referralIntervalOptions,
  referralGroupingOptions,
  referralProductTypeMap,
} from '@common/services/pl-client-referral';
import { PLReferral, PLReferralsService } from '../pl-referrals.service';
import { PLClientEvaluationReassignService } from '@modules/clients/pl-client-evaluation-reassign/pl-client-evaluation-reassign.service';
import { PLClientService } from '@modules/clients/pl-client.service';
import {
  PLClientReferralMatchReferralService,
  Provider,
} from '../pl-client-referral-match/pl-client-referral-match-referral.service';

import { serviceDurationPluralizationMapping } from '@common/services/pl-client-service';
import { SPECIALTIES_LONG_NAME } from '@common/assigment-machine/specialties.constants';

@Component({
  selector: 'pl-client-referral-reassign',
  templateUrl: './pl-client-referral-reassign.component.html',
  styleUrls: ['./pl-client-referral-reassign.component.less'],
  providers: [
    PLClientReferralMatchReferralService,
    PLClientEvaluationReassignService,
    PLClientService,
  ],
})
export class PLClientReferralReassignComponent implements OnInit, OnDestroy {
  @Input() referrals: PLReferral[] = [];
  @Input() providers: Provider[] = [];
  @Input() currentUserEmail: string;
  requestLink: string;
  discipline: string;
  submiting: boolean;
  loading: boolean;
  selectedProviderUserId: string;
  closed$ = new Subject<{ type: 'submit' | 'cancel'; errors?: any[] }>();
  showGeneralEducationIndicator$: Observable<boolean>;

  referralDetailState = new Map<string, boolean>();

  readonly durationPluralization = serviceDurationPluralizationMapping;
  readonly intervalOptions: Option[] = referralIntervalOptions;
  readonly groupingOptions: Option[] = referralGroupingOptions;

  constructor(
    private featureFlagsService: FeatureFlagsService,
    private plClientSvc: PLClientService,
    private plReferralsService: PLReferralsService,
    private plEvaluationReassign: PLClientEvaluationReassignService,
    private plClientReferralMatchService: PLClientReferralMatchReferralService,
  ) {}

  ngOnInit() {
    this.getProviders();
    this.referrals.sort((r1, r2) =>
      r1.client?.firstName
        .concat(r1.client?.lastName, ' ')
        .localeCompare(r2.client?.firstName.concat(r2.client?.lastName, ' ')),
    );
    this.initReferralDetailStat();
    this.showGeneralEducationIndicator$ =
      this.featureFlagsService.isFeatureEnabled(
        FeatureFlagName.showGeneralEducationIndicator,
      );
  }

  ngOnDestroy() {
    this.closed$.complete();
  }

  private close(submit = false, errors?: any[]) {
    this.closed$.next({ errors, type: submit ? 'submit' : 'cancel' });
  }

  productTypeName(referral: PLReferral): string {
    return referralProductTypeMap[referral.productTypeCode];
  }

  getProviders() {
    const { discipline, id, locationName } = this.referrals[0];
    this.discipline = discipline;
    this.loading = true;
    this.requestLink =
      `https://www.tfaforms.com/4649022?` +
      `tfa_874=${locationName}&` +
      `tfa_875=${encodeURIComponent(this.currentUserEmail)}`;
    this.plClientReferralMatchService.getProviders(id).subscribe(providers => {
      this.loading = false;
      this.providers = providers.sort(
        (a, b) => b.remainingAvailableHours - a.remainingAvailableHours,
      );
    });
  }

  initReferralDetailStat() {
    this.referrals.forEach((referral, index) => {
      this.referralDetailState.set(referral.id, false);
    });
  }

  getReferralDetailStat(referral: PLReferral): boolean {
    return this.referralDetailState.get(referral.id);
  }

  toggleReferralDetailStat(referral: PLReferral) {
    this.referralDetailState.set(
      referral.id,
      !this.referralDetailState.get(referral.id),
    );
  }

  isDirectTherapyProductType(referral: PLReferral): boolean {
    return referral.productTypeCode === 'direct_service';
  }

  isBMHReferral(referral: PLReferral): boolean {
    return this.plClientSvc.isBMHReferral(referral);
  }

  isPartOfDirectServices(referral: PLReferral): boolean {
    return DIRECT_SERVICES_PRODUCTS.includes(referral.productTypeCode);
  }

  specialtiesLabel(referral: PLReferral) {
    const specialtiesDict = {
      isAac: SPECIALTIES_LONG_NAME.AAC,
      isAsl: SPECIALTIES_LONG_NAME.ASL,
      isDhh: SPECIALTIES_LONG_NAME.DHH,
      isVi: SPECIALTIES_LONG_NAME.VI,
    };
    const result = Object.keys(specialtiesDict).reduce((prev, current) => {
      if (referral[current]) {
        const label = specialtiesDict[current];
        return !prev ? label : `${prev},${label}`;
      }
      return prev;
    }, '');
    return result;
  }

  organizationName(): string {
    return this.referrals[0].organizationName;
  }

  cancel() {
    this.close();
  }

  reassign() {
    const providerUserId = this.selectedProviderUserId;
    const reasonToUnmatch = REFERRAL_REASON.INCORRECT_REFERRAL; // Should user select a reason?
    this.submiting = true;

    const reassignReferralsCalls = this.referrals.map(
      ({ id: referralId, state, clientService, productTypeCode, client }) => {
        const productName = referralProductTypeMap[productTypeCode];
        const params = { referralId, providerUserId };
        let observer: Observable<any>;
        switch (state) {
          case PL_REFERRAL_STATE.Converted:
            observer =
              productName === CLINICAL_PRODUCT_TYPE.NAME.EVAL
                ? this.plEvaluationReassign.makeMatch(
                    clientService.id,
                    providerUserId,
                    '',
                    true,
                  )
                : this.plReferralsService.reassignReferral(
                    clientService.id,
                    providerUserId,
                    true,
                  );
            break;
          case PL_REFERRAL_STATE.Proposed:
          case PL_REFERRAL_STATE.Open:
          case PL_REFERRAL_STATE.Unmatched:
            observer = this.plReferralsService.matchReferral(params);
            break;
          case PL_REFERRAL_STATE.Matched:
            const unmatchParams = { referralId, reasonToUnmatch };
            observer = this.plReferralsService
              .unmatchReferral(unmatchParams, true)
              .pipe(
                switchMap(({ unmatchReferral }) => {
                  if (!unmatchReferral.errors) {
                    return this.plReferralsService.matchReferral(params, true);
                  }
                  return of({ error: unmatchReferral.errors });
                }),
                catchError(err => {
                  console.log(err);
                  return throwError(err.data.unmatchReferral.errors);
                }),
              );
            break;
        }
        if (!observer) {
          return of({});
        }
        return observer.pipe(
          first(),
          catchError(err => {
            let errorMessage = '';
            const clientName = `${client.lastName}, ${client.firstName}`;
            if (err && err.message) {
              errorMessage = err.message.replace('GraphQL error: ', '');
            } else if (Array.isArray(err) && err[0] && err[0].message) {
              errorMessage = err[0].message;
            }
            errorMessage = errorMessage.replace(
              new RegExp('client', 'ig'),
              clientName,
            );
            if (!errorMessage) {
              errorMessage = clientName;
            }
            return of({ error: errorMessage });
          }),
        );
      },
    );

    forkJoin(reassignReferralsCalls)
      .pipe(defaultIfEmpty())
      .subscribe(
        (res: any[]) => {
          const errors = res.filter(r => !!r).filter(r => r.error);
          this.submiting = false;
          this.close(true, errors);
        },
        () => {
          this.submiting = false;
        },
      );
  }
}
