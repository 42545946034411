<div class="pl-assignment-manager-returning-details">
  <div class="returning-reasoning-title">Returning provider preferences</div>
  <div class="returning-reasoning-details">
    <div *ngIf="mainServiceLine">
      {{ mainServiceLine | plServiceLineTitle }}
    </div>
    <div *ngIf="mainServiceLine">•</div>
    <div>{{ schoolYearName }}</div>
  </div>
  <div class="section-break"></div>
  <div class="returning-reasoning-preferences">
    <div>
      <div class="returning-reasoning-preference-name">
        {{ assignment.provider }}
      </div>
      <div *ngIf="!assignment.providerPreferences?.length">
        <ng-container
          *ngTemplateOutlet="preferenceDetailsTemplate"
        ></ng-container>
      </div>
      <div *ngFor="let preference of assignment.providerPreferences">
        <ng-container
          *ngTemplateOutlet="
            preferenceDetailsTemplate;
            context: { $implicit: preference }
          "
        ></ng-container>
      </div>
    </div>
    <div>
      <div class="returning-reasoning-preference-name">
        {{ orgName }}
      </div>
      <div *ngIf="!assignment.organizationPreferences?.length">
        <ng-container
          *ngTemplateOutlet="
            preferenceDetailsTemplate;
            context: { $implicit: preference }
          "
        ></ng-container>
      </div>
      <div *ngFor="let preference of assignment.organizationPreferences">
        <ng-container
          *ngTemplateOutlet="
            preferenceDetailsTemplate;
            context: { $implicit: preference }
          "
        ></ng-container>
      </div>
    </div>
  </div>

  <div class="returning-reasoning-content">
    <span>{{ assignment.returningReasoning }}</span>
  </div>
</div>

<ng-template #preferenceDetailsTemplate let-preference>
  <div class="returning-reasoning-preference">
    <div
      class="returning-reasoning-preference-icon {{
        preference?.preference === 'yes'
          ? 'yes'
          : preference?.preference === 'no'
          ? 'no'
          : ''
      }}"
    >
      <mat-icon>
        {{
          preference?.preference === 'yes'
            ? 'check'
            : preference?.preference === 'no'
            ? 'close'
            : 'remove'
        }}
      </mat-icon>
    </div>
    <span *ngIf="preference?.preference; else noPreference">
      {{ preference?.preference | plCapitalizeFirst
      }}{{ preference?.comments ? ',' : '' }}
      {{ preference?.comments }}
    </span>
    <ng-template #noPreference>No preference</ng-template>
  </div>
</ng-template>
