import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plServiceLineTitle',
})
export class PLServiceLineTitlePipe implements PipeTransform {
  private readonly SERVICELINE_MAPPING = {
    'BMH Direct Therapy and Assessments by an MHP':
      'Direct therapy & assessments',
    'BMH Direct Therapy and Assessment by an SP':
      'Direct therapy & assessments',
    'OT Direct Therapy and Assessment by an OT': 'Direct therapy & assessments',
    'SLT Supervision by an SLP': 'Supervision',
    'SLT Direct Therapy and Assessments by an SLP':
      'Direct therapy & assessments',
    'PA Assessments and Consultation by an SP':
      'Psychoeducational assessments & consultation',
    'OT Supervision by an OT': 'Supervision',
    'Psychoeducational Assessment by Ed Diag': 'Psychoeducational assessments',
    'PT Evaluation': 'Evaluation',
    'PT Therapy': 'Therapy',
  };

  transform(serviceline: any): string {
    return this.SERVICELINE_MAPPING[serviceline]
      ? this.SERVICELINE_MAPPING[serviceline]
      : serviceline;
  }
}
