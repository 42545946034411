<div
  class="pl-student-status-help"
  [ngClass]="{ 'debug-show-div': util.showDivs(_state) }"
>
  <div class="debug-component-name" *ngIf="util.showDivs(_state)">
    {{ _state.componentName }} {{ _state.ID }}
  </div>

  <pl-modal-header-wrapper [headerText]="'Student Status Definitions'">
    <div class="definitions">
      <div class="definition margin-xlarge-b">
        <b>Onboarding</b> - The student has been referred to Presence for the
        current school year, but no session has been documented.
      </div>
      <div class="definition margin-xlarge-b">
        <b>In Service</b> - Service has documented direct/consult/supervision in
        the last 60 days - students with more than one service will appear as
        "in service" as long as one service has started. Please refer to the
        student profile for more information about individual services
      </div>
      <div class="definition margin-xlarge-b">
        <b>Not In Service</b> - Service status is completed, canceled or idle
      </div>
    </div>
  </pl-modal-header-wrapper>
</div>
