import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs from 'dayjs';
import { forkJoin } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  FeatureFlagName,
  FeatureFlagsService,
} from '@root/src/app/common/feature-flags';
import { PLGraphQLService, PLHttpService } from '@root/src/lib-components';
import { PLAssignmentStatusEnum } from '../../assignment-manager/pl-assignment-manager.model';
import { parseDurationString } from '../../schedule/utils/assignments';
import { PLProviderService } from '../pl-provider.service';

@UntilDestroy()
@Component({
  selector: 'pl-provider-availability',
  templateUrl: './pl-provider-availability.component.html',
  styleUrls: ['./pl-provider-availability.component.less'],
})
export class PLProviderAvailabilityComponent implements OnInit {
  showTabs = false;
  provider: any;
  loading = false;
  showPageNewOverView = false;
  cardItems: { value: number; title: string; description: string }[] = [];

  constructor(
    private router: Router,
    private plProvider: PLProviderService,
    private featureFlagsService: FeatureFlagsService,
    private plHttp: PLHttpService,
    private plGraphQL: PLGraphQLService,
  ) {}

  ngOnInit(): void {
    const curUuid = this.router.url.split('/')[2];
    this.loading = true;
    this.plProvider
      .getProvider(curUuid)
      .pipe(untilDestroyed(this), first())
      .subscribe((res: any) => {
        this.provider = res.provider;
        this.loading = false;
        this.getCardItemsData(this.provider.user.id);
      });

    this.featureFlagsService
      .isFeatureEnabled(FeatureFlagName.showNewProviderOverviewPage)
      .subscribe(enabled => {
        this.showPageNewOverView = enabled;
      });
  }

  getCardItemsData(provider: string): void {
    let GQL_GET_AVAILABILITY = `
            query getProviderAvailability($providerId: UUID){
                availabilityPreference(providerId: $providerId){
                    maxWeeklyHours
                }
            }`;

    const $assignmentProposals = this.plHttp
      .get('assignmentProposals', { provider: provider, limit: 1000 })
      .pipe(
        map((r: any) => {
          return r.results;
        }),
      );

    const $availability = this.plGraphQL
      .query(GQL_GET_AVAILABILITY, { providerId: provider })
      .pipe(first());

    forkJoin([$assignmentProposals, $availability]).subscribe(
      ([assignmentProposals, availability]: [any[], any]) => {
        const DESIRED_HOURS =
          availability?.availabilityPreference?.maxWeeklyHours || 0;
        const ASSIGNED_HOURS = assignmentProposals.reduce((acc, val) => {
          let hours;
          // Following the a similar logic as in provider assignments page
          // edu-clients/src/app/modules/assignment-manager/pl-provider-assignments.component.ts
          switch (val.status) {
            case PLAssignmentStatusEnum.INITIATED:
            case PLAssignmentStatusEnum.PENDING:
            case PLAssignmentStatusEnum.ACTIVE:
              hours = val.hours;
              break;
            case PLAssignmentStatusEnum.COMPLETED:
              if (dayjs().diff(val.end_date) < 0) {
                hours = val.hours;
              }
              break;
          }

          if (hours) {
            return (
              acc +
              Math.round(
                (dayjs.duration(parseDurationString(hours)).asHours() +
                  Number.EPSILON) *
                  100,
              ) /
                100
            );
          } else return acc;
        }, 0);

        const _REMAINING_HOURS = DESIRED_HOURS - ASSIGNED_HOURS;
        const REMAINING_HOURS = _REMAINING_HOURS > 0 ? _REMAINING_HOURS : 0;

        this.cardItems = [
          {
            value: DESIRED_HOURS,
            title: 'Total Hours Desired',
            description:
              'Hours the Provider wants to work per week. Includes direct and indirect time',
          },
          {
            value: ASSIGNED_HOURS,
            title: 'Total Hours Assigned',
            description:
              'Hours the Provider is currently assigned to work, across all sites.',
          },
          {
            value: REMAINING_HOURS,
            title: 'Total Remaining Hours ',
            description: 'Hours the Provider has left available to be assigned',
          },
        ];
      },
    );
  }
}
