import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { PLHttpService } from '@root/src/lib-components';
import { PaginatedParams, PaginatedResponse } from '../../interfaces';
import { Invoice } from '../models';

export interface InvoicesPreviewResponse extends Invoice {
  timeblocks: any[];
  catchedError?: boolean; // Only used when server errors are thrown
}

export interface InvoiceListParams extends PaginatedParams {
  status__in?: string;
}

export type InvoiceListResponse = PaginatedResponse<Invoice>;

@Injectable({ providedIn: 'root' })
export class InvoicesDataService {
  constructor(private plHttp: PLHttpService) {}

  list(params?: InvoiceListParams): Observable<InvoiceListResponse> {
    const httpOpts = {
      url: `${environment.apps.apiWorkplace.url}/api/v3/invoices/`,
      method: 'GET',
      params,
    };
    return this.plHttp.go(httpOpts);
  }

  get(uuid: String): Observable<Invoice> {
    const httpOpts = {
      url: `${environment.apps.apiWorkplace.url}/api/v3/invoices/${uuid}/`,
      params: { expand: 'summary' },
      method: 'GET',
    };
    return this.plHttp.go(httpOpts);
  }

  retract(uuid: string): Observable<Invoice> {
    const httpOpts = {
      url: `${environment.apps.apiWorkplace.url}/api/v3/invoices/${uuid}/retract/`,
      method: 'PUT',
    };
    return this.plHttp.go(httpOpts);
  }
}
