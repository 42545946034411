import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { selectCurrentUser } from '@app/common/store';
import { OverlappingEventsService } from '@common/schedule/services';
import {
  overlappingValidationMessages,
  OverlappingValidations,
} from '../constants/overlapping-validations.constants';
import { PLEvent } from '../models';
import { selectEvents } from '../store/schedule';

@Injectable({ providedIn: 'root' })
export class OverlappingValidationsService {
  user$ = this.store.select(selectCurrentUser);
  events$ = this.store.select(selectEvents);

  constructor(
    private store: Store,
    private overlappingEventsService: OverlappingEventsService,
  ) {}

  logError(errorMessage: OverlappingValidations, appointment: PLEvent) {
    combineLatest([this.events$, this.user$])
      .pipe(take(1))
      .subscribe(([events, user]) => {
        const overlappingEvents =
          this.overlappingEventsService.getOverlappingEvents(
            appointment,
            events,
            this.overlappingEventsService.getEventId(appointment),
          );

        if (
          errorMessage &&
          overlappingValidationMessages.includes(errorMessage) &&
          overlappingEvents.length === 0
        ) {
          const exceptionExtra = {
            billing_code: appointment.billing_expanded?.code,
            user: {
              uuid: user.uuid,
              timezone: user.xProvider?.timezone,
            },
            appointment: {
              uuid: appointment.uuid,
              start: appointment.start,
              end: appointment.end,
              original_start: appointment.original_start,
              original_end: appointment.original_end,
            },
            event: {
              uuid: appointment.event.uuid,
              start: appointment.event.start,
              end: appointment.event.end,
              end_recurring_period: appointment.event.end_recurring_period,
              recurrence_frequency: appointment.event.recurrence_frequency,
              recurrence_params: appointment.event.recurrence_params,
            },
          };

          Sentry.captureMessage(errorMessage, {
            extra: exceptionExtra,
          });
        }
      });
  }
}
