export enum OverlappingValidations {
  organization = 'Overlapping events must be at the same organization.',
  serviceModel = 'Overlapping events must have the same billing type (e.g. Dedicated, Hourly, etc.)',
  specialty = 'Overlapping events must be for the same specialties.',
  dedicated = 'Dedicated services blocks cannot overlap each other.',
}

export const overlappingValidationMessages = Object.values(
  OverlappingValidations,
);
