<div class="modal-content">
  <div class="flex flex-col">
    <div class="flex w-full">
      <h2>Opportunity details</h2>
      <button mat-icon-button (click)="closeDetailsOverlay()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="details">
      <div class="opportunity">{{ opportunityName }}</div>
      <div class="service-line">{{ serviceLine | plServiceLineTitle }}</div>
    </div>
    <div class="w-full content" *ngIf="!loading">
      <div class="grid-container">
        <div class="grid-item">
          <div class="grid-item-key">Caseload age</div>
          <div class="grid-item-value caseload-age">
            {{ results['caseload_age'] | plFormatDelimiter }}
          </div>
        </div>
        <div class="grid-item">
          <div class="grid-item-key">Level of student support needed</div>
          <div class="grid-item-value level-of-student-support-needed">
            {{ results['level_of_student_support_needed'] | plFormatDelimiter }}
          </div>
        </div>
        <div class="grid-item">
          <div class="grid-item-key">Grouping</div>
          <div class="grid-item-value grouping">
            {{ results['grouping_c'] | plFormatDelimiter }}
          </div>
        </div>
        <div class="grid-item">
          <div class="grid-item-key">Case managing</div>
          <div class="grid-item-value case-managing">
            {{ results['case_managing'] | plFormatDelimiter }}
          </div>
        </div>
        <div class="grid-item">
          <div class="grid-item-key">Assessments</div>
          <div class="grid-item-value assessments">
            {{ results['assessments'] | plFormatDelimiter }}
          </div>
        </div>
        <div class="grid-item">
          <div class="grid-item-key">Out of school time</div>
          <div class="grid-item-value out-of-school-time">
            {{ results['out_of_school_day_hours'] | plFormatDelimiter }}
          </div>
        </div>
        <div class="grid-item">
          <div class="grid-item-key">Compensatory time</div>
          <div class="grid-item-value compensatory-time">
            {{ results['compensatory_time'] | plFormatDelimiter }}
          </div>
        </div>
        <div class="grid-item">
          <div class="grid-item-key">Medicaid billing</div>
          <div class="grid-item-value medicaid-billing">
            {{ results['medicaid_billing'] | plFormatDelimiter }}
          </div>
        </div>
        <div class="grid-item">
          <div class="grid-item-key">Medicaid system</div>
          <div class="grid-item-value medicaid-system">
            {{ results['medicaid_system'] | plFormatDelimiter }}
          </div>
        </div>
        <div class="grid-item">
          <div class="grid-item-key">IEP system</div>
          <div class="grid-item-value iep-system">
            {{ results['iep_tracking'] | plFormatDelimiter }}
          </div>
        </div>
        <div class="grid-item">
          <div class="grid-item-key">Student information system</div>
          <div class="grid-item-value student-info-system">
            {{ results['student_information_system'] | plFormatDelimiter }}
          </div>
        </div>
        <div class="grid-item">
          <div class="grid-item-key">Primary support person</div>
          <div class="grid-item-value primary-support-person">
            {{ results['primary_support_person'] | plFormatDelimiter }}
          </div>
        </div>
        <div class="grid-item">
          <div class="grid-item-key">District interviews</div>
          <div class="grid-item-value district-interviews">
            {{ results['district_interviews'] | plFormatDelimiter }}
          </div>
        </div>
        <div class="grid-item">
          <div class="grid-item-key">Residency requirements</div>
          <div class="grid-item-value residency-requirements">
            {{ results['residency_requirements'] | plFormatDelimiter }}
          </div>
        </div>
        <div class="grid-item">
          <div class="grid-item-key">Min. provider hours</div>
          <div class="grid-item-value min-provider-hours">
            {{ results['minimum_provider_hours'] | plFormatDelimiter }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex w-full gap-24 justify-end">
      <a [href]="salesforceUrl" target="_blank">
        <span class="salesforce-link">View in Salesforce</span>
      </a>

      <button
        mat-button
        color="primary"
        type="button"
        (click)="closeDetailsOverlay()"
      >
        Close
      </button>
    </div>
  </div>
</div>
