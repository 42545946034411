<pl-modal-header-wrapper [headerText]="'Edit Notification Preferences'">
  <form>
    <div class="pl-provider-notification-preferences">
      <div class="list">
        <pl-input-checkbox
          *ngFor="let opt of notificationPreferences"
          (onChange)="evaluateNotificationPreferences()"
          [(model)]="opt.selected"
          [label]="opt.label"
          [disabled]="opt.disabled"
        >
        </pl-input-checkbox>
      </div>
      <div
        class="phone-number-input-section"
        [hidden]="
          !(
            isSMSPreferenceSelected &&
            featureFlagShowNotificationPreferencesWithPhoneNumber
          )
        "
      >
        <pl-input-text
          class="form-input"
          [label]="'Phone number'"
          [(model)]="phoneNumber"
          [required]="
            isSMSPreferenceSelected &&
            featureFlagShowNotificationPreferencesWithPhoneNumber
          "
          placeholder="(555) 555-5555"
        ></pl-input-text>
        <pl-error-message
          *ngIf="phoneNumber.length < 9"
          [message]="'Enter a valid phone number'"
        ></pl-error-message>
        <div>
          By entering your cell phone number, you agree to receive recurring
          notifications about assignments from Presence at this cell phone
          number. Msg and data rates may apply. Msg frequency varies. Reply HELP
          for help and STOP to cancel.
        </div>
      </div>
      <hr />
      <button pl-button class="success" (click)="onClickSave()">Save</button>
    </div>
  </form>
</pl-modal-header-wrapper>
