import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { environment } from '@environments/environment';
import { CurrentUserService } from '@modules/user/current-user.service';

/**
 * helper to filter only api call urls for tracing
 * @param url - The URL to check\
 * @returns true if the URL is localhost or ends with allowed presence.com
 * or presencelearning.com domains, false otherwise
 */
export function isValidUrl(url: string): boolean {
  try {
    const parsedUrl = new URL(url);
    const hostname = parsedUrl.hostname;

    if (hostname === 'localhost' || hostname === '127.0.0.1') {
      return true;
    }
    const validDomains = [
      'workplace.presencelearning.com',
      'workplace.presence.com',
      'login.presencelearning.com',
      'login.presence.com',
      'api.presencelearning.com',
      'api.presence.com',
      'apps.presencelearning.com',
      'apps.presence.com',
    ];
    // also covers staging-* and dev-* domains
    return validDomains.some(domain => hostname.endsWith(domain));
  } catch (error) {
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class DatadogService {
  private currentUserService: CurrentUserService;
  constructor(currentUserService: CurrentUserService) {
    this.currentUserService = currentUserService;
    if (environment.datadogAppId && environment.datadogApiKey) {
      environment.datadogRumEnabled && this.initializeDatadogRUM();
      environment.datadogLogsEnabled && this.initializeDatadogLogs();
    }
    this.setUserContext();
  }

  private initializeDatadogRUM() {
    datadogRum.init({
      applicationId: environment.datadogAppId,
      clientToken: environment.datadogApiKey,
      site: 'datadoghq.com',
      service: 'edu-clients',
      env: environment.datadogEnvName,
      version: environment.git_sha.slice(0, 4),
      sessionSampleRate: environment.datadogEnvName === 'production' ? 10 : 100,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      traceContextInjection: 'all',
      compressIntakeRequests: true,
      sessionReplaySampleRate: 0, // Privacy concerns
      allowedTracingUrls: [
        url => {
          return isValidUrl(url);
        },
      ],
    });

    datadogRum.startSessionReplayRecording();
  }

  private initializeDatadogLogs() {
    datadogLogs.init({
      clientToken: environment.datadogApiKey,
      service: 'edu-clients',
      env: environment.datadogEnvName,
      version: environment.git_sha.slice(0, 4),
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
      sessionSampleRate: environment.datadogEnvName === 'production' ? 10 : 100,
      silentMultipleInit: true,
      allowFallbackToLocalStorage: true,
    });
  }

  get RUM() {
    return datadogRum;
  }

  private setUserContext() {
    this.currentUserService.getCurrentUser().subscribe(user => {
      if (user) {
        datadogRum.setUser({
          id: user.uuid,
        });
      }
    });
  }

  public logMessage(message: string, context?: object) {
    datadogLogs.logger.log(message, context);
  }

  public logError(error: Error, context?: object) {
    datadogLogs.logger.error(error.message, { ...context, error });
  }
}
