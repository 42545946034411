import { PLClinicalServiceTypeCode } from '../../enums';
import { DemandSpecialty } from '../constants/demand-specialties.constants';

export enum OpportunityType {
  DEDICATED = 'dedicated',
  HOURLY = 'hourly',
  LEGACY_FTE = 'legacy_fte',
  UNKNOWN = 'unknown',
}

export enum AssignmentType {
  DEDICATED = 'Dedicated',
  NON_DEDICATED = 'Non-dedicated',
  LEGACY_FTE = 'Legacy FTE',
}

export enum AssignmentTypePreference {
  THERAPY_ONLY = 'DIRECT',
  EVALUATION_ONLY = 'ASSESSMENT',
  THERAPY_AND_EVALUATION = 'ASSESSMENT_DIRECT',
}

export interface ReturningPreference {
  main_service_line: string;
  school_year_name: string;
  comments: string;
  preference: string;
}

export interface UserReturningInfo {
  user_is_returning: boolean;
  user_has_org_preference: boolean;
  user_has_provider_preference: boolean;
  provider_preferences: Array<ReturningPreference>;
  organization_preferences: Array<ReturningPreference>;
}
export interface PLAssignmentRequirement {
  estimated_met_date: string;
  qualification: string;
  met: boolean;
  options: string[];
  pending: boolean;
  instructions_for_requirement: Array<{ [key: string]: string }>;
}

export interface AssignmentProposalOrganization {
  uuid: string;
  organization_type: string;
  state: string;
  timezone: string | null;
  name: string;
  clinical_success_manager_slp_ot: string;
  clinical_success_manager_slp_ot_email: string;
  clinical_success_manager_pes_mhc: string;
  clinical_success_manager_pes_mhc_email: string;
}

export interface AssignmentProposal {
  uuid: string;
  organization: AssignmentProposalOrganization;
  user: string;
  user_first_name: string;
  user_last_name: string;
  status: string;
  status_detail: string;
  school_year: string;
  start_date: string;
  end_date: string;
  hours: string;
  demand: string;
  pl_rejected_reason: string | null;
  pl_rejected_other_reason: string | null;
  removed_reason: string | null;
  requirements: PLAssignmentRequirement[];
  service_lines: string[];
  is_fte: boolean;
  user_separation_date: string | null;
  modified: string;
  is_esy: boolean;
  pay_rate: string;
  unmet_requirements_count: 0;
  cem_demo_reason: string | null;
  block_assignment_time_reason: string | null;
  service_model: 'DH' | 'SH' | null;
  specialties: DemandSpecialty[];
  service_type_codes: PLClinicalServiceTypeCode[];
  is_assessment: boolean;
  user_returning_info: UserReturningInfo;
  expiration_time?: string;
  projected_therapy_start_date?: string;
  opportunity_type: OpportunityType;
  is_recommitment: boolean;
  is_on_hold: boolean | null;
  on_hold_reason: string | null;
  on_hold_as_of: string | null;
}
