import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EMPLOYMENT_STATUS } from '@common/constants';
import { PLProviderService } from '@modules/providers/pl-provider.service';
import { CurrentUserService } from '@modules/user/current-user.service';

@Injectable()
export class PLProviderPayRatesRouteGuardService implements CanActivate {
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router,
    private plProviderService: PLProviderService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.currentUserService.getCurrentUser().pipe(
      switchMap(user => {
        const providerUuidFromURI = route.parent?.params?.id;

        if (providerUuidFromURI) {
          return this.plProviderService.getProvider(providerUuidFromURI).pipe(
            map(({ provider }) => {
              let {
                mayViewProvider,
                mayViewPersonnel,
                mayViewRates,
                isSelfProvider,
              } = this.plProviderService.calculatePermissions(user, provider);

              if (
                provider.employmentStatus === EMPLOYMENT_STATUS.SUBCONTRACTOR
              ) {
                // Subcontractor pay rates - whether the requesting user of the page is the provider or not - are not viewable
                this.router.navigate(['/landing']);
                return false;
              }

              if (state.url.includes(user.uuid)) {
                // If you are yourself (e.g., you're visiting a bookmarked page for your own /provider-pay-rates)
                // then return true without having to check permissions
                return true;
              }

              if (
                mayViewProvider &&
                (mayViewPersonnel || isSelfProvider) &&
                mayViewRates
              ) {
                return true;
              }

              this.router.navigate(['/landing']);
              return false;
            }),
          );
        } else {
          this.router.navigate(['/landing']);
          return of(false);
        }
      }),
    );
  }
}
