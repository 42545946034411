import dayjs from 'dayjs';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plDayjsFormat',
})
export class PLDayjsFormatPipe implements PipeTransform {
  transform(date: dayjs.ConfigType, format: string, inputFormat?: string): any {
    return dayjs(date, inputFormat).format(format);
  }
}
