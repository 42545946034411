// TODO: Start using this instead of `edu-clients/src/app/common/constants/index.ts`

// Anything added in this `enum` add it to the `referralProductTypeMap` at `pl-client-referral.ts`
export enum PLClinicalProductCode {
  BIG = 'groupbmh_bi', // TODO: seems that this product wll be fully removed from the platform; check with product
  DIR_SVC = 'direct_service',
  EVAL = 'evaluation_with_assessments',
  SV = 'supervision',
  TG = 'groupbmh_ti', // TODO: seems that this product wll be fully removed from the platform; check with product
  BMH_GROUP = 'group_bmh',
  CONS = 'consultation',
  REC_REV = 'records_review',
  SC = 'screening',
  GROUP_READING = 'group_reading',
  SOCIAL_WELLNESS = 'prosocial_group',
  BMH_SV = 'mhc_prep_individual',
  RSM_MEETING = 'rsm_meeting',
}

// Anything added in this `enum` add it to the `referralProductTypeMap` at `pl-client-referral.ts`
export enum PLClinicalProductName {
  BIG = 'Behavior Intervention Group',
  DIR_TE = 'Direct Therapy',
  EVAL = 'Evaluation',
  SV = 'Supervision',
  TE = 'Therapy',
  TG = 'Trauma-informed Group', // TODO: seems that this product wll be fully removed from the platform; check with product
  TG_CAPITALIZE = 'Trauma Informed Group', // TODO: seems that this product wll be fully removed from the platform; check with product
  BIG_LOWER_CASE = 'big', // TODO: seems that this product wll be fully removed from the platform; check with product
  BIG_LOWER_CASE2 = 'behavior intervention group', // TODO: seems that this product wll be fully removed from the platform; check with product
  TG_LOWER_CASE = 'trauma informed group', // TODO: seems that this product wll be fully removed from the platform; check with product
  TG_LOWER_CASE2 = 'trauma-informed group', // TODO: seems that this product wll be fully removed from the platform; check with product
  BIG_UPPER_CASE = 'BIG', // TODO: seems that this product wll be fully removed from the platform; check with product
  TG_UPPER_CASE = 'TRAUMA INFORMED GROUP', // TODO: seems that this product wll be fully removed from the platform; check with product
  GROUP_READING = 'Group Reading Service',
  SOCIAL_WELLNESS = 'Social Wellness',
  MEETING = 'Meeting',
  CONS = 'Consultation',
  RSM_MEETING = 'Meeting',
}

export const DIRECT_SERVICES_PRODUCTS: string[] = [
  PLClinicalProductCode.TG,
  PLClinicalProductCode.SV,
  PLClinicalProductCode.BIG,
  PLClinicalProductCode.CONS,
  PLClinicalProductCode.DIR_SVC,
  PLClinicalProductCode.RSM_MEETING,
];
