import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { MAX_QUERY_LIMIT } from '@common/services';
import { PLHttpService, PLUrlsService } from '@root/index';

@Injectable({ providedIn: 'root' })
export class PLProvidersService {
  private urlKey = 'providers';

  constructor(
    private plHttp: PLHttpService,
    private plUrls: PLUrlsService,
    @Inject(MAX_QUERY_LIMIT) private limit: number,
  ) {}

  get(limit?: number, providerUuid?: string): Observable<any> {
    const params: any = {
      limit: limit || this.limit,
      is_active: true,
      user__is_active: true,
    };
    const url = this.plUrls.urls.providers;
    if (providerUuid) {
      return this.plHttp.get(this.urlKey, params, `${url}${providerUuid}/`);
    }
    return this.plHttp.get(this.urlKey, params);
  }
}
