import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { AppStore } from '@app/appstore.model';
import { User } from '@modules/user/user.model';

import { Option } from '@common/interfaces';
import { selectCurrentUser } from '@common/store';
import { PLProviderTypesService } from '@common/services/pl-provider-types.service';

import { noop } from 'rxjs';
import { PLMayService, PLGraphQLService } from '@root/index';
import { PLUtilService } from '@root/src/app/common/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const providerProfilesQuery = require('../queries/provider-profiles.graphql');

@UntilDestroy()
@Component({
  selector: 'pl-providers-list',
  templateUrl: './pl-providers-list.component.html',
  styleUrls: ['./pl-providers-list.component.less'],
})
export class PLProvidersListComponent implements OnInit {
  user: User;
  data: any[] = [];
  columns: any = [];
  plProvidersList: any = {
    providers: [],
    total: 0,
    // currentPage: 1,
    // pageSize: 25,
    loading: false,
  };
  filterSelectOpts: any[] = [
    {
      value: 'lastName_Icontains',
      label: 'Last Name',
      defaultVisible: true,
      placeholder: 'Type to filter..',
    },
    {
      value: 'firstName_Icontains',
      label: 'First Name',
      defaultVisible: true,
      placeholder: 'Type to filter..',
    },
    {
      value: 'providerType_Icontains',
      label: 'Provider Type',
      defaultVisible: false,
      placeholder: 'Select an item',
      selectOpts: [], // This will be filled when calling `formProviderTypeSelectOpts`.
    },
    {
      value: 'subStatus_Icontains',
      label: 'Sub-Status',
      defaultVisible: false,
      placeholder: 'Select an item',
      selectOpts: this.formStatusSelectOpts(),
    },
  ];

  constructor(
    private router: Router,
    private plMay: PLMayService,
    private store: Store<AppStore>,
    private plUtilSvc: PLUtilService,
    private plGraphQL: PLGraphQLService,
    private plProviderTypesSvc: PLProviderTypesService,
  ) {}

  ngOnInit(): void {
    this.formProviderTypeSelectOpts();
    this.store
      .select(selectCurrentUser)
      .pipe(untilDestroyed(this))
      .subscribe((user: User) => {
        this.user = user;
      });
  }

  onQuery(info: { query: any; queryId: string }) {
    const vars = Object.assign({}, info.query, {
      first: info.query.first,
      offset: info.query.offset,
      orderBy: info.query.orderBy,
      isActive: true,
      user_IsActive: true,
    });

    // this.plProvidersList.pageSize = info.query.first;
    // this.plProvidersList.currentPage = info.query.page;
    this.plProvidersList.loading = true;
    this.plGraphQL
      .query(providerProfilesQuery, vars, {})
      .pipe(first())
      .subscribe(
        (res: any) => {
          const newQuery = this.plUtilSvc.reduceQueryOffsetBasedOnTableRecords(
            info,
            res?.providerProfiles?.length,
          );

          if (!Object.keys(newQuery).length) {
            this.data = res.providerProfiles
              ? this.formatProviders(res.providerProfiles)
              : [];
            this.plProvidersList.providers = this.data;
            this.plProvidersList.total = res.providerProfiles_totalCount;
            this.plProvidersList.loading = false;
            return;
          }

          this.onQuery(newQuery);
        },
        (error: any) => {
          this.plProvidersList.loading = false;
          console.error(
            `There was an error while getting the absences records.`,
          );
          console.error(error);
        },
      );
  }

  onRowClick(data: any) {
    if (data.user && data.user.id) {
      this.router.navigate(['/provider', data.user.id]);
    }
  }

  /**
   * Forms the `Provider Type` options based on the available providers from the app.
   * Then assigns the providers to the object with the value `providerType_Icontains`.
   *
   * For this scenario the `value` of the option has to be equal to the `label`.
   */
  formProviderTypeSelectOpts(): void {
    this.plProviderTypesSvc.formProviderTypeOptions();
    this.plProviderTypesSvc.providerTypesSubject
      .pipe(
        filter((options: any) => options.length),
        first(),
      )
      .subscribe((providerTypeOptions: Option[]) => {
        // TODO: should the below line be transformed before the subscription?
        providerTypeOptions.forEach(
          providerType => (providerType.value = providerType.label),
        );
        this.filterSelectOpts.forEach(selectOpt => {
          if (selectOpt.value === 'providerType_Icontains') {
            selectOpt.selectOpts = providerTypeOptions;
          }
        });
      }, noop);
  }

  formStatusSelectOpts(): Option[] {
    return [
      { value: 'Onboarding', label: 'Onboarding' },
      { value: 'Ready for Placement', label: 'Ready for Placement' },
      { value: 'Providing Services', label: 'Providing Services' },
    ];
  }

  shouldShowSubStatusRow(): boolean {
    return (
      this.plMay.isAdminType(this.user) || this.plMay.isSuperuser(this.user)
    );
  }

  private formatProviders(providers: any[]) {
    return providers.map((provider: any) => {
      let providerTypeLongName = '';

      if (provider && provider.providerTypes) {
        providerTypeLongName = provider.providerTypes
          .map(providerType => providerType.longName)
          .join(', ');
      }

      return Object.assign(provider, {
        xLastName: provider.user.lastName,
        xFirstName: provider.user.firstName,
        xProviderTypeName: providerTypeLongName,
      });
    });
  }
}
