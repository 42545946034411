<div class="pl-provider-locations">
  <pl-table
    [data]="data"
    [columns]="columns"
    [count]="dataInfo.count"
    [queryId]="dataInfo.queryId"
    [reQuery]="reQuery"
    [rowHrefFn]="onRowHref"
    (onQuery)="onQuery($event)"
  ></pl-table>
</div>
