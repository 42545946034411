import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CanDeactivateGuard } from '@common/can-deactivate-guard.service';
import { PLCommonModule } from '@common/index';
import {
  PLInputModule,
  PLIconModule,
  PLModalModule,
  PLClosablePageHeaderModule,
  PLStepsModule,
  PLDotLoaderModule,
  PLTableModule,
  PLTableFrameworkModule,
} from '@root/index';


import { PLClientMergePermissionsService } from './pl-client-merge-permissions.service';
import { PLClientMergeComponent } from './pl-client-merge.component';
import { PLClientMergeService } from './pl-client-merge.service';
import { PLCompareClientsComponent } from './pl-compare-clients/pl-compare-clients.component';
import { PLCompareClientsService } from './pl-compare-clients/pl-compare-clients.service';
import { PLConfirmClientsComponent } from './pl-confirm-clients/pl-confirm-clients.component';
import { PLMergeCompleteComponent } from './pl-merge-complete/pl-merge-complete.component';
import { PLMergeTipsComponent } from './pl-merge-tips/pl-merge-tips.component';
import { PLSelectClientsTableService } from './pl-select-clients/pl-select-clients-table.service';
import { PLSelectClientsComponent } from './pl-select-clients/pl-select-clients.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: PLClientMergeComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { title: 'Client Merge' },
        children: [
          { path: '', redirectTo: 'select-clients', pathMatch: 'full' },
          { path: 'select-clients', component: PLSelectClientsComponent },
          { path: 'compare-clients', component: PLCompareClientsComponent },
          { path: 'confirm', component: PLConfirmClientsComponent },
          { path: 'complete', component: PLMergeCompleteComponent },
        ],
      },
    ]),
    PLInputModule,
    PLIconModule,
    PLModalModule,
    PLTableModule,
    PLTableFrameworkModule,
    PLClosablePageHeaderModule,
    PLStepsModule,
    PLDotLoaderModule,
    PLCommonModule,
  ],
  exports: [
    PLClientMergeComponent,
    PLMergeTipsComponent,
    PLSelectClientsComponent,
    PLCompareClientsComponent,
    PLConfirmClientsComponent,
    PLMergeCompleteComponent,
  ],
  declarations: [
    PLClientMergeComponent,
    PLMergeTipsComponent,
    PLSelectClientsComponent,
    PLCompareClientsComponent,
    PLConfirmClientsComponent,
    PLMergeCompleteComponent,
  ],
  providers: [
    PLClientMergeService,
    PLClientMergePermissionsService,
    PLSelectClientsTableService,
    PLCompareClientsService,
  ],
})
export class PLClientMergeModule {
  static forRoot(): ModuleWithProviders<PLClientMergeModule> {
    return {
      ngModule: PLClientMergeModule,
      providers: [
        PLClientMergeService,
        PLClientMergePermissionsService,
        PLSelectClientsTableService,
        PLCompareClientsService,
      ],
    };
  }
}
