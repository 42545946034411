// Constants

export enum ADDED_AMOUNT {
  'NONE' = 'NONE_ADDED',
  'SOME' = 'SOME_ADDED',
  'ALL' = 'ALL_ADDED',
}

export enum NEED_REVIEW_AMOUNT {
  'NONE' = 'NONE_NEED_REVIEW',
  'SOME' = 'SOME_NEED_REVIEW',
  'ALL' = 'ALL_NEED_REVIEW',
}

export enum BLOCKED_AMOUNT {
  'NONE' = 'NONE_BLOCKED',
  'SOME' = 'SOME_BLOCKED',
  'ALL' = 'ALL_BLOCKED',
}

const CANCEL_HEADER = 'Confirm Cancel';
const CANCEL_PRIMARY_LABEL = 'Proceed';
const CANCEL_SECONDARY_LABEL = 'Go Back';

const FINISH_HEADER = 'Confirm Finish';
const FINISH_PRIMARY_LABEL = 'Finish';
const FINISH_SECONDARY_LABEL = 'Go Back';

// Types

type ModalData = {
  skipModal: boolean;
  header: string;
  content: string;
  primaryLabel: string;
  primaryCallback: () => void;
  secondaryLabel: string;
  secondaryCallback: () => void;
};

// Service Here

import { Injectable } from '@angular/core';
import { PLConfirmDialogService, PLLinkService } from '@root/index';
import { PLAddReferralsNavigationService } from '../pl-add-referrals-navigation.service';

@Injectable()
export class PLAddReferralsConfirmationService {
  CANCEL_MODAL_DATA: Record<string, ModalData>;

  FINISH_MODAL_DATA: Record<string, ModalData>;

  constructor(
    private pLAddReferralsNavigationService: PLAddReferralsNavigationService,
    private plConfirm: PLConfirmDialogService,
    private plLink: PLLinkService,
  ) {
    this.FINISH_MODAL_DATA = {
      'ALL_ADDED-NONE_NEED_REVIEW-NONE_BLOCKED': {
        header: FINISH_HEADER,
        skipModal: true,
        content: ``,
        primaryLabel: FINISH_PRIMARY_LABEL,
        primaryCallback: this.doFinish.bind(this),
        secondaryLabel: FINISH_SECONDARY_LABEL,
        secondaryCallback: this.closeModal,
      },
      'SOME_ADDED-SOME_NEED_REVIEW-NONE_BLOCKED': {
        header: FINISH_HEADER,
        skipModal: false,
        content: `
                    <div>
                        It appears you have referrals that require review, if you proceed these referrals will not be added.<br><br>
                        Do you want to finish?
                    </div>
                `,
        primaryLabel: FINISH_PRIMARY_LABEL,
        primaryCallback: this.doFinish.bind(this),
        secondaryLabel: FINISH_SECONDARY_LABEL,
        secondaryCallback: this.closeModal,
      },
      'SOME_ADDED-SOME_NEED_REVIEW-SOME_BLOCKED': {
        header: FINISH_HEADER,
        skipModal: false,
        content: `
                    <div>
                        It appears that you have referrals that require review and other referrals that cannot be added.<br><br>
                        If you proceed these referrals will not be added and this page will no longer be visible.
                        To keep a record of referrals that were not added download the “summary report” before clicking finish.
                    </div>
                `,
        primaryLabel: FINISH_PRIMARY_LABEL,
        primaryCallback: this.doFinish.bind(this),
        secondaryLabel: FINISH_SECONDARY_LABEL,
        secondaryCallback: this.closeModal,
      },
      'NONE_ADDED-ALL_NEED_REVIEW-NONE_BLOCKED': {
        header: FINISH_HEADER,
        skipModal: false,
        content: `
                    <div>
                        It appears you have referrals that require review, if you proceed these referrals will not be added.
                    </div>
                `,
        primaryLabel: FINISH_PRIMARY_LABEL,
        primaryCallback: this.doFinish.bind(this),
        secondaryLabel: FINISH_SECONDARY_LABEL,
        secondaryCallback: this.closeModal,
      },
      'NONE_ADDED-SOME_NEED_REVIEW-SOME_BLOCKED': {
        header: FINISH_HEADER,
        skipModal: false,
        content: `
                    <div>
                        It appears that you have referrals that require review and other referrals that cannot be added.<br><br>
                        If you proceed these referrals will not be added and this page will no longer be visible.
                        To keep a record of referrals that were not added download the “summary report” before clicking finish.
                    </div>
                `,
        primaryLabel: FINISH_PRIMARY_LABEL,
        primaryCallback: this.doFinish.bind(this),
        secondaryLabel: FINISH_SECONDARY_LABEL,
        secondaryCallback: this.closeModal,
      },
      'NONE_ADDED-NONE_NEED_REVIEW-ALL_BLOCKED': {
        header: FINISH_HEADER,
        skipModal: false,
        content: `
                    <div>
                        It appears that you have referrals that cannot be added and no action can be taken at this time.<br><br>
                        If you proceed this page will no longer be visible.
                        To keep a record of referrals that were not added download the “summary report” before clicking finish.
                    </div>
                `,
        primaryLabel: FINISH_PRIMARY_LABEL,
        primaryCallback: this.doFinish.bind(this),
        secondaryLabel: FINISH_SECONDARY_LABEL,
        secondaryCallback: this.closeModal,
      },
    };

    this.CANCEL_MODAL_DATA = {
      'ALL_ADDED-NONE_NEED_REVIEW-NONE_BLOCKED': {
        header: CANCEL_HEADER,
        skipModal: false,
        content: `
                    <div>
                        If you cancel now, none of the referrals you have uploaded will be added.<br><br>
                        Do you want to proceed with the canceling?
                    </div>
                `,
        primaryLabel: CANCEL_PRIMARY_LABEL,
        primaryCallback: this.doCancel.bind(this),
        secondaryLabel: CANCEL_SECONDARY_LABEL,
        secondaryCallback: this.closeModal,
      },
      'SOME_ADDED-SOME_NEED_REVIEW-NONE_BLOCKED': {
        header: CANCEL_HEADER,
        skipModal: false,
        content: `
                    <div>
                        If you cancel now, none of the referrals you have uploaded will be added.<br><br>
                        Do you want to proceed with the canceling?
                    </div>
                `,
        primaryLabel: CANCEL_PRIMARY_LABEL,
        primaryCallback: this.doCancel.bind(this),
        secondaryLabel: CANCEL_SECONDARY_LABEL,
        secondaryCallback: this.closeModal,
      },
      'SOME_ADDED-SOME_NEED_REVIEW-SOME_BLOCKED': {
        header: CANCEL_HEADER,
        skipModal: false,
        content: `
                    <div>
                        If you cancel now, none of the referrals you have uploaded will be added.<br><br>
                        Do you want to proceed with the canceling?
                    </div>
                `,
        primaryLabel: CANCEL_PRIMARY_LABEL,
        primaryCallback: this.doCancel.bind(this),
        secondaryLabel: CANCEL_SECONDARY_LABEL,
        secondaryCallback: this.closeModal,
      },
      'NONE_ADDED-ALL_NEED_REVIEW-NONE_BLOCKED': {
        header: CANCEL_HEADER,
        skipModal: false,
        content: `
                    <div>
                        If you cancel now you will not be able to review these referrals later without uploading them again.<br><br>
                        Do you want to proceed with the canceling?
                    </div>
                `,
        primaryLabel: CANCEL_PRIMARY_LABEL,
        primaryCallback: this.doCancel.bind(this),
        secondaryLabel: CANCEL_SECONDARY_LABEL,
        secondaryCallback: this.closeModal,
      },
      'NONE_ADDED-SOME_NEED_REVIEW-SOME_BLOCKED': {
        header: CANCEL_HEADER,
        skipModal: false,
        content: `
                    <div>
                        If you cancel now this page will no longer be visible.
                        To keep a record of the reasons that referrals were not added download the “summary report” before clicking cancel.<br><br>
                        Do you want to proceed with the canceling?
                    </div>
                `,
        primaryLabel: CANCEL_PRIMARY_LABEL,
        primaryCallback: this.doCancel.bind(this),
        secondaryLabel: CANCEL_SECONDARY_LABEL,
        secondaryCallback: this.closeModal,
      },
      'NONE_ADDED-NONE_NEED_REVIEW-ALL_BLOCKED': {
        header: CANCEL_HEADER,
        skipModal: false,
        content: `
                    <div>
                        If you cancel now this page will no longer be visible.
                        To keep a record of the reasons that referrals were not added download the “summary report” before clicking cancel.<br><br>
                        Do you want to proceed with the canceling?
                    </div>
                `,
        primaryLabel: CANCEL_PRIMARY_LABEL,
        primaryCallback: this.doCancel.bind(this),
        secondaryLabel: CANCEL_SECONDARY_LABEL,
        secondaryCallback: this.closeModal,
      },
    };
  }

  doCancel() {
    this.pLAddReferralsNavigationService.confirmCancel();
  }

  doFinish() {
    this.pLAddReferralsNavigationService.confirmFinish();
  }

  goBack() {
    this.plLink.goBack();
  }

  /**
   * closeModal does nothing, an empty function will close the modal with no further action
   */
  closeModal() {}

  getModalKey(rowsData: {
    added: number;
    needReview: number;
    blocked: number;
  }): string {
    const total: number =
      rowsData.added + rowsData.needReview + rowsData.blocked;
    const addedKey =
      rowsData.added === 0
        ? ADDED_AMOUNT.NONE
        : rowsData.added === total
        ? ADDED_AMOUNT.ALL
        : ADDED_AMOUNT.SOME;
    const needReviewKey =
      rowsData.needReview === 0
        ? NEED_REVIEW_AMOUNT.NONE
        : rowsData.needReview === total
        ? NEED_REVIEW_AMOUNT.ALL
        : NEED_REVIEW_AMOUNT.SOME;
    const blockedKey =
      rowsData.blocked === 0
        ? BLOCKED_AMOUNT.NONE
        : rowsData.blocked === total
        ? BLOCKED_AMOUNT.ALL
        : BLOCKED_AMOUNT.SOME;

    const key = `${addedKey}-${needReviewKey}-${blockedKey}`;
    return key;
  }

  showModal(
    modalType: 'finish' | 'cancel',
    rowsData: { added: number; needReview: number; blocked: number },
  ) {
    const MODAL_DATA_SOURCE =
      modalType === 'cancel' ? this.CANCEL_MODAL_DATA : this.FINISH_MODAL_DATA;

    const modalData = MODAL_DATA_SOURCE[this.getModalKey(rowsData)];

    if (!modalData.skipModal) {
      this.plConfirm.show(modalData);
    } else if (modalType === 'finish') {
      // This section is reached if the modal is skipped and the modal type is finish
      this.doFinish();
    } else {
      // This section is reached if the modal is skipped and the modal type is cancel
      this.doCancel();
    }
  }
}
