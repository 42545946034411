import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AssignmentProposalOrganization } from '@common/assigment-machine/models';
import { AssignmentProposalDataService } from '@common/assigment-machine/services';
import { AsyncDataService } from '@common/services/async-data.service';
import { getOrganizationsFromAssignments } from '../../../utils/assignments';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class PLTimesheetProgressOrganizationFilterService extends AsyncDataService<
  AssignmentProposalOrganization[]
> {
  constructor(private dataService: AssignmentProposalDataService) {
    super();

    this.fetch();
  }

  private fetch() {
    this.setLoading();
    this.dataService
      .list({ only_payable: true, limit: 1000 })
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          const dedicated_organizations = getOrganizationsFromAssignments(
            response.results.filter(
              assignment =>
                assignment.service_model === 'DH' &&
                ['active'].includes(assignment.status),
            ),
          );
          this.setData(dedicated_organizations);
        },
        error => {
          console.warn('[Event Assignments Error]', error);
          this.setError(error);
        },
      );
  }
}
