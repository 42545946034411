import { Injectable } from '@angular/core';
import {
  IConfigCatClient,
  LogLevel,
  User as ConfigCatUser,
} from 'configcat-common';
import * as configcat from 'configcat-js';

import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { switchMap, map, distinctUntilChanged, filter } from 'rxjs/operators';

import { environment } from '@environments/environment';

import {
  ConfigName,
  FeatureFlagName,
  FeatureFlagsCommon,
  TextValueName,
} from './feature-flags';
import { User } from '../../modules/user/user.model';

function getCacheKey(): string {
  return 'js_config_v5' + '_' + environment.configCatSdkKey;
}

/**
 * TODO: updgrade to latest version of configcat
 * Because configcat v7 uses typescript 4.9 the declaration files
 * are not compatible with our current version (4.1) so we had to downgrade
 * to v6
 */
@Injectable()
export class ConfigCatFeatureFlagsService implements FeatureFlagsCommon {
  client: IConfigCatClient;

  private clientConfigChanged$ = new Subject<void>();

  private user$ = new ReplaySubject<ConfigCatUser>();

  private remoteConfig$ = new BehaviorSubject<Record<ConfigName, unknown>>(
    {} as Record<ConfigName, unknown>,
  );
  private allFeatureFlags$: Observable<Record<FeatureFlagName, boolean>>;
  private allTextValues$: Observable<Record<TextValueName, string>>;

  private isLoading$ = new BehaviorSubject<boolean>(true);

  constructor() {
    this.allFeatureFlags$ = this.remoteConfig$.pipe(
      map(config => {
        return Object.keys(config).reduce((obj, key) => {
          if (Object.values(FeatureFlagName).includes(key as FeatureFlagName)) {
            obj[key as FeatureFlagName] = config[key] as boolean;
          }
          return obj;
        }, {} as Record<FeatureFlagName, boolean>);
      }),
    );
    this.allTextValues$ = this.remoteConfig$.pipe(
      map(config => {
        return Object.keys(config).reduce((obj, key) => {
          if (Object.values(TextValueName).includes(key as TextValueName)) {
            obj[key as TextValueName] = config[key] as string;
          }
          return obj;
        }, {} as Record<TextValueName, string>);
      }),
    );
    this.initClient();
  }

  identifyUser(user: User) {
    this.user$.next(
      new ConfigCatUser(user.uuid, user.email, null, {
        username: user.username,
      }),
    );
  }

  getAllFeatures() {
    return this.allFeatureFlags$;
  }

  isFeatureEnabled(flagName: FeatureFlagName) {
    const isFeatureEnabled$ = this.allFeatureFlags$.pipe(
      map(features => features?.[flagName] ?? false),
      distinctUntilChanged(),
    );

    return this.isLoading$.pipe(
      filter(isLoading => !isLoading),
      switchMap(() => isFeatureEnabled$),
    );
  }

  getTextValue(textValueName: TextValueName) {
    const textValue$ = this.allTextValues$.pipe(
      map(textValues => textValues?.[textValueName]),
      distinctUntilChanged(),
    );

    return this.isLoading$.pipe(
      filter(isLoading => !isLoading),
      switchMap(() => textValue$),
    );
  }

  private initClient() {
    const logger = configcat.createConsoleLogger(
      environment.debug ? LogLevel.Warn : LogLevel.Error,
    );
    /**
     * TODO: remove once we update to angular 12 + typescript 4.3+
     * Workaround to get configcat v6.0.1 working
     * this is not an issue with latest version
     */
    localStorage.removeItem(getCacheKey());

    this.client = configcat.createClient(environment.configCatSdkKey, {
      logger,
      pollIntervalSeconds: environment.debug ? 10 : 60,
      configChanged: () => {
        this.clientConfigChanged$.next();
      },
    });

    combineLatest([this.clientConfigChanged$, this.user$])
      .pipe(switchMap(([, user]) => this._getAllValues(user)))
      .subscribe(values => {
        this.remoteConfig$.next(values);
        this.isLoading$.next(false);
      });
  }

  private _getAllValues(
    user: ConfigCatUser,
  ): Observable<Record<string, boolean>> {
    return new Observable<Record<string, boolean>>(observer => {
      this.isLoading$.next(true);
      this.client
        .getAllValuesAsync(user)
        .then(values => {
          const result = values.reduce((obj, next) => {
            obj[next.settingKey] = next.settingValue as boolean;
            return obj;
          }, {} as Record<string, boolean>);
          observer.next(result);
          observer.complete();
        })
        .catch(err => {
          observer.error(err);
        });
    });
  }
}
