import type { Observable } from 'rxjs';
import { User } from '@modules/user/user.model';

export enum FeatureFlagName {
  payRatesFY24 = 'payRatesFY24',
  showGeneralEducationIndicator = 'showGeneralEducationIndicator',
  timesheetProgressBar = 'timesheetProgressBar',
  showNewNavigation = 'showNewNavigation',
  newProviderProfile = 'newProviderProfile',
  showNotificationPreferencesWithPhoneNumber = 'showNotificationPreferencesWithPhoneNumber',
  addEventModalSupportDedicatedSpecialty = 'addEventModalSupportDedicatedSpecialty',
  useNewProviderProfile = 'usenewproviderprofile',
  showNewProviderOverviewPage = 'showNewProviderOverviewPage',
  showLastWorkDayField = 'showLastWorkDayField',
  showZenDeskChatBot = 'showZenDeskChatBot',
  authenticateZendeskChat = 'authenticateZendeskChat',
  zendeskSupportLinks = 'zendeskSupportLinks',
  isFY25 = 'isFY25',
  showNewLandingPage = 'showNewLandingPage',
  showReservedAssignment = 'showReservedAssignment',
  aiSummarization = 'aiSummarization',
  newUploadReferral = 'newUploadReferral',
}

export enum TextValueName {
  specialtiesDeprecationDate = 'specialtiesDeprecationDate',
}

export type ConfigName = FeatureFlagName | TextValueName;

export type FeatureFlagAll = Partial<Record<FeatureFlagName, unknown>>;

export interface FeatureFlagsCommon {
  identifyUser(user: User): void;
  getAllFeatures(): Observable<FeatureFlagAll>;
  isFeatureEnabled(key: FeatureFlagName): Observable<boolean>;
  getTextValue(key: TextValueName): Observable<string>;
}
