import {
  Component,
  Output,
  Input,
  EventEmitter,
  OnInit,
  OnChanges,
} from '@angular/core';

import { PLTableWrapperComponent } from './pl-table-wrapper.component';
import {
  PLTableFrameworkService,
  PageInfo,
} from './pl-table-framework.service';
import { PLTableFrameworkUrlService } from './pl-table-framework-url.service';

@Component({
  selector: 'pl-table-footer',
  templateUrl: './pl-table-footer.component.html',
  styleUrls: ['./pl-table-footer.component.less'],
})
export class PLTableFooterComponent implements OnInit, OnChanges {
  @Output() readonly onQuery = new EventEmitter<any>();
  @Output() public onPageChange = new EventEmitter<PageInfo>();

  @Input() total: number = 0;
  @Input() totalLabel = 'Total';

  @Input() currentPage: number; // No default prop is set so that the table can get its last value set.
  @Input() currentPageForce: number = 1;
  @Input() currentPageEnableForceUpdates = false;

  @Input() pageSize: number | any; // No default prop is set so that the table can get its last value set.
  @Input() pageSizes: number[]; // No default prop is set so that the table can get its last value set.

  @Input() selected: number = 0;
  @Input() stickyFooter = false;
  @Input() useFixedPageSize = false;

  offset: number = 0;
  totalPages: number = 1;
  pageSizeOpts: any[] = [];
  isDebug: boolean = false;
  private pagingIniting: boolean = false;
  private pagingUpdatedInfo: any = {};
  private isComponentInitialized = false;

  private keycodes: any = {
    enter: 13,
  };

  constructor(
    private plTableFramework: PLTableFrameworkService,
    private plTableWrapperComp: PLTableWrapperComponent,
    private plTableFrameworkUrlSvc: PLTableFrameworkUrlService,
  ) {}

  /**
   * Calling the `query` method will trigger the `pagingUpdated$` observable.
   *  | `pageSize` and `currentPage` get their store value from the service if no input props are set.
   *  | This allows to remember any last value set before leaving the table and then choosing to come back.
   * Only in the first round of the `pagingUpdated$` observable the component gets initializd.
   *  | -> Therefore the `isComponentInitialized` is true until the `pagingUpdated$` gets triggered.
   */
  ngOnInit() {
    // Note: This block was moved from the constructor so it can see Input values
    this.plTableFramework.pagingUpdated$.subscribe((pagingInfo: any) => {
      this.pagingIniting = true;
      this.pagingUpdatedInfo = pagingInfo;

      if (!this.useFixedPageSize && pagingInfo.pageSize !== this.pageSize) {
        this.pageSize = pagingInfo.pageSize;
      }
      if (pagingInfo.currentPage !== this.currentPage) {
        this.currentPage = pagingInfo.currentPage;
      }

      if (this.isComponentInitialized) {
        this.setTotal();
      } else {
        this.init();
        this.resetPagingIniting();
        this.isComponentInitialized = true;
      }
    });

    this.isDebug = !!localStorage.getItem('PL_DEBUG_TABLE_FOOTER');
    if (this.isDebug) console.log('🌿 table footer init', { STATE: this });

    this.pageSize = this.pageSize
      ? this.pageSize
      : this.plTableFramework.pageSize;
    this.currentPage = this.currentPage
      ? this.currentPage
      : this.plTableFramework.currentPage;
    this.query();
  }

  /**
   * Don't act 'til the component is `initialized` from within `ngOnInit()`.
   */
  ngOnChanges(changes: any) {
    if (this.isComponentInitialized) {
      if (this.isDebug) {
        console.log('🌿 table footer changes', { STATE: this });
      }
      if (this.currentPageEnableForceUpdates) {
        this.currentPage = this.currentPageForce;
        this.pagingUpdatedInfo.currentPage = this.currentPage;
      }
      // Do not allow overriding url paging info if set.
      if (
        changes.pageSize &&
        this.pagingIniting &&
        this.pagingUpdatedInfo.pageSize
      ) {
        this.pageSize = this.pagingUpdatedInfo.pageSize;
        this.setTotal();
      }
      if (
        changes.currentPage &&
        this.pagingIniting &&
        this.pagingUpdatedInfo.currentPage
      ) {
        this.currentPage = this.pagingUpdatedInfo.currentPage;
        this.setTotal();
      }
      if (changes.total) {
        this.setTotal();
      }
      this.setDefaults();
      this.setPaging();
    }
  }

  setDefaults() {
    if (!this.pageSize) {
      this.pageSize = 25;
    }
    if (!this.total) {
      this.total = 0;
    }
  }

  init() {
    this.setDefaults();
    this.setPaging();
    this.setPageSizeOpts();
  }

  private setPageSizeOpts() {
    const opts: any[] = [];
    let foundCurrentValue: boolean = false;
    const sizes = this.pageSizes || [5, 10, 25, 50, 100];
    for (let ii = 0; ii < sizes.length; ii++) {
      if (!foundCurrentValue && sizes[ii] > parseInt(this.pageSize, 10)) {
        opts.push({ value: this.pageSize, label: this.pageSize });
        foundCurrentValue = true;
      }
      opts.push({ value: sizes[ii], label: sizes[ii] });
      if (sizes[ii] === parseInt(this.pageSize, 10)) {
        foundCurrentValue = true;
      }
    }
    this.pageSizeOpts = opts;
  }

  /**
   * Sets the number of pages the table has and the page where the user is located.
   * Sets the total of pages since the total of records are distributed across the pages.
   * Sets the `currentPage`:
   *  either to default which is 1,
   *  or when this is > than the total of pages it's set to the actual # of `totalPages` (i.e. 5 of 5),
   *  or to `pagingUpdatedInfo.currentPage` (helps to remember the value when coming back to the table using the back button from the browser).
   * `setOffset()` is called for checking whether the offset and the paging has to be updated.
   */
  private setTotal() {
    if (this?.total > this.pageSize) {
      this.totalPages = Math.ceil(this.total / this.pageSize);

      if (this.currentPage > this.totalPages)
        this.currentPage = this.totalPages;
      if (this.currentPage === 1 && this.pagingUpdatedInfo.currentPage) {
        this.currentPage = this.pagingUpdatedInfo.currentPage;
      }
    } else {
      this.totalPages = 1;
      this.currentPage = 1;
    }

    this.setOffset();
  }

  private setPaging() {
    // If set already (from url), do not want to override.
    if (!this.pagingIniting) {
      this.setTotal();
      this.updatePaging();
    }
  }

  /**
   * The `offset` helps for querying the records of a table.
   * It's based upon the current page and the page size:
   *  ( e.g. `currentPage = 0` & `pageSize = 25`; `offset = 0`;
   *  | e.g. `currentPage = 1` & `pageSize = 25`; `offset = 25`;
   *  | e.g. `currentPage = 2` & `pageSize = 25`; `offset = 50`; )
   * Whenever the offset changes update the paging as well.
   */
  private setOffset() {
    const previousOffset = this.offset;
    const newOffset = (this.currentPage - 1) * this.pageSize;

    if (newOffset !== previousOffset) {
      this.offset = newOffset;
      this.updatePaging();
    }
  }

  private resetPagingIniting() {
    this.pagingIniting = false;
  }

  prevPage() {
    this.resetPagingIniting();
    if (this.currentPage > 1) {
      this.currentPage--;
      this.setOffset();
      this.query();
    }
  }

  nextPage() {
    this.resetPagingIniting();
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.setOffset();
      this.query();
    }
  }

  changePageKeyup(evt: any) {
    if (evt.keyCode === this.keycodes.enter) {
      this.changePage(evt);
    }
  }

  changePage(evt: any) {
    this.resetPagingIniting();
    if (this.currentPage >= 1 && this.currentPage <= this.totalPages) {
      this.setPaging();
      this.setOffset();
      this.query();
    } else {
      if (this.currentPage < 1) {
        this.currentPage = 1;
      } else if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages;
      }
      this.setOffset();
    }
  }

  changePageSize() {
    this.resetPagingIniting();
    this.setPaging();
    this.query();
  }

  /**
   * Update the pagination only when:
   *  the component ain't initialized means when `ngOnInit()` is doing its work,
   *  or when the page size or the current page was changed by the user.
   * The validation restricts overcalling since there are some subscribers binded to this update.
   * If the page upsated then update the URL as well;
   *   since there are scenarios (like filtering) where even though the footer updates the URL doesn't.
   */
  private updatePaging() {
    const pageSizeChanged = this.pageSize !== this.plTableFramework.pageSize;
    const currentPageChanged =
      this.currentPage !== this.plTableFramework.currentPage;
    const shouldUpdatePage =
      !this.isComponentInitialized || pageSizeChanged || currentPageChanged;

    if (shouldUpdatePage) {
      this.plTableFramework.updatePaging(this.pageSize, this.currentPage);
      const query = this.plTableFramework.formQuery(); // Query must be formed after the page update.
      const stateName = this.plTableWrapperComp.stateName;
      const queryParams = this.plTableFramework.getQueryParams(query);
      this.plTableFrameworkUrlSvc.updateUrl(stateName, queryParams);
      this.onPageChange.emit({
        currentPage: this.currentPage,
        offset: this.offset,
        pageSize: this.pageSize,
      });
    }
  }

  private query() {
    this.updatePaging();

    const data = {
      pageSize: this.pageSize,
      currentPage: this.currentPage,
      offset: this.offset,
    };
    const query = this.plTableFramework.formQuery();
    const queryInfo = { data: data, query: query };

    if (this.onQuery.observers.length) this.onQuery.emit(queryInfo);
    // TODO: Check if below could be skipped when `plTableFramework.initialLoadQuery` is already called.
    this.plTableFramework.updateQuery(queryInfo);
  }
}
