import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { AsyncStatus } from '@root/src/types';

export interface AsyncState<Data> {
  status: AsyncStatus;
  data?: Data;
  error?: any;
}

export class AsyncDataService<Data = unknown> {
  private state$ = new BehaviorSubject<AsyncState<Data>>({ status: 'idle' });

  readonly isIdle$: Observable<boolean>;

  readonly isLoading$: Observable<boolean>;

  readonly hasError$: Observable<boolean>;

  readonly hasData$: Observable<boolean>;

  readonly data$: Observable<Data>;

  readonly error$: Observable<any>;

  protected keepPreviousData = false;

  protected get data() {
    return this.state$.value.data;
  }

  constructor() {
    this.isIdle$ = this.selectProperty(({ status }) => status === 'idle');
    this.isLoading$ = this.selectProperty(({ status }) => status === 'loading');
    this.hasError$ = this.selectProperty(({ status }) => status === 'error');
    this.hasData$ = this.selectProperty(({ status }) => status === 'success');
    this.data$ = this.selectProperty(state => state?.data);
    this.error$ = this.selectProperty(state => state?.error);
  }

  protected setIdle() {
    this.setState({
      status: 'idle',
    });
  }

  protected setLoading() {
    this.setState({
      status: 'loading',
    });
  }

  protected setData(data: Data) {
    this.setState({
      status: 'success',
      data,
    });
  }

  protected setError(error?: any) {
    this.setState({
      status: 'error',
      error,
    });
  }

  protected setState(newState: AsyncState<Data>) {
    const prevState = this.state$.value;
    if (this.keepPreviousData) {
      this.state$.next({ ...prevState, ...newState });
    } else {
      this.state$.next({ ...newState });
    }
  }

  private selectProperty<R>(mapFn: (value: AsyncState<Data>) => R) {
    return this.state$.pipe(
      map(state => mapFn(state)),
      distinctUntilChanged(),
    );
  }
}
