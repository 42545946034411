import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import {
  PLDotLoaderModule,
  PLIconModule,
  PLInputModule,
  PLModalModule,
} from '@root/index';
import { PLButtonModule } from '@root/src/lib-components';
import { PLDashboardComponent } from './pl-dashboard/pl-dashboard.component';
import { PLOnboardingTasksComponent } from './pl-dashboard/pl-onboarding-tasks/pl-onboarding-tasks.component';
import { PlLandingPageComponent } from './pl-landing-page/landing-page/pl-landing-page.component';
import { PlClinicalContactsCardComponent } from './pl-landing-page/pl-clinical-contacts-card/pl-clinical-contacts-card.component';
import { PlDedicatedServiceHoursCardComponent } from './pl-landing-page/pl-dedicated-service-hours-card/pl-dedicated-service-hours-card.component';
import { PlQuickLinksCardComponent } from './pl-landing-page/pl-quick-links-card/pl-quick-links-card.component';
import { PlRoomCardComponent } from './pl-landing-page/pl-room-card/pl-room-card.component';
import { PlScheduleCardComponent } from './pl-landing-page/pl-schedule-card/pl-schedule-card.component';
import { PlTaskCardComponent } from './pl-landing-page/pl-task-card/pl-task-card.component';
import { PLFTEHoursModule } from '../fte/fte.module';
// Services
import { PLOrganizationsService } from '../organizations/pl-organizations.service';
// Components

import { PLTimesheetProgressBarModule } from '../schedule/components/pl-timesheet-progress-bar/pl-timesheet-progress-bar.module';
import { PLTimesheetProgressAssignmentFilterService } from '../schedule/components/pl-timesheet-progress-bar/services/pl-timesheet-progress-assignment-filter.service';
import { PLProviderOnboardingV2Component } from '../v2-provider-onboarding/pl-provider-onboarding-v2/pl-provider-onboarding-v2.component';
// Directives

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    PLIconModule,
    PLInputModule,
    PLDotLoaderModule,
    PLFTEHoursModule,
    PLModalModule,
    PLButtonModule,
    MatTooltipModule,
    MatSelectModule,
    PLTimesheetProgressBarModule,
    MatTabsModule,
    MatSelectModule,
    PLTimesheetProgressBarModule,
  ],
  declarations: [
    PLDashboardComponent,
    PLOnboardingTasksComponent,
    PLProviderOnboardingV2Component,
    PlLandingPageComponent,
    PlTaskCardComponent,
    PlRoomCardComponent,
    PlScheduleCardComponent,
    PlDedicatedServiceHoursCardComponent,
    PlQuickLinksCardComponent,
    PlClinicalContactsCardComponent,
  ],
  exports: [
    PLDashboardComponent,
    PLProviderOnboardingV2Component,
    PlLandingPageComponent,
    PlTaskCardComponent,
    PlRoomCardComponent,
    PlScheduleCardComponent,
    PlDedicatedServiceHoursCardComponent,
    PlQuickLinksCardComponent,
    PlClinicalContactsCardComponent,
  ],
  providers: [
    PLOrganizationsService,
    PLTimesheetProgressAssignmentFilterService,
  ],
})
export class PLDashboardModule {}
