<div class="card pl-provider-details-assignment-preferences">
  <div class="header">
    <h3>Assignment preferences</h3>
    <span class="last-updated">Last updated October 1, 2023</span>
  </div>

  <table *ngIf="provider">
    <tbody>
      <tr *ngIf="assignmentType" class="assignment-type-preference">
        <th scope="row">Assignment type</th>
        <td>{{ assignmentType }}</td>
      </tr>
      <tr
        *ngIf="
          provider.education_level_preferences &&
          provider.education_level_preferences.length
        "
      >
        <th
          [attr.rowspan]="provider.education_level_preferences.length + 1"
          scope="row"
        >
          Grade level
        </th>
      </tr>
      <tr
        class="education-level-preference"
        *ngFor="let preference of provider.education_level_preferences"
      >
        <td>{{ preference.name }}</td>
      </tr>
      <tr
        *ngIf="
          provider.support_level_preferences &&
          provider.support_level_preferences.length
        "
      >
        <th
          [attr.rowspan]="provider.support_level_preferences.length + 1"
          scope="row"
        >
          Severity of needs
        </th>
      </tr>
      <tr
        class="support-level-preference"
        *ngFor="let preference of provider.support_level_preferences"
      >
        <td>{{ preference.name }}</td>
      </tr>
      <tr
        *ngIf="
          provider.school_type_preferences &&
          provider.school_type_preferences.length
        "
      >
        <th
          [attr.rowspan]="provider.school_type_preferences.length + 1"
          scope="row"
        >
          School type
        </th>
      </tr>
      <tr
        class="school-type-preference"
        *ngFor="let preference of provider.school_type_preferences"
      >
        <td>{{ preference.name }}</td>
      </tr>
    </tbody>
  </table>
</div>
