import { Component, Input, OnInit } from '@angular/core';
import { PLUrlsService } from '@root/src/lib-components';

@Component({
  selector: 'pl-provider-details-room-details',
  templateUrl: './pl-provider-details-room-details.component.html',
  styleUrls: [
    './pl-provider-details.common.less',
    './pl-provider-details-room-details.component.less',
  ],
})
export class PLProviderDetailsRoomDetailsComponent implements OnInit {
  @Input() provider: any; // replace 'any' with the actual type
  @Input() userType: string;
  @Input() currentUserEnabledUiFlags: string[];
  urls: any = {};
  mayViewRoom: boolean = false;
  mayObserve: boolean = false;

  constructor(private plUrls: PLUrlsService) {}

  ngOnInit() {
    this.setURLs();
  }

  setURLs() {
    this.mayViewRoom =
      this.currentUserEnabledUiFlags &&
      this.currentUserEnabledUiFlags.includes('room-view-room-url');
    this.mayObserve =
      this.currentUserEnabledUiFlags &&
      this.currentUserEnabledUiFlags.includes('room-observe-session');
  }
}
