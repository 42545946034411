import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest, EMPTY } from 'rxjs';
import { catchError, shareReplay, startWith, switchMap } from 'rxjs/operators';
import {
  TimesheetProgress,
  TimesheetProgressDataService,
  TimesheetProgressFilter,
} from '@common/billing/services';
import { ScheduleEffects } from '../../../store/schedule';

@Injectable({ providedIn: 'root' })
export class PLTimesheetProgressBarService {
  progressData$: Observable<TimesheetProgress>;

  private filter$ = new BehaviorSubject<TimesheetProgressFilter>({});

  constructor(
    private progressService: TimesheetProgressDataService,
    private scheduleEffects: ScheduleEffects,
  ) {
    this.progressData$ = this.getProgressData();
  }

  filter(newFilter: TimesheetProgressFilter) {
    this.filter$.next({
      ...this.filter$.value,
      ...newFilter,
    });
  }

  private getProgressData() {
    return combineLatest([
      this.filter$,
      this.scheduleEffects.scheduleUpdated$.pipe(startWith({})),
    ]).pipe(
      switchMap(([filter]) =>
        this.progressService.getTimesheetProgress(filter).pipe(
          catchError(error => {
            console.warn('[Timesheet Progress Error]', error);
            return EMPTY;
          }),
        ),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
