import { Component, Input } from '@angular/core';

import { Store } from '@ngrx/store';

import dayjs from 'dayjs';
import { orderBy } from 'lodash';

import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { AppStore } from '@app/appstore.model';

import {
  EMPLOYMENT_STATUS,
  PROVIDER_ASSIGNMENT_TYPE_OPTIONS,
} from '@common/constants';
import { PLProviderAssignmentTypes, PLProviderTypeCode } from '@common/enums';
import { PLProviderProfileService } from '@common/services';
import { selectCurrentUser, selectCurrentUserLoaded } from '@common/store';
import { User } from '@modules/user/user.model';
import {
  PLAssumeLoginService,
  PLGraphQLService,
  PLHttpService,
  PLMayService,
  PLModalService,
  PLToastService,
  PLUrlsService,
} from '@root/index';
import {
  FeatureFlagName,
  FeatureFlagsService,
} from '@root/src/app/common/feature-flags';
import { PLProviderAreasOfSpecialtyComponent } from './pl-provider-areas-of-specialty.component';
import { PLProviderAssignmentTypeComponent } from './pl-provider-assignment-type.component';
import { PLProviderLanguagesComponent } from './pl-provider-languages.component';
import { PLProviderNotificationPreferencesComponent } from './pl-provider-notification-preferences.component';
import { PLProviderService } from '../pl-provider.service';

const providerTasksQuery = require('./../queries/provider-tasks.graphql');

@Component({
  selector: 'pl-provider-account-settings',
  templateUrl: './pl-provider-account-settings.component.html',
  styleUrls: [
    '../../../common/less/app/card-section.less',
    './pl-provider-account-settings.component.less',
  ],
})
export class PLProviderAccountSettingsComponent {
  @Input() provider: any = {};

  agreements: any[] = [];

  user: User;
  urls: any = {};

  mayGenerateRoom = false;
  mayResetWhiteboard = false;
  mayViewSchedule = false;
  mayViewRoom = false;
  mayObserve = false;
  mayViewProvider = false;
  mayViewPersonnel = false;

  isSelfProvider = false;
  isAdmin = false;

  onboardingTasks: any[] = [];

  rates = {};

  showTips = {
    status: false,
  };
  showAgreements = true;

  isPAProvider = false;

  assignmentTypeLabel: string = '';

  assignmentTypeOptions = PROVIDER_ASSIGNMENT_TYPE_OPTIONS;

  mayViewRates = false;
  mayViewPayRatesTable = false;

  private loading: any = {
    newRoom: false,
    resetWhiteboard: false,
  };

  private newRoomConfirmVisible = false;

  private subscription: Subscription = new Subscription();
  PROVIDER_RATES_LINK: string;

  constructor(
    private plHttp: PLHttpService,
    private store: Store<AppStore>,
    private plUrls: PLUrlsService,
    private plToast: PLToastService,
    private plMay: PLMayService,
    private plProvider: PLProviderService,
    private plGraphQL: PLGraphQLService,
    private assumeLoginService: PLAssumeLoginService,
    private plModal: PLModalService,
    private toastr: ToastrService,
    private plProviderProfileService: PLProviderProfileService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    this.subscription.add(
      this.featureFlagsService
        .isFeatureEnabled(FeatureFlagName.payRatesFY24)
        .subscribe(payRatesFY24Enabled => {
          this.mayViewPayRatesTable = payRatesFY24Enabled;
        }),
    );
  }

  ngOnInit() {
    this.provider = {};

    this.subscription.add(
      this.store
        .select(selectCurrentUserLoaded)
        .pipe(
          filter(loadedUser => loadedUser),
          switchMap(() =>
            forkJoin([this.store.select(selectCurrentUser).pipe(first())]),
          ),
        )
        .subscribe(([user]: [User]) => {
          this.PROVIDER_RATES_LINK = `/provider/${user.uuid}/provider-pay-rates`;
          this.user = user;
          this.checkPrivileges(); // TODO: this is repeated in the `getFromRoute`
          this.getAgreements(); // TODO: this is repeated in the `getFromRoute`
        }),
    );

    this.subscription.add(
      this.plProvider.getFromRoute().subscribe((res: any) => {
        // Provider may be old, so check and compare id first.
        const url = window.location.href;
        if (
          url.includes(res.provider.user.id) &&
          this.provider.id !== res.provider.id
        ) {
          this.provider = res.provider;
          this.isPAProvider = res.provider?.providerTypes.find(
            ({ code }) => code === PLProviderTypeCode.PA,
          );
          this.assignmentTypeLabel = this.getAssignmentTypePreferenceLabel(
            res.provider?.assignmentTypePreference,
          );
          this.formProviderInfo();
          this.checkPrivileges(); // TODO: this is repeated in the `selectCurrentUser`
          this.getOnboardingTasks(); // TODO: this is repeated in the `selectCurrentUser`
          this.getAgreements();
          this.setNotificationPreferences();
        }
      }),
    );

    this.setUrls();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setUrls() {
    this.urls.room = this.plUrls.urls.roomFE;
    this.urls.schedule = this.plUrls.urls.scheduleFE;
  }

  checkPrivileges() {
    this.mayViewPersonnel = false;

    if (this.user && this.user.uuid) {
      this.isAdmin = this.plMay.isAdminType(this.user);
      this.mayGenerateRoom =
        this.isAdmin || this.plMay.generateNewRoom(this.user);
      this.mayResetWhiteboard =
        this.isAdmin || this.plMay.resetRoomWhiteboard(this.user);
      this.mayObserve =
        this.user.xEnabledUiFlags &&
        this.user.xEnabledUiFlags.includes('room-observe-session');
      this.mayViewRoom =
        this.user.xEnabledUiFlags &&
        this.user.xEnabledUiFlags.includes('room-view-room-url');
      this.mayViewPersonnel =
        this.user.xGlobalPermissions &&
        this.user.xGlobalPermissions.viewPersonnel
          ? true
          : false;
    }
    if (this.provider && this.provider.user) {
      this.mayViewSchedule = this.plMay.viewSchedule(this.provider);
    }
    // Reset.
    this.isSelfProvider = false;
    if (this.provider && this.provider.user && this.user) {
      const isAdminType: boolean = this.plMay.isAdminType(this.user);
      const hasViewPersonnelPermission: boolean =
        this.user.xGlobalPermissions &&
        this.user.xGlobalPermissions.viewPersonnel;

      this.isSelfProvider = this.provider.user.id === this.user.uuid;
      this.mayViewProvider =
        this.isSelfProvider || isAdminType || hasViewPersonnelPermission;
      this.mayViewRates =
        (this.isSelfProvider &&
          this.provider?.employmentStatus !==
            EMPLOYMENT_STATUS.SALARIED_EMPLOYEE) ||
        isAdminType ||
        hasViewPersonnelPermission;
    }
  }

  resetWhiteboard() {
    this.loading.resetWhiteboard = true;
    this.plHttp
      .put('roomResetWhiteboard', { user_uuid: this.provider.user.id })
      .subscribe(
        (res: any) => {
          this.plToast.show('success', 'Whiteboard reset.', 2000, true);
          this.loading.resetWhiteboard = false;
        },
        (err: any) => {
          this.loading.resetWhiteboard = false;
        },
      );
  }

  toggleNewRoomConfirm() {
    this.newRoomConfirmVisible = !this.newRoomConfirmVisible;
  }

  newRoom() {
    this.loading.newRoom = true;
    this.plHttp.save('room', { user_uuid: this.provider.user.id }).subscribe(
      (res: any) => {
        this.plToast.show('success', 'New room generated.', 2000, true);
        this.loading.newRoom = false;
        this.toggleNewRoomConfirm();
      },
      (err: any) => {
        this.loading.newRoom = false;
        this.toggleNewRoomConfirm();
      },
    );
  }

  formProviderInfo() {
    const provider = { ...this.provider };
    provider.xMailing = this.provider.billingAddress.street
      ? `${provider.billingAddress.street},
            ${provider.billingAddress.city},
            ${provider.billingAddress.state},
            ${provider.billingAddress.postalCode}`
      : `${provider.billingAddress.state}`;

    if (provider.areasOfSpecialty) {
      provider.areasOfSpecialty = provider.areasOfSpecialty.sort(
        (a: any, b: any) => a.name.localeCompare(b.name),
      );
    }

    if (provider.languages) {
      provider.languages = provider.languages.sort((a: any, b: any) =>
        a.name.localeCompare(b.name),
      );
    }

    provider.x = {};
    const dateFields = [
      'readyForPlacementDate',
      'firstSession',
      'lastSession',
      'separationDate',
      'techCheckCompletionDate',
    ];
    dateFields.forEach(field => {
      provider.x[field] =
        provider[field] !== null
          ? dayjs(this.provider[field]).format('M/D/YYYY')
          : '';
    });
    this.provider = provider;
  }

  getOnboardingTasks() {
    const vars = {
      id: this.provider.user.id,
      code: 'onboarding',
    };
    this.plGraphQL
      .query(providerTasksQuery, vars, {})
      .pipe(first())
      .subscribe((res: any) => {
        this.onboardingTasks = this.formatTasks(res.providerTasks);
      });
  }

  formatTasks(tasks: any[]) {
    let onboardingMap = {
      'onboarding-npin': {
        order: 10,
        name: 'Submitted NPI number',
      },
      'onboarding-w9-tax-form': {
        order: 20,
        name: 'Submitted tax information',
      },
      // "onboarding-contractor-status": 3,
      'onboarding-liability-insurance': {
        order: 40,
        name: 'Submitted professional liability insurance information',
      },
      'onboarding-driver-license': {
        order: 50,
        name: 'Submitted driver license',
      },
      'onboarding-orientation': {
        order: 1,
        name: 'Completed provider onboarding orientation',
      },
      'onboarding-provider-lounge': {
        order: 70,
        name: 'Visited provider lounge',
      },
      'onboarding-telehealth-institute-prime': {
        order: 5,
        name: 'Completed Telehealth Institute onboarding courses',
      },
      'onboarding-assignment-readiness-check': {
        order: 7,
        name: 'Completed assignment readiness check',
      },
      'onboarding-demographic-survey': {
        order: 101,
        name: 'Completed demographic survey',
      },
      // 'onboarding-best-practices': 11,
    };

    const onboardingTasks: any[] = [
      // Fake an onboarding wizard complete task.
      {
        id: 'wizardDone',
        xSort: 0,
        xIsComplete: this.provider.isOnboardingWizardComplete,
        xName: 'Completed onboarding wizard',
      },
    ];
    tasks.forEach((task: any) => {
      if (task.taskType.code.includes('onboarding-')) {
        // Get owner for this user id since isComplete is in there.
        let isComplete = false;
        for (let ii = 0; ii < task.owners.length; ii++) {
          if (task.owners[ii].user.id === this.provider.user.id) {
            isComplete = task.owners[ii].isComplete;
            break;
          }
        }
        onboardingTasks.push(
          Object.assign(task, {
            xSort: onboardingMap[task.taskType.code].order || 100,
            xIsComplete: isComplete,
            xName: onboardingMap[task.taskType.code].name,
          }),
        );
      }
    });
    return orderBy(onboardingTasks, ['xSort'], ['asc']);
  }

  getAgreements() {
    if (
      this.provider &&
      this.provider.user &&
      (this.isSelfProvider ||
        (this.user.xGlobalPermissions &&
          this.user.xGlobalPermissions.viewPersonnel))
    ) {
      this.showAgreements = true;
      const params = {
        provider_uuid: this.provider.user.id,
      };
      this.plHttp
        .get('fountainProviderSignedAgreements', params)
        .subscribe((foutainRes: any) => {
          const todayDate = new Date();
          if (todayDate.getMonth() < 6) {
            todayDate.setFullYear(todayDate.getFullYear() - 1);
          }
          const asOfDate = `${todayDate.getFullYear()}-07-01`;
          const fountainAgreements = foutainRes.map((agreement: any) => {
            return {
              code: agreement.template_title,
              agreed_on: null,
              as_of_date: asOfDate,
              end_date: null,
              document_url: agreement.file_url,
            };
          });
          this.plHttp
            .get('providerAgreements', params)
            .subscribe((res: any) => {
              this.plHttp
                .get('preagreementW2sList', params)
                .subscribe((res2: any) => {
                  res2.forEach((agreement: any) => {
                    agreement.document_url = agreement.document_url.replace(
                      '/sign',
                      '/status',
                    );
                  });
                  this.agreements = this.formatAgreements(
                    fountainAgreements.concat(res).concat(res2),
                  );
                });
            });
        });
    } else {
      this.showAgreements = false;
    }
  }

  formatAgreements(agreements: any[]) {
    const dateFields = ['as_of_date', 'end_date', 'agreed_on'];
    agreements.forEach((agreement: any) => {
      agreement.x = {};
      dateFields.forEach(field => {
        if (agreement[field]) {
          agreement.x[field] =
            agreement[field] !== null && agreement[field] !== undefined
              ? dayjs(agreement[field], 'YYYY-MM-DD').format('M/D/YYYY')
              : '';
        }
      });
    });
    return agreements.sort((a: any, b: any) =>
      b.agreed_on?.localeCompare(a.agreed_on),
    );
  }

  assumeUser() {
    this.assumeLoginService.assume(this.provider.email);
  }

  toggleShowTips(key: string) {
    this.showTips[key] = !this.showTips[key];
  }

  onEditLanguages() {
    let modalRef: any;

    const params: any = {
      userId: this.provider.user.id,
      selectedLanguages: this.provider.languages,
      onSave: (languages: any) => {
        this.provider.languages = languages;
        this.toastr.success('Languages saved!', 'Complete', {
          positionClass: 'toast-bottom-right',
        });
        modalRef.instance.destroy();
      },
    };

    this.plModal
      .create(PLProviderLanguagesComponent, params)
      .subscribe((ref: any) => {
        modalRef = ref;
      });
  }

  onEditAreasOfSpecialty() {
    let modalRef: any;

    const params: any = {
      userId: this.provider.user.id,
      selectedAreas: this.provider.areasOfSpecialty,
      onSave: (areas: any) => {
        this.provider.areasOfSpecialty = areas;
        this.toastr.success('Areas of Specialty saved!', 'Complete', {
          positionClass: 'toast-bottom-right',
        });

        modalRef.instance.destroy();
      },
    };

    this.plModal
      .create(PLProviderAreasOfSpecialtyComponent, params)
      .subscribe((ref: any) => {
        modalRef = ref;
      });
  }

  setNotificationPreferences() {
    // Email notifications will be always on for all providers
    if (!this.provider.notificationPreference.includes('EMAIL')) {
      this.provider.notificationPreference = [
        'EMAIL',
        ...this.provider.notificationPreference,
      ];
    }
    this.provider.notificationPreference =
      this.provider.notificationPreference.filter((n: string) => n.length);
  }

  getNotificationPreferencesText(notificationType: string) {
    const notification = this.plProviderProfileService
      .getNotificationPreferences()
      .find(n => notificationType === n.value);
    return notification && notification.label;
  }

  onEditNotificationPreferences() {
    let modalRef: any;

    const params: any = {
      userId: this.provider.user.id,
      phoneNumber: this.provider.phone,
      selectedNotificationPreferences: this.provider.notificationPreference,
      onSave: (notifications: any) => {
        this.provider.notificationPreference = notifications;
        this.toastr.success('Notification Preferences saved!', 'Complete', {
          positionClass: 'toast-bottom-right',
        });

        modalRef.instance.destroy();
      },
    };

    this.plModal
      .create(PLProviderNotificationPreferencesComponent, params)
      .subscribe((ref: any) => {
        modalRef = ref;
      });
  }

  onEditAssignmentTypePreference(): void {
    let modalRef: any;

    const params: any = {
      userId: this.provider.user.id,
      selectedAssignmentType: this.provider.assignmentTypePreference,
      onSave: (assignmentTypePreference: PLProviderAssignmentTypes) => {
        this.provider.assignmentTypePreference = assignmentTypePreference;
        this.toastr.success('Assignment Type Preference saved!', 'Complete', {
          positionClass: 'toast-bottom-right',
        });
        this.assignmentTypeLabel = this.getAssignmentTypePreferenceLabel(
          assignmentTypePreference,
        );

        modalRef.instance.destroy();
      },
    };

    this.plModal
      .create(PLProviderAssignmentTypeComponent, params)
      .subscribe((ref: any) => {
        modalRef = ref;
      });
  }

  getAssignmentTypePreferenceLabel(
    assignmentTypePreference: PLProviderAssignmentTypes,
  ): string {
    let assignmentTypeLabel: string = '';

    if (assignmentTypePreference) {
      const { label } = this.assignmentTypeOptions.find(
        ({ id }) => id === assignmentTypePreference,
      );

      assignmentTypeLabel = label;
    }

    return assignmentTypeLabel;
  }

  onQuery(info: { data: any }) {
    this.agreements.sort((a: any, b: any) => {
      let itemA;
      let itemB;
      switch (info.data.orderKey) {
        case 'agreed_on':
          itemA = a.agreed_on;
          itemB = b.agreed_on;
          break;
        case 'code':
          itemA = a.code;
          itemB = b.code;
          break;
        case 'as_of_date':
          itemA = a.as_of_date;
          itemB = b.as_of_date;
          break;
        default:
          return;
      }
      return info.data.orderDirection === 'descending'
        ? itemB.localeCompare(itemA)
        : itemA.localeCompare(itemB);
    });
  }
}
