import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PLGraphQLService, PLTimezoneService } from '@root/index';

import {
  REFERRAL_REASON,
  REFERRAL_REASON_LABEL,
} from '@root/src/app/common/constants';
import { REFERRALS_DECLINE_HISTORY_GQL } from '../queries/referrals-decline-history.graphql';

export interface ReferralDeclineHistory {
  referralCreatedOn: string;
  providerFirstName: string;
  providerLastName: string;
  declineReason: string;
  unmatchedBy: string;
}

export interface ReferralDeclineHistoryGQLResponse {
  referral: {
    declineHistory: {
      created: string;
      provider: {
        lastName: string;
        firstName: string;
      };
      reason: string;
      state: string;
    }[];
  };
}

@Injectable()
export class PLReferralCyclesModalService {
  private declineReason = {
    [REFERRAL_REASON.SCHEDULING_CONFLICT]:
      REFERRAL_REASON_LABEL.SCHEDULING_CONFLICT,
    [REFERRAL_REASON.CLINICAL_REASON]: REFERRAL_REASON_LABEL.CLINICAL_REASON,
    [REFERRAL_REASON.GROUPING]: REFERRAL_REASON_LABEL.GROUPING,
    [REFERRAL_REASON.LANGUAGE]: REFERRAL_REASON_LABEL.LANGUAGE,
    [REFERRAL_REASON.DUPLICATE_REFERRAL]:
      REFERRAL_REASON_LABEL.DUPLICATE_REFERRAL,
    [REFERRAL_REASON.DUPLICATE_OF_SERVICE_IN_PROGRESS]:
      REFERRAL_REASON_LABEL.DUPLICATE_OF_SERVICE_IN_PROGRESS,
    [REFERRAL_REASON.INCORRECT_REFERRAL]:
      REFERRAL_REASON_LABEL.INCORRECT_REFERRAL,
  };

  private gql = {
    referralDeclineHistory: REFERRALS_DECLINE_HISTORY_GQL,
  };

  constructor(
    private plGraphQL: PLGraphQLService,
    private plTimezoneSvc: PLTimezoneService,
  ) {}

  getReferralDeclineHistory(
    referralId: string,
  ): Observable<ReferralDeclineHistory[]> {
    return this.plGraphQL
      .query(this.gql.referralDeclineHistory, { id: referralId })
      .pipe(
        map(({ referral }: ReferralDeclineHistoryGQLResponse) => {
          const declineHistory = (referral && referral.declineHistory) || [];

          return declineHistory.map(decline => {
            const splitReferralCreated = this.plTimezoneSvc
              .toUTC(decline.created, 'YYYY/MM/DD')
              .split('/');
            const referralCreated = `${splitReferralCreated[1]}/${splitReferralCreated[2]}/${splitReferralCreated[0]}`;
            const unmatched =
              decline.state === 'RETRACTED'
                ? 'Empowerment Manager'
                : 'Provider';

            return {
              referralCreatedOn: referralCreated,
              providerFirstName: decline.provider.firstName,
              providerLastName: decline.provider.lastName,
              declineReason: this.declineReason[decline.reason],
              unmatchedBy: unmatched,
            };
          });
        }),
      );
  }
}
