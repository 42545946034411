import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { forEach } from 'lodash';
import { forkJoin } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { AssignmentProposal } from '@common/assigment-machine/models';
import { PLProviderTypeCode } from '@root/src/app/common/enums';
import { PlClinicalContactsManager } from '@root/src/app/common/interfaces/pl-clinical-contacts-manager';
import { PLAssignmentStatusEnum } from '../../../assignment-manager/pl-assignment-manager.model';
import { PLAssignmentManagerService } from '../../../assignment-manager/pl-assignment-manager.service';
import { PLProviderService } from '../../../providers/pl-provider.service';

@Component({
  selector: 'pl-clinical-contacts-card',
  templateUrl: './pl-clinical-contacts-card.component.html',
  styleUrls: ['./pl-clinical-contacts-card.component.less'],
})
export class PlClinicalContactsCardComponent implements OnInit {
  @Input() currentUserId: string;
  @Output() hasclinicalContacts: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  empowermentManager = {} as PlClinicalContactsManager;
  clinicalSuccessManagersList: PlClinicalContactsManager[] = [];
  assigmentStatusEnum = PLAssignmentStatusEnum;
  showClinicalSuccessManagersList = false;
  isSltOptProvider = false;
  isMchProvider = false;
  constructor(
    private plProviderService: PLProviderService,
    private plAssignmentManagerService: PLAssignmentManagerService,
  ) {}

  ngOnInit(): void {
    forkJoin([
      this.plProviderService.getProvider(this.currentUserId).pipe(first()),
      this.plAssignmentManagerService.fetchAssignmentProposals().pipe(first()),
    ]).subscribe(([providerResponse, proposalsResponse]: [any, any]) => {
      // set empowerment manager
      this.hasclinicalContacts.emit(true);
      this.empowermentManager.name = `${providerResponse.provider.accountOwner.firstName} ${providerResponse.provider.accountOwner.lastName}`;
      this.empowermentManager.initials = `${providerResponse.provider.accountOwner.firstName[0]}${providerResponse.provider.accountOwner.lastName[0]}`;
      this.empowermentManager.email =
        providerResponse.provider.accountOwner.email;

      // set provider type
      this.setProviderType(providerResponse.provider.providerTypes[0].code);

      let visibleProposals = proposalsResponse.filter(
        proposal =>
          proposal.status === PLAssignmentStatusEnum.ACTIVE ||
          proposal.status === PLAssignmentStatusEnum.INITIATED ||
          (proposal.status === PLAssignmentStatusEnum.RESERVED &&
            proposal.is_recommitment),
      );

      this.setClinicalSuccessManagersList(visibleProposals);
    });
  }
  public setClinicalSuccessManagersList(
    visibleProposals: AssignmentProposal[],
  ) {
    console.log('visibleProposals', visibleProposals);
    let managerMap = new Map();
    let managers: PlClinicalContactsManager[] = [];

    forEach(visibleProposals, (proposal: AssignmentProposal) => {
      let manager;
      if (
        proposal.organization.clinical_success_manager_slp_ot &&
        this.isSltOptProvider
      ) {
        manager = {
          name: proposal.organization.clinical_success_manager_slp_ot,
          email: proposal.organization.clinical_success_manager_slp_ot_email,
          initials: this.getCustomerSuccessManagerInitials(
            proposal.organization.clinical_success_manager_slp_ot,
          ),
          organizationName: proposal.organization.name,
        };

        if (managerMap.has(manager.name)) {
          let existingManager = managerMap.get(manager.name);
          if (
            !existingManager.organizationName.includes(manager.organizationName)
          ) {
            existingManager.organizationName += ', ' + manager.organizationName;
          }
        } else {
          managerMap.set(manager.name, manager);
          managers.push(manager);
        }
      }

      if (
        proposal.organization.clinical_success_manager_pes_mhc &&
        this.isMchProvider
      ) {
        manager = {
          name: proposal.organization.clinical_success_manager_pes_mhc,
          email: proposal.organization.clinical_success_manager_pes_mhc_email,
          initials: this.getCustomerSuccessManagerInitials(
            proposal.organization.clinical_success_manager_pes_mhc,
          ),
          organizationName: proposal.organization.name,
        };
        if (managerMap.has(manager.name)) {
          let existingManager = managerMap.get(manager.name);
          if (
            !existingManager.organizationName.includes(manager.organizationName)
          ) {
            existingManager.organizationName += ', ' + manager.organizationName;
          }
        } else {
          managerMap.set(manager.name, manager);
          managers.push(manager);
        }
      }
    });

    this.clinicalSuccessManagersList = managers;

    if (
      this.clinicalSuccessManagersList.length >= 1 &&
      this.clinicalSuccessManagersList.length <= 3
    ) {
      this.showClinicalSuccessManagersList = true;
    }
  }

  public setProviderType(providerType: any): void {
    const sltOptProviderTypes = [
      PLProviderTypeCode.SLP,
      PLProviderTypeCode.OT,
      PLProviderTypeCode.APE,
      PLProviderTypeCode.PT,
    ];
    const mchProviderTypes = [
      PLProviderTypeCode.MHP,
      PLProviderTypeCode.PA,
      PLProviderTypeCode.EDDIAG,
    ];

    this.isSltOptProvider = sltOptProviderTypes.includes(providerType);
    this.isMchProvider = mchProviderTypes.includes(providerType);
  }

  public getCustomerSuccessManagerInitials(customerSuccesManagerName): string {
    const words = customerSuccesManagerName.trim().split(' ');

    if (words.length === 1) {
      return words[0][0].toUpperCase();
    }

    return `${words[0][0]}${words[1][0]}`.toUpperCase();
  }
}
